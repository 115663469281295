(function() {

	var admin_path = window.location.pathname.replace(/\/(index.html)?$/, ''),
		base_path = admin_path.replace(/(.+)?\/.*?$/, '$1/'),
		host = window.location.protocol + '//' + window.location.host,

	has = {
		localstorage: 'localStorage' in window && window.localStorage !== null
	},

	config = {

		retina: ('devicePixelRatio' in window && devicePixelRatio > 1) || ('matchMedia' in window && matchMedia("(min-resolution:144dpi)").matches),

		cache: false,

		easing: 'easeInOutQuint',

		paths: {
			admin: host + admin_path,
			base: host + base_path,
			base_relative: base_path,
			store: false
		},

		fallback_previews: {
			image: 'images/no-preview.png',
			video: 'images/no-preview.png'
		},

		plugin_paths: null,

		reserved: ['app','library','upload','site','text','settings','plugins','login','store'],

		uploader: {

			dragging: false,

			dragTimeout: null,

			currentFile: null,

			properties: {
				runtimes				: 'html5,flash',
				flash_swf_url			: 'js/moxie.swf',
				browse_button_hover		: 'hover',
				browse_button_active	: 'active',
				headers: {
					'X-KOKEN-AUTH': 'cookie'
				}
			}

		}

	},

	helpers = {

		parse_tag_input: function(str) {
			str = str.toLowerCase().replace(/[\s,\,]+/g, ' ').replace(/[<>]/g, '');

			var multis = str.match(/"(.*?)"/g),
				tags = [];

			if (multis) {
				$.each(multis, function(i, tag) {
					str = str.replace(tag, '');
					tags.push(tag.replace(/["]/g, ''));
				});
			}

			str = str.replace(/[\s]+/, ' ').trim();
			if (str.length) {
				tags = tags.concat(str.split(' '));
			}
			return tags;
		},

		bool: function(str) {

			return ( str !== null && str.replace(/['"]/g,'') == 'true' ) ? true : false;
		},

		clean: function(str) {

			return str.replace(/['"]/g,'');

		},

		file_ext: function(file) {

			var ext = file.lastIndexOf('.') + 1;

			return ( ext > 0 ) ? file.substr( ext ).replace(/\?.*$/, '') : '';

		},

		capitalize: function(str) {

			return str.charAt(0).toUpperCase() + str.slice(1);

		},

		distinctArray: function(left,right) {

			var filtered;

			filtered = $.grep( left, function(k,v) {
				return right.indexOf(k) >= 0;
			});

			return ( filtered.length === left.length ) ? false : true;

		},

		guid: function() {
			return 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
			    return v.toString(16);
			});
		},

		outside: function(el,cb) {

			$('html').on('click.outside', function(e) {
				$('html').off('.outside');
				if ($(e.target).closest(el).length <= 0) {
					cb.call(el);
				}
			});

		},

		rtrim: function(str,charlist) {

			// http://phpjs.org/functions/rtrim

			charlist = !charlist ? ' \\s\u00A0' : (charlist + '').replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '\\$1');

			var re = new RegExp('[' + charlist + ']+$', 'g');

			return (str + '').replace(re, '');

		},

		strcon: function(haystack,needle) {

			var i, len, ret = false;

			for ( i = 0, len = haystack.length; i < len; i++ ) {

				if ( haystack[i].indexOf(needle) >= 0 ) {

					ret = true;
					break;

				}

			}

			return ret;

		},

		stristr: function(haystack,needle,bool) {

			// http://phpjs.org/functions/stristr

			var pos = 0;

			haystack += '';
			pos = haystack.toLowerCase().indexOf( ( needle + '' ).toLowerCase() );

			if (pos == -1) {

				return false;

			} else {

				if (bool) {

					return haystack.substr(0, pos);

				} else {

					return haystack.slice(pos);

				}

			}

		},

		unique: function(arr) {

			var temp = [];

			$.each( arr, function(k,v) {
				if ( temp.indexOf(v) < 0 ) {
					temp.push(v);
				}
			});

			return temp;

		},

		uniqueObjects: function(arr) {

			var filtered = [];

			$.each( arr, function() {

				var parent	= this,
					dupe	= false;

				if ( filtered.length <= 0 ) { filtered.push(this); return; }

				$.each( filtered, function() {

					var a = JSON.stringify(this),
						b = JSON.stringify(parent);

					if ( a == b ) { dupe = true; return; }

				});

				if (!dupe) { filtered.push(this); }

			});

			return filtered;

		},

		viewport_area: function() {

			if ( $('#mid-content').length > 0 ) {

				var item = $('#content_list > li').first(),
				midOff	= $('#mid-content').offset(),
				midWidth	= $('#mid-content').width(),
				midHeight	= ( $('#mid-menu-bot').offset().top ) - ( midOff.top ),
				col			= Math.floor( midWidth / item.outerWidth(true) ),
				row			= Math.floor( midHeight / item.outerHeight(true) );

				return {
					col		: col,
					row		: row,
					total	: col * ( row + 5 ),
					width	: midWidth,
					height	: midHeight
				};

			}

		}

	},

	framework = {

		/****************************************************************************************************************

			METHOD: K.cache()

			PARAMS: prop (object|string)

			DESCRIPTION:
			K.cache

			EXAMPLE:
			this.cache('<div/>');
			this.cache($('#id'));


		****************************************************************************************************************/

		cache: function(prop) {

			var type = ( prop instanceof Object ) ? 'val' : 'key';

			if ( type === 'key' ) {
				this.internalcache._current = prop;
			} else {
				this.internalcache[this.internalcache._current] = {
					src		: prop.html(),
					scroll	: prop.closest('.scroll_wrapper').scrollTop()
				};
			}

		},

		/****************************************************************************************************************

			METHOD: K.change()

			PARAMS: name (string)

			DESCRIPTION:
			K.change checks for a defined interface, if it finds one already defined it uses that one, otherwise it will
			create a new interface.

			EXAMPLE:
			this.change('login',$('#container'));


		****************************************************************************************************************/

		change: function(name,cb) {

			if ( this.observers && this.observers['interface'] ) {
				if ( this.interfaces[name] ) {
					delete this.interfaces[name];
				}
			}

			this.create(name,cb);

		},

		/****************************************************************************************************************

			METHOD: K.cookie()

			PARAMS: key (string), val (string), expire (number)

			DESCRIPTION:
			K.cookie sets and/or retrieves a cookie. The expire param is the expiration of the cookie set in days. It is
			set to 30 days by default.

			EXAMPLE:
			// Set a cookie
			K.cookie( 'foo', 'bar' );

			// Retrieve a cookie
			K.cookie( 'foo' ); // Will return bar

			// Expire a cookie
			K.cookie( 'foo', null );


		****************************************************************************************************************/

		cookie: function(key, val, expire) {

			var date	= new Date(),
				expires = '',
				path = key === 'preview' || key === 'share_to_tumblr' ? '/' : this.config.paths.admin.replace(window.location.protocol + '//' + window.location.host, ''),
				parts, i, len, item, name;

			// Specifically check for undefined here, as false signifies we want cookie to expire when browser is closed
			expire = expire === undefined ? 30 : expire;

			if ( val === null ) {

				expires = 'expires=' + date.setTime( date.getTime() + ( -1 * 24 * 60 * 60 * 1000 ) ) + date.toGMTString();

				document.cookie = key + '=' + val + ';' + expires + '; path=' + path;

			} else if ( val ) {

				if ( expire ) {

					date.setTime( date.getTime() + ( expire * 24 * 60 * 60 * 1000 ) );

					expires = '; expires=' + date.toGMTString();

				}

				document.cookie = key + '=' + JSON.stringify(val) + expires + '; path=' + path;

			}

			if ( arguments.length > 0 ) {

				parts	= document.cookie.split(';');
				name	= key + '=';

				for ( i = 0, len = parts.length; i < len; i++ ) {

					item = parts[i].trim();

					if ( item.indexOf(name) === 0 ) {

						return JSON.parse( decodeURIComponent(item).substring(name.length, item.length) );

					}

				}

				return null;

			}

		},

		/****************************************************************************************************************

			METHOD: K.create()

			PARAMS: name (string)

			DESCRIPTION:
			K.create creates a new interface, pass in the name of the interface and it will do the rest. This method
			makes an assumption that you have setup a matching template called [name].tmpl.html in the templates directory.

			EXAMPLE:
			this.create('login');


		****************************************************************************************************************/

		create: function(name,cb) {

			if ( !name ) { return; }

			var self = this;

			var rebuildInheritance = function(name,type) {

				var alteredSubclass = this.subclass({});

				for ( var k in this.interfaces.app[type] ) {
					if ( this.interfaces.app[type].hasOwnProperty(k) ) {
						alteredSubclass[k] = this.interfaces.app[type][k];
					}
				}

				return this.subclass.call( alteredSubclass, ( typeof this[name][type] === 'function' ) ? this[name][type].call(this) : this[name][type] );

			};

			var GUI = function() {

				var req;

				if (config.reserved.indexOf(name) !== -1) {
					req = this.config.paths.base_relative + 'admin/templates/' + name + '.tmpl.html?1502123397177';
				} else {
					req = this.config.paths.base_relative + 'storage/plugins/koken-hook-example/plugin.tmpl.html?1502123397177';
				}

				this.sync({
					request: req,
					context: this,
					finished: function(data) {

						var self = this.interfaces[name];

						this.interfaces.active = {
							id: name
						};

						// Clear previous bindings
						$('body').off('keydown').off();
						$(document).off('mouseover,mouseout');

						// Clear previous pub/subs
						this.pubsub('reset');

						$('#templates').append(data);

						if ( this[name].observers ) {
							this.observers = ( name !== 'app' && this[name].observers ) ? rebuildInheritance.call(this, name, 'observers' ) : this.subclass( this[name].observers.call(this) );
						}

						if ( this[name].models ) {
							this.models = ( name !== 'app' && this[name].models ) ? rebuildInheritance.call(this, name, 'models' ) : this.subclass((this[name].models instanceof Function) ? this[name].models.call(this) : this[name].models);
						}

						if ( this[name].controllers ) {
							this.controllers = ( name !== 'app' && this[name].controllers ) ? rebuildInheritance.call(this, name, 'controllers' ) : this.subclass(this[name].controllers || {});
						}

						if ( this[name].views ) {
							this.views = ( name !== 'app' && this[name].views ) ? rebuildInheritance.call( this, name, 'views' ) : this.subclass(this[name].views || {});
						}

						if ( this[name].events ) {
							this.events = ( name !== 'app' && this[name].events ) ? rebuildInheritance.call( this, name, 'events' ) : this.subclass(this[name].events || {});
						}

						if ( this[name].router ) {
							this.router = ( name !== 'app' && this[name].router ) ? rebuildInheritance.call(this, name, 'router' ) : this.subclass( this[name].router() );
						}

						if ( this[name].exit && name !== 'app' ) {
							this.exit = this[name].exit;
						}

						this[name].init && this[name].init.call(this);

						// Add reset method to internal ko for putting observables back to their original state
						ko.subscribable.fn.reset = function() {
							var obs = this;
							obs( obs() instanceof Array ? [] : '' );
						};

						if ( name !== 'app' && name !== 'login' ) {
							this.observers['interface'](name);
						}

						if ( cb && cb.call ) { cb.call(this); }

					}
				});

			};

			if ( this.interfaces[name] instanceof _Koken === false ) {
				this.interfaces[name] = this.subclass(GUI);
			}

		},

		/****************************************************************************************************************

			METHOD: K.construct()

			PARAMS: constructor (object), prototypes[arguments array] (object)

			DESCRIPTION:
			K.construct is a simple constructor function to create classes with appropriate interface scope

			EXAMPLE:
			K.construct({ name: 'classname', fn: function() { console.log('im a class'); } });


		****************************************************************************************************************/

		construct: function(constructor) {

			var args		= Array.prototype.slice.call(arguments,1),
				i			= 0,
				len			= args.length,
				self		= this;

			this.klass[constructor.name] = function() {

				var context = this;

				return (function() {

					var intface	= this.interfaces[this.interfaces.active.id],
						temp	= intface.subclass(context),
						o;

					temp.extend = function(id,fn) {

						var args = Array.prototype.slice.call(arguments,2),
							self = this,
							orig = this[id];

						this[id] = function() {
							orig.apply(self,args);
							fn.apply(self,args);
						};

					};

					intface.classes						= intface.classes || {};
					intface.classes[constructor.name]	= intface.classes[constructor.name] || intface.subclass({});
					o									= (function() {
						constructor.fn.apply( this, arguments );
						temp.id = temp.id || arguments[0];
					}).apply( temp, arguments );

					for ( var key in o ) {
						if ( key !== constructor.name && o.hasOwnProperty(key) ) {
							temp[key] = o[key];
						}
					}

					return intface.classes[constructor.name][temp.id] = temp;

				}).apply(self,arguments);

			};

			if ( len <= 0 ) { return; }

			do {
				self.klass[constructor.name].prototype[args[i].name] = args[i].fn;
				i++;
			} while ( i < len );

		},

		/****************************************************************************************************************

			METHOD: K.map()

			PARAMS: observer (object), data (object), exclude (array)

			DESCRIPTION:
			K.map is used to turn JSON into proper knockout observable/observable arrays

			EXAMPLE:
			K.map()


		****************************************************************************************************************/

		map: function(observer,data,exclude) {

			if ( $.isEmptyObject(data) ) { return false; }

			var val,

			wrap = function(obs,data,recur) {

				for ( var k in data ) {

					val = data[k];

					if ( val instanceof Object && ! $.isArray(val) ) {
						wrap( obs[k] = obs[k] || {}, val, true );
					} else {
						if ( exclude && exclude.indexOf(k) >= 0 ) {
							obs[k] = val;
						} else if (obs[k]) {
							if ( obs[k] instanceof Function ) {
								obs[k](val);
							}
						} else {
							obs[k] = ( $.isArray(val) ) ? ko.observableArray(val) : ko.observable(val);
						}
					}

				}

				if (!recur) { return obs; }

			};

			observer = wrap(observer,data);

		},

		notify: function() {

			var e			= $('#notifications'),
				template	= $('#notify_mu'),
				note, label, txt, msg, origH;

			e.append( template.html() );

			note			= e.find('.note').last();
			label			= note.find('.icon:first');
			txt				= note.find('.text:first');

			e.height('auto');
			if (e.find('.note').length === 1) {
				e.hide();
			}
			note.hide();

			var init = function(obj, type) {

				if (typeof obj == 'object') {
					var key = obj.shift();
					if (!K.messages[key]) {
						return;
					}
					msg = vsprintf(K.messages[key], obj);
				} else {
					msg = obj;
				}

				if (type === 'wait') {
					msg += '…';
				}

				txt.html(msg);
				label.removeClass('note-success note-warn note-wait').addClass('icon note-' + type);

				if (!e.is(':visible')) {
					note.show();
					e.fadeIn();
				} else {
					note.fadeIn();
				}

				if (type != 'wait') {
					setTimeout(function() {
						if (e.find('.note').length === 1) {
							e.fadeOut(null, function() {
								note.remove();
							});
						} else {
							note.fadeOut(null, function() {
								$(this).remove();
							});
						}
					}, 4000);
				}
			};

			return {

				warn: function(obj) {
					init(obj, 'warn');
				},

				success: function(obj) {
					init(obj, 'success');
				},

				wait: function(obj) {
					init(obj, 'wait');
					return this;
				}

			};

		},

		/****************************************************************************************************************

			METHOD: K.pubsub

			PARAMS: none

			DESCRIPTION:
			K.pubsub utilizes jqueries binds to make a basic pub/sub system

			EXAMPLE:
			K.pubsub()


		****************************************************************************************************************/

		pubsub: function(namespace,opt) {

			var self = this;

			if ( ! this.pubsub._subs ) { this.pubsub._subs = []; }

			var subscribe = function() {

				if ( self.pubsub._subs.indexOf(namespace) >= 0 ) { return; }

				$(self.interfaces.app).bind( namespace, opt );
				self.pubsub._subs.push(namespace);

			};

			var trigger = function() {
				$(self.interfaces.app).trigger( namespace, opt );
			};

			if ( opt && opt instanceof Function ) {
				subscribe();
			} else if ( namespace !== 'reset' ) {
				trigger();
			} else if ( namespace === 'reset' ) {
				$(self.interfaces.app).unbind();
				this.pubsub._subs = [];
			}


		},

		/****************************************************************************************************************

			METHOD: K.queue

			PARAMS: ajax paramaters [see api.jquery.com/jQuery.ajax] (object)

			DESCRIPTION:
			K.queue is used to queue up ajax requests from K.sync. It shouldn't ever be called directly as all ajax request
			should be passing through K.sync. Its simply here in core to make it easier to keep track of a global queue.

			EXAMPLE:
			K.queue(params)


		****************************************************************************************************************/

		queue: function(items) {

			this.queue.async = this.queue.async || [];
			this.queue.sync = this.queue.sync || [];
			this.queue.counts = this.queue.counts || {
				async: 0,
				sync: 0,
				max_async: 4,
				max_sync: 1
			}

			if (this.system && this.system.max_parallel_requests) this.queue.counts.max_async = this.system.max_parallel_requests;

			var self = this,

			verifyData = function(data, xhr) {
				if (xhr.status === 304 && !data && !this.rerun) {
					this.cache = false;
					this.rerun = true;
					putIntoQueue(this);
					return false;
				} else {
					return true;
				}
			},

			putIntoQueue = function(item) {
				self.queue[(item.method === 'PUT' || item.method === 'DELETE') ? 'sync' : 'async'].push(item);
			},

			runQueue = function() {
				var _run = function(type) {
					if (this.queue[type].length > 0 && this.queue.counts[type] < this.queue.counts['max_' + type]) {
						var _item = this.queue[type].splice(0,1)[0],
							_method = _item.method,
							_success = _item.success,
							_error = _item.error;

						delete _item.method; // jQuery xhr object doesn't filter out method which allows it to override their type parameter
						delete _item.success;
						delete _item.error;

						if (!_item.headers) _item.headers = [];

						if (_item.headers || _item.data || _method || _item.dataType === 'json') _item.headers['X-Koken-Auth'] = 'cookie';

						if (_item.data && _item.data.length > 0 || _method) _item.type = 'POST';

						if (_method === 'PUT' || _method === 'DELETE') {
							_item.headers['X-Http-Method-Override'] = _method;
							_item.data = ( _item.data ) ? _item.data + '&_method=' + _method : '_method=' + _method;
						}

						this.queue.counts[type]++;

						$.ajax(_item)
							.done(_success)
							.fail(_error)
							.always(function() {
								self.queue.counts[type]--;
								runQueue.call(self);
							});

					}
				}
				_run.call(this, 'async');
				_run.call(this, 'sync');
			}

			if (!(items.request instanceof Array)) {
				putIntoQueue(items);
			} else if (items.request instanceof Array) {
				var clonedItems = $.extend({}, items);

				$.each(clonedItems.request, function(i, item) {
					var newRequest = $.extend({}, item);

					newRequest.request = item.request;
					newRequest.url = item.request.indexOf('.tmpl') === - 1 ? self.config.paths.base_relative + 'api.php?/' + item.request : item.request;

					if (clonedItems.headers || clonedItems.data || clonedItems.method) {
						newRequest.headers = [];

						if (clonedItems.data && clonedItems.data.length > 0 || clonedItems.method) newRequest.type = 'POST';

						if (clonedItems.method === 'PUT' || clonedItems.method === 'DELETE') {
							newRequest.headers['X-Http-Method-Override'] = clonedItems.method;
							newRequest.data = ( clonedItems.data ) ? clonedItems.data + '&_method=' + clonedItems.method : '_method=' + clonedItems.method;
						}
					}

					if (i < (clonedItems.request.length - 1)) {
						newRequest.success = function(data, status, xhr) {
							if (verifyData.call(newRequest, data, xhr)) {
								if (data.error === 'Unable to parse the info.json file for this theme.') {
					        		new self.klass.modal('bindingsError', 'bindings_error_modal', {
				        				hasKeepCancel: {
				        					cancelId: '#cancel_leave'
				        				},
				        				msg: 'Koken has detected a formatting error in your theme\'s info.json file. If developing your own theme, please check the file and try again. You may also switch to a different theme to resolve this issue.'
				        			});
								}
								item.finished && item.finished.call(clonedItems.context || this, ( data.length === 1 ) ? data.shift() : data);
							}
						}
					} else if (i === (items.request.length - 1)) {
						newRequest.success = function(data, status, xhr) {
							if (verifyData.call(newRequest, data, xhr)) {
								var _data = ( data.length === 1 ) ? data.shift() : data;
								item.finished && item.finished.call(clonedItems.context || this, _data);
								clonedItems.finished && clonedItems.finished.call(clonedItems.context || this, _data);
							}
						}
					}

					putIntoQueue(newRequest);
				});
			}

			runQueue.call(this);

		},

		/****************************************************************************************************************

			METHOD: K.router()

			PARAMS: none

			DESCRIPTION:
			K.router only needs to be run by the K.hashchange method, should not be invoked outside of that function

			EXAMPLE:
			K.router()


		****************************************************************************************************************/

		router: function(fragment) {

			var plugin_paths		= this.config.plugin_paths || [];

			if ( typeof plugin_paths !== 'string' ) {
				$.each(this.interfaces.app.observers.channels(), function(i, channel) {
					plugin_paths.push(channel.id);
				});

				if ( plugin_paths.length > 0 ) {
					plugin_paths = this.config.plugin_paths = '|' + plugin_paths.join('|');
				}
			}

			var regx				= '^\\/(library|text|site|settings|store|plugins' + plugin_paths + ')\\/?(.*)?\\/?$',
				parts				= fragment.match(new RegExp(regx)),
				target_interface	= parts ? parts[1] : 'library',
				local				= parts && parts[2] ? parts[2] : false,
				self				= this,
				reg, doesMatch, matches;

			if ( this.interfaces[this.interfaces.active.id].observers.hasChanges ) {
				if ( this.interfaces[this.interfaces.active.id].observers.hasChanges() ) {
					var c_leave = function() {
						self.interfaces[self.interfaces.active.id].observers.hasChanges(false);
						$.bbq.removeState( $.param.fragment() );
						$.bbq.pushState( self.hash, 2 );
						window.setTimeout(function() {
							self.interfaces[self.interfaces.active.id].observers.hasChanges(true);
						},10);
					}
					var k_leave = function() {
						self.interfaces[self.interfaces.active.id].observers.hasChanges(false);
						if ( $.param.fragment().split('/').length > 0 ) {
							self.router( $.param.fragment() );
						}
					}
					$(document)
						.off('click.schanges')
						.on( 'click.schanges', '#cancel_leave', c_leave)
						.on( 'click.schanges', '#keep_leave', k_leave);

					this.interfaces[this.interfaces.active.id].classes.modal.unsaved_changes.show();
					return false;
				}
			}

			// This sets the url of the main nav item to the current hash so that when a user returns to an
			// interface they pick up where they left off
			// Splitting by /install here to ensure store installation links don't get re-ran
			if (local && local.indexOf('upload') === -1) {
				$('#main-nav a[class~="' + target_interface + '-interface"]').attr('href', '#' + fragment.split('/install')[0]);
			}

			// Prevents scroll tracks from jumpiness when toggling between views that switch in/out of having scrollbars
			$('.scroll_track').css('opacity','0');

			if ( this.interfaces.active.id !== target_interface ) {

				$('#edit-area').length && $('#edit-area').remove();

				requestAnimationFrame(function() {
					self[self.active.id].exit && self[self.active.id].exit();
					self.change.apply( self.app, [ target_interface, function() {
						self.interfaces.router( fragment );
					}]);
				});

			} else {

				var intface = this.interfaces[this.interfaces.active.id];

				var pluginCleanup = function() {
					if (intface.plugins) {
						$.each(intface.plugins, function(i, plugin) {
							if (plugin.rendered.length > 0) {
								$.each(plugin.rendered, function(j, rendered) {
									rendered.remove();
								});
							}
						});
					}
				};

				this.hash = fragment;

				$('.redactor_air').remove();
				$('.redactor_dropdown').remove();

				if ( (!local || local.length === 0) && intface.router['default'] ) {

					pluginCleanup();
					intface.router['default'].apply( intface );
					return;

				}

				if (intface.plugins) {
					$.each(intface.plugins, function(i, plugin) {
						if (plugin.routes.length > 0) {
							$.each(plugin.routes, function(j, route) {
								if (route._interface === intface.classes.app.koken.channel) {
									intface.router[route._path] = route._callback;
									intface.router[route._path].plugin = route._context;
								}
							});
						}
					});
				}

				var _routes = [];

				for( var r in intface.router ) {
					if ( intface.router.hasOwnProperty(r) && r != 'default'  ) {
						_routes.push(r);
					}
				}

				if (_routes.length > 0) {
					_routes.sort(function(a,b) {
						return (a.length > b.length) ? -1 : 1;
					});

					$.each(_routes, function(i, route) {
						reg = new RegExp(route);
						doesMatch = reg.test(local);

						if (doesMatch) {
							matches = local.match(reg);
							if (intface.router[route].plugin) {
								if (fragment.indexOf('upload') !== -1 && intface.observers.view() !== 'upload') {
									intface.observers.pluginPath(fragment);
									$.bbq.pushState( '/library/upload', 2 );
								} else {
									if (!self.app.classes.app.koken.lastLocal) intface.router['default'].apply( intface );
									requestAnimationFrame(function() {
										intface.router[route].apply(intface.router[route].plugin, [matches]);
									});
								}
							} else {
								pluginCleanup();
								intface.router[route].apply(intface, [matches]);
							}

							self.app.classes.app.koken.lastLocal = local;
							return false;
						}
					});
				}

				// Nothing matched, hit the default if it exists
				if ( intface.router['default'] && !doesMatch ) {

					pluginCleanup();
					intface.router['default'].apply( intface, [ local ] );
					self.app.classes.app.koken.lastLocal = local;
					return;

				}

			}

		},

		session: function() {

			if (arguments.length) {

				var data = arguments[0];

				this._session = data;

			} else {
				return this._session || false;
			}

		},

		store_api: function(payload, cb) {
			payload.uuid = this.observers.settings.uuid();
			$.post(this.config.paths.store + '/console_webhook_endpoint', payload, $.proxy(function() {
				if (cb && typeof cb === 'function') {
					cb.call(this);
				}
			}, this));
		},

		persist: function() {

			if (!has.localstorage) { return false; }

			// Protect these keys and their values from cache expiration
			var protect = [ 'library.quick_collection' ];

			if (arguments.length === 2) {
				while(1) {
					try {
						localStorage.setItem(arguments[0], JSON.stringify(arguments[1]));
						break;
					} catch( err ) {
						// Over our storage quota
						// Pop off oldest cache until we have more room
						if (err.name == 'QUOTA_EXCEEDED_ERR') {
							var len = localStorage.length;
							while( len-- ) {
								var k = localStorage.key(len);
								if ($.inArray(k, protect) === -1) {
									localStorage.removeItem( k );
									break;
								}
							}
							// If we get here, there just isn't any room or something, so let's bail.
							break;
						} else {
							// Some other error, better bail.
							break;
						}
					}
				}
			}

			return JSON.parse( localStorage.getItem( arguments[0] ) );

		},

		/****************************************************************************************************************

			METHOD: K.subclass()

			PARAMS: fn (function)

			DESCRIPTION:
			K.subclass initializes and sets up a subclass with proper context. It also allows you to pass in an optional function
			to be run during setup.

			EXAMPLE:


		****************************************************************************************************************/

		subclass: function(fn) {

			var k,

			F = function() {

				if ( ! ( this && this.hasOwnProperty && ( this instanceof F ) ) ) {
					return new F();
				}

				if (fn && fn instanceof Function) {
					fn.call(this);
				}

				if (fn && fn instanceof Object) {
					for (var k in fn) {
						this[k] = fn[k];
					}
				}

				return this;

			};

			F.prototype = this;

			return F();

		},

		/****************************************************************************************************************

			METHOD: K.sync()

			PARAMS: ajax paramaters [see api.jquery.com/jQuery.ajax] (object), scope (object)

			DESCRIPTION:
			K.sync is a wrapper for simpler/easier ajax calls. It will automatically set GET/POST depending on
			whether or not it needs it. Multiple ajax calls can be made asynchronously by passing in an array
			of urls. If making multiple requests the success function that is passed in will not fire
			until all of the requests have completed. Conversely the error function will fire if any of the
			requests fails but not until its done making all of its requests. Upon failure any data it received from the
			successful requests will be passed to the error handler. this.sync can also be passed the scope as the second
			parameter which will set the callback to that scope upon execution. this.sync({ request: url }) is the only
			requirement to make the call successful.

			EXAMPLE:
			this.sync({
				request: '/my/data/url',
				success: successFunc,
				error: errorFunc,
				data: 'passThisVar=true'
			});

			this.sync({
				request: ['/my/data/url1','/my/data/url2','/my/data/ur3','/my/data/ur4'],
				success: successFunc, // Will be fired when all requests are completed
				error: errorFunc // Will fire if any requests fail after all requests have been made
			});


		****************************************************************************************************************/

		sync: function(params) {

			var res		= {},
				len		= 0,
				args	= [].splice.call(arguments,1),
				self	= this,

			run = function(url,index) {

				params.url		= url instanceof Object && url.request || url;
				params.dataType	= helpers.file_ext(params.url);
				params.error	= function(d,r,o) {
					try {
						d = JSON.parse(d.responseText);
					} catch(e) {
						d = { error: true };
					}
					if (url.finished) url.finished.call( params.context || this, d );
					res.data[index]	= d;
					var _ctxt = this;
					requestAnimationFrame(function() {
						params.finished && params.finished.call( params.context || _ctxt, ( res.data.length === 1 ) ? res.data.shift() : res.data, args );
					});
				};
				params.success	= function(d,r,o) {
					if (url.finished) url.finished.call( params.context || this, d );
					if ( o.getResponseHeader('Content-Type') !== 'application/x-javascript' ) res.data[index] = d;
					var _ctxt = this;
					requestAnimationFrame(function() {
						params.finished.call( params.context || _ctxt, ( res.data.length === 1 ) ? res.data.shift() : res.data, args );
					});
				};

				if ( params.dataType === 'js' ) {
					params.dataType = 'script';
				} else if ( params.dataType === 'json' ) {
					params.dataType = 'json';
				} else if ( params.dataType !== 'html' ) {
					params.dataType = 'json';
					params.url = (params.url.indexOf('://') === -1) ? self.config.paths.base + 'api.php?/' + params.url : params.url;
				}

				if (params.request instanceof Array && index === 0 || index === 0) self.queue(params);

			};

			params.headers = params.headers || [];

			res.data	= [];
			res.status	= [];

			// Checks if request exists, if it does and its a string make it an array, if its already an array leave it alone,
			// otherwise its just a simple url so split to make an array
			$.each( (params.request) ? params.request.split && params.request.split(' ') || params.request : params.split(' '), function(i,key) {
				len = i+1;
				run(key,i);
			});

		},

		unmap: function(data) {

			var unwrapped,

			extract = function(d,recur) {

				for ( var k in d ) {

					unwrapped = ko.utils.unwrapObservable(d[k]);

					if ( unwrapped instanceof Object && unwrapped instanceof Array === false ) {
						extract(d[k],true);
					} else {
						d[k] = unwrapped;
					}

				}

				if (!recur) { return d; }

			};

			return extract( $.extend( true, {}, data ) );

		}

	},

	// Internal Only
	K = function() {

		if ( ! ( this && this.hasOwnProperty && ( this instanceof K ) ) ) {
			return new K();
		}

		this.config = config || {};
		this.has = has || {};

		return this;

	},

	// Used for plugin private functions to be able to access the internal Koken [object object]
	__k,

	// Mapped to [window] for plugin authors
	__Koken = function() {

		var _guid;

		var _data = function() {
			var plugins = __k.interfaces.app.observers.user_plugins(),
				path = __k.plugins[this.kguid].path,
				data = {};

			if (plugins) {
				$.each(plugins, function(i, plugin) {
					if (plugin.path === path) {
						$.each(plugin.data, function(i, setting) {
							data[setting.key] = setting.value;
						});
						return false;
					}
				});
			}

			return data;
		};

		var _internalTemplate = function(needle,selector,plugin) {

			if (!$('#container ' + needle).length) return;

			var existingElement;
			plugin.elements.length && $.each(plugin.elements, function(i, element) {
				if (element.needle == needle) {
					existingElement = element;
					return false;
				}
			});

			if (!existingElement) {
				var existingElement = {
					'needle': needle,
					'guid': helpers.guid()
				}
				plugin.elements.push(existingElement);
			}

			var _vm = this,
				_element = $('<span/>').addClass('plugin_' + plugin.kguid + ' e_' + existingElement.guid).html($('#templates ' + selector).html()),
				_obj = {};

			existingElement = $('.plugin_' + plugin.kguid + '.e_' + existingElement.guid);
			if (!existingElement.length) {

				// If the user has set an html string via _render we convert it to a template to keep bindings properly nested
				if (_vm.html) {
					var templateId = 'template_' + helpers.guid(),
						innerHtml = $('<span id="' + templateId + '" />').append(_vm.html);
					_vm.html = templateId;
					$('#templates').append(innerHtml);
				}

				// Create model and init ko for newly rendered element
				if (_vm.appendAfter) {
					_element = _element.insertAfter('#container ' + needle + (_vm.appendAfter ? ' [data-plugin-target="' + _vm.appendAfter + '"]' : ''));
				} else {
					_element = _element.appendTo('#container ' + needle);
				}
				if ($.isEmptyObject(_vm)) { return; }
				__k.map(_obj, _vm);
				ko.applyBindings(_obj, _element.get(0));

			} else {
				// Update view models for elements already created
				if ($.isEmptyObject(_vm)) { return; }
				if (_vm.html) delete _vm.html;
				if (existingElement.length) __k.map(ko.dataFor(existingElement.get(0)), _vm);
			}

			return existingElement;

		}

		var _hook = function(handler,cb) {
			if(!__k.plugins[this.kguid]) return;
			var self = this;
			__k.plugins[this.kguid].hooks = __k.plugins[this.kguid].hooks || '.' + this.namespace;
			$(document).off(handler + '.' + this.namespace).on(handler + '.' + this.namespace, function(e,data) {
				var _args = Array.prototype.slice.call(arguments, 1);
				requestAnimationFrame(function() {
					cb.apply($.extend({}, self, {context: $(e.target)}), _args);
				});
			});
		}

		var _route = function(interface, path, callback) {
			if(!__k.plugins[this.kguid]) return;

			var parts = path.split('/').slice(1),
				obj = {},
				self = this,
				modified_route = path.split('/').join('/');

			obj[modified_route] = function() {
				callback.call(self);
			};

			__k.plugins[this.kguid].routes.push({
				_path: modified_route,
				_callback: callback,
				_interface: interface,
				_context: self
			});

			if (typeof __k.interfaces[interface].router !== 'function') $.extend(__k.interfaces[interface].router, obj);
		}

		var _get = function(which) {
			var activeInterface = __k.interfaces[__k.interfaces.active.id];

			switch(which) {
				case 'album' :
					return activeInterface.observers.properties.album.selected();
					break;
			}
		}

		var _channel = function(channel,callback) {
			__k.interfaces.app.observers.channels.push(channel);
			var _kproto = _Koken.prototype[channel.id] = {};
			callback && callback.call(_kproto);
		}

		var _change = {
			view: function(id) {
				if ( __k.interfaces.active.id === 'settings' ) {
					__k.interfaces.settings.observers.state.view(id);
				}
			}
		}

		var _event = function(type,callback) {
			if(!__k.plugins[this.kguid]) return;
			var self = this;
			__k.plugins[this.kguid].events.push({
				_type: type,
				_callback: callback,
				_context: self
			});
		}

		var _validate = function(selector) {
			if (typeof selector === 'boolean') {
				$(document).off('.validate_' + $('.ui-sheet.active').attr('id'));
				__k.interfaces.app.observers.valid(selector);
				return;
			} else {
				__k.interfaces[__k.interfaces.active.id].classes.form_validation[__k.interfaces.active.id].valid(selector,true);
			}
		}

		var _internalKokenTemplates = {
			'koken.library.albumSidebar': {
				id: 'kokenLibrarySidebar',
				selector: '#album_side div[data-event="sidebar"]',
				callback: function(args) {
					var item = __k.unmap(__k.interfaces.library.observers.sidebar.asset);
					item.save = function(data, instant) {
						instant = instant || false;
						data.id = __k.interfaces.library.observers.sidebar.asset.id();
						__k.interfaces.library.controllers.update(item.album_type ? 'albums' : 'content', data, {instant: instant});
					};
					args.push(item);

					return args;
				}
			},
			'koken.library.sidebar': {
				id: 'kokenLibrarySidebar',
				selector: '#content_side div[data-event="sidebar"]',
				callback: function(args) {
					var item = __k.unmap(__k.interfaces.library.observers.sidebar.asset);
					item.save = function(data) {
						data.id = __k.interfaces.library.observers.sidebar.asset.id();
						__k.interfaces.library.controllers.update(item.album_type ? 'albums' : 'content', data);
					};
					args.push(item);

					$('input[type="ccolor"]').spectrum({
						showInput: true,
						hide: function(color) {
							$(this).trigger('change');
						}
					});
					return args;
				}
			},
			'koken.library.multiSidebar': {
				id: 'kokenLibrarySidebar',
				selector: '#multi_side div[data-event="sidebar"]',
				callback: function(args) {
					var items = [];
					$.each(__k.interfaces.library.observers.selection(), function(i, asset) {

						var item = __k.unmap(__k.interfaces.library.controllers.get_asset_by_id(asset));
						item.save = function(data) {
							data.id = item.id;
							__k.interfaces.library.controllers.update(item.album_type ? 'albums' : 'content', data);
						};

						items.push(item);
					});
					args.push(items);

					return args
				},
			},
			'koken.library.navigation': {
				id: 'kokenLibraryNavigation',
				selector: '#content_subnav .lib-nav:first',
				callback: function() {
					__k.interfaces.library.controllers.set_current_nav();
				}
			},
			'koken.sheet.section': {
				id: 'kokenSheetSection',
				selector: function() {
					var self = this.interfaces[this.interfaces.active.id],
						id = '';
					$('#container').find('.ui-sheet').each( function() {
						if (self.observers.sheets[$(this).attr('id')].active()) {
							id = $(this).attr('id');
						}
					});
					return '#' + id + ' .import_sheet_sections';
				}
			},
			'koken.settings.edit.top': {
				id: 'kokenSettingsEdit',
				selector: '#plugin_opt_top'
			},
			'koken.settings.edit.bottom': {
				id: 'kokenSettingsEdit',
				selector: '#plugin_opt_bot'
			}
		}

		var _reloadLibraryContent = function() {
			__k.interfaces.library.observers.api_url('');
		}

		var _render = function(id,selector,callback) {
			if(!__k.plugins[this.kguid]){return;}
			if(id.indexOf('koken.') != -1) {
				// If selector is a function, execute it
				if (typeof _internalKokenTemplates[id].selector === 'function') _internalKokenTemplates[id].selector = _internalKokenTemplates[id].selector.call(__k);

				// Process internal template and return rendered element if necessary
				if (_internalKokenTemplates[id]) var _renderedElement = _internalTemplate.call(selector || {}, _internalKokenTemplates[id].selector, '#' + _internalKokenTemplates[id].id, this);

				// Execute internal and plugin callbacks if necessary
				requestAnimationFrame($.proxy(function() {
					var args = [];
					if (_internalKokenTemplates[id].callback) args = _internalKokenTemplates[id].callback.call(this, [_renderedElement]);
					_renderedElement && callback && callback.apply(this, args);
				}, this));
			} else {
				selector = selector || this.context || $('#container');
				var _template = $('#templates').find('#' + id),
					_selector = typeof selector === 'object' ? selector : $(selector),
					_vm = function() {},
					self = this;

				_vm.prototype.__selector__ = _selector;

				if (_template.find('div[data-bind]').length <= 0) {
					var _toAppend = $(_template.html());
					_toAppend.data('kguid', this.kguid);
					_selector.append(_toAppend);
					__k.plugins[this.kguid].rendered.push(_toAppend);
				}

				requestAnimationFrame(function() {
					$(_template.html()).first().get(0) && ko.applyBindings(new _vm(), $(_template.html()).first().get(0));
					$(window).trigger('resize');
					callback && callback.call(self);
				});
			}
		}

		var _scrollbar = function(id) {
			return __k.interfaces.app.classes.scrollbar[id];
		}

		var _save = function(obj, cb) {
			var payload = [],
				updated = [],
				plugin,
				self = this;

			var plugin = ko.utils.arrayFirst(__k.interfaces.app.observers.user_plugins(), function(item) {
				return item.path == __k.plugins[self.kguid].path;
			});

			$.each(plugin.data, function(i, data) {
				if (obj[data.key] !== undefined && obj[data.key] !== data.value) {
					payload.push(data.key + '=' + obj[data.key]);
					data.value = obj[data.key];
				}
				updated.push(data);
			});

			__k.sync({
				request: 'plugins/' + plugin.id,
				method: 'PUT',
				data: payload.join('&'),
				context: this,
				finished: function(data) {
					if (data && data.error) {
						cb && cb.call(this, data);
					} else {
						plugin.data = updated;
						cb && cb.call(this, true);
					}
				}
			})
		}

		var _sync = function(obj) {
			obj = $.extend({
				method: 'POST',
				data: '',
				finished: false
			}, obj);

			__k.sync((!obj.action) ? obj : {
				request: 'plugins/call/plugin:' + __k.plugins[this.kguid].path + '/method:' + obj.action,
				method: obj.method === 'GET' ? false : obj.method,
				data: obj.data,
				context: this,
				finished: obj.finished
			});
		}

		var _sheet = function(id) {
			var self = this,
				sheet = __k.interfaces[__k.interfaces.active.id].observers.sheets[id];

			if (!sheet.plugins) sheet.plugins = {};
			if (!sheet.plugins[this.kguid]) sheet.plugins[this.kguid] = {};

			return {
				display: function(callback) {
					if (callback) sheet.plugins[self.kguid].onload = function() {
						requestAnimationFrame(function() {
							callback.call(self);
						});
					};
					__k.interfaces.app.events.toggle_sheet.call(__k.interfaces[__k.interfaces.active.id], id);
				}
			}
		}

		return {

			extend: function(path, hasTemplate, cb) {
				_guid = helpers.guid();

				var props = {},
					templates;

				var _cb = (function(__callback) {
					var self = this;

					props = {
						kguid: self.kguid,
						elements: [],
						namespace: 'plugin_' + helpers.guid(),
						channel: _channel,
						change: _change,
						event: _event,
						get: _get,
						hook: _hook,
						notify: framework.notify,
						sync: _sync,
						scrollbar: _scrollbar,
						save: _save,
						sheet: _sheet,
						data: _data,
						route: _route,
						render: _render,
						reloadLibraryContent: _reloadLibraryContent,
						utilities: $.extend(helpers, {cookie: framework.cookie}, {map: framework.map}, {persist: framework.persist}),
						validate: _validate,
						config: __k.config
					};

					var __init = function(data) {
						cb.call(props);
						if (data) __k.plugins[props.kguid].templates = data;
						__callback && __callback();
					}

					if (!cb) return;

					if (!hasTemplate) {
						__init();
					} else {
						__k.sync({
							request: __k.config.paths.base_relative + 'storage/plugins/' + path + '/console/plugin.tmpl.html?1502123397177',
							finished: function(data) {
								$('#templates').append(data);
								__init(data);
							}
						});
					}
				});

				var _update = function(callback) {
					var doesHaveTemplates = this.templates,
						self = this;

					var reinit = function(data) {
						$.ajax({
							url: '../api.php?/plugins/css',
							success: function(data){
								 $('<style></style>').appendTo('head').html(data);
							}
						});

						$.ajax({
							url: '../api.php?/plugins/js',
							dataType: 'script',
							cache: true,
							success: function() {
								__k.interfaces.plugins && $.each(__k.interfaces.plugins, function(i, plugin) {
									if (plugin.path === self.path) plugin.init(function() {
										requestAnimationFrame(function() {
											callback && callback();
										});
									})
								});
							}
						});
					}

					$(document).off(this.hooks);
					delete __k.plugins[this.kguid];

					if (doesHaveTemplates) {
						$(this.templates).each(function() {
							if ($(this).attr('type') === 'text/html') $('#templates').find('#' + this.id).remove();
						});
					}

					if (doesHaveTemplates) {
						__k.sync({
							request: __k.config.paths.base_relative + 'storage/plugins/' + this.path + '/console/plugin.tmpl.html?1502123397177',
							finished: function(data) {
								$('#templates').append(data);
								reinit(data);
							}
						});
					} else {
						reinit();
					}
				}

				__k.plugins = __k.plugins || {};
				__k.plugins[_guid] = __k.plugins[_guid] || {
					routes: [],
					events: [],
					rendered: [],
					init: _cb,
					update: _update,
					path: path,
					hooks: '',
					kguid: _guid
				};

			}
		}
	},

	// Internal Only
	_Koken = function() {

		'use strict';

		if ( ! ( this && this.hasOwnProperty && ( this instanceof _Koken ) ) ) return new _Koken();

		ko.bindingHandlers.slider = {
			init: function(element, valueAccessor, allBindingsAccessor) {
				var data = ko.unwrap(valueAccessor()),
					override = allBindingsAccessor().sliderOptions;

				var prefix = data.length > 1 ? data.shift() : null,
					setting = data.shift();

				// Range sliders emit on `input` during the slide and `change` after slide completes
				$(element)
					.off('.slider')
					.attr({
						'type': 'range',
						'min': (override && override.min) ? override.min : setting.min,
						'max': (override && override.max) ? override.max : setting.max,
						'step': (override && override.step) ? override.step : (setting.step || 1),
						'data-send-as': setting.send_as || '',
						'data-default-value': setting.default || setting.value,
						'name': (override && override.name) ? override.name : setting.key,
						'value': (override && override.value) ? override.value : (setting.value || setting.default),
						'id': (override && override.id) ? override.id : prefix + 'option_' + setting.key
					});

			}
		}

		var ErrorHandlingBindingProvider = function() {
		    var original = new ko.bindingProvider();

		    //determine if an element has any bindings
		    this.nodeHasBindings = original.nodeHasBindings;

		    //return the bindings given a node and the bindingContext
		    this.getBindings = function(node, bindingContext) {
		        var result;

		        try {
		            result = original.getBindings(node, bindingContext);
		        }
		        catch (e) {
		        	if (!self.interfaces.app.classes.modal.bindingsError) {
		        		new self.klass.modal('bindingsError', 'bindings_error_modal', {
	        				hasKeepCancel: {
	        					keepId: '#keep_leave',
	        					keepFn: function() {
	        						self.interfaces.app.classes.modal.bindingsError.hide();
	        						self.notify().wait('Clearing system caches and reloading');
	        						this.sync({
	        							request: 'system/clear_caches',
	        							method: 'POST',
	        							context: this,
	        							finished: function(data) {
	        								try {
	        									localStorage.clear();
	        								} catch (e) {}
	        								window.location.reload();
	        							}
	        						});
	        					},
	        					cancelId: '#cancel_leave'
	        				}
	        			});
		        	}
		        	if (self.interfaces.app.observers.modal.current() !== 'bindings_error_modal') self.interfaces.app.classes.modal.bindingsError.show();
		            if (console && console.group) {
		            	console.warn(e.message);

		            	console.groupCollapsed('Node:');
		            	console.log(node);
		            	console.log($(node));
		            	console.groupEnd();

		            	console.groupCollapsed('Bindings data:');
		            	console.log(bindingContext.$data);
		            	console.groupEnd();

		            	console.groupCollapsed('Stack reference:');
		            	console.log(e.stack);
		            	console.groupEnd();

		            	console.log('---');
		            }
		        }

		        return result;
		    };
		};

		ko.bindingProvider.instance = new ErrorHandlingBindingProvider();

		$.fn.selectRange = function(start, end) {
		    return this.each(function() {
		        if (this.setSelectionRange) {
		            this.focus();
		            this.setSelectionRange(start, end);
		        } else if (this.createTextRange) {
		            var range = this.createTextRange();
		            range.collapse(true);
		            range.moveEnd('character', end);
		            range.moveStart('character', start);
		            range.select();
		        }
		    });
		};

		var self				= this;
		this.helpers			= helpers;
		this.internalcache		= {};
		this.hash				= '';
		this.klass				= {};

		this.interfaces	= this.subclass(function() {
			this.active = '';
		});

		this.sync({
			request: 'system',
			context: this,
			finished: function(data) {

				this.system = data || {};
				this.system.apiError = false;
				this.config.paths.store = data.store;

				if (data && typeof data === 'object' && !$.isEmptyObject(data) && !data.version) {
					// Will error if it receives data but is not formed in the correct system object
					this.system.apiError = true;
				} else if (!data) {
					// Will error if it receives no data at all
					this.system.apiError = true;
				}

				if (this.system.apiError) {

					// We errored, look for an error message and show the interface
					if (typeof data.error === 'string') {
						this.system.apiErrorMessage = data.error;
					}

					this.system.apiMaintenance = data.http === 503;

					this.create( 'login', function() {
						$('#container').attr('data-bind','template: { name: \'login_interface\' }');
						ko.applyBindings( this.observers, $( '#login' ).get(0) );
						var h = $('#app-signin').height() + 100;
						$('#signin').height(h).css('margin-top', -((h / 2) + 25));
					});

				} else {
					this.sync({
						request: 'sessions',
						context: this,
						finished: function(data) {

							this.session( (data.token) ? data: {} );

							// Tests for browser support functionality
							(function() {

								var tests = [],
									self = this;

								this.system.browserSupported = true;

								if (navigator.userAgent.indexOf('Phantom') !== -1) return;

								// Testing if IE
								tests.push(!$('html').is('.ie'));

								// Testing for .calc() functionality
								var el = document.createElement('div');
								el.style.cssText = 'width:-moz-calc(10px);width:-webkit-calc(10px);width:-o-calc(10px);width:-ms-calc(10px);width:calc(10px);';
								tests.push(!!el.style.length);

								tests = $.each(tests, function(i,test) {
									if (test === false) {
										self.system.browserSupported = false;
										return false;
									}
								});

							}).call(this);

							var which = ( this.session().token ) ? 'app' : 'login';

							// Load in our plugin bindings
							this.sync({
								request: this.config.paths.base + 'admin/templates/plugins.tmpl.html?1502123397177',
								finished: function(data) {
									$('#templates').append(data);
								}
							});

							// Start initializing all the plugins
							if (this.plugins) {
								$.each(this.plugins, function(i, plugin) {
									plugin.init();
								});
							}

							this.create( which, function() {
								$('#container').attr('data-bind','template: { name: \'' + which + '_interface\' }');
								var self = this;
								// Fixes random bindings errors on interface loads
								requestAnimationFrame(function() {
									ko.applyBindings( self.observers, $( '#' + which ).get(0) );
								});
							});

						}
					});
				}
			}
		});

	};

	K.prototype = framework;

	// _Koken will also inherit K
	_Koken.prototype = K();

	// Add interface methods to the prototype
	// ___INTERFACES___
	_Koken.prototype.app = {

	init: function() {

		if (!this.system.browserSupported) {
			this.observers.logout();
		}

		Piecon.setOptions({
			color: '#ff6e00', // Pie chart color
			background: '#fff', // Empty pie chart color
			shadow: '#ff6e00', // Outer ring color
			fallback: false // Toggles displaying percentage in the title bar (possible values - true, false, 'force')
		});

		var self = this,
			resizeInt;

		this.construct(
			{
				name: 'app',
				fn: function(id) {

					var self = this;

					var delay = (function() {
						var timer = 0;
						return function(cb,ms) {
							clearTimeout(timer);
							timer = setTimeout(cb,ms);
						};
					})();

					this.id = id;
					this.channel = '';
					this.lastClickedElement = {};
					this.lastHash = $.param.fragment();
					this.lastLocal = null;
					this.firstRun = this.cookie( 'app:firstrun' ) || false;
					this.sortToDrag = false;
					this.itemsBeingSortedDragged = [];
					this.tagsHasBeenEdited = false;

					$(window).on( 'focus', 'input:not(.hasDatepicker),textarea', function(e) {
						self.lastFocused = $(e.target);
					});

					this.resizeHandlers = [];

					$(window).on('resize', function() {
						delay(function() {
							$(window).trigger( 'complete.resize', self );
						}, 250);

						$('.sheet-middle:visible, .sheet-middle.no-pad:visible').each( function() {
							var e = $(this);
							if (e.hasClass('fixed')) return;
							// 178 = UI chrome (header + footer) + 65 (margin added to sheet-middle to compensate for sheet-actions overlay)
							var contain = e.parents('.ui-sheet'),
								sub = 178 + (contain.height() - e.height());

							// Import content sheet has an options row that can fold out, so this gives it a little more room.
							if (contain.attr('id') === 'import_content') {
								sub += 36;
							}

							e.css('max-height', $(window).height() - sub);
							e.find('.scroll_wrapper').css('max-height', $(window).height() - sub);
						});

						$('.modal-wrap').css({
							'margin-top'	: '-' + ( $('.modal-wrap').height() / 2 ) + 'px',
							'margin-left'	: '-' + ( $('.modal-wrap').width() / 2 ) + 'px'
						});

						if (self.channel !== '') {
							$(window).trigger(self.channel + '.resize');
						}

						self.controllers.lazy_album_sheet();

					}).trigger('resize');

				}
			}
		);

		this.construct(
			{
				name: 'scrollbar',
				fn: function(id) {

					var self		= this, timer;

					this.el			= (id instanceof Object) ? ( this.id = id.attr('id'), id ) : $( '#' + id );
					this.ratio		= 0;
					this.handlers	= [];
					this.lastpos	= 0;
					this.calcs		= { height: {}, top: {} };


					this.el.wrapInner('<div class="scroll_content" />');
					this.el.wrapInner('<div class="scroll_wrapper scroll_off" />');
					this.el.find('.scroll_wrapper').prepend($('#scroller_mu').html());

					this.elements = function() {
						return {
							track		: $( '.scroll_track', self.el ),
							bar			: $( '.scroll_bar', self.el ),
							content		: $( '.scroll_content', self.el ),
							container	: $( '.scroll_wrapper', self.el )
						};
					}.call(this);

					if (this.el.hasClass('top-in')) {
						this.elements.content.addClass('top-in');
						this.el.removeClass('top-in');
					}
					this.elements.container.off('scroll').on( 'scroll', null, this, function(e,params) {
						if ( self.handlers.indexOf('stop') >= 0 ) {
							clearTimeout(self.timer);
							self.timer = setTimeout( function() { self.el.trigger('stop.scroll'); clearTimeout(self.timer); }, 250 );
						}
						e.data.update();
						self.el.trigger('during.scroll', params);
					});

					this.interfaces.app.classes.app.koken.resizeHandlers.push(this);

					if ( this.interfaces.app.classes.app.koken.resizeHandlers.length === 1 ) {
						this.loopy();
					}

					this.update();

				}
			},
			{
				name: 'update',
				fn: function() {

					var parent = $('#' + this.el.get(0).id),
						container = parent.find('.scroll_wrapper'),
						content = parent.find('.scroll_content');

					this.calcs.height.container = container.outerHeight(true);
					this.calcs.top.wrapper = container.scrollTop();
					this.calcs.height.content = content.outerHeight(true);

					var cache_key = this.calcs.top.wrapper + '_' + this.calcs.height.container + '_' + this.calcs.height.content;

					if (this.lastpos === cache_key) {
						return;
					} else {
						this.lastpos = cache_key;
					}

					if ( this.calcs.height.container <= 0 || this.calcs.height.content <= 0 ) { return; }

					this.ratio = this.calcs.height.container / this.calcs.height.content;

					if ( this.ratio < 1 ) {

						parent.find('.scroll_track').css('opacity','1');
						parent.find('.scroll_track_off').removeClass('scroll_track_off');
						parent.find('.scroll_off').removeClass('scroll_off');

						var bar_p = ( this.calcs.height.container / this.calcs.height.content ) * 100;

						this.calcs.height.bar = this.calcs.height.container / bar_p;

						var top = Math.min( Math.round( this.calcs.top.wrapper * this.ratio ), this.calcs.height.content - this.calcs.height.bar );

						parent.find('.scroll_bar').css({
							top: top + 'px',
							height: bar_p + '%'
						}).show();

						if ( this.handlers.indexOf('page') >= 0 && content.height() - ( this.calcs.top.wrapper + this.calcs.height.container ) < 200 && content.height() > 0 ) {
							this.el.trigger('page.scroll');
						}

					} else {

						container.addClass('scroll_off');
						parent.find('.scroll_track_footer').addClass('scroll_track_off');
						parent.find('.scroll_bar').hide();

					}

				}
			},
			{
				name: 'loopy',
				fn: function(pause) {

					cancelAnimationFrame(resizeInt);

					if ( pause ) { return; }

					var self = this,
						resize = function() {
							$.each( self.interfaces.app.classes.app.koken.resizeHandlers, function() {
								if ( this.el.css('display') === 'none' || ( this.el.closest('.ui-sheet').length > 0 && this.el.closest('.ui-sheet').css('display') === 'none' ) ) { return; }
								this.update();
							});
							self.loopy();
						};

					resizeInt = requestAnimationFrame(resize);

				}
			},
			{
				name: 'listen',
				fn: function(type,cb) {
					var self = this;
					if ( this.handlers.indexOf(type) < 0 ) { this.handlers.push(type); }
					this.el.off(type + '.scroll').on( type + '.scroll', function(ev,params) {
						cb.call(self,params);
					});
				}
			},
			{
				name: 'reset',
				fn: function() {
					this.elements.container.scrollTop(0);
				}
			}
		);

		ko.bindingHandlers.scrollbar = {
			init: function(el) {

				el = $(el);

				var id = el.attr('id'),
					scrollbar = new self.klass.scrollbar(el);

				if ( id === 'mid_multi' ) {

					var show_tick = null;

					scrollbar.listen( 'during', function(params) {
						if (show_tick === null) {
							show_tick = this.observers.order_by() !== 'manual';
						}

						if (show_tick && (!params || !params.suppress)) {
							this.events.scroll_overlay();
							$('#scroll-tick').show();
						}
					});
					scrollbar.listen( 'stop', function() {
						show_tick = null;
						$('#scroll-tick').fadeOut(null, function() { $(this).removeClass('loading'); });
						this.controllers.lazy('#content_list','suppress');
					});
				}

				if ( id === 'entry-list-all' || id === 'mid_multi' ) {
					scrollbar.listen( 'page', function() {
						this.controllers.mid_scroll_finish();
					});
				}

			}

		};

		this.construct(
			{
				name: 'channel',
				fn: function() {}
			}
		);

		this.construct(
			{
				name: 'form_validation',
				fn: function() {
					this.validate = [];
				}
			},
			{
				name: 'getErrorMessageElement',
				fn: function(el,isError) {
					if (isError) { el.addClass('valerror'); } else { el.removeClass('valerror'); }
					return el.parent().find('.form-error-msg');
				}
			},
			{
				name: 'not_empty',
				fn: function(el,suppress) {
					var res		= ( el.val().trim() !== '' ) ? true : false,
						self	= this;

					if (!res) {
						if (!suppress) {
							this.getErrorMessageElement(el,true).text( el.data('error-msg') ).show();
						}
					} else {
						if (!suppress) {
							this.getErrorMessageElement(el).hide();
						}
					}

					return res;
				}
			},
			{
				name: 'regex',
				fn: function(el,suppress) {
					var val = el.val().trim(),
						regex = RegExp(el.attr('data-validate-regex')),
						match = regex.test(val);

					if (match) {
						this.getErrorMessageElement(el).hide();
						return true;
					} else {
						this.getErrorMessageElement(el,true).show();
						return false;
					}
				}
			},
			{
				name: 'email',
				fn: function(el,suppress) {
					var email = el.val().trim(),
						self = this;

					if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
						this.getErrorMessageElement(el).hide();
						return true;
					} else {
						this.getErrorMessageElement(el,true).text( 'Not a valid email address' ).show();
						return false;
					}
				}
			},
			{
				name: 'url',
				fn: function(el,suppress) {
					var url = el.val().trim(),
						self = this;

					if (!url.length) return false;

					// EPIC regex FTW
					if (/^(https?:\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url) ||
						/^mailto:[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/.test(url) ||
						/^tel:.{3,}/.test(url)
					) {
						this.getErrorMessageElement(el).hide();
						return true;
					} else {
						this.getErrorMessageElement(el,true).text( 'Not a valid URL' ).show();
						return false;
					}
				}
			},
			{
				name: 'content_url',
				fn: function(el,suppress) {
					var url = el.val().trim(),
						self = this;

					if (!url.length) return false;

					if (/^https?:\/\/.*\.(jpeg|jpg|gif|png|mp4)$/.test(url)) {
						this.getErrorMessageElement(el).hide();
						return true;
					} else {
						this.getErrorMessageElement(el,true).text( 'Not a valid content URL' ).show();
						return false;
					}
				}
			},
			{
				name: 'path',
				fn: function(el,suppress) {
					var url = el.val().trim(),
						self = this;

					if (!url.length) return false;

					if (/[^\/a-zA-Z0-9_-]/.test(url) || /^[^\/]/.test(url)) {
						this.getErrorMessageElement(el,true).text( 'Not a valid path' ).show();
						return false;
					} else {
						this.getErrorMessageElement(el).hide();
						return true;
					}
				}
			},
			{
				name: 'unique',
				fn: function(el,suppress) {
					var val = el.val().trim(),
						scope = el.attr('data-validate-scope'),
						valid = true;

					if (val.length) {
						$.each($(scope), function(i, _el) {
							if (_el === el.get(0)) return;
							var v = $(_el).val().trim();
							if (val === v) {
								valid = false;
								return false;
							}
						});

					} else {
						valid = false;
					}

					if (valid) {
						this.getErrorMessageElement(el).hide();
					} else {
						this.getErrorMessageElement(el,true).show();
					}

					return valid;
				}
			},
			{
				name: 'valid',
				fn: function(el, isSheet, canBeValidated, handlersBound) {

					if ( this.validate[el] ) return;

					var validations			= $('#' + el).find('[data-validate]:visible:not([data-validate="' + el + '"]):not(button[data-validate])'),
						self				= this,
						namespace			= 'validate_' + el,
						bindings			= 'keyup.' + namespace,
						validateBtn			= $('#' + el).find('button[data-validate]'),
						validateBtnActv		= validateBtn.length > 0,
						validationsVisible	= validations.filter(function() { return $(this).is(':visible'); });

					// Reset button class if all the validations are now hidden/removed
					if (!validations.length || (!validationsVisible.length && validateBtnActv)) validateBtn.removeClass('disabled');

					if (!handlersBound) {
						$(document).off('.' + namespace);
						if (validateBtnActv) {
							$(document)
								.on( bindings + ' change.' + namespace, '#' + el + ' input, #' + el + ' select', function() {
									self.valid.call(self, el, false, true, true);
								});
						} else {
							$(document)
								.on( bindings, '#' + el + ' input', function() {
									self.valid.call(self, el, false, true, true);
								});
						}
					}

					if (!isSheet && canBeValidated) {

						var results = [];

						validations.each(function(idx, element) {
							var isAlreadyValid = $(element).data('validateAllowEmpty') && !$(element).val().length;
							if (isAlreadyValid) self.getErrorMessageElement($(element)).hide();
							results.push((isAlreadyValid) ? true : self[$(element).data('validate')]($(element)));
						});

						if (results.indexOf(false) !== -1) {
							if (validateBtnActv && validationsVisible.length) validateBtn.addClass('disabled');
							this.interfaces.app.observers.valid(false);
							return false;
						} else {
							if (validateBtnActv && validationsVisible.length) validateBtn.removeClass('disabled');
							this.interfaces.app.observers.valid(true);
							return true;
						}

						if (validateBtnActv && validationsVisible.length) areAllValid && validateBtn.removeClass('disabled') || validateBtn.addClass('disabled');
						this.interfaces.app.observers.valid(areAllValid);
						return areAllValid;

					}
				}
			}
		);

		this.construct(
			{
				name: 'editor',
				fn: function(id, initial_value, selector) {
					initial_value = initial_value || '';
					var self = this;

					// Altering ACE prototype to never use workers to suppress js errors
					ace.EditSession.prototype.$useWorker = false;

					this.editorWindow = ace.edit(selector);

					// ACE is inserting not appending to the head so we have to force it
					this.editorWindow.setTheme( ( this.cookie( 'editor:theme' ) && this.helpers.clean( this.cookie( 'editor:theme' ) ) ) || 'ace/theme/koken' );
					$('style#ace-tm,style#ace-koken').remove();
					this.editorWindow.setTheme( ( this.cookie( 'editor:theme' ) && this.helpers.clean( this.cookie( 'editor:theme' ) ) ) || 'ace/theme/koken' );

					this.editorWindow.getSession().setMode('ace/mode/css');
					this.editorWindow.setShowPrintMargin(false);
					this.editorWindow.setValue(initial_value);
					this.editorWindow.focus();
					this.editorWindow.autosave = true;
				}
			}
		);

		this.construct(
			{
				name: 'colorpicker',
				fn: function(id, el) {
					var self = this;
					requestAnimationFrame(function() {
						el.spectrum({
							showInput: true,
							change: function(color) {
								$(this).trigger('colorupdate');
							},
							hide: function(color) {
								$(this).trigger('change');
							},
							show: function() {
								self.helpers.outside('.sp-container', function() {
									el.spectrum('hide');
								});
							}
						});
					});
				}
			}
		);

		this.construct(
			{
				name: 'slider_number_toggle',
				fn: function() {

					// Used to swap out a slider with a number input and vice-versa
					// .rangeinput is the class needed on the container of the slider elements
					// new this.klass.slider_number_toggle(); can be placed anywhere it makes sense
					// to start listening for sliders
					// ESC/RETURN/Blur cause the number input to toggle back to the slider

					var doc = $(document),
						autosaveInterval = 1000, // milliseconds
						rangeTimer;

					var changeToSlider = function() {
						var rangeEl = $('.__rangeinput__');
						rangeEl.parent().find('span').show();
						rangeEl.parent().find('input[type="range"]').show();
						rangeEl.remove();
					};

					doc
						.off('click.rangeinput','change.rangeinput','keydown.rangeinput')
						.on('click.rangeinput', '.rangeinput', function(ev) {

							if ( ev.target.tagName === 'INPUT' ) return true;

							var el = $(this).find('span'),
								parent = el.parent(),
								input = parent.find('input[type="range"]'),
								data = ko.dataFor(input.get(0));

							if ( $('.__rangeinput__').length > 0 ) changeToSlider();

							if ( input.length > 0 ) {
								parent.find('span').hide();
								input.hide();
								parent.append('<input class="__rangeinput__ field side-col" type="number" min="' + ((data.setting && data.setting.min) ? data.setting.min : input.attr('min') ) + '" max="' + ((data.setting && data.setting.max) ? data.setting.max : input.attr('max') ) + '" step="' + ((data.setting && data.setting.step) ? data.setting.step : input.attr('step') ) + '" value="' + input.val() + '" />');
								$('.__rangeinput__').focus().on('blur.rangeinput', changeToSlider);
							}

						})
						.on('change.rangeinput', '.__rangeinput__', function(e) {

							if ( e.target.tagName !== 'INPUT' ) return true;

							var el = $(this),
								parent = el.parent(),
								input = parent.find('input[type="range"]');

							input.val(el.val()).trigger('change');

							clearInterval(rangeTimer);
							rangeTimer = window.setInterval(function() {
								clearInterval(rangeTimer);
								input.trigger('mouseup');
							}, autosaveInterval);

						})
						.on('keydown.rangeinput', function(e) {

							if ( e.target.tagName !== 'INPUT' ) { return true; }

							if ( e.which === 27 || e.which === 13 ) {
								$('.__rangeinput__').parent().find('input[type="range"]').trigger('mouseup');
								changeToSlider();
								return false;
							}

						});

				}
			}
		);

		this.construct(
			{
				name: 'autocomplete',
				fn: function() {}
			},
			{
				name: 'tags',
				fn: function(element,appendToElement,triggerElement) {
					var self = this;
					if (element.hasClass('ui-autocomplete-input')) {
						element.autocomplete('destroy');
					}
					element
						.autocomplete({
							select: function(e, ui) {
								e.preventDefault();
								element.addClass('autocompleting');
								var item = $(element),
									parts = ui.item.label.split(' ');
								if ($(element).closest('#right-col-xtra').length > 0 || self.interfaces.active.id == 'library') {
									item.val((parts.length > 0) ? '"' + ui.item.label + '"' : ui.item.label);
								} else {
									item.val(ui.item.label);
								}
								requestAnimationFrame(function() {
									triggerElement && triggerElement.trigger('click');
									item.val('');
									element.removeClass('autocompleting');
								});
								element.autocomplete('destroy');
								self.classes.autocomplete.library.tags(element, appendToElement, triggerElement);

							},
							source: function(req, cb) {
								var term = req.term,
									matches = [];
								$.each(self.observers.tags(), function(i, t) {
									if (t.title.match(RegExp(term, 'gi'))) {
										matches.push(t.title);
									}
								});
								cb.apply(this, [ matches ]);
							},
							appendTo: appendToElement,
							create: function() {
								$(appendToElement).addClass('k-dropdown')
							}
						});
				}
			}
		);

		this.construct(
			{
				name: 'modal',
				fn: function(id,tid,opts) {

					this.onload;
					this.templateId	= tid;
					this.options	= opts;

				}
			},
			{
				name: 'show',
				fn: function() {

					var self = this,
						args = arguments;

					this.observers.modal.canClose( ( !this.options || this.options.allowClose === undefined ) ? true : this.options.allowClose );

					if (this.observers.modal.canClose()) {
						$('body').removeClass('progress');
					} else {
						$('body').addClass('progress');
					}

					if (this.onload) requestAnimationFrame(function() { self.onload.apply(self, args); })

					if ( this.options && this.options.canClickOutside ) {
						window.setTimeout(function() {
							self.helpers.outside( $('#modal-template'), function() {
								self.hide();
							});
						},1000);
					}

					this.observers.modal.current(this.templateId);
					this.observers.modal.active(true);
					this.observers.modal.msg((this.options && this.options.msg) ? this.options.msg : '');

					$(document).on( 'click', 'a.modal-close', function() {
						self.hide();
						if ( this.options && this.options.hasKeepCancel ) self.options.hasKeepCancel.closeFn.apply(self.interfaces[self.interfaces.active.id],args);
					}).trigger('resize');

					if ( this.options && this.options.hasKeepCancel ) {
						$( '.' + this.templateId ).find(this.options.hasKeepCancel.keepId).click(function() {
							self.options.hasKeepCancel.keepFn.apply(self.interfaces[self.interfaces.active.id],args);
							self.hide();
						});
						$( '.' + this.templateId ).find(this.options.hasKeepCancel.cancelId).click(function() {
							self.options.hasKeepCancel.cancelFn && self.options.hasKeepCancel.cancelFn.apply(self.interfaces[self.interfaces.active.id],args);
							self.hide();
						});
					}

				}
			},
			{
				name: 'progress',
				fn: function(percent) {
					$('.update_progress span').addClass('migrate');
					$('.update_progress span').width( Math.min( percent*100, 100 ) + '%' );
				}
			},
			{
				name: 'hide',
				fn: function() {
					$('body').removeClass('progress');
					this.observers.modal.current('');
					this.observers.modal.active(false);
					$('.update_progress span').removeClass('migrate');
					$('.update_progress span').width('100%');
				}
			}
		);

		this.construct(
			{
				name: 'channel',
				fn: function(id) {
					this.firstLoad = true;
					this.uploader = null;
					this.uploaderIsBeingInitialized = false;
				}
			}
		);

		new this.klass.app('koken');

		$(window).on('complete.resize', function(evt,koken) {
			if ( koken.channel === 'library' ) {
				self.controllers.lazy.call( self.interfaces[koken.channel], '#content_list' );
			}
		});

		// Creating modals...
		new this.klass.modal( 'tour', 'tour_modal', { canClickOutside: true });
		new this.klass.modal( 'php_upgrade', 'php_upgrade_modal', {
			canClickOutside: true,
			hasKeepCancel: {
				cancelId: '#cancel_leave'
			}
		});
		new this.klass.modal( 'about', 'about_modal', { canClickOutside: true });
		new this.klass.modal( 'map', 'map_modal', { canClickOutside: true });
		new this.klass.modal( 'help', 'help_modal', { canClickOutside: true });
		new this.klass.modal( 'keyboard_shortcuts', 'keyboard_modal', { canClickOutside: true });
		new this.klass.modal( 'share_asset', 'share_modal' );
		new this.klass.modal( 'match_album_visibility', 'match_album_visibility_modal', {
			canClickOutside: true,
			hasKeepCancel: {
				keepId: '#keep_leave',
				cancelId: '#cancel_leave'
			}
		});
		this.classes.modal.match_album_visibility.onload = function(items, fromVisibility, toVisibility, type) {
			$('#right-col-xtra').height($(window).height() - 26);
			this.observers.visibility.items((items.length > 0) ? items : []);
			this.observers.visibility.viz_type_reset();
			if (type) this.observers.visibility.album_type(type);
			this.observers.visibility.viz_type_current(toVisibility === 'private' ? 'public and unlisted' : fromVisibility);
			this.observers.visibility.viz_type_new(toVisibility);
		}
		new this.klass.modal( 'unpublish_essay', 'unpublish_essay_text_modal', {
			hasKeepCancel: {
				keepId: '#keep_unpublish',
				keepFn: function() {
					this.notify().success(this.observers.page_to_edit.title() + ' saved as draft.');
					this.events.save(false, false, false, true);
				},
				cancelId: '#cancel_unpublish'
			}
		});
		this.classes.modal.unpublish_essay.onload = function() {
			$('#unpublish_essay_type').text(self.interfaces.text.observers.page_to_edit.page_type());
		}
		new this.klass.modal( 'text_live', 'text_live_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function() {
					$('[data-action="publish"]').data('publish','true').trigger('click');
				},
				cancelId: '#cancel_leave'
			}
		});
		new this.klass.modal( 'unsaved_changes', 'unsaved_changes_text_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function() { this.events.leave_edit(true); },
				cancelId: '#cancel_leave'
			}
		});
		new this.klass.modal( 'delete_tag', 'delete_tag_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function(el,parent) {
					self.interfaces.app.events.delete_tag(el,parent);
					el.parent().remove();
				},
				cancelId: '#cancel_leave'
			}
		});
		new this.klass.modal( 'confirm_site_revert', 'confirm_site_revert_modal', {
			hasKeepCancel: {
				keepId: '#keep_revert',
				keepFn: function(el,parent) {
					self.interfaces.site.events.do_revert(el,parent);
				},
				cancelId: '#cancel_revert'
			}
		});
		new this.klass.modal( 'confirm_site_revert_settings', 'confirm_site_revert_settings_modal', {
			hasKeepCancel: {
				keepId: '#keep_revert_settings',
				keepFn: function(el,parent) {
					self.interfaces.site.events.do_revert_settings(el,parent);
				},
				cancelId: '#cancel_revert_settings'
			}
		});
		this.classes.modal.delete_tag.onload = function(el) {
			$('#delete_tag_name').text(ko.dataFor(el[0]).title);
		}
		new this.klass.modal( 'delete_category', 'delete_category_text_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function(el,parent) {
					self.interfaces.app.events.category_actions(el,parent,true);
				},
				cancelId: '#cancel_leave'
			}
		});
		this.classes.modal.delete_category.onload = function() {
			// Prevents the drawer from collapsing
			$('#right-col-xtra').height($(window).height() - 26);
		}
		new this.klass.modal( 'draft_exists', 'draft_exists_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function() { this.events.init_draft($('a#use_theme'),true); },
				cancelId: '#cancel_leave'
			}
		});
		this.classes.modal.draft_exists.onload = function() {
			var self = this;
			$(document).off('.draftexists').on('click.draftexists', '#open_draft', function() {
				self.interfaces.site.events.switch_draft(ko.dataFor($('a#use_theme').get(0)).path, function() {
					self.classes.modal.draft_exists.hide();
					$('#edit_current_draft').trigger('click');
				});
				return false;
			});
		}
		new this.klass.modal( 'theme_change', 'theme_change_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function() { this.events.publish( $('#siteui-publish-buttons a'), true ); },
				cancelId: '#cancel_leave'
			}
		});
		new this.klass.modal( 'delete_text', 'delete_text_modal', {
			hasKeepCancel: {
				keepId: '#keep_delete',
				keepFn: function() { this.events.delete_text(); },
				cancelId: '#cancel_delete'
			}
		});
		new this.klass.modal( 'delete_draft', 'delete_draft_modal', {
			hasKeepCancel: {
				keepId: '#keep_delete',
				keepFn: function() { this.events.delete_draft( $('#draft_hist_list li.selected a'),true); },
				cancelId: '#cancel_delete'
			}
		});
		new this.klass.modal( 'reset_links', 'reset_links_modal', {
			hasKeepCancel: {
				keepId: '#keep_reset',
				keepFn: function() { this.events.reset_nav(null,true); },
				cancelId: '#cancel_reset'
			}
		});
		new this.klass.modal( 'update_available', 'update_modal', {
			allowClose: false
		});
		new this.klass.modal( 'install_plugin', 'install_plugin_modal', {
			allowClose: false
		});
		new this.klass.modal( 'install_theme', 'install_theme_modal', {
			allowClose: false
		});
		new this.klass.modal( 'update_products', 'update_products_modal', {
			allowClose: false
		});
		new this.klass.modal( 'update_products_fail', 'update_products_fail_modal');
		new this.klass.modal( 'update_failed', 'update_fail_modal', {
			allowClose: false
		});
		new this.klass.modal( 'update_recovered', 'update_recovered_modal');
		new this.klass.modal( 'update_compat_fail', 'update_compat_fail_modal');
		new this.klass.modal( 'cover', 'cover_modal', {
			hasKeepCancel: {
				keepId: '#keep_leave',
				keepFn: function(set,asset) { this.events.assign_cover('set',set,asset); },
				cancelId: '#cancel_leave'
			}
		});

		$(document).on( 'click', '#console-logo', function() {
			self.classes.modal.about.show();
		});

		this.controllers.load_tree();
		this.controllers.fetch_themes();
		this.events.init_copiers();
		this.events.init_triggers();
		this.observers.platform( ( window.navigator.platform.toLowerCase().indexOf('mac') < 0 ) ? 'pc' : 'mac' );
		this.observers.system = this.system;
		this.observers.real_base_path = this.config.paths.base;
		this.controllers.load_categories();

		var migrations_running = false;

		this.sync({
			request: [
				{
					request: 'tags/order_by:last_used',
					finished: function(data) {
						this.controllers.load('tags',data);
					}
				},
				{
					request: 'site',
					finished: function(data) {
						this.observers.urls = data.urls;
						this.map( this.observers.url_data, data.url_data );
						this.observers.has_urls(true);
					}
				},
				{
					request: 'plugins/cache:false',
					finished: function(data) {
						this.observers.process_plugin_data(data);
					}
				},
				{
					request: 'settings',
					finished: function(settings) {
						this.observers.reset_settings(settings);

						if (!settings.has_toured && navigator.userAgent.toLowerCase().indexOf('phantom') === -1) {
							window.setTimeout(function() {
								self.classes.modal.tour.show();
							}, 1500);

							this.sync({
								request: 'settings',
								method: 'PUT',
								data: 'has_toured=true'
							});
						}

						$.getJSON(this.config.paths.store + '/installs/' + this.observers.settings.uuid() + '/updates', $.proxy(function(data) {
							this.observers.update_count(data.products.length);

							if (!settings.disable_auto_updates && data.koken.version != this.system.version) {
								if (!this.cookie('ignore_release') || this.cookie('ignore_release') != data.koken.version) {
									this.observers.update_available(data.koken.version);
								}
								this.observers.update_available_version(data.koken.version);
								this.observers.update_url(data.koken.url);
							}
						}, this));
					}
				}
			],
			context: this,
			finished: function(data) {

				// Migrations will automatically reload browser
				if (data.migrations.length) {
					migrations_running = true;
					this.events.run_migrations(data.migrations);
					return;
				}

				var self = this, active, timer;

				$(document).on('click dblclick', '#container', function(e) {

					var parent	= ( $(e.target).closest('[data-event]').data('event') === false ) ? $(e.target).closest('[data-event]').parent().closest('[data-event]') : $(e.target).closest('[data-event]'),
						handler	= parent.data('event'),
						action	= ( handler && handler.indexOf('dbl') >= 0 ) ? 'dblclick' : 'click',
						handle;

					active = self.interfaces.active.id;

					self.classes.app.koken.lastClickedElement = $(e.target);

					// Check if link is external or clicking on a label
					if ( e.type === 'click' ) {
						if ( $(e.target).attr('href') || ( $(e.target)[0].tagName !== 'A' && $(e.target).closest('a').attr('href') ) ) {
							if ( ( $(e.target).attr('href') && $(e.target).attr('href').indexOf('http') >= 0 ) || ( $(e.target).closest('a').attr('href') && $(e.target).closest('a').attr('href').indexOf('mailto') >= 0 ) ) {
								if ( $(e.target)[0].tagName !== 'A' ) { $(e.target).closest('a').trigger('click'); }
								return true;
							}
						}

						if (e.target.nodeName.toLowerCase() === 'label') {
							return;
						}

						if ( $(e.target).closest('a').attr('href') && $(e.target).closest('a').attr('href').indexOf('#/') >= 0 ) {
							if ( ! e.metaKey && ! e.ctrlKey && ! e.shiftKey ) {
								window.location.hash = $(e.target).closest('a').attr('href');
								return false;
							}
						}
					}

					if ( handler && e.type == action && $(e.target).closest('.disabled').length <= 0 && ! $(e.target).find('a:first').hasClass('disabled') ) {
						if ( self.interfaces[active].events && self.interfaces[active].events[handler] ) {
							handle = self.interfaces[active].events[handler];
						} else if ( self.interfaces.app.events && self.interfaces.app.events[handler] ) {
							handle = self.interfaces.app.events[handler];
						}

						if (handle) {
							handle.call( self.interfaces[active], $(e.target), parent, e );
							if ( e.target.tagName !== 'INPUT' && e.type === 'click' || e.type === 'dblclick' ) {
								return false;
							}
						}
					} else {
						if ( active !== 'login' && e.target.tagName !== 'INPUT' && $(e.target).attr('id') !== 'upload-bttn' ) {
							return false;
						}
					}

					if (self.plugins) {
						// Didn't hit anything, let's check the plugins
						var _didFindPluginEvent = false;
						$.each(self.plugins, function(i, _plugin) {
							$.each(_plugin.events, function(j, _event) {
								if (_event._type == handler) {
									_event._callback.call(_event._context);
									_didFindPluginEvent = true;
								}
							});
						});
						if (_didFindPluginEvent) {
							return false;
						}
					}

				});

				$(window).bind( 'hashchange', function(e) {

					// $('.ui-sheet-bg').fadeOut();
					$(window).off('keyup.sheet')

					$.param.fragment.noEscape(':,/');

					if ( !$.isEmptyObject(self.classes.app.koken.lastClickedElement) && self.classes.app.koken.lastClickedElement.closest('#main-nav').length ) {
						var a = self.classes.app.koken.lastClickedElement.closest('li').find('a');
						if ( a.hasClass('selected') && a.attr('href') !== '#' + self.classes.app.koken.lastHash) {
							window.location.hash = self.classes.app.koken.lastHash;
							return false;
						}
					}

					self.classes.app.koken.lastHash = $.param.fragment();

					if ( $.param.fragment() === '' ) {
						$.bbq.pushState( '/library', 2 );
					} else {
						self.interfaces.router( $.param.fragment() );
						self.observers.hash( $.param.fragment() );
					}

					$('#container').find('.ui-sheet').each( function() {
						self.interfaces[self.interfaces.active.id].observers.sheets[$(this).attr('id')] && self.interfaces[self.interfaces.active.id].observers.sheets[$(this).attr('id')].active(false);
					});

				});

				$(window).trigger('hashchange');

			}
		});

		this.observers.settings.uploading_default_license.subscribe( function(val) {

			var ob = this.observers.settings.uploading_default_license_clean;
			if (val === 'all') {
				ob('All rights reserved');
			} else {
				var bits = val.split(','),
					clean;

				if (bits[0] === 'y') {
					clean = 'Commercial';
				} else {
					clean = 'NonCommercial';
				}

				switch(bits[1]) {
					case 's':
						clean += '-ShareAlike';
						break;

					case 'n':
						clean += '-NoDerivs';
						break;
				}

				ob(clean);
			}
		}, this);

		this.map( this.observers.user, this.session().user );

		this.observers['interface'].subscribe(function(val) {

			var self = this;

			this.classes.app.koken.channel = val;
			this.classes.app.koken.resizeHandlers = [];

			if ( val !== 'text' && $('#edit-area').length && $('#edit-area').data('redactor') ) {
				$('#edit-area').destroyEditor();
			} else if (val !== 'site') {
				$('.sp-container').remove();
			}

		}, this);

		window.setInterval( function() {
			$('span.moment_output').each( function(i, s) {
				$(s).text( moment( $(s).data('raw') * 1000 ).fromNow() );
			});
		}, 30000);

		$(document).trigger('app.loaded');

	},

	events: {

		delete_tag_confirm: function(el, parent) {
			this.classes.modal.delete_tag.show(el,parent);
		},

		delete_tag: function(el, parent) {
			this.interfaces.app.models.tags.del(ko.dataFor(el[0]).id);
		},

		init_copiers: function() {

			var self = this;

			$(document).on('mouseenter mouseleave', 'a.copier', function() {
				if ( $(this).parent().find('.zclip').length > 0 ) return;
				$(this).zclip({
					copy: function() { return $(this).parent().parent().find('textarea').val() },
					path: 'js/ZeroClipboard.swf',
					afterCopy: function() { self.notify().success('Link copied to clipboard'); }
				});
			});

		},

		init_triggers: function() {

			// Setup trigger for closing datepickers
			$(document).on('click', 'button:contains("Set")', function() {
				$('#right-col-xtra button:contains("Done")').trigger('click');
			});

		},

		category_actions: function(el,parent,override) {

			var p		= el.parents('div.col-row:first');

			if ( ! p.length ) { return; }

			var	id		= ko.dataFor(p[0]).id,
				app		= this.interfaces.app,
				input	= p.find('div.check-edit input'),
				ids		= [],
				ret		= {};

			if ( el.is(':checked') ) {
				this.observers.alteredcategories.push( el.closest('div.col-row').find('label').text().trim() );
			}

			if ( el.hasClass('edit-cat') ) {
				p.find('.display').hide();
				p.find('.panel-hover-link').hide();
				p.find('.check-edit').show();
				p.addClass('editing');
				$('input.field').off('.drawer_editcat').on('keydown.drawer_editcat', function(e) {
					if ( e.which === 13 ) {
						// Fix for sheets losing ENTER priority
						if ($(e.target).closest('.ui-sheet').length >= 1) {
							return false;
						}
						if ($(e.target).val() !== '') {
							$(e.target).parent().find('.save-edit-cat').length && $(e.target).parent().find('.save-edit-cat').trigger('click');
						}
					}
				});
			}

			if ( el.hasClass('cancel-cat') ) {
				p.find('.display').show();
				p.find('.check-edit').hide();
				p.find('.panel-hover-link').removeAttr('style');
				p.removeClass('editing');
			}

			if ( el.hasClass('add-cat') ) {

				var _inp		= parent.parent().parent().find('input'),
					hasCheck	= parent.closest('#category_edit_drawer').find('input:checked'),
					self		= this;

				if ( _inp.val().trim().length <= 0 ) {
					this.notify().warn('Categories cannot be left blank');
					return false;
				}

				self.observers.tempcategories([]);

				$.each( hasCheck, function(k,v) {
					self.observers.tempcategories.push( $(this).closest('div.col-row').data('id') );
				});

				id = _inp.val();
				self.observers.alteredcategories.push(id);
				app.models.categories.set(id);
				_inp.val('');

			}

			if ( el.hasClass('delete-cat') ) {
				if (typeof override == 'boolean' && override == true) {
					app.models.categories.del(id);
				} else {
					this.classes.modal.delete_category.show(el,parent);
					$('.modal-outer #category_name').text(el.parents('div.col-row:first').find('label').text());
				}
			}

			if ( el.hasClass('save-edit-cat') ) {
				$('span.cat[data-id=' + id + ']').html( input.val() );
				app.models.categories.update( id, input.val(), function() {
					if (p.find('input[type="checkbox"]').prop('checked') == true) $('#right-col-xtra').find('.col-row[data-id="' + id + '"] input').prop('checked',true);
				});

			}

			if ( el.hasClass('save-cat') ) {

				var catsChecked = [];

				$('#category_edit_drawer').find('input:checked').each( function(k,v) {
					catsChecked.push($(v).parent().find('label').text());
					ids.push($(v).parent().data('id'));
				});

				this.pubsub( 'category.save', ids.join(',') );

				// Retest if cats checked are the same as altered categories, if not update altered categories
				var replaceAlteredCategories = [];
				$.each(this.observers.alteredcategories(), function(i, ac) {
					if (catsChecked.indexOf(ac) != -1) {
						replaceAlteredCategories.push(ac);
					}
				});
				this.observers.alteredcategories(replaceAlteredCategories);

				if ( this.observers.alteredcategories().length <= 0 ) return;

				var tot = ( $('#three-col-mid li.selected').length > 0 ) ? $('#three-col-mid li.selected').length : 1,
					_cats = this.helpers.unique( this.observers.alteredcategories() ).join(', '),
					msg = ( this.observers.sidebar && this.observers.sidebar.asset.album_type && tot === 1 ) ? 'Category ' + _cats + ' assigned to ' + this.observers.sidebar.asset.title() : 'Category ' + _cats + ' assigned to ' + tot + ' items';

				if ( $('#three-col-mid li.selected').data('which') === 'thumbnail' ) msg = 'Category ' + _cats + ' assigned to ' + tot + ' items';

				this.notify().success(msg);
				this.observers.alteredcategories([]);

			}

		},

		tag_actions: function(el,parent) {

			var tags = [], self = this;

			$('#tags_edit_drawer .active-tag').each(function(idx, element) {
				tags.push($(element).text());
			});

			if ( el.hasClass('create-tag') ) {

				this.classes.app.koken.tagsHasBeenEdited = true;

				var input = el.closest('.col-group').find('input'),
					parsed_tags = this.helpers.parse_tag_input(input.val()),
					noobs = $.grep(parsed_tags, function(x) { return $.inArray(x, tags) < 0; });

				if (noobs.length) {
					this.notify().success( noobs.join(', ') + ' tag' + (noobs.length > 1 ? 's' : '') + ' created' );
					tags = tags.concat(noobs);

					if (self.interfaces.active.id === 'library' && self.interfaces.library.observers.selection().length > 1) {
						this.pubsub('tags.multisave', {
							data: tags.join(','),
							type: 'create'
						});
					} else {
						this.pubsub('tags.save', {
							data: tags.join(','),
							type: 'save'
						});
					}

					var recent = $.map( this.observers.tags(), function(tag, i) { return tag.title; } );

					$.each(noobs, function(i, t) {
						if ( $.inArray(t, recent) === -1 ) {
							self.observers.tags.remove( function(item) { return item.title == t; });
							self.observers.tags.unshift({ title: t });
						}
					});

				} else {
					if (parsed_tags.length <= 0) {
						this.notify().warn('Tags cannot be left blank');
					} else {
						this.notify().warn('Tag "' + parsed_tags.join(', ') + '" has already been assigned');
					}
				}

				input.val('');

				requestAnimationFrame(function() {
					input.focus();
				});

			}

			if ( el.hasClass('recent-tag') ) {

				this.classes.app.koken.tagsHasBeenEdited = true;

				var _t = el.text().toLowerCase().replace(/[\s,\,]+/g, ' ');
				tags.push(_t);
				this.observers.temptags.push(_t);

				if (self.interfaces.active.id === 'library' && self.interfaces.library.observers.selection().length > 1) {
					this.pubsub('tags.multisave', {
						data: tags.join(','),
						type: 'create'
					});
				} else {
					this.pubsub('tags.save', {
						data: tags.join(',')
					});
				}

				this.observers.tags.remove( function(item) { return item.title == _t; });
				this.observers.tags.unshift({ title: _t });
			}

			if ( el.hasClass('done-tag') ) {

				// TODO: Globalize this, makes no sense the way I setup
				if ( this.views && this.views.close_sidebar_drawer ) this.views.close_sidebar_drawer();
				if ( this.events.close_sidebar_drawer ) this.events.close_sidebar_drawer();

				this.observers.drawerOpen = false;

				if (self.interfaces.active.id === 'library' && self.interfaces.library.observers.selection().length > 1) {
					this.pubsub('tags.multisave');
				} else {
					if (this.classes.app.koken.tagsHasBeenEdited) {
						this.classes.app.koken.tagsHasBeenEdited = false;
						this.notify().success('Tags have been successfully updated');
					}
					if ( this.observers.temptags().length <= 0 ) return;
					this.observers.temptags([]);
				}

			}

			if ( el.hasClass('remove-tag') ) {

				this.classes.app.koken.tagsHasBeenEdited = true;

				var tagBeingDeleted = el.parent().find('a:first').text();

				el.parent().remove();

				tags = [];

				$('#tags_edit_drawer').find('.active-tag').each( function(k,v) {
					tags.push( $(v).text() );
				});

				var removing = [];

				$.each( this.observers.temptags(), function(k,v) {
					if ( tagBeingDeleted != v ) {
						removing.push(v)
					}
				});

				this.observers.temptags(removing);

				if (self.interfaces.active.id === 'library' && self.interfaces.library.observers.selection().length > 1) {
					this.pubsub( 'tags.save', {
						data: (!self.observers.sidebar || self.observers.sidebar.id() === 'album') ? tags.join(',') : tagBeingDeleted,
						type: 'delete'
					});
				} else {
					this.pubsub( 'tags.save', {
						data: tags.join(',')
					});
				}

			}

		},

		tour: function(el,parent) {

			var didChange = false;

			var nextPage = function() {

				var links = parent.find('.pag a'),
				currentLink = parent.find('.pag .c0'),
				next = currentLink.index() + 1;

				if ( next > ( links.length - 1 ) ) {
					next = 0;
				}

				links.eq(next).addClass('c0');
				currentLink.removeClass('c0');

			}

			var prevPage = function() {

				var links = parent.find('.pag a'),
				currentLink = parent.find('.pag .c0'),
				prev = currentLink.index() - 1;

				if ( prev < 0 ) {
					prev = links.length - 1;
				}

				links.eq(prev).addClass('c0');
				currentLink.removeClass('c0');

			}

			if ( el.closest('div').hasClass('pag') ) {
				if ( ! el.hasClass('c0') ) {
					parent.find('.c0').removeClass('c0');
					el.addClass('c0');
					didChange = true;
				}
			} else {
				if ( el.closest('div').hasClass('next') ) {
					nextPage();
					didChange = true;
				}
				if ( el.closest('div').hasClass('prev') ) {
					prevPage();
					didChange = true;
				}
			}

			if ( !didChange ) { return; }

			$('.tour-current').stop().fadeOut(function() {
				$(this).removeClass('tour-current');
				$( '#tour-' + parent.find('.pag .c0').text().toLowerCase() ).addClass('tour-current');
				$('.tour-current').fadeIn();
			});

		},

		update_slug: function(el, parent) {
			var slug = parent.parent().find('input[type="text"]').val(),
				existingSlug = parent.parent().find('input[type="hidden"]').val(),
				validateRegex = /^[0-9\-]*[a-z][a-z0-9\-]*$/;

			if (slug === existingSlug) {
				this.views.close_sidebar_drawer();
				return;
			}

			if (!slug.length) {
				this.notify().warn('Site link slug may not be empty');
				return;
			}

			if (!validateRegex.test(slug)) {
				this.notify().warn('Slug edit error. Must contain at least one lowercase letter.');
				return;
			}

			var url = 'text',
				id;

			if (this.observers.sidebar) {
				url = this.observers.sidebar.id() === 'content' ? 'content' : 'albums';
				id = this.observers.sidebar.asset.id();
			} else {
				id = this.observers.page_to_edit.id();
			}

			var notify = this.notify().wait('Updating slug');

			this.sync({
				request: url + '/' + id,
				method: 'PUT',
				context: this,
				data: 'slug=' + slug,
				finished: function(data) {
					if (data.error) {
						notify.warn(data.error);
					} else {
						notify.success('Site link slug changed to /' + data.slug);

						if (url === 'text') {
							this.observers.page_to_edit.url(data.url);
							this.observers.page_to_edit.slug(data.slug);
						} else {
							this.observers.sidebar.asset.url(data.url);
							this.observers.sidebar.asset.slug(data.slug);
						}

						this.views.close_sidebar_drawer();
					}
				}
			});
		},

		timestamp_actions: function(el,parent) {

			if ( el.hasClass('datepicker') ) {

				if ( ! el.hasClass('hasDatepicker') ) {

					var val = el.val(),
						max = new Date().getTime();

					if (el.data('raw') && el.data('raw') > 0) {
						val = el.data('raw');
					}

					if ( ! isNaN(val) && (val.length > 0 || val > 0) ) {

						var v = Number(val);

						var pad = function(n) {
							return n < 10 ? '0' + n : n;
						};

						if (el.data('no-tz-shift')) {
							v = moment.utc(v*1000);
						} else {
							v = moment(v*1000).tz(this.observers.settings.site_timezone());
						}

						el.val(v.format('YYYY/MM/DD HH:MM:SS'));

					}

					el.datetimepicker({
						timeFormat: 'HH:mm:ss',
						dateFormat: 'yy/mm/dd',
						maxDate: max
					});

				}

				el.datetimepicker('show');

			}

			if ( el.hasClass('done-timestamp') ) {

				this.pubsub( 'timestamp.save', parent.find('input').val() );

				// TODO: Globalize this, makes no sense the way I setup
				if ( this.views && this.views.close_sidebar_drawer ) { this.views.close_sidebar_drawer(); }
				if ( this.events.close_sidebar_drawer ) { this.events.close_sidebar_drawer(); }

			}

			if ( el.hasClass('done-timestamp-created') ) {

				this.pubsub( 'timestamp_created.save', parent.find('input').val() );

				// TODO: Globalize this, makes no sense the way I setup
				if ( this.views && this.views.close_sidebar_drawer ) { this.views.close_sidebar_drawer(); }
				if ( this.events.close_sidebar_drawer ) { this.events.close_sidebar_drawer(); }

			}

		},

		toggle_sheet: function(el) {

			var sheet	= typeof el === 'string' ? el : el.closest('[data-sheet]').data('sheet'),
				current	= this.observers.sheets[sheet].active(),
				reset	= this.observers.sheets[sheet].reset,
				scroll	= $('#'+sheet).find('.scroll_wrapper'),
				self	= this;

			if (reset) {
				reset.call( this.observers.sheets[sheet] );
			}

			$('#container').find('.ui-sheet').each( function() {
				if (self.observers.sheets[$(this).attr('id')]) self.observers.sheets[$(this).attr('id')].active(false);
			});

			if ( current && this.observers.sheets[sheet].onunload ) this.observers.sheets[sheet].onunload.call(this,$('#'+sheet));

			this.observers.valid(false);

			$('#'+sheet).css({ left: '50%' });
			this.observers.sheets[sheet].active(!current);

			if ( this.observers.sheets[sheet].active() ) {

				$('#'+sheet).find('input').off('focus keyup');
				$('#'+sheet).find('.sheet-options .sub:not(.override-autoclose)').removeClass('open').hide();
				$('#'+sheet).find('.sheet-options .arrow-toggle:not(.override-autoclose)').removeClass('open');

				$('#container').trigger('sheet.activated', this.observers.sheets[sheet]);

				if ( this.observers.sheets[sheet].onload ) {
					this.observers.sheets[sheet].onload.call(this,$('#'+sheet));

					if (this.observers.sheets[sheet].plugins) {
						$.each(this.observers.sheets[sheet].plugins, function(i, plugin) {
							if (plugin.onload) plugin.onload();
						});
					}
				}

				self.classes.form_validation[self.interfaces.active.id].valid && self.classes.form_validation[self.interfaces.active.id].valid(sheet, true);

				// $('.ui-sheet-bg').fadeIn();

			} else {
				if ( this.observers.sheets[sheet].onunload ) {
					this.observers.sheets[sheet].onunload.call(this,$('#'+sheet));
				}
				// $('.ui-sheet-bg').fadeOut();
			}

			if ( this.observers.sheets[sheet].active() ) {
				$('input[type="text"]:first, textarea:first', '#' + sheet).focus();
				$(window).off('keyup.sheet').on( 'keyup.sheet', function(e) {
					if ( e.which === 13 ) {
						$('#'+sheet).find('button').closest('[data-validate]').find('button').andSelf().focus().trigger('click');
						requestAnimationFrame(function() {
							if ($('.form-error-msg:visible').length <= 0) {
								$(window).off('keyup.sheet');
							} else {
								$('.form-error-msg').closest('li').find('input').focus();
							}
						});
					}
					if ( e.which === 27 ) {
						$(window).off('keyup.sheet');
						self.interfaces.app.events.toggle_sheet.call(self,el);
					}
				});
			} else {
				$(window).off('.sheet');
			}

			$(window).trigger('resize');

		},

		toggle_dropdown: function(el,parent) {

			var ob		= ( parent.closest('#interface_container').length <= 0 ) ? this.interfaces.app.observers : this.observers,
				dd		= el.closest('[data-dropdown]').data('dropdown'),
				current	= ob.dropdowns[dd].active(),
				wedge	= ob.dropdowns[dd].wedge.offset || 0,
				offset	= ob.dropdowns[dd].offset || 0,
				top		= ob.dropdowns[dd].top || 0,
				self	= this,
				id;

			parent.closest('#container').find('.opt-menu').each( function() {
				var _ob = ( $(this).closest('#interface_container').length <= 0 ) ? self.interfaces.app.observers : self.observers;
				id = $(this).attr('id');
				if ( id && id.indexOf('_dd') >= 0 ) {
					id = id.replace('_dd','');
					_ob.dropdowns[id].active(false);
				}
			});

			// Cleanup for replace image button
			if (!current) $('#mid-menu-top .moxie-shim').remove();

			ob.dropdowns[dd].active(!current);

			if ( ob.dropdowns[dd].active() === true && ! $('#'+dd+'_dd').data('bound') ) {
				$('#'+dd+'_dd').data('bound',true).find('li').each( function(k,v) {

					var item = $(v);

					if (item.find('a').attr('title') === 'insert_break') {
						item.empty().addClass('break');
					}

					if ( item.attr('data-bind') ) {

						if ( item.data('bind').length > 0 ) {
							item.prepend('<!-- ko with: dropdowns.' + dd + '.items()[' + k + '] -->').append('<!-- /ko -->');
							var _el = item.get(0);
							ko.cleanNode(_el);
							ko.applyBindings(ob, _el);
						}

						item.find('.bind').each( function(j,w) {
							var _el = w;
							ko.cleanNode(_el);
							ko.applyBindings(ob, _el);
						});

					}

				});

			}

			if (!$('#'+dd+'_dd').find('li:hidden').last().hasClass('break') && $('#'+dd+'_dd').find('li:hidden').last().index() - $('#'+dd+'_dd').find('li.break').index() >= 0 ) {
				$('#'+dd+'_dd').find('li.break').hide();
			} else {
				$('#'+dd+'_dd').find('li.break').show();
			}

			if ( ob.dropdowns[dd].active() === true && ob.dropdowns[dd].onload ) {
				ob.dropdowns[dd].onload.call( this, $('#'+dd+'_dd') );
			}

			this.helpers.outside( $('#'+dd+'_dd'), function() {
				ob.dropdowns[dd].active(false);
				if ( ! ob.dropdowns[dd].active() && ob.dropdowns[dd].onunload ) {
					ob.dropdowns[dd].onunload.call( self, $('#'+dd+'_dd') );
				}
			});

			if ( ob.dropdowns[dd].position ) {
				if ( ob.dropdowns[dd].position() === 'above' ) {
					$('#'+dd+'_dd').css({ top: 'auto', bottom: 10 }).find('.wedge').addClass('rotate');
				}
			}

			$('#'+dd+'_dd').on( 'click', 'li', function() {
				ob.dropdowns[dd].active(false);
				if ( ! ob.dropdowns[dd].active() && ob.dropdowns[dd].onunload ) {
					ob.dropdowns[dd].onunload.call( self, $('#'+dd+'_dd') );
				}
			});

			$('#'+dd+'_dd').css( ( parseInt( offset, 10 ) < 0 ) ? 'right' : 'left', Math.abs( parseInt( offset, 10 ) ) + 'px' ).find('.wedge').css('left',wedge);

			if ( top != 0 && ! $('#'+dd+'_dd').data('ddm') ) {
				$('#'+dd+'_dd').css( 'top', ( parseInt( $('#'+dd+'_dd').css('top'), 10 ) + parseInt( top, 10 ) ) + 'px' ).data('ddm',true);
			}

		},

		toggle_visibility: function(el,parent) {

			var isSheet = ( parent.closest('.ui-sheet').length > 0 ) ? true : false;

			if ( parent.closest('.scroll_wrapper').length ) {
				var scroll_top = parent.closest('.scroll_wrapper')[0].scrollTop;
			}

			parent.next().toggle().toggleClass('open').end().find('a').toggleClass('open');
			parent.next().find('input[type="text"]').focus();

			if ( scroll_top > 0 ) {
				parent.closest('.scroll_wrapper')[0].scrollTop = (scroll_top + 40);
			}

			if (!isSheet && parent.attr('id') !== '') {
				this.cookie('library:' + parent.attr('id'), parent.find('a:first').hasClass('open') ? 'open' : 'closed');
			}

		},

		ignore_release: function() {
			this.cookie('ignore_release', this.observers.update_available());
			this.observers.update_available(false);
		},

		run_migrations: function(migrations) {
			this.classes.modal.update_available.show();

			var total = migrations.length + 1,
				self = this,
				migrate = function() {
					self.classes.modal.update_available.progress( (total-(migrations.length-1))/total );
					$('.update_progress').siblings('h1').text('Updating');
					$('.update_progress').siblings('p').text('The new version is now being installed.');
					if (migrations.length) {
						next();
					} else {
						$('.update_progress').siblings('h1').text('Refreshing');
						$('.update_progress').siblings('p').text('One moment.');
						if (self.has.localStorage) {
							localStorage.clear();
						}
						setTimeout(function() {
							location.reload(true);
						}, 1000);
					}
				},
				next = function() {
					var n = migrations.shift();
					self.sync({
						request: 'update/migrate/' + n.replace(/\.php$/, ''),
						method: 'POST',
						context: self,
						finished: function(data) {
							if (data && data.done) {
								migrate();
							} else {
								migrations.unshift(n);
								next();
							}
						}
					});
				};

				migrations = [ 'schema' ].concat(migrations);
				migrate();
		},

		install_release: function() {

			var self = this;

			this.classes.modal.update_available.show();

			this.sync({
				request: 'update',
				method: 'POST',
				data: 'url=' + this.observers.update_url(),
				context: this,
				finished: function(data) {
					if (data && data.error) {
						this.classes.modal.update_failed.show();

						$(document)
							.on( 'click', '#clear_error', function() {
								self.classes.modal.update_failed.hide();
							});

						$.post('../recover.php');
					} else if (data && data.requirements) {
						this.classes.modal.update_compat_fail.show();

						$.each(data.requirements, function(i, obj) {
							$('<li/>')
								.text(obj.required + '. Your server has only: ' + obj.local + '.')
								.appendTo('#unmet_requirements');
						});

						$(document)
							.on( 'click', '#clear_error', function() {
								self.classes.modal.update_compat_fail.hide();
							});
					} else if (data && data.migrations !== undefined) {
						this.events.run_migrations(data.migrations);
					} else {
						$.post('../recover.php', function() {
							self.classes.modal.update_recovered.show();

							$(document)
								.on( 'click', '#clear_error', function() {
									self.classes.modal.update_recovered.hide();
								});
						});
					}
				}
			});
		}

	},

	controllers: {

		fetch_themes: function(callback) {
			this.sync({
				request: 'themes',
				context: this,
				finished: function(data) {
					this.observers.themes(data);
					callback && callback.apply(this, data);
				}
			});
		},

		toggle_plugin: function(data, callback) {
			var	notify = this.notify(),
				self = this;

			if (data.activated) {
				notify.wait('Disabling');

				this.interfaces.plugins && $.each(this.interfaces.plugins, function(i, plugin) {
					if (plugin.path == data.path) {
						$(document).off(self.interfaces.plugins[i].hooks);
						delete self.interfaces.plugins[i];
					}
				});

				this.sync({
					request: 'plugins/' + data.id,
					method: 'DELETE',
					context: this,
					finished: function(d) {
						notify.success(data.name + ' has been disabled');
						var copy = $.extend({}, data);
						delete(copy.id);
						copy.activated = false;
						this.observers.user_plugins.replace( data, copy );
						this.observers.process_plugin_data({ plugins: this.observers.user_plugins() });
						callback && callback.call(this);
					}
				});
			} else {
				notify.wait('Activating');
				this.sync({
					request: 'plugins',
					method: 'POST',
					data: "path=" + data.path,
					context: this,
					finished: function(d) {
						var _plugin;
						$.each(d.plugins, function(i, p) {
							if (p.path === data.path) {
								_plugin = p;
								return false;
							}
						});

						if (_plugin.console) {
							$.ajax({
								url: '../api.php?/plugins/css',
								success: function(data){
									 $('<style></style>').appendTo('head').html(data);
								}
							});

							$.ajax({
								url: '../api.php?/plugins/js',
								dataType: 'script',
								cache: true,
								context: this,
								success: function() {
									var self = this;
									this.interfaces.plugins && $.each(this.interfaces.plugins, function(i, plugin) {
										if (plugin.path == data.path) plugin.init(function() {
											notify.success(data.name + ' has been activated');
											requestAnimationFrame(function() {
												callback && callback.call(self);
											});
										});
									});
								}
							});
						} else {
							notify.success(data.name + ' has been activated');
							callback && callback.call(this);
						}

						this.observers.process_plugin_data(d);
					}
				});
			}
		},

		load_categories: function() {

			var page = 0,
				pages = Infinity,
				data = [],
				self = this;

			function next()
			{
				if (page >= pages) {
					self.controllers.load('categories', data);
				} else {
					page++;
					self.sync({
						request: 'categories/page:' + page,
						context: self,
						finished: function(d) {
							data = data.concat(d.categories);
							pages = d.pages;
							next();
						}
					});
				}
			}

			next();
		},

		lazy_album_sheet: function() {
			var l = function() {
				var img = $('.ui-sheet.active span.preview img.loading:first');
				if (img.length) {
					img.on('load', function() {
						img.removeClass('loading');
						l();
					});
					img.attr('src', img.data('src'));
				}
			}

			l();
		},

		lazy: function(el,opts) {

			if ( !opts ) {
				this.interfaces.app.classes.scrollbar.mid_multi.update();
				this.interfaces.app.classes.scrollbar.mid_multi.loopy(true);
			}

			el = ( typeof el === 'string' ) ? $(el) : el;

			if ( el.find('li').length <= 0 ) { return; }

			var wrap		= el.closest('.scroll_wrapper'),
				self		= this;

			// Prevents js errors if for some reason the scrollbar didn't load
			if ( wrap.length <= 0 ) { return; }

			var top			= wrap.offset().top,
				bottom		= top + wrap.height(),
				loadklass	= (opts && opts !== 'suppress') ? 'loading-sheet' : 'loading';

			if (top === bottom) {
				// Wait for sheet resize if necessary
				window.setTimeout(function() {
					self.controllers.lazy(el, opts);
				}, 10);
			}

			var load_image = function() {

				var l		= $('.loadme:first'),
					img		= l.find('img:first'),
					item	= (opts && opts !== 'suppress') ? self.observers.load_content.content()[l.data('index')] : self.observers.content()[l.data('index')],
					url, src;

				if ( !item ) {
					if ( !opts ) {
						window.setTimeout(function() {
							self.interfaces.app.classes.scrollbar.mid_multi.loopy();
						},250);
					}
				}

				if ( el.hasClass('set') || el.hasClass('album') ) {
					load_image();
					return;
				}

				if ( l.length ) {

					if ( item && item.file_modified_on && ! item.album_type ) {

						img.off('.tload' + item.id).on('load.tload' + item.id, function() {
							var sm = $(this).parents('li').find('span.tn-wrap img');
							sm.off('.ns' + item.id).on('load.ns' + item.id, function() {
								$(this).fadeIn();
							});
							sm.attr('src', $(this).attr('src'));

							$(this).fadeIn(500, function() {
								l.removeClass( loadklass + ' staged').addClass('loaded');
							});

							load_image();
						});

						l.removeClass('loadme').addClass('staged');

						var uri = img.data('url') || item.presets && item.presets.small[self.config.retina ? 'hidpi_url' : 'url'] || item.original.url;
						src = uri.replace(RegExp('^' + location.protocol + '//(www.)?' + location.host.replace(/^www\./, '')), '');
						img.attr( 'src', src );

					}

				}

			};

			$('.loadme').removeClass('loadme');

			el.children(':not(.loaded,.staged,.album,.set)').each( function(k,v) {

				var t	= $(v).offset().top,
					_t	= t + $(v).height();

				if ( _t > top && t < bottom ) {
					$(v).addClass( loadklass + ' loadme' );
				} else if ( t > bottom ) {
					return false;
				}

			});

			el.children().each(function() {

				if ( el[0].id === 'content_list' ) {
					// This initiates the drag methods since we now know this asset is in the DOM
					var id = $(this).data('id');
					if ( self.classes.asset[id] && self.classes.asset[id].drag ) { self.classes.asset[id].drag( $(this) ); }
				}

			});

			load_image();

		},

		multiselect: function(el,cb) {

			var self = this;

			$(document).off('click.shortcuts').on( 'click.shortcuts', el, function(e) {

				// Turning off clicks on favorite,featured,cover badges
				if ( $(e.target).closest('.img-badges').length > 0 && !$(e.target).hasClass('unlisted') && !$(e.target).hasClass('private') ) { return; }

				var selection	= self.observers.load_content.selected.content().length && self.observers.load_content.selected.content() || self.observers.selection(),
					item		= $(this),
					parent		= item.parent(),
					id			= item.data('id'),
					current		= selection[0],
					index;

				if ( e.shiftKey ) {

					var base = item.siblings('li[data-id="' + current + '"]');

					selection.push(id);

					if ( item.index() > base.index() ) {

						item.prevUntil(base).each(function(i, _li) {
							selection.push($(_li).data('id'));
						});

					} else {

						item.nextUntil(base).each(function(i, _li) {
							selection.push($(_li).data('id'));
						});

					}

				} else if ( e.metaKey || e.ctrlKey ) {

					index = $.inArray( id, selection );

					if ( index !== -1 ) {
						selection.splice(index, 1);
					} else {
						selection.push(id);
					}

				} else {
					selection = [item.data('id')];
				}

				selection = self.helpers.unique(selection);

				self.observers.load_content.selected.content(selection);

				if (cb) cb(selection);

			});

		},

		load_content: function(el,url,scroll,type,visibility) {

			el		= ( typeof el === 'string' ) ? $(el) : el;
			scroll	= ( typeof scroll === 'string' ) ? $(scroll) : scroll;
			type	= type || 'photo';
			visibility = visibility || false;

			//if ( ! el[0].tagName ) { return false; }

			var load_to = this.observers.load_content.selected.content().length ? this.observers.load_content.selected.content()[0] : false;

			this.observers.load_content.content([]);
			this.observers.load_content.selected.content([]);

			if (this.observers.load_content.subscription) {
				this.observers.load_content.subscription.dispose();
			}

			var self = this;

			this.observers.load_content.subscription = this.observers.load_content.selected.content.subscribe( function(val) {
				el.find('.selected').removeClass('selected');
				$.each( val, function(i, v) {
					el.find('li[data-id="' + v + '"]').addClass('selected');
				});

				if (val.length === 0) {
					self.observers.load_content.selected.text('');
				} else if (val.length === 1) {
					$.each( self.observers.load_content.content(), function(k,v) {
						if ( v.id == val[0] ) {
							var _filename = v.filename;
							if (_filename === 'Vimeo') {
								_filename = (v.title.length > 0) ? v.title : 'Vimeo video';
							}
							if (_filename === 'Instagram') {
								_filename = (v.title.length > 0) ? v.title : 'Instagram photo';
							}
							self.observers.load_content.selected.text(_filename);
							return false;
						}
					});
				} else {
					self.observers.load_content.selected.text('Multiple (' + val.length + ')');
				}
			});

			var show_error = function() {
				el.html( $('#sheet_content_error').html() );
			};

			if (url === 'last_upload') {
				if (this.observers.settings.last_upload()) {
					url = 'content/after:' + this.observers.settings.last_upload() + '/order_by:uploaded_on/visibility:any';
				} else {
					show_error();
					return;
				}
			} else if (url === 'quick_collection') {
				if ( this.persist('library.quick_collection') ) {
					url = 'content/' + this.persist('library.quick_collection') + '/visibility:any';
				} else {
					show_error();
					return;
				}
			}

			this.observers.load_content.url(url);

			var load_page			= 1,
				is_loading			= false,
				pages_loaded		= 0,
				total_pages			= -1,
				content_per_page	= 50;

			var load = function() {

				var _url = url + '/types:' + type + '/limit:' + content_per_page + '/page:' + load_page;

				if (visibility) {
					_url += '/visibility:' + visibility;
				}

				self.sync({
					request: _url,
					context: self,
					finished: function(data) {

						var fragments = [], els;

						var create_node = function(d,k) {

							var w	= d.presets.small.width,
								h	= d.presets.small.height;

							return '<li data-id="' + d.id + '" data-index="' + k + '">\
								<a href="#" class="content">\
									<span class="img-wrap" style="height:' + h + 'px; width:' + w + 'px;">\
										<span class="vid_time">' + ((d.duration && d.duration.clean) ? d.duration.clean : '') + '</span>\
										<img width="' + w + '" height="' + h + '" class="media" data-url="' + d.presets.small[self.config.retina ? 'hidpi_url' : 'url'] + '" style="display:none;">\
									</span>\
								</a>\
							</li>';

						};

						pages_loaded	= data.page;
						total_pages		= data.pages;

						if ( total_pages > 0 ) {

							var ids = [];

							$.each( data.content, function(k,v) {
								var index = ( (data.page-1) * content_per_page ) + k;
								ids.push(v.id);
								self.observers.load_content.content.push(v);
								fragments.push( create_node(v,index) );
							});

							if ( data.page === 1 ) {
								el.html(fragments.join(''));
							} else {
								el.append(fragments.join(''));
							}

							self.controllers.multiselect( '#' + el.attr('id') + ' li' );

							this.classes.scrollbar[scroll.attr('id')].listen( 'during', function() {
								self.lazy(el,true);
							});

							this.classes.scrollbar[scroll.attr('id')].listen( 'page', function() {
								if ( !is_loading && ( pages_loaded < total_pages && total_pages > 1 ) ) {
									load_page++;
									is_loading = true;
									load();
								}
							});

							is_loading = false;

							self.controllers.lazy(el,true);

							if (load_to) {
								if ($.inArray(load_to, ids) === -1 && pages_loaded < total_pages) {
									load_page++;
									is_loading = true;
									load();
								} else {
									self.observers.load_content.selected.content([ load_to ]);
									load_to = false;
								}
							}

						} else {

							show_error();

						}

					}
				});

			};

			load();

		},

		replace: {

			init: function() {

				var uploader	= $.extend(this.config.uploader, {}, true),
					self		= this;

				uploader.properties.filters = [
					{
						title		: "Image files",
						extensions	: "jpg,gif,png,jpeg"
					},
					{
						title		: "Videos",
						extensions	: "mp4,m4v"
					}
				];

				$('.plupload').remove();
				$('.moxie-shim.moxie-shim-html5').remove();

				if ( uploader.instance ) { delete uploader.instance; }

				uploader.properties.browse_button	= 'replace_content';
				uploader.properties.drop_element	= false;
				uploader.properties.url				= this.config.paths.base_relative + 'api.php?/content/' + this.observers.selection();

				uploader.instance					= new plupload.Uploader(uploader.properties);
				uploader.instance.context			= this;
				uploader.instance.uploaded			= [];

				uploader.instance.__koken = {
					progress: false,
					id: this.observers.selection()
				};

				uploader.instance.bind( 'PostInit', function( up, params ) {

					if ( up.features.chunks ) {
						up.settings.max_file_size	= plupload.parseSize('5gb');
						up.settings.chunk_size		= self.system.upload_limit;
					} else {
						up.settings.max_file_size	= self.system.upload_limit;
					}

					// Need this to trigger replace instead of new
					up.settings.multipart_params = {
						_method: 'PUT'
					};

				} );

				uploader.instance.bind( 'UploadProgress', function( up, file ) {

					// Per item progress
					up.__koken.progress.stop().animate( { width: up.total.percent + '%' } );
					up.__koken.thumb.stop().animate( { opacity: (100 - up.total.percent)/100 });

				});

				uploader.instance.bind( 'Error', function( up, error ) {
					self.notify().warn( 'Error uploading ' + error.file.name + ': ' + error.message );
				});

				uploader.instance.bind( 'QueueChanged', function( up ) {

					if ( up.files.length ) {

						var el = $('#list_item_' + up.__koken.id);
						el.addClass('replacing');
						up.__koken.thumb = el.find('.img-wrap img');
						el.find('.img-wrap').append('<div class="replace-progress"><div></div></div>');
						up.__koken.progress = el.find('.img-wrap .replace-progress div');
						up.__koken.filename = el.find('span.title').html();
						el.find('span.title').html('Replacing…');
						up.start();
					}

				});

				uploader.instance.bind( 'FileUploaded', function( up, file, data ) {

					var index = up.context.observers.properties.content.selected.index(),
						item = up.context.observers.content()[index],
						data = $.parseJSON(data.response),
						li = $('#list_item_' + up.__koken.id),
						img	= $('#lib-content-sel img'),
						message = item.filename + ' has been replaced';

					if (data.filename != item.filename) {
						message += ' with ' + data.filename;
					}

					item = $.extend({}, item, data),

					img.parent().addClass('loading');
					img.animate({ opacity: 0 });

					li.removeClass('loaded').addClass('loading');

					up.context.controllers.update_item( item, true );

					up.context.notify().success(message);

				} );

				uploader.instance.init();
			}
		},

		plugin_upload: {

			init: function(el) {

				var uploader	= $.extend(this.config.uploader, {}, true),
					self		= this;

				uploader.properties.filters = [
					{
						title		: "Upload",
						extensions	: el.data('allowed')
					}
				];

				if ( uploader.instance ) { delete uploader.instance; $('.plupload').remove(); $('.moxie-shim.moxie-shim-html5').remove(); }

				uploader.properties.browse_button	= el.attr('id');
				uploader.properties.url				= this.config.paths.base_relative + 'api.php?/content';

				uploader.instance					= new plupload.Uploader(uploader.properties);
				uploader.instance.context			= this;
				uploader.instance.uploaded			= [];

				uploader.instance.bind( 'PostInit', function( up, params ) {

					if ( up.features.chunks ) {
						up.settings.max_file_size	= plupload.parseSize('5gb');
						up.settings.chunk_size		= self.system.upload_limit;
					} else {
						up.settings.max_file_size	= self.system.upload_limit;
					}

					up.settings.multipart_params = {
						plugin		: el.data('plugin'),
						basename	: el.data('basename')
					};

				});

				uploader.instance.bind( 'FileUploaded', function( up, file, data ) {

					window.setTimeout(function() {

						var item = $.parseJSON( data.response );
						$('#plugin-edit-' + el.data('field')).val(item.filename);
						var path = self.config.paths.base_relative + 'storage/plugins/' + el.data('plugin') + '/storage/' + item.filename + '?' + Math.round(Math.random()*10000);
						$('#plugin-upload-' + el.data('field') + '-preview').attr('src', path);

					}, 250);

				});

				uploader.instance.bind( 'Error', function( up, error ) {
					self.notify().warn( 'Error uploading ' + error.file.name + ': ' + error.message );
				});

				uploader.instance.bind( 'UploadProgress', function( up, file ) {

					// Progress somewhere, maybe a .wait()

				});

				uploader.instance.bind( 'QueueChanged', function( up, e ) {
					if (up.files.length > 1) {
						up.splice(1, up.files.length - 1);
					}
					if ( up.files.length === 1 ) {
						up.start();
					}
				});

				uploader.instance.init();
			}
		},

		text_upload: {

			init: function() {

				var uploader	= $.extend(this.config.uploader, {}, true),
					self		= this;

				uploader.properties.filters = [
					{
						title		: "Image files",
						extensions	: "jpg,gif,png,jpeg"
					}
				];

				if ( uploader.instance ) { delete uploader.instance; $('.plupload').remove(); $('.moxie-shim.moxie-shim-html5').remove(); }

				uploader.properties.browse_button	= 'text-upload';
				uploader.properties.drop_element	= 'text-upload';
				uploader.properties.url				= this.config.paths.base_relative + 'api.php?/content';

				uploader.instance					= new plupload.Uploader(uploader.properties);
				uploader.instance.context			= this;
				uploader.instance.uploaded			= [];

				uploader.instance.bind( 'PostInit', function( up, params ) {

					if ( up.features.chunks ) {
						up.settings.max_file_size	= plupload.parseSize('5gb');
						up.settings.chunk_size		= self.system.upload_limit;
					} else {
						up.settings.max_file_size	= self.system.upload_limit;
					}

					up.settings.multipart_params = {
						text		: true
					};

				});

				uploader.instance.bind( 'FileUploaded', function( up, file, data ) {

					window.setTimeout(function() {

						var item = $.parseJSON( data.response );
						self.observers.sheets.shortcode_upload.filename( item.filename );

						self.observers.sheets.shortcode_upload.state('ready');

						up.refresh();
						up.splice();

					}, 250);

				});

				uploader.instance.bind( 'Error', function( up, error ) {
					self.notify().warn( 'Error uploading ' + error.file.name + ': ' + error.message );
				});

				uploader.instance.bind( 'UploadProgress', function( up, file ) {

					$('#text-upload-progress').text( up.total.percent );

				});

				uploader.instance.bind( 'QueueChanged', function( up, e ) {
					if (up.files.length > 1) {
						up.splice(1, up.files.length - 1);
					}
					if ( up.files.length === 1 ) {
						self.observers.sheets.shortcode_upload.filename(up.files[0].name);
						self.observers.sheets.shortcode_upload.state('uploading');
						up.start();
					}
				});

				uploader.instance.init();
			}
		},

		upload: {

			instance: null,

			cleanup: function() {
				$('.plupload,.moxie-shim').remove();
			},

			init: function(override) {

				var uploader	= $.extend(this.config.uploader, {}, true),
					self		= this;

				self.classes.channel.library.isUploading = true;

				if (!override) {
					uploader.properties.filters = [
						{
							title		: "Image files",
							extensions	: "jpg,gif,png,jpeg"
						},
						{
							title		: "Videos",
							extensions	: "mp4,m4v"
						}
					];

					uploader.properties.browse_button	= 'upload-drop-area';
					uploader.properties.drop_element	= 'upload-drop-area';
					uploader.properties.url				= this.config.paths.base_relative + 'api.php?/content';
					uploader.instance					= new plupload.Uploader(uploader.properties);
					uploader.instance.context			= this;
					uploader.instance.uploaded			= [];
					uploader.instance.starttime			= Math.floor(new Date().getTime() / 1000);

					this.interfaces.app.controllers.upload.instance = uploader.instance;
				} else {
					uploader = override;
					uploader.instance.uploaded			= [];
					uploader.instance.starttime			= Math.floor(new Date().getTime() / 1000);
					uploader.instance.unbind('QueueChanged');
				}

				uploader.updateStatus = function( up, file ) {

					this.currentFile = file;
					file.current = up.total.uploaded + up.total.failed + 1;
					file.total = up.files.length;

					$('#file-info').html( $('#upload_file').html() );
					$('#file-info').find('.up_name').html(file.name);
					$('#file-info').find('.up_current').html(file.current);
					$('#file-info').find('.up_total').html(file.total);

					$('#upload_queued_num').text(file.total - file.current);

				};

				uploader.close = function( up, refresh ) {

					$('#app').removeClass('uploading');
					$('#upload-drop-start').show();
					$('#upload-drop-queued').hide();
					$('#upload_queued_num').text(0);

					up.__koken.container.slideUp( 650, function() {

						if (refresh) {

							self.classes.app.koken.lastClickedElement = false;

							if ( up.uploaded.length > 0 && up.toAlbum || override && up.toAlbum ) {
								var albumParams = {
									request: 'albums/' + up.toAlbumId + '/content/' + up.uploaded.join(','),
									method: 'POST',
									context: up.context,
									finished: function(data) {
										if (!override) {
											uploader.instance.unbindAll();
										}
										this.observers.update_album_counts(data);
										this.controllers.load_content(null, true);
									}
								}
								if (!up.context.observers.properties.album.selected().album.public) albumParams.data = 'match_album_visibility=1';
								up.context.sync(albumParams);
								if (!override) {
									up.toAlbum = false;
									up.toAlbumId = null;
								}
								up.uploaded = [];
							} else if (up.uploaded.length) {
								up.context.notify().success( up.uploaded.length + ' items finished uploading to the Library' );
								if (!override) {
									requestAnimationFrame(function() {
										$.bbq.pushState( '/library/content/after:' + up.context.observers.settings.last_upload() + ((up.uploaded.length > 1) ? '/visibility:any/order_by:uploaded_on' : '/view:single/selection:' + up.uploaded[0]), 2 );
									});
								} else {
									if (up.context.observers.api_url().indexOf('favorites') !== -1) {
										up.context.sync({
											request: 'favorites/' + up.uploaded.join(','),
											method: 'POST',
											context: up.context,
											finished: function(data) {
												up.context.controllers.load_content(null, true);
											}
										});
									} else if (up.context.observers.api_url().indexOf('content/featured') !== -1) {
										up.context.sync({
											request: 'content/featured/' + up.uploaded.join(','),
											method: 'POST',
											context: up.context,
											finished: function(data) {
												up.context.controllers.load_content(null, true);
											}
										});
									} else {
										up.context.controllers.load_content(null, true);
									}
									up.uploaded = [];
								}
								up.context.controllers.update_summary();
							}

						}

						// Refresh button placement and reset uploader
						up.refresh();
						up.__koken.bars.container.hide();
						up.__koken.bars.item.css( { width: '0%' } );
						up.__koken.bars.total.css( { width: '0%' } ); // Remove for single bar
						up.__koken.progress = 0; // Only needed for single bar
						up.total.reset();
						up.splice();

						if (!override) self.interfaces.app.controllers.upload.cleanup();

						Piecon.reset();

						self.classes.channel.library.isUploading = false;

					});

				};

				// Store our own info object
				uploader.instance.__koken = {

					progress	: 0,
					container	: $('#upload-queue'),
					status		: $('#upload-queue div.upload-msg:first'),
					bars		: {
						container	: $('#upload-queue div.bar:first'),
						item		: $('#upload-queue div.bar:first div div:last'),
						total		: $('#upload-queue div.bar:first div div:first')
					}

				};

				uploader.instance.bind( 'PostInit', function( up, params ) {

					if ( up.features.chunks ) {
						up.settings.max_file_size	= plupload.parseSize('5gb');
						up.settings.chunk_size		= self.system.upload_limit;
					} else {
						up.settings.max_file_size	= self.system.upload_limit;
					}

					if ( up.features.dragdrop ) {
						$('#upload-drop-area')
							.off('.pdrag')
							.on('dragover.pdrag', function() {
								$(this).addClass('dragover');
								uploader.dragging = true;
							})
							.on('dragleave.pdrag', function() {
								$(this).removeClass('dragover');
								uploader.dragging = false;
							});
					}

				});

				uploader.instance.bind( 'UploadFile', uploader.updateStatus );

				uploader.instance.bind( 'Error', function( up, error ) {
					self.notify().warn( 'Error uploading ' + error.file.name + ': ' + error.message );
				});

				uploader.instance.bind( 'FileUploaded', function( up, file, data ) {

					// Add uploaded item to the collection and update the view
					var item = $.parseJSON( data.response );
					up.uploaded.push(item.id);

				});

				uploader.instance.bind( 'UploadProgress', function( up, file ) {

					var d = Math.ceil( ( up.total.size - up.total.loaded ) / up.total.bytesPerSec ),
						str;

					if ( !isNaN( d ) && d !== Infinity ) {
						var h = Math.floor(d / 3600);
						var m = Math.floor(d % 3600 / 60);
						var s = Math.floor(d % 3600 % 60);
						str = ( ( h > 0 ? h + ":" : "" ) + ( m > 0 || h > 0 ? ( h > 0 && m < 10 ? "0" : "" ) + String(m) + ":" : "0:" ) + ( s < 10 ? "0" : "" ) + s );
					}

					$('#remaining').html( str );

					// Single bar
					var p = up.total.percent;
					if ( p > up.__koken.progress ) {
						up.__koken.bars.item.stop().animate( { width: up.total.percent + '%' } );
					}

					// Per item progress
					Piecon.setProgress(up.total.percent);

				});

				uploader.instance.bind( 'StateChanged', function( up ) {

					if ( up.state == 2 ) {
						$('#app').addClass('uploading');
						$(document)
							.off('.uprogress')
							.on('click.uprogress', '#upload-progress-cancel-bttn', function () {
								up.stop();
							});
					} else if ( up.state == 1 && up.__koken.container.is(':visible') ) {
						uploader.close(up, true);
					}

				});

				uploader.instance.bind( 'QueueChanged', function( up, e ) {

					if ( up instanceof plupload.Uploader ) { $.extend(up,e); }

					if ( up.files.length ) {
						if ( up.state !== 2 ) {

							up.toAlbumId = self.observers.properties.album.selected().album && self.observers.properties.album.selected().album.id;
							up.toAlbum = (override) ? up.toAlbumId : up.context.observers.shouldUploadToAlbum();

							$('#upload_preset').val( self.observers.settings.uploading_default_visibility() );

							$('#upload-cancel-bttn')
								.off('.cancel')
								.on('click.cancel', function () {
									uploader.close(up);
								});

							var startUpload = function() {

								up.settings.multipart_params = {};
								up.settings.multipart_params.upload_session_start = uploader.instance.starttime;
								up.settings.multipart_params.visibility = (self.observers.sheets.import_content.active()) ? $('#upload_visibility option:selected').val() : self.observers.settings.uploading_default_visibility();
								up.settings.multipart_params.license = self.observers.settings.uploading_default_license();
								up.settings.multipart_params.max_download = self.observers.settings.uploading_default_max_download_size();

								self.observers.settings.last_upload( up.settings.multipart_params.upload_session_start );

								// $('.ui-sheet-bg').fadeOut();

								self.observers.sheets.import_content.active(false);

								if (!self.observers.sheets.import_content.active()) {
									if (up.context.observers.api_url().indexOf('content/visibility:private') !== -1) up.settings.multipart_params.visibility = 'private';
									if (up.context.observers.api_url().indexOf('content/visibility:unlisted') !== -1) up.settings.multipart_params.visibility = 'unlisted';
								}

								up.__koken.bars.container.show();
								up.__koken.status.html( $('#upload_filecon').html() );

								up.__koken.container.slideDown(function() {
									up.uploaded = [];
									up.start();
								});
							}

							if (override) startUpload();

							$('button.import')
								.off('.import')
								.on('click.import', function (e) {
									e.preventDefault();
									$('button.import').off('.import')
									startUpload();
								})
								.removeClass('disabled');

							// Import sheet stuff
							$('#upload-drop-start').hide();
							$('#upload-drop-queued').show();

							var itemsQueuedElement = $('#upload_queued_num'),
								currentItemsQueued = parseInt(itemsQueuedElement.text(), 10),
								len = up.files.length,
								timer;

							if (currentItemsQueued <= 0 && up.toAlbum) $('#import_tar_coll:hidden').show();

							timer = window.setInterval(function() {
								if (++currentItemsQueued <= len) {
									$('#upload_queued_num').text(currentItemsQueued);
								} else {
									clearInterval(timer);
								}
							}, (300/(len/2)));

						}
					}

				});

				if (!override) {
					uploader.instance.init();
				} else {
					uploader.instance.trigger('QueueChanged');
				}

			}

		},

		load_albums_full: function() {
			// Recursively load full album tree
			this.sync({
				request: 'albums',
				context: this,
				finished: function(data) {
					var self = this,
						load = function(album) {
							self.sync({
								request: 'albums/' + album.id + '/content',
								context: self,
								finished: function(_data) {

									$.each(_data.albums, function(i, a) {
										a.albums = ko.observableArray([]);
										a.level = album.level + 1;
									});

									album.albums( _data.albums );

									$.each( album.albums(), function(i, a) {
										if (a.album_type === 'set' && a.counts.total > 0) {
											load(a);
										}
									});
								}
							});
						};

					$.each(data.albums, function(i, a) {
						a.albums = ko.observableArray([]);
						a.level = 0;
					});

					this.observers.albums_full( data.albums );

					$.each( this.observers.albums_full(), function(i, a) {
						if (a.album_type === 'set' && a.counts.total > 0) {
							load(a);
						}
					});
				}
			});
		},

		load_tree: function(cb) {

			this.sync({
				request: [
					{
						request: 'albums/tree',
						finished: function(data) {
							if (!Array.isArray(data)) data = [data];
							this.observers.albums(data);
							if (this.controllers.events_init) {
								this.controllers.events_init();
							}

							var flat = [],
								add = function(arr) {
									$.each(arr, function(i, item) {
										flat.push(item);
										if (item.children) {
											add(item.children);
										}
									});
								};

							add(data);

							this.observers.albums_flat(flat);
						}
					},
					{
						request: 'albums/tree/visibility:unlisted',
						finished: function(data) {
							if (!Array.isArray(data)) data = [data];
							this.observers.unlisted_albums(data);
							if (this.controllers.events_init) {
								this.controllers.events_init();
							}
						}
					},
					{
						request: 'albums/tree/visibility:private',
						finished: function(data) {
							if (!Array.isArray(data)) data = [data];
							this.observers.private_albums(data);
							if (this.controllers.events_init) {
								this.controllers.events_init();
							}
						}
					}
				],
				context: this
			});
		},

		load: function(name,data,opt) {

			var temp	= [],
				d		= (data[name]) ? data[name] : data,
				len		= d.length-1,
				i		= 0,
				k, current;

			if (d && d.length > 0) {
				do {
					if (opt) {
						for ( k in opt ) {
							d[i][k] = opt[k];
						}
					}
					temp[temp.length] = d[i];
				} while( i++ < len );

				current = this.observers[name]().slice();
				$.merge( current, temp );
				this.observers[name](current);

			}

		}

	},

	models: function() {

		var self = this, models = {};

		models.tags = {

			add: function(tag,ob) {

				var tags	= ob || [],
					add		= [];

				if ( typeof tag === 'object' ) {

					$.each(tag, function(i, t) {
						if ($.inArray(t, tags) === -1 && t.length) {
							add.push(t);
						}
					});

				} else {

					var noob = tag.trim();

					if ($.inArray(noob, tags) === -1) {
						if (/\s+/g.exec(noob)) {
							noob = noob.replace(/"+/g, '');
						}
						add.push(noob);
					}

				}

				return tags.concat(add);

			},

			del: function(id) {

				var n = self.notify().wait('Deleting tag');

				self.sync({
					request: 'tags/' + id,
					method: 'DELETE',
					context: self,
					finished: function() {
						n.success('Tag deleted');
						this.pubsub('tags.delete', {
							id: id
						});
					}
				});

			}

		};

		models.categories = {

			update: function(id,val,cb) {

				self.sync({
					request: 'categories/' + id,
					method: 'PUT',
					data: 'title=' + encodeURIComponent(val),
					context: self,
					finished: function(data) {
						this.observers.categories.remove(function(item) { return item.id == id; });
						this.observers.categories.push( data );
						this.observers.sort('categories', 'title');
						requestAnimationFrame(function() {
							cb && cb();
						});
					}
				});

			},

			set: function(val) {

				var catDoesntExist = true,
					note = self.notify().wait('Creating category');

				self.observers.categories().forEach(function(category) {
					if (category.title === val) catDoesntExist = false;
				});

				if (catDoesntExist) {
					self.sync({
						request: 'categories',
						method: 'POST',
						data: 'title=' + encodeURIComponent(val),
						context: self,
						finished: function(data) {
							if (this.interfaces.active.id === 'library') {
								this.interfaces.library.observers.sheets.create_collection.category('');
							}
							this.observers.categories.push(data);
							this.observers.sort('categories', 'title');
							requestAnimationFrame(function() {
								self.observers.categories().forEach(function(category) {
									if (category.title === val) {
										$('#right-col-xtra').find('.col-row[data-id="' + category.id + '"] input').attr('checked','checked');
										if ($('#create_sheet_categories').is(':visible')) {
											$('#create_sheet_categories').find('input[value="' + category.id + '"]').attr('checked', 'checked');
										}
									}
								});
							})
							note.success(data.title + ' category created');
						}
					});
				} else {
					note.warn('A category using that name already exists');
				}

			},

			del: function(id) {

				self.sync({
					request: 'categories/' + id,
					method: 'DELETE',
					context: self,
					finished: function() {
						this.observers.categories.remove(function(item) { return item.id == id; });
					}
				});

			}

		};

		return models;

	},

	observers: function() {

		var _session = this.session();
		var self = this;

		return {

			settings: {
				uploading_default_license_clean: ko.observable(''),
				uploading_default_license: ko.observable(''),
				image_processing_library: ko.observable(''),
				image_processing_libraries: ko.observableArray([]),
				last_upload: ko.observable(false),
				uuid: ko.observable(''),
				site_url: ko.observable(''),
				site_timezone: ko.observable(''),
				__site_url_has_changed: ko.observable(false),
				image_use_defaults: ko.observable(true),
				use_default_labels_links: ko.observable(true),
				site_time_format: ko.observable(''),
				site_date_format: ko.observable('')
			},
			update_count: ko.observable(0),
			paths: this.config.paths,
			system: {},
			user: {},
			urls: {},
			has_urls: ko.observable(false),
			url_data: {
				content: {
					singular: ko.observable(''),
					plural: ko.observable(''),
					url: ko.observable('')
				},
				favorite: {
					singular: ko.observable(''),
					plural: ko.observable(''),
					url: ko.observable('')
				},
				feature: {
					singular: ko.observable(''),
					plural: ko.observable(''),
					url: ko.observable('')
				},
				album: {
					singular: ko.observable(''),
					plural: ko.observable(''),
					url: ko.observable('')
				},
				set: {
					singular: ko.observable(''),
					plural: ko.observable('')
				},
				essay: {
					singular: ko.observable(''),
					plural: ko.observable(''),
					url: ko.observable('')
				},
				page: {
					singular: ko.observable(''),
					plural: ko.observable(''),
					url: ko.observable('')
				},
				tag: {
					singular: ko.observable(''),
					plural: ko.observable('')
				},
				category: {
					singular: ko.observable(''),
					plural: ko.observable('')
				},
				timeline: {
					singular: ko.observable(''),
					plural: ko.observable('')
				},
				home: ko.observable('')
			},
			update_available: ko.observable(false),
			update_available_version: ko.observable(false),
			update_url: ko.observable(''),
			'interface': ko.observable(),
			platform: ko.observable(),
			albums: ko.observableArray([]),
			albums_flat: ko.observableArray([]),
			albums_full: ko.observableArray([]),
			unlisted_albums: ko.observableArray([]),
			private_albums: ko.observableArray([]),
			tags: ko.observableArray([]),
			categories: ko.observableArray([]),
			tempcategories: ko.observableArray([]),
			alteredcategories: ko.observableArray([]),
			temptags: ko.observableArray([]),
			hashchange: ko.observable(false),
			hash: ko.observable(''),
			valid: ko.observable(false),
			default_links: {},
			base_path: ko.observable(''),
			real_base_path: '',
			plugins: ko.observableArray([]),
			pulse_transitions_that_ease: ko.observableArray([ 'slide' ]),
			user_plugins: ko.observableArray([]),
			user_shortcodes: ko.observableArray([]),
			user_pulse_plugins: ko.observableArray([]),
			user_pulse_transitions: ko.observableArray([]),
			user_pulse_transitions_settings: ko.observableArray([]),
			user_oembeds: ko.observableArray([]),
			user_oembed_thumbs: [],
			user_shortcodes_icons: {},
			user_custom_sources: {
				albums: ko.observableArray([]),
				content: ko.observableArray([]),
				essays: ko.observableArray([]),
			},
			user_custom_sources_keys: {
				albums: ko.observableArray([]),
				content: ko.observableArray([]),
				essays: ko.observableArray([]),
			},
			themes: ko.observableArray([]),
			decodeUrl: function(url) {
				return decodeURIComponent(url());
			},
			visibility: {
				items: ko.observableArray([]),
				viz_type_current: ko.observable('public'),
				viz_type_new: ko.observable('unlisted'),
				album_type: ko.observable('album'),
				viz_type_reset: function() {
					this.album_type('album');
				}
			},
			update_album_counts: function(data) {
				var allAlbums = this.albums_flat().concat(this.unlisted_albums());
				allAlbums.forEach(function(album) {
					if (album.id === (data.album && data.album.id || data)) {
						if (typeof data === 'number') {
							album.count++;
						} else {
							album.count = data.album.counts.total;
						}
						return false;
					}
				});
			},
			channels: ko.observableArray([]),
			shouldUploadToAlbum: ko.observable(false),
			hasPlugin: function(key, value) {
				var hasPlugin = false;
				$.each(this.user_plugins(), function(i, _plugin) {
					if (_plugin[key] === value) {
						hasPlugin = _plugin;
						return;
					}
				});
				return hasPlugin;
			},
			postLoad: function(elements,observer) {
				var _plugin = this.name.replace('_interface','');
				if ( config.reserved.indexOf(_plugin) === -1 ) {
					$.each(observer.channels(), function(i, channel) {
						if ( channel.id === _plugin ) {
							if (channel.columns) {
								var container = $('#interface_container'),
									html = $('#interface_container').html(),
									tmpl = $('#koken' + channel.columns + 'Col'),
									el;

								// TODO: Make this more dynamic
								$(tmpl.html()).children().each(function() {
									if ( $(this).children().length <= 0 ) {
										el = $(this).attr('id');
									}
								});

								$('#interface_container').html(tmpl.html());
								requestAnimationFrame(function() {
									$('#interface_container').find('#' + el).append(html);
								});
							}
						}
					});
				}
			},
			dropdowns: {
				user: {
					items: ko.observable([
						{
							title: 'Help',
							'class': 'opt-support',
							bind: 'click: function() { this.classes.modal.help.show(); }'
						},
						{
							title: 'Take a tour',
							'class': 'opt-tour',
							bind: 'click: function() { this.classes.modal.tour.show(); }'
						},
						{
							title: 'Keyboard shortcuts',
							'class': 'opt-keyboard',
							bind: 'click: function() { this.classes.modal.keyboard_shortcuts.show(); }'
						},
						{
							title: 'Sign out',
							'class': 'opt-signout',
							bind: 'click: observers.logout'
						}
					]),
					onload: function(el) {
						el.closest('div[data-event="toggle_dropdown"]').find('.head-link').addClass('selected');
					},
					onunload: function(el) {
						el.closest('div[data-event="toggle_dropdown"]').find('.head-link').removeClass('selected');
					},
					active: ko.observable(false),
					offset: '-20px',
					top: '10px',
					id: ko.observable('user'),
					wedge: {
						offset: '85px'
					}
				}
			},

			load_content: {
				subscription: null,
				content: ko.observableArray([]),
				url: ko.observable(),
				selected: {
					content: ko.observableArray([]),
					text: ko.observable('')
				}
			},

			uCaseWords: function(str) {
				return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
					return $1.toUpperCase();
				});
			},

			sort: function(type,which) {

				var l, r;

				if ( ! which ) {
					which = 'title';
				}

				this[type].sort( function(left, right) {

					l = left[which];
					r = right[which];

					if (typeof(l) == 'string') {
						l = l.toLowerCase();
					}

					if (typeof(r) == 'string') {
						r = r.toLowerCase();
					}

					if (l === r) { return 0; }
					// Numbers before strings
					if (!isNaN(l) && isNaN(r)) { return -1; }
					return l < r ? -1 : 1;

				} );

			},

			modal: {
				active: ko.observable(false),
				current: ko.observable('empty_modal'),
				canClose: ko.observable(true),
				msg: ko.observable('')
			},

			truncate: function(str, len) {
				var txt = $('<div/>').html(str).text();
				len = len || 300;
				str = str || '';
				return ( str.length <= len ) ? txt : txt.replace(/\n/g, ' ').substr(0, len).replace(/[^a-z0-9]$/, '') + '…';
			},

			format_date: function(ts, fmt, apply_timezone) {

				if (apply_timezone === undefined) {
					apply_timezone = true;
				}

				var fmt = fmt || 'MMM D YYYY h:mm a';

				if (!$.isNumeric(ts)) {
					var d = new Date(ts).getTime();
					ts = Math.round(d / 1000);
				}

				if (apply_timezone && this.settings.site_timezone() !== 'UTC') {
					return moment.tz(new Date(ts*1000), this.settings.site_timezone()).format(fmt);
				} else {
					return moment.utc(ts*1000).format(fmt);
				}

			},

			reset_settings: function(data) {
				if (data.site_url === 'default') {
					this.base_path(this.config.paths.base);
					data.site_url = this.base_path();
				} else {
					var base = window.location.protocol + '//' + window.location.host + data.site_url + '/';
					this.base_path( base.replace(/[\/]+$/, '/') );
					data.site_url = this.helpers.rtrim(this.base_path(), '/');
				}
				data.__site_url_has_changed = false;
				this.map( this.settings, data);
			},

			form_site_link: function(type, vars) {
				var link = this.urls[type];
				if (!link) return false;
				return this.helpers.rtrim( this.base_path(), '/') + ( this.system.rewrite_enabled ? '' : '/index.php?' ) + link;
			},

			inArrayTags: function(search, searchIn) {
				var title = search.title,
					found = false;

				$.each(searchIn, function(i, tag) {
					if (tag.title === title) {
						found = true;
						return false;
					}
				});

				return found;
			},

			logout: function() {
				this.sync({
					request: 'sessions',
					method: 'DELETE',
					finished: function() {
						window.location.reload();
					}
				});
			},

			process_plugin_data: function(data, reload) {
				var user_plugins = [],
					user_transitions = [],
					user_transitions_settings = [],
					user_pulse_plugins = [],
					self = this;

				$('#pulse_option_transition_type').empty();

				self.observers.user_custom_sources.albums([]);
				self.observers.user_custom_sources.content([]);
				self.observers.user_custom_sources.essays([]);

				$.each(data.custom_sources, function(key, info) {
					self.observers.user_custom_sources[info.scope].push({
						label: info.label,
						key: key
					});

					self.observers.user_custom_sources_keys[info.scope].push(key);
				});

				$.each(data.plugins, function(i, plugin) {
					var data = [];
					if ( plugin.shortcodes && plugin.activated && (plugin.internal || plugin.setup) ) {
						self.observers.user_shortcodes( $.extend( {}, self.observers.user_shortcodes(), plugin.shortcodes ) );
						$.each(plugin.shortcodes, function(key, data) {
							self.observers.user_shortcodes_icons[key] = data.media_row_icon || data.icon || false;
						});
					}
					if (plugin.oembeds && plugin.activated && (plugin.internal || plugin.setup)) {
						self.observers.user_oembeds( $.extend( {}, self.observers.user_oembeds(), plugin.oembeds ) );
						$.each(plugin.oembeds, function(key, data) {
							self.observers.user_shortcodes_icons['koken_oembed_' + key] = data.icon || false;
							if (data.has_thumbnail) {
								self.observers.user_oembed_thumbs.push(key);
							}
						});
					}
					if ( plugin.pulse && plugin.activated ) {
						if (plugin.transitions) {
							user_transitions.push({ title: plugin.title, transitions: plugin.transitions });
							$.each(plugin.transitions, function(i, obj) {
								if (obj.supports_easing) {
									self.observers.pulse_transitions_that_ease.push(obj.value);
								}
							});
							if (plugin.settings) {
								$.each(plugin.settings, function(i, setting) {
									setting.key = i;
									user_transitions_settings.push(setting);
								});
							}
						} else {
							var settings = [ {
								label: "Enabled",
								type: "boolean",
								value: false,
								key: plugin.ident + '_enabled',
								plugin_enabler: true,
								control_first: true
							} ];
							if (plugin.settings) {
								$.each(plugin.settings, function(i, setting) {
									setting.key = i;
									settings.push(setting);
								});
							}
							plugin.settings = [ { group: plugin.title, icon: 'plugin', settings: settings } ];
							user_pulse_plugins.push( plugin );
						}
					}
					if (reload && self.interfaces.plugins) {
						$.each(self.interfaces.plugins, function(i, __plugin) {
							if (__plugin.path === plugin.path) __plugin.update();
						});
					}
					if (plugin.data) {
						$.each(plugin.data, function(key, arr) {
							if (!arr.key) {
								arr.key = key;
							}
							data.push(arr);
						});
					}
					plugin.data = data;

					if (!plugin.internal) {
						user_plugins.push(plugin);
					}
				});

				this.observers.plugins( data.plugins );
				this.observers.user_plugins( user_plugins );
				this.observers.user_pulse_transitions( user_transitions );
				this.observers.user_pulse_transitions_settings( user_transitions_settings );
				this.observers.user_pulse_plugins( user_pulse_plugins );
			},

			multi_in_array: function(needles, haystack) {
				var max = -1;

				$.each(needles, function(i, needle) {
					max = Math.max(max, $.inArray(needle, haystack));
				});

				return max;
			},

			get_album_cover: function(album) {
				if (album.covers.length) {
					return album.covers[0].presets.tiny.cropped[this.config.retina ? 'hidpi_url' : 'url'];
				}
				return 'images/no-cover-48.png';
			},

			get_preset_url: function(preset) {
				if (typeof preset === 'function') {
					preset = preset();
				}
				return preset[this.config.retina ? 'hidpi_url' : 'url'];
			},

			make_url_safe: function(str, fallback) {
				var foreign_characters = {
					'ä|æ|ǽ': 'ae',
					'ö|œ': 'oe',
					'ü': 'ue',
					'Ä': 'Ae',
					'Ü': 'Ue',
					'Ö': 'Oe',
					'À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ|Α|Ά|Ả|Ạ|Ầ|Ẫ|Ẩ|Ậ|Ằ|Ắ|Ẵ|Ẳ|Ặ|А': 'A',
					'à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª|α|ά|ả|ạ|ầ|ấ|ẫ|ẩ|ậ|ằ|ắ|ẵ|ẳ|ặ|а': 'a',
					'Б': 'B',
					'б': 'b',
					'Ç|Ć|Ĉ|Ċ|Č': 'C',
					'ç|ć|ĉ|ċ|č': 'c',
					'Д': 'D',
					'д': 'd',
					'Ð|Ď|Đ|Δ': 'Dj',
					'ð|ď|đ|δ': 'dj',
					'È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě|Ε|Έ|Ẽ|Ẻ|Ẹ|Ề|Ế|Ễ|Ể|Ệ|Е|Ё|Э': 'E',
					'è|é|ê|ë|ē|ĕ|ė|ę|ě|έ|ε|ẽ|ẻ|ẹ|ề|ế|ễ|ể|ệ|е|ё|э': 'e',
					'Ф': 'F',
					'ф': 'f',
					'Ĝ|Ğ|Ġ|Ģ|Γ|Г': 'G',
					'ĝ|ğ|ġ|ģ|γ|г': 'g',
					'Ĥ|Ħ': 'H',
					'ĥ|ħ': 'h',
					'Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ|Η|Ή|Ί|Ι|Ϊ|Ỉ|Ị|И|Й': 'I',
					'ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı|η|ή|ί|ι|ϊ|ỉ|ị|и|й': 'i',
					'Ĵ': 'J',
					'ĵ': 'j',
					'Ķ|Κ|К': 'K',
					'ķ|κ|к': 'k',
					'Ĺ|Ļ|Ľ|Ŀ|Ł|Λ|Л': 'L',
					'ĺ|ļ|ľ|ŀ|ł|λ|л': 'l',
					'М': 'M',
					'м': 'm',
					'Ñ|Ń|Ņ|Ň|Ν|Н': 'N',
					'ñ|ń|ņ|ň|ŉ|ν|н': 'n',
					'Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ|Ο|Ό|Ω|Ώ|Ỏ|Ọ|Ồ|Ố|Ỗ|Ổ|Ộ|Ờ|Ớ|Ỡ|Ở|Ợ|О': 'O',
					'ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º|ο|ό|ω|ώ|ỏ|ọ|ồ|ố|ỗ|ổ|ộ|ờ|ớ|ỡ|ở|ợ|о': 'o',
					'П': 'P',
					'п': 'p',
					'Ŕ|Ŗ|Ř|Ρ|Р': 'R',
					'ŕ|ŗ|ř|ρ|р': 'r',
					'Ś|Ŝ|Ş|Ș|Š|Σ|С': 'S',
					'ś|ŝ|ş|ș|š|ſ|σ|ς|с': 's',
					'Ț|Ţ|Ť|Ŧ|τ|Т': 'T',
					'ț|ţ|ť|ŧ|т': 't',
					'Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ|Ũ|Ủ|Ụ|Ừ|Ứ|Ữ|Ử|Ự|У': 'U',
					'ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ|υ|ύ|ϋ|ủ|ụ|ừ|ứ|ữ|ử|ự|у': 'u',
					'Ý|Ÿ|Ŷ|Υ|Ύ|Ϋ|Ỳ|Ỹ|Ỷ|Ỵ': 'Y',
					'ý|ÿ|ŷ|ỳ|ỹ|ỷ|ỵ': 'y',
					'В': 'V',
					'в': 'v',
					'Ŵ': 'W',
					'ŵ': 'w',
					'Ź|Ż|Ž|Ζ|З': 'Z',
					'ź|ż|ž|ζ|з': 'z',
					'Æ|Ǽ': 'AE',
					'ß': 'ss',
					'Ĳ': 'IJ',
					'ĳ': 'ij',
					'Œ': 'OE',
					'ƒ': 'f',
					'ξ': 'ks',
					'π': 'p',
					'β': 'v',
					'μ': 'm',
					'ψ': 'ps',
					'Ж': 'Zh',
					'ж': 'zh',
					'Х': 'Kh',
					'х': 'kh',
					'Ц': 'Tc',
					'ц': 'tc',
					'Ч': 'Ch',
					'ч': 'ch',
					'Ш': 'Sh',
					'ш': 'sh',
					'Щ': 'Shch',
					'щ': 'shch',
					'Ю': 'Iu',
					'ю': 'iu',
					'Я': 'Ia',
					'я': 'ia'
				};

				$.each(foreign_characters, function(r, replacement) {
					str = str.replace(RegExp(r, 'g'), replacement);
				});

				str = str.replace(/&.+?/, '');
				str = str.replace(/\s+/g, '-');
				str = str.replace(/[^A-Za-z\-\d+]/g, '');

				if (str.match(/^\d+$/)) {
					str += '-1';
				}

				str = str.toLowerCase();

				if (str.length === 0 && fallback) {
					return fallback;
				} else {
					return str;
				}
			},
			order_by_string: function() {
				var order_by = this.order_by();
				var match;

				if (match = order_by.match(/(.*)_on$/)) {
					order_by = 'date ' + match[1];
				} else if (match = order_by.match(/koken_(.*)/)) {
					order_by = match[1];
				}

				return order_by.substr(0,1).toUpperCase() + order_by.substr(1).replace('_', ' ');
			},
			sync_order_by: function(order_string) {
				var url = this.api_url();
				var controllers = self.interfaces.library.controllers;

				if (/albums\/\d+\/content$/.test(url)) {
					var album_id = this.properties.album.selected().album.id;
					var is_set = this.properties.album.selected().album.album_type === 'set';

					self.sync({
						request: 'albums/' + album_id,
						method: 'PUT',
						data: 'sort=' + order_string,
						context: self,
						finished: function() {
							controllers.load_content(false, true);

							if (is_set) {
								controllers.load_tree();
							}
						}
					});
				} else {
					var	type = 'album';

					if (url.indexOf('favorites') !== -1) {
						type = 'favorite';
					} else if (url.indexOf('content') === 0) {
						if (url.indexOf('featured') !== -1) {
							type = 'feature';
						} else {
							type = 'content';
						}
					} else if (url.indexOf('text') === 0) {
						type = 'essay';
					}

					self.sync({
						request: 'site/set_order',
						method: 'PUT',
						data: 'type=' + type + '&order=' + order_string,
						context: self,
						finished: function() {
							if (url.indexOf('text') === 0) {
								this.interfaces.text.controllers.load_text(false, true);
							} else {
								controllers.load_content(false, true);

								if (type === 'album') {
									controllers.load_tree();
								}
							}
						}
					});
				}
			},
			change_order_by: function(val) {

				var direction = ' desc';

				if (val === 'manual') {
					direction = ' asc';
				} else if (val === 'filename' || val === 'title') {
					direction = ' asc';
				}

				this.sync_order_by(val + direction);
			},
			change_order_direction: function(val) {
				this.sync_order_by(this.order_by() + ' ' + val);
			},
			slug_is_editable: function(data) {
				var type = 'content';

				if (data.page_type) {
					type = data.page_type();
				} else if (data.album_type && data.album_type()) {
					type = 'album';
				}

				return this.url_data[type].url().indexOf('slug') !== -1;
			}

		};

	}

};

	_Koken.prototype.library = {

	init: function() {

		var self = this,
			val;

		// TODO: Namespace all keyboard shortcuts
		$(window).off('keydown');

		$(window).on('library.resize', function() {
			if (self.observers.view() === 'single') {
				self.views.asset_resize();
			}
			self.events.show_cover.call(self);

			$('#content_side').css('height', $('#content_sidebar_info_container').height() - $('#content_sidebar_info_container').find('.col-select').outerHeight(true) - 1);

			var head = $('#three-col-right').find('.col-select'),
				sidebar = $('#right-col-xtra');

			if ( head.length <= 0 || !head.is(':visible') ) {
				head = $('#three-col-right div').eq(1);
				sidebar.css({
					marginTop: 0 + 'px',
					height: ( ( $(window).height() - head.height() ) - head.offset().top ) - 19 + 'px'
				});
			} else {
				sidebar.css({
					marginTop: ( head.height() + 31 ) + 'px',
					height: ( ( $(window).height() - head.height() ) - head.offset().top ) - 47 + 'px'
				});
			}
		});

		// Start validation listeners
		new this.klass.form_validation('library');
		// Start autocomplete
		new this.klass.autocomplete('library');
		// Create new channel object
		new this.klass.channel('library');

		this.events.init();

		this.construct(
			{
				name: 'asset',
				fn: function(d) {

					this.id				= d.id;
					this.data			= {};
					this.el				= function() { return $('#content_list li[data-id="' + this.id + '"]'); };
					this.key			= this.id + '-' + (d.file_modified_on ? d.file_modified_on.timestamp : d.modified_on.timestamp);

					$.extend(this.data,d);

				}
			},
			{
				name: 'cache',
				fn: function(type) {
					return this.persist(this.key) || this.data.presets[type||'small'][this.config.retina ? 'hidpi_url' : 'url'];
				}
			},
			{
				name: 'drag',
				fn: function(el) {

					var self			= this,
						isSingleView	= false,
						which			= ( self.observers.api_url().indexOf('order_by') < 0 && self.observers.sortable() === true ) ? 'sortable' : 'draggable',
						params			= {},
						_el, _items, hasBeenUpdated = false, ulCopy, ulCopyData;

					params.sortable = {
						helper: 'clone',
						appendTo: 'body',
						tolerance: 'pointer',
						distance: 10,
						cursorAt: {
							top: 10,
							left: 10
						},
						start: function(e,ui) {

							$('a[data-event="toggle_dropdown"].selected').parent().trigger('click');

							var itemSortDrag = self.classes.app.koken.itemsBeingSortedDragged;

							_items = ( ui.helper.hasClass('selected') ) ? $('#content_list li.selected:not(.ui-sortable-helper,.ui-sortable-placeholder)').hide() : [];

							ui.helper.addClass('ui-draggable-dragging').find('.content').append( ( ( _items.length > 1 ) ? '<span class="badge-count" style="opacity: 1;">' + _items.length + '</span>' : '' ) ).addClass('item_to_sort');
							ui.helper.find('.badge-count').css({
								left: ( ( ui.helper.find('img').width() < 100 ) ? ( ui.helper.find('img').width() - 3 ) : 93 ) + 'px'
							});
							ui.helper.closest('li').addClass('trashable');
							ui.placeholder.css('visibility','visible').show();

							$('[data-event="sort_year"]').closest('.lib-nav').find('a.item').addClass('nothoverable');

							self.classes.app.koken.sortToDrag = true;

							if ( _items.length > 0 ) {
								$.each( _items, function() {
									itemSortDrag.push( $(this).data('id') )
								});
							} else {
								itemSortDrag.push( ui.helper.data('id') )
							}

							ulCopy = $('#content_list').clone();
							ulCopyData = $('#content_list').data();
							delete ulCopyData.sortable;
							ulCopy.find('.ui-sortable-placeholder').remove();

						},
						stop: function(e,ui) {

							if ( hasBeenUpdated && !self.observers.isBeingDropped ) {

								var ids = [],
									freeze = self.observers.content().slice(0);

								$.each( $('#content_list li'), function() {
									ids.push( $(this).data('id') );

									var count = ( self.observers.view() === 'list' ) ? $(this).find('.cnt') : $(this).find('.count'),
										item = freeze[count.text() - 1];

									count.text( $(this).index() + 1 );
									$(this).data('index', $(this).index());
									self.observers.content.splice( $(this).index(), 1, item);
								});

								if (self.observers.is_quick_collection()) {

									var remainder = self.observers.quick_collection().slice(ids.length);
									self.observers.quick_collection( ids.concat(remainder) );

								} else {

									self.sync({
										request: self.observers.api_url() + '/order:' + ids.join(','),
										method: 'PUT',
										context: self,
										finished: function() {
											self.controllers.load_tree();
											self.classes.app.koken.itemsBeingSortedDragged = [];

											if (this.observers.order_by() !== 'manual') {
												this.observers.change_order_by('manual');
											}
										}
									});

								}

							}

							$.each(self.classes.app.koken.itemsBeingSortedDragged, function(i, itemID) {
								$('#content_list li[data-id="' + itemID + '"]').removeClass('loaded')
							});
							window.setTimeout(function() {
								$.each(self.classes.app.koken.itemsBeingSortedDragged, function(i, itemID) {
									$('#content_list li[data-id="' + itemID + '"]').addClass('loaded')
								});
								if (!hasBeenUpdated) self.classes.app.koken.itemsBeingSortedDragged = [];
							}, 100);

							ui.item.css('display','');
							$('.nothoverable').removeClass('nothoverable');
							$('#content_list li.selected:not(.ui-sortable-helper)').show();
							$('.item_to_sort').removeClass('item_to_sort');
							self.classes.app.koken.sortToDrag = false;

							if ( self.observers.isBeingDropped ) {
								self.classes.app.koken.itemsBeingSortedDragged = [];
								$('#content_list').replaceWith(ulCopy);
								ulCopy.data(ulCopyData);
								ulCopy.sortable(params.sortable).find('li').show();
								ko.applyBindings( self.observers, $('#content_list')[0] );
							}

							self.observers.isBeingDropped = false;
							hasBeenUpdated = false;

							self.controllers.events_init();

						},
						beforeStop: function(e,ui) {

							if ( !ui.helper.hasClass('selected') && _items.length > 1 ) { return; }

							var index,
								currItems	= $('#content_list li').clone(true),
								ids			= [];

							$.each( _items, function() {
								ids.push( $(this).data('id') );
								self.classes.app.koken.itemsBeingSortedDragged.push($(this).data('id'))
							});

							$('#content_list li:not(.ui-sortable-helper,.ui-sortable-placeholder)').each(function() {
								if ( ids.indexOf( $(this).data('id') ) >= 0 ) {
									$(this).remove();
								}
							});

							index = ui.placeholder.index()-1;
							if ( index < 0 ) {
								if ( _items.insertBefore ) { _items.insertBefore( $('#content_list li:eq(0)') ); }
							} else {
								$('#content_list li:not(.ui-sortable-helper,.ui-sortable-placeholder):eq(' + index + ')').after(_items);
							}

							if ( self.observers.api_url().indexOf('albums') < 0 ) {
								$('.badge-count').remove();
							}

						},
						update: function(e,ui) { hasBeenUpdated = true; }
					};

					params.draggable = {
						appendTo: 'body',
						helper: function() {
							$(this).addClass('__' + self.data.__koken__ + '__');
							// Overriding clone param because of draggable single image view
							return self.el().clone().removeAttr('id');
						},
						revert: function(e,ui) {
							if ( $(this).data('ui-draggable') ) {
								$(this).data('ui-draggable').originalPosition = $(this).find('img:visible').offset();
							}
							return !e;
						},
						zIndex: 14000,
						easing: config.easing,
						opacity: 0.80,
						distance: 10,
						cursorAt: {
							top: 10,
							left: 10
						},
						drag: function(e,ui) {
							if ( this.tagName === 'IMG' ) {
								ui.position.top  = e.pageY - 10;
								ui.position.left = e.pageX - 10;
							}
						},
						start: function(e,ui) {

							$('a[data-event="toggle_dropdown"].selected').parent().trigger('click');

							var li	= $(e.target).closest('li'),
								sel = $('#content_list li.selected'),
								base, index;

							if ( !ui.helper.find('img.media:first').attr('src') ) {
								ui.helper.find('img.media:first').prop( 'src', self.cache() );
							}

							$('[data-event="sort_year"]').closest('.lib-nav').find('a.item').addClass('nothoverable');

							if ( ! e.shiftKey && sel.length  <= 1 || ! e.shiftKey && ! li.hasClass('selected') ) {
								sel.removeClass('selected');
							} else if ( e.shiftKey ) {

								base	= li.siblings('li[data-id="' + sel.data('id') + '"]');
								index	= base.index();

								li.addClass('selected');

								if ( li.index() > index ) {

									li.prevUntil(base).each(function(i, _li) {
										$('#content_list [data-id="' + $(_li).data('id') + '"]').addClass('selected');
									});

								} else {

									li.nextUntil(base).each(function(i, _li) {
										$('#content_list [data-id="' + $(_li).data('id') + '"]').addClass('selected');
									});

								}

							}

							ui.helper.closest('li').addClass('trashable');

							$('#lib-overlay-content').fadeTo( 'fast', 0.80 );

							if ( $('#content_list li.selected').length > 1 ) {
								if ( ui.helper.hasClass('album') ) {

									var tot		= 0,
										badge	= ui.helper.find('.badge-count');

									$('.lib-content').find('li.selected .badge-count').each( function() {
										tot += parseInt( $(this).text(), 10 );
									});

									badge.text(tot);

								} else {

									ui.helper.find('.content').append('<span class="badge-count" style="opacity: 1;">' + $('.lib-content').find('li.selected').length + '</span>');
									ui.helper.find('.badge-count').css({
										left: ( ( ui.helper.find('img').width() < 100 ) ? ( ui.helper.find('img').width() - 3 ) : 93 ) + 'px'
									});

								}
							} else {
								$('#content_list [data-id="' + ui.helper.data('id') + '"]').addClass('selected');
							}

						},
						stop: function(e,ui) {

							var ids = [];

							// jQueryUI does not change the mouse cursor back if you dont move the mouse, this fixes that
							$(document.body).css('cursor','default');

							$.each( $('#content_list li.selected'), function(k,v) {
								ids.push( $(v).data('id') );
							});

							$(this).removeClass('__content__').removeClass('__album__');

							$('.nothoverable').removeClass('nothoverable');

							self.controllers.rewrite_hash({ name: 'selection', val: ids });

							$('#lib-overlay-content').fadeOut();

						}
					};

					if ( which === 'sortable' ) { el = el.closest('ul'); if ( el.data('sortable') ) { return; } }
					el[which](params[which]);
					if ( which === 'sortable' ) { el.disableSelection(); }

				}
			}
		);

		this.sync({
			request: 'trash',
			context: this,
			finished: function(data) {
				this.observers.properties.trash.total(data.total);
			}
		});

		this.controllers.update_summary();

		// Hookup the search field
		$(document).off('keyup.search').on('keyup.search', 'input.search', function(e) {
			var val = $(this).val().trim();
			if ( e.which === 13 && val !== '' ) {
				$(this).blur();
			}
			if ( val !== '' ) {
				$('#search_form .input-clear').removeClass('hide').show();
			} else {
				$('#search_form .input-clear').hide();
			}
		});

		$(document).off('blur.search').on('blur.search', 'input.search', function(e) {
			var val = $(this).val().trim();
			if ( val !== '' ) {
				$('#search_form .input-clear').show();
			} else {
				$('#search_form .input-clear').hide();
			}
			self.observers.search(val);
		});

		this.observers.search.subscribe( function(val) {
			this.controllers.rewrite_hash({ name: 'search', val: !val ? false : val });
			this.controllers.rewrite_hash({ name: 'search_filter', val: !val ? false : this.observers.search_filter() });

			if (!val) {
				$('#multi-search-head').hide();
			}
		}, this);

		this.observers.sidebar.id.subscribe( function(v) {

			var self = this;

			this.views.close_sidebar_drawer();

			var asset = {},
				type;

			$(document).off('keyup.sidebar', '#content_sidebar_template input, #content_sidebar_template textarea').on('keyup.sidebar', '#content_sidebar_template input, #content_sidebar_template textarea', function(ev) {
				if (ev.which === 27) {
					$(ev.target).blur();
				}

				if ($(this).parent().hasClass('plugin-fields')) {
					return;
				}

				if (v === 'multi') {

					var val		= $(this).val(),
						assets	= [],
						obj;

					// Easy way to handle summary character count for multi sidebar
					$('#multi_album_summary .char_count').text(255 - val.length);

					type = $(this).data('ob');

					// Dont process empty title fields
					if (val.trim().length <= 0 && type === 'title') return;

					$.each( self.observers.selection(), function(k,v) {
						obj = { id: v };
						obj[type] = val;
						assets.push(obj);
					});

					var _asset_type = (self.observers.api_url().indexOf('albums') !== -1) ? 'albums' : 'content';
					if ($('#content_list li.selected').hasClass('content')) _asset_type = 'content';

					self.controllers.update(_asset_type, assets);

				} else {

					if (asset.id && asset.id !== self.observers.sidebar.asset.id()) asset = {};

					asset.id					= self.observers.sidebar.asset.id();
					type						= self.observers.sidebar.id() === 'content' ? 'content' : 'albums';
					asset[$(this).data('ob')]	= $(this).val();

					if (type == 'albums' && self.observers.sidebar.asset.visibility.raw() !== 'public') {
						type = self.observers.sidebar.asset.visibility.raw() + '_albums';
					}

					if (self.observers.sidebar.id() !== 'content' && self.observers.api_url().indexOf('visibility:') === -1 && self.observers.api_url().indexOf('albums/featured') === -1) {
						if (asset.title) self.observers.properties.album.selected().album.title = asset.title;
						if (asset.description) self.observers.properties.album.selected().album.description = asset.description;
						if (asset.summary) self.observers.properties.album.selected().album.summary = asset.summary;
					}

					self.controllers.update(type, asset, { sidebar: false });

				}

			});

			this.observers.sidebar.asset.title.subscribe(function(v) {
				if (this.observers.sidebar.asset.album_type()) {
					var self = this;
					var recursiveTitleChange = function(albums) {
						$.each( albums, function() {
							if ( this.id === self.observers.sidebar.asset.id() ) {
								this.title = v;
							} else if ( this.children ) {
								recursiveTitleChange(this.children);
							}
						});
					};
					recursiveTitleChange(this.observers.albums());
				}
				$('#content_list li[data-id="' + this.observers.sidebar.asset.id() + '"]').find('span.ttle').text(v);
			}, this);

			// Used for multiselect sidebars to know if asset is album|content
			requestAnimationFrame(function() {
				self.observers.isMultiAlbum($('#mid-content li.selected').hasClass('album'));
			});

		}, this );

		this.observers.content.subscribe( function(val) {

			if ( this.observers.content_loading && !val.length ) {
				this.observers.loading(true);
				return;
			}

			// No content, show the empty view
			if ( val.length <= 0) {
				this.observers.view('empty');
				this.observers.loading(false);
				if (this.observers.properties.album.selected().album) {
					this.observers.sidebar.id('album');
				}
			} else {
				this.observers.selected_type( val[0].album_type ? 'set' : 'content');
				this.observers.loading(false);
				if (this.observers.view() === 'empty') {
					this.observers.view('grid');
				} else if ( this.observers.view() === 'single' && this.observers.properties.album.selected().album && this.observers.properties.album.selected().album.album_type === 'set') {
					this.observers.view('grid');
					this.observers.sidebar.id('album');
				}
			}

		}, this);

		this.observers.view.subscribe(function(val) {

			if ( val === 'grid' || val === 'list' ) {
				this.cookie('library:view',val);
			}

			if ( val === 'single' &&
					this.observers.content().length > 0 &&
					this.observers.selection().length === 0) {

				this.controllers.rewrite_hash({ name: 'selection', val: this.observers.content()[0].id });

			}

			if ( this.observers.content().length <= 0 && (val === 'grid' || val === 'list') ) {
				this.observers.view('empty');
			} else if (val === 'single') {
				this.views.asset_single_view();
			} else {
				var self = this;
				requestAnimationFrame( function() {
					self.controllers.lazy('#content_list');
				});
				this.controllers.events_init();
			}

			if (val !== 'loading' && val !== 'empty' && val !== 'upload') {
				this.controllers.rewrite_hash({ name: 'view', val: val === 'grid' ? false : val });
			}
		}, this);

		this.observers.thumbnail_scale.subscribe(this.events.scale_thumbnails, this);
		this.events.scale_thumbnails(this.observers.thumbnail_scale());

		this.observers.dropdowns.filter.selected.subscribe( function(val) {

			this.controllers.rewrite_hash({ name: 'selection', val: false});

			if ( $.inArray(val.trim(), [ 'none', 'any' ]) !== -1 ) {
				$('#multi-filter-head').hide();
			} else {
				var self = this;
				$.each(this.observers.dropdowns.filter.items(), function(i, item) {
					if (item.title.toLowerCase() === val) {
						self.observers.dropdowns.filter.title( item.desc.replace('Show', 'Showing') );
						return false;
					}
				});
				$('#multi-filter-head').show();
			}

			var v = 1;

			switch(val) {
				case 'public':
				case 'unlisted':
				case 'private':
					v = val;
					val = 'visibility';
					break;

				case 'photo':
				case 'video':
					v = val;
					val = 'types';
					break;

				case 'any':
					v = false;
					break;
			}

			if (v !== false) {
				this.controllers.rewrite_hash({ name: val, val: v, replace: [ 'favorites', 'covers', 'visibility', 'types', 'independent', 'koken_.*?'] });
			}

		}, this);

		this.observers.drawer.state.subscribe(function(val) {

			if ( val === 'open' && this.observers.selection().length > 1 && this.observers.drawer.id() === 'tags_edit' ) {

				var common = {};

				$.each(this.observers.selection(), function(i, sel) {
					var asset = self.controllers.get_asset_by_id(sel);
					if (asset) {
						if (asset.tags.length) {
							asset.tags.forEach(function(tag) {
								if (common[tag.title]) {
									common[tag.title].count++;
								} else {
									common[tag.title] = { count: 1, raw: tag }
								}
							});
						}
					}
				});

				$.each(common, function(idx, tag) {
					if (tag.count === self.observers.selection().length) {
						$('#tags_edit_drawer').find('label:contains(' + tag.raw.title + ')').parent().find('input').prop('checked', true);
						$('#tags_edit_drawer').find('label:contains(' + tag.raw.title + ')').parent().find('input').prop('indeterminate', false);
					} else {
						$('#tags_edit_drawer').find('label:contains(' + tag.raw.title + ')').parent().find('input').prop('indeterminate', true);
					}
				});

			}

			if ( val === 'open' && this.observers.selection().length <= 1 && this.observers.drawer.id() === 'category_edit' ) {
				$('[data-event="category_actions"]').each(function() {
					if ( $(this).find('input').prop('indeterminate') == true ) $(this).find('input').prop('indeterminate', false);
				});
			}

			if ( val === 'open' && this.observers.selection().length > 1 && this.observers.drawer.id() === 'category_edit' ) {

				// Reset all checkboxes
				requestAnimationFrame(function() {
					$('#category_edit_drawer input[type="checkbox"]').each(function() {
						$(this).prop('indeterminate',false);
						$(this).prop('checked', false);
					});
				});

				// Check all categories that are all in selected assets
				requestAnimationFrame(function() {
					if (this.observers.multiselection_categories.common.length) {
						this.observers.multiselection_categories.common.forEach(function(item) {
							$('#right-col-xtra').find('#content_category_' + item).prop('checked',true);
						});
					}
				}.bind(this));

				// Set indeterminate checkboxes for everything mixed
				requestAnimationFrame(function() {
					if (this.observers.multiselection_categories.mixed.length) {
						this.observers.multiselection_categories.mixed.forEach(function(item) {
							$('#right-col-xtra').find('#content_category_' + item).prop('indeterminate',true);
						});
					}
				}.bind(this));

			}

			// Possible cruft, check if can be removed
			if ( val === 'closed' && (this.observers.selection().length <= 1 && this.observers.drawer.id() === 'tags_edit') ) {
				var parent = $('#right-col-data');
				if ( parent.find('.data-submit') ) {
					parent.find('.data-submit').trigger('save_drawer_changes');
				}
			}

		}, this);

		this.observers.properties.album.selected.subscribe( function(val) {

			if (val.album) {

				var a = val.album;

				$('#mid_multi .container').css('top','0px');

				this.observers.header.title( a.title );
				this.observers.header.icon( a.album_type === 'set' ? 'label-album-set' : 'label-album' );

				this.map( this.observers.sidebar.asset, a );

				if (this.observers.view() !== 'single') {
					this.observers.sidebar.id('album');
				}

				$('#container').trigger('selection.loaded', a);
			}

		}, this);

		this.observers.header.title.subscribe( function(val) {
			if (val.toString().toLowerCase() !== 'search') {
				this.observers.header.collection(val.toString());
			}
		}, this);

		this.observers.selection.subscribe(function(v) {

			var self = this;

			$('ul#content_list li.selected, ul#mid-list li.selected').removeClass('selected');

			if ( v.length && !isNaN(v[0]) ) {

				var el = $('#list_item_' + v[0]);

				// TODO: Drawer fix but needs height fixed too
				/*
				if (!this.observers.drawerOpen) {
					this.views.close_sidebar_drawer();
					this.observers.drawerOpen = false;
				}
				*/

				if ( this.observers.sidebar.id() === 'trash' ) {

					$('.restore-all').attr('disabled',false);

				} else {

					if (v.length === 1) {

						var itemId = el.data('id'),
							item, index;
						// TODO: Eventually mapping this.observers.content() into an object key map so we can just select by id would be ideal
						$.each(this.observers.content(), function(i, asset) {
							if (asset.id == itemId) {
								item = asset;
								index = i;
								return;
							}
						});

						if ( !item ) return;

						this.observers.properties.content.selected.index(index);

						this.observers.sidebar.asset.album_type(false);

						this.map( this.observers.sidebar.asset, item );

						if (!item.source) this.observers.sidebar.asset.source.url(false);

						this.observers.sidebar.hasgeo( (item.geolocation) ? true: false );

						if ( item.album_type ) {
							this.observers.sidebar.id('album');
						} else {
							this.views.asset_multi_view(item);
						}

						this.observers.get_selected_asset_categories();

						$(window).trigger('resize');
						$('#container').trigger('selection.loaded', item);
					} else {

						this.observers.sidebar.id('multi');
						// TODO: Could probably combine properties.multi.selected with
						// content.selected.id, since they are the same value. Just move that code here?
						this.observers.properties.multi.selected(v);

						if ($('#content_list li.content').length) {
							this.observers.isContent(true);
						} else {
							this.observers.isContent(false);
						}

						// Resetting common tags
						this.observers.tagsCommonAcrossAll([]);
						this.observers.selectionHasTags(false);

						var common = {},
							items = [],
							tags = [],
							tagsToShow = { cache: [], actual: [] }

						$.each(this.observers.selection(), function(i, sel) {
							var asset = self.controllers.get_asset_by_id(sel);
							if (asset) {
								items.push(asset);
								if (!self.observers.selectionHasTags() && asset.tags.length) self.observers.selectionHasTags(true);
								if (asset.tags.length) {
									asset.tags.forEach(function(tag) {
										tags.push(tag);
										if (common[tag.title]) {
											common[tag.title].count++;
										} else {
											common[tag.title] = { count: 1, raw: tag }
										}
									});
								}
							}
						});

						$.each(common, function(idx, tag) {
							if (tag.count === self.observers.selection().length) self.observers.tagsCommonAcrossAll.push(tag.raw);
						});

						tags.forEach(function(tag, idx) {
							if (tagsToShow.cache.indexOf(tag.title) === -1) {
								tagsToShow.cache.push(tag.title);
								tagsToShow.actual.push(tag);
							}
						});

						this.map(this.observers.sidebar.asset, {tags: tagsToShow.actual});

						// Start checking other properties

						if ($('#content_list li.content').length) {

							var licenses = [], visibilities = [], downloads = [],
								license = false, visible = false, download = false;

							$.each(v, function(i, id) {
								var asset = self.controllers.get_asset_by_id(id);

								if (!asset) return;

								licenses.push(asset.license);
								visibilities.push(asset.visibility);
								downloads.push(asset.max_download);

								license = asset.license;
								visible = asset.visibility;
								download = asset.max_download;
							});
							$.each(licenses, function(i, l) {
								if (l.clean != license.clean) {
									license = {
										raw: '',
										clean: ''
									};
								}
							});
							$.each(visibilities, function(i, v) {
								if (v.clean != visible.clean) {
									visible = {
										raw: '',
										clean: ''
									};
								}
							});
							$.each(downloads, function(i, d) {
								if (d.clean != download.clean) {
									download = {
										raw: '',
										clean: ''
									};
								}
							});
							this.map( this.observers.sidebar.asset, {
								license: license,
								visibility: visible,
								max_download: download
							});
						}

						$('#container').trigger('selection.loaded', [items]);
					}

					if ( this.classes.app.koken.lastFocused ) {
						this.classes.app.koken.lastFocused.focus();
					}

				}

				$.each(v, function(i, id) {
					$('#list_item_' + id).addClass('selected');
				});

			} else if ( v.length === 0 ) {
				if ( this.observers.sidebar.id() === 'trash' ) {
					$('.restore-all').attr('disabled',true);
				}
				if (this.observers.properties.album.selected().album) {
					this.map( this.observers.sidebar.asset, this.observers.properties.album.selected().album );
					this.observers.sidebar.id('album');
					this.observers.get_selected_asset_categories();
					$('#container').trigger('selection.loaded', this.observers.properties.album.selected().album);
				} else {
					if ( this.observers.sidebar.id() !== 'trash' || $('.public').hasClass('selected') ) {
						this.observers.sidebar.id('fixed');
					}
				}
				if (this.observers.view() === 'single' || this.observers.view() === 'empty') {
					requestAnimationFrame(function() {
						self.observers.view('grid');
					});
				}
				this.observers.properties.content.selected.index(-1);
			}

			$('#content_sidebar_template div.value input').off('keydown.sidebar').on( 'keydown.sidebar', function(e) {
				if ( e.which === 13 ) {
					$(this).blur();
				}
			});

			if (v.length > 1) {
				$('#multi_sidebar_info_container').find('.value input').val('');
				$('#multi_sidebar_info_container').find('.value textarea').val('');
			}

		}, this);

		this.observers.quick_collection.subscribe(function(val) {
			if (val.length === 1) {
				// Need to fake this, otherwise urls could end up like /content/100, which is not a list)
				val.push(0);
			}
			this.persist('library.quick_collection', val.join(','));
		}, this);

		this.observers.api_url.subscribe(function() {
			this.controllers.set_current_nav();
		}, this);

	},

	router: function() {

		return {

			'default': function(url) {

				url = url || 'content';

				// If plugin is activated and url hash references plugin reset to content url
				var url_parts = url.split('/');
				$.each(this.interfaces.app.observers.plugins(), function(i, plugin) {
					if (plugin.console && url_parts.indexOf(plugin.php_class_name.replace('Koken', '').toLowerCase().trim()) !== -1) {
						$.bbq.pushState( '/library', 2 );
					}
				});
				//

				var defaultView = this.cookie('library:view') || 'grid';

				function is_orderable(url) {
					return url.match(/albums\/\d+\/content$/) ||
					url.match(/(content|albums)\/featured(\/.*)?$/) ||
					url.match(/favorites/) ||
					url.match(/quick_collection:true/) ||
					url.match(/albums\/visibility:public/);
				}

				this.observers.is_sortable(
					(url.match(/^albums\/\d+\/content/) && url.indexOf('visibility') === -1 &&  url.indexOf('types') === -1 && url.indexOf('covers') === -1) ||
					(url.match(/^content/) && url.indexOf('year') === -1 && url.indexOf('after') === -1 &&  url.indexOf('quick') === -1 && url.indexOf('unlisted') === -1 && url.indexOf('private') === -1) ||
					url.match(/favorites/) ||
					url.match(/albums\/visibility:public/)
				);

				var upload_match_url = url.replace(/\/selection:[\d,]+/, '');
				this.observers.is_uploadable(
					upload_match_url === 'content' ||
					upload_match_url.match(/albums\/\d+\/content$/) ||
					upload_match_url.match(/content\/featured(\/.*)?$/) ||
					upload_match_url.match(/favorites/) ||
					upload_match_url.match(/content\/visibility:/)
				);

				var final_url = url,
					filters = [ 'covers', 'types', 'independent', 'koken_.*?' ].concat(url.indexOf('albums') === 0 ? [] : ['visibility']),
					regex = RegExp('/(' + filters.join('|') + '|selection|view|order_by|year|month|order_direction|search_filter|search|quick_collection):([^\/]+)', 'g'),
					non_api = [ "selection", "view", "quick_collection" ],
					matches,
					observers = {
						"selection": [],
						"view": defaultView,
						"order_by": "published_on",
						"order_direction": is_orderable(url) || url.match(/albums\/visibility:(unlisted|private)/) ? false : 'desc',
						"search": '',
						"search_filter": ( this.cookie('library:search_filter') === null ) ? 'tags' : this.cookie('library:search_filter'),
						"year": false,
						"month": false,
						"filter": 'none'
					},
					self = this,
					obj = {
						cb: function() {
							// Pick out search_filter and run it first so it sticks
							if (observers.search_filter) {
								self.observers.search_filter( observers.search_filter );
								delete(observers.search_filter);
							}
							for (var i in observers) {
								if (i === 'filter') {
									self.observers.dropdowns.filter.selected(observers[i]);
								} else {
									if (i.indexOf('order_') === 0 && self.observers.is_sortable()) continue;
									self.observers[i]( observers[i] );
								}
							}
						},
						load_until: false
					};

				if ( this.config.cache && ! $.isEmptyObject(this.internalcache) ) {
					this.cache( $('#content_list') );
				}

				this.observers.sidebar.hasgeo(false);

				var order_defined = false,
					order_direction_defined = false;
					is_quick = false;

				while( (matches = regex.exec(url)) !== null) {

					var ob = matches[1],
						val = matches[2];

					if (ob === 'quick_collection') {
						var ids = self.observers.quick_collection();
						if (ids.length < 2) {
							if (ids.length) {
								ids.push(ids[0]);
							} else {
								ids = [0,0];
							}
						}

						final_url += '/' + ids.join(',') + '/visibility:any';
						is_quick = true;
					} else if ($.inArray(ob, filters) !== -1) {
						observers.filter = ob === 'covers' || ob === 'independent' ? ob : val;
					} else if (/^koken_/.test(ob)) {
						observers.filter = ob;
					} else {
						if (ob === 'selection') {
							if (val == 'NaN') self.controllers.rewrite_hash({ name: 'selection', val: false});
							obj.load_until = Number(val);
							val = val.split(',').map( function(id) { return Number(id); });

							if (url.match(/trash/)) this.observers.trashRestoreDisabled(false);
							$('#container').trigger('selection.loaded');
						}

						if (val.length > 1) this.observers.get_selected_asset_categories(val);

						// Fixes for [object,object] appearing after some bogus selections
						if (val[0].toString() == 'NaN') self.controllers.rewrite_hash({ name: 'selection', val: false});

						if (ob === 'view' && (val === 'empty' || val === 'upload')) {
							val = 'grid';
						}

						observers[ob] = val;

						if (ob === 'order_by') {
							order_defined = true;
						} else if (ob === 'order_direction') {
							order_direction_defined = true;
						}
					}

					if ($.inArray(ob, non_api) !== -1) {
						final_url = final_url.replace(matches[0], '');
					}

				}

				this.observers.is_quick_collection(is_quick);

				if (final_url.match(/albums\/\d+\/content/) || is_orderable(final_url) || is_quick) {
					if (is_orderable(final_url) || is_quick) {
						this.observers.sortable(true);
					}
					this.observers.default_sort('manual');
					if (!order_defined) {
						observers.order_by = 'manual';
						observers.order_direction = false;
					} else if (observers.order_by !== 'manual' && !order_direction_defined) {
						observers.order_direction = observers.order_by === 'title' ? 'asc' : 'desc';
					}
				} else if (final_url.match(/albums\/visibility:/)) {
					this.observers.sortable(false);
					this.observers.default_sort('title');
					if (!order_defined || $.inArray(observers.order_by, [ 'title', 'modified_on', 'created_on' ] ) === -1) {
						observers.order_by = 'title';
					}
					if (!order_direction_defined) {
						observers.order_direction = observers.order_by === 'title' ? 'asc' : 'desc';
					}
				} else if (final_url.match(/content\/(unlisted|privat)/)) {
					if (!order_defined) {
						observers.order_by = 'captured_on';
					}
					this.observers.default_sort('captured_on');
					this.observers.sortable(false);
				} else {
					this.observers.default_sort('published_on');
					this.observers.sortable(false);
				}

				if (observers.order_direction === false && final_url.indexOf('order_direction:') !== -1) {
					final_url = final_url.replace(/\/order_direction:(asc|desc)/, '');
				}

				if ( this.observers.api_url() !== final_url ) {

					$('#content_list').empty();
					if (!final_url.match(/search:[^\/]/)) {
						this.observers.search('');
					}
					this.observers.api_url( final_url );
					this.observers.passengers.next_page = false;
					self.controllers.load_content(obj);

				} else {
					obj.cb();
				}

				if ( final_url.match(/trash/) ) {
					this.observers.dropdowns.filter.selected('none');
				}

				requestAnimationFrame(function() {
					$.each(self.classes.scrollbar, function(i, scroll) {
						if (this.loopy) {
							this.lastpos = -1;
							this.elements.container.trigger('scroll', {
								suppress: true
							});
						}
					});
				});

				this.controllers.events_init();

				this.views.close_sidebar_drawer();
				$('#container').trigger('library.loaded');

				$(window).off('mousemove').on('mousemove', function(e) {
					if ( e.pageX > 240 ) {
						$('[data-event="toggle_gear_popout"]').hide();
					}
				}).trigger('resize');

			}

		};

	},

	events: {

		enable_plugin_import: function(el) {
			var plugin = this.observers.hasPlugin('php_class_name', el.data('php')),
				self = this;
			if (plugin) {
				this.controllers.toggle_plugin(plugin, function() {
					var selected = $('#import_content li.selected a');
					$('#container').trigger('library.loaded');
					$('#container').trigger('sheet.activated', self.observers.sheets.import_content);
					requestAnimationFrame(function() {
						selected.trigger('click');
					});
				});
			}
		},

		toggle_focal: function(el) {
			this.observers.focal_point( !this.observers.focal_point() );
		},

		sort_year: function(el, parent) {
			var data = ko.dataFor(el[0]);

			if (parent.hasClass('selected')) {
				$.bbq.pushState( '/library', 2 );
			} else {
				$.bbq.pushState( '/library/content/year:' + data.year, 2 );
			}
		},

		sort_month: function(el, parent) {
			var data = ko.dataFor(el[0]),
				p = el.parent();

			if (parent.hasClass('selected')) {
				$.bbq.pushState( '/library', 2 );
			} else {
				$.bbq.pushState( '/library/content/year:' + data.year + '/month:' + data.month, 2 );
			}
		},

		init: function() {

			this.setup_keyboard_bindings();
			this.setup_drawer_events();
			this.gear_hovering();

		},

		load_quick_collection: function() {
			var ids = this.observers.quick_collection();
			if (ids.length) {

			} else {
				this.observers.view('empty');
			}
		},

		setup_keyboard_bindings: function() {

			var self = this;

			this.controllers.multiselect( '#content_list li', function(sel) {
				self.controllers.rewrite_hash({ name: 'selection', val: sel });
			});

			$(document).on('dblclick', '#content_list li', function(e) {

				if ( self.observers.sidebar.id() !== 'trash' ) {
					var li = $(e.target).closest('li');
					if (li.hasClass('album') || li.hasClass('set')) {
						location.hash = '/library/albums/' + li.data('id') + '/content';
					} else {
						self.controllers.rewrite_hash({ name: 'view', val: 'single'});
					}

				}

			});

			$(document).on('dblclick', '#mid-single-wrap', function() {
				if (self.observers.view() === 'single') {
					self.controllers.rewrite_hash({ name: 'view', val: 'grid'});
				}
			});

			$(window).on('keydown', function(e) {

				var bypass = false;

				if ( $("*:focus").length > 0 && ( e.metaKey || e.ctrlKey ) ) {
					if ( e.which === 37 || e.which === 39 ) {
						$("*:focus").blur();
						bypass = true;
					}
				}

				if ( e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'TEXTAREA' && e.target.nodeName !== 'SELECT' || bypass ) {

					switch (e.which) {
						case 38:
						case 40:
							if (self.observers.view() === 'grid') {
								var index = self.observers.properties.content.selected.index(),
									cols = 0,
									offset = false,
									new_index = false;

								// This seems more reliable than trying to guess view viewport_area()
								$('#content_list li').each(function(i, li) {
									if (!offset || offset === $(li).offset().top) {
										offset = $(li).offset().top;
										cols++;
									} else {
										return false;
									}
								});

								new_index = e.which === 38 ? index - cols : index + cols;

								if (new_index >= 0 && new_index < self.observers.content().length) {
									self.controllers.rewrite_hash({ name: 'selection', val: self.observers.content()[new_index].id});
									e.preventDefault();
								}
							} else if (self.observers.view() === 'list') {
								if (e.which === 38) {
									self.observers.prev_item();
								} else {
									self.observers.next_item();
								}
							}
							break;

						case 65:
							if (e.metaKey && self.observers.view() !== 'single') {
								self.events.select_all();
							}
							break;

						case 67:
							if ( e.metaKey || e.ctrlKey ) { return; }
							if ( $('[data-ob="caption"]').length > 0 ) {
								$('[data-ob="caption"]').focus();
							} else {
								$('[data-ob="description"]').focus();
							}
							e.preventDefault();
							break;

						case 84:
							if ( e.metaKey || e.ctrlKey ) { return; }
							$('[data-ob="title"]').focus();
							e.preventDefault();
							break;

						case 70:
							if (e.metaKey) {
								$('#search_form input').focus();
								e.preventDefault();
							}
							break;

						case 73:
							if (e.metaKey || e.ctrlKey) {
								e.preventDefault();
								self.events.toggle_sheet($('[data-sheet="import_content"]'))
							}
							break;

						case 39:
							self.observers.next_item();
							e.preventDefault();
							break;

						case 37:
							self.observers.prev_item();
							e.preventDefault();
							break;

						case 27:
							// TODO: Temp fix until we can refactor dropdowns into a proper class
							$('#container').find('.opt-menu').each( function() {
								var _ob = ( $(this).closest('#interface_container').length <= 0 ) ? self.interfaces.app.observers : self.observers;
								id = $(this).attr('id');
								if ( id && id.indexOf('_dd') >= 0 ) {
									id = id.replace('_dd','');
									_ob.dropdowns[id].active(false);
								}
							});
							if ( self.observers.selection().length > 0 ) {
								if (self.observers.view() === 'single') {
									self.controllers.rewrite_hash({ name: 'view', val: false});
									self.controllers.rewrite_hash({ name: 'selection', val: false});
								} else {
									self.controllers.rewrite_hash({ name: 'selection', val: false});
								}
							}
							break;
					}

				}

			});

		},

		search_filter: function() {
			var opt = $('#search_form .opt-menu');
			opt.find('.wedge').css('left', '20px');
			opt.css('left', '-10px').show();
		},

		search_filter_change: function(el) {
			var val;
			if (el.hasClass('opt-tags')) {
				val = 'tags';
			} else if (el.hasClass('opt-filename')) {
				val = 'filename';
			} else if (el.hasClass('opt-title')) {
				val = 'title';
			} else {
				val = 'category';
			}
			this.observers.search_filter(val);
			this.cookie( 'library:search_filter', val );
			$('#search_form input').val('');
		},

		badges: function(el,parent,e) {

			// Capture clicks with a modifier key being pressed (ALT|SHIFT|CTRL|CMD), create a
			// new event with the modifier and trigger it on the image itself
			if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) {
				var ev = $.Event('click', {
					metaKey: e.metaKey,
					ctrlKey: e.ctrlKey,
					shiftKey: e.shiftKey
				});
				$(el).closest('li').trigger(ev);
				return false;
			}
			//

			if ( el.hasClass('fave') || el.hasClass('featured') ) {

				var type	= ( el.closest('.album-stack').length > 0 ) ? 'albums' : 'content',
					which	= ( el.hasClass('fave') ) ? 'favorite' : 'featured',
					data	= { id: el.closest('li').data('id'), type: type };

				data[which] = 0;

				if ($('#content_list li[data-id="' + el.closest('li').data('id') + '"]').hasClass('album')) {
					this.notify().success( $('#content_list li[data-id="' + el.closest('li').data('id') + '"]').find('.title').text() + ' ' + ( ( el.hasClass('fave') ) ? 'removed from Favorites' : 'removed from Featured albums' ) );
				} else {
					this.notify().success( $('#content_list li[data-id="' + el.closest('li').data('id') + '"]').find('.title.filename').text() + ' ' + ( ( el.hasClass('fave') ) ? 'removed from Favorites' : 'removed from Featured content' ) );
				}

				if (this.observers.api_url() === 'favorites' || this.observers.api_url() === 'content/featured') {
					switch(this.observers.api_url()) {
						case 'favorites' :
							if (which === 'favorite') el.closest('[data-which="thumbnail"]').remove();
							break;
						case 'content/featured' :
							if (which === 'featured') el.closest('[data-which="thumbnail"]').remove();
							break;
					}

					if (!$('#content_list li[data-which="thumbnail"]').length) {
						this.observers.view('empty');
					}
				}

				if (this.observers.api_url() === 'albums/featured') {
					el.closest('li.album.featured.loaded').remove();
					if (!$('#content_list li.album.featured.loaded').length) {
						this.observers.view('empty');
					}
				}

				this.controllers.update(type, data, {
					instant: true,
					finished: function() {
						if (type === 'albums' && which === 'featured') {
							this.controllers.load_tree();
						}
					}
				});

			}

		},

		setup_drawer_events: function() {

			var parent	= $('#right-col-data'),
				asset	= {},
				self	= this,
				type, item;

			$('html')
				.off('.drawer')
				.on('keypress.drawer', parent.find('input'), function(e) {
					if (e.which == 13 && $(this).val() !== '') {
						var p = $(this).parents('div:first');
						p.find((p.hasClass('check-edit')) ? 'a[data-event="save"]' : 'button.data-submit').trigger('click');
					}
				})
				.on('click.drawer', parent, function(e) {

					if ( !$(e.target).hasClass('data-submit') ) return true;

					type = $(e.target).closest('[data-submit]').data('submit');

					var _asset = { id: self.observers.sidebar.asset.id && self.observers.sidebar.asset.id() },
						asset_type	= ( self.observers.sidebar.id() === 'album' ) ? 'albums' : 'content',
						el			= $(e.target),
						item;

					var save_asset = function() {

						var item = el.parent().parent().find('input:first'),
							temp = {},
							ob = $.extend( true, {}, this.properties.multi.labels() ),
							bits, clean, ids, selected;

						if (type === 'visibility' && self.observers.sidebar.id() === 'album' && (self.classes.channel.library.albumShouldMatchVsibility === null || typeof self.classes.channel.library.albumShouldMatchVsibility === 'undefined')) {

							var shouldShowModal = false,
								allAlbums = $.extend({}, self.observers.albums_flat(), self.observers.unlisted_albums(), self.observers.private_albums());

							function recursive_search(album) {
								if (album.id == self.observers.sidebar.asset.id() && album.count > 0) {
									shouldShowModal = true;
									return false;
								}

								if (album.children) {
									return $.each(album.children, function(j, a) {
										return recursive_search(a);
									});
								}
							}

							$.each(allAlbums, function(i, album) {
								return recursive_search(album);
							});

							if (shouldShowModal) {
								self.classes.modal.match_album_visibility.options.hasKeepCancel.keepFn = function() {
									self.classes.channel.library.albumShouldMatchVsibility = true;
									$('#right-col-xtra button.data-submit').click();
									this.views.close_sidebar_drawer();
								}
								self.classes.modal.match_album_visibility.options.hasKeepCancel.cancelFn = function() {
									self.classes.channel.library.albumShouldMatchVsibility = false;
									$('#right-col-xtra button.data-submit').click();
									this.views.close_sidebar_drawer();
								};
								if (self.observers.sidebar.asset.album_type) {
									self.classes.modal.match_album_visibility.show(0, self.observers.sidebar.asset.visibility.raw(), $('input[name="visibility"]:checked').val(), (self.observers.sidebar.asset.album_type() !== 'set') ? 'album' : 'set');
								} else {
									self.classes.modal.match_album_visibility.show(0, self.observers.sidebar.asset.visibility.raw(), $('input[name="visibility"]:checked').val());
								}
							} else {
								self.classes.channel.library.albumShouldMatchVsibility = false;
								$('#right-col-xtra button.data-submit').click();
								this.views.close_sidebar_drawer();
							}

							return;
						}

						if (type === 'multi_history') {

							var _type = self.observers.multiHistory.drawer();

							if (self.observers.multiHistory.type() === 'shift') {

								var _years = self.observers.multiHistory.date.years(),
									_months = self.observers.multiHistory.date.months(),
									_days = self.observers.multiHistory.date.days(),
									_hours = self.observers.multiHistory.date.hours(),
									_mins = self.observers.multiHistory.date.minutes(),
									apiStr = '';

								if (_years === 0 && _months === 0 && _days === 0 && _hours === 0 && _mins === 0) return;

								if (_years !== 0) apiStr += _years + ' years ';
								if (_months !== 0) apiStr += _months + ' months ';
								if (_days !== 0) apiStr += _days + ' days ';
								if (_hours !== 0) apiStr += _hours + ' hours ';
								if (_mins !== 0) apiStr += _mins + ' minutes ';

								temp[_type] = apiStr.trim();

							} else if (self.observers.multiHistory.type() === 'captured') {

								var itemData = self.controllers.get_asset_by_id(_asset.id);

								if (itemData.captured_on.utc) {
									temp[_type] = itemData.captured_on.timestamp;
								} else {
									temp[_type] = 'captured_on';
								}

							} else {

								var dt = $('#right-col-data input.datepicker').val().trim(),
									itemData = self.controllers.get_asset_by_id(_asset.id),
									raw_dt = dt,
									msg;

								if (_type === 'captured_on' && itemData.captured_on.utc === false) raw_dt += ' GMT';

								temp[_type] = moment(raw_dt).unix();

							}

						} else if ( type === 'license' ) {

							if ($('input[name=lic1]').is(':checked')) {
								temp.raw = $('input[name=lic2]:checked').val() + ',' + $('input[name=lic3]:checked').val();
							} else {
								temp.raw = 'all';
								temp.clean = '© All rights reserved';
							}

							if (!temp.clean) {

								bits = temp.raw.split(',');

								if (bits[0] == 'y') {
									clean = 'Commercial';
								} else {
									clean = 'NonCommercial';
								}

								switch(bits[1]) {
									case 's':
										clean += '-ShareAlike';
										break;
									case 'n':
										clean += '-NoDerivs';
										break;
								}

								temp.clean = clean;

								self.observers.multiedit.changed.license = clean;

								if ( this.sidebar.id() === 'multi' ) {
									ob.license = clean;
									self.observers.properties.multi.labels(ob);
								}

							}
						} else if ( $('div[data-submit=' + type + ']').find('input[name=' + type + ']').length ) {

							temp.raw = $('div[data-submit=' + type + ']').find('input[name=' + type + ']:checked').val();

							if ( type === 'visibility' ) {

								clean = helpers.capitalize(temp.raw);

								if ( this.sidebar.id() === 'multi' ) {
									ob.visibility = clean;
									self.observers.properties.multi.labels(ob);

									if ( this.sidebar.asset.visibility.clean() != clean ) {
										self.observers.multiedit.changed.visibility = clean;
									}
								}

								// Remove album from site nav href if changing from public to unlisted
								if ( temp.raw !== 'public' && $('#main-nav a[class~="site-interface"]').attr('href').indexOf(this.sidebar.asset.title().toLowerCase().replace(' ','_')) >= 0 ) {
									$('#main-nav a[class~="site-interface"]').attr('href', '#/site');
								}

							} else if ( type === 'max_download' ) {

								switch(temp.raw) {
									case 'original':
										clean = 'Original';
										break;

									case 'huge':
										clean = 'Huge (2048)';
										break;

									case 'xlarge':
										clean = 'X-Large (1600)';
										break;

									case 'large':
										clean = 'Large (1024)';
										break;

									case 'medium_large':
										clean = 'Medium-Large (800)';
										break;

									case 'medium':
										clean = 'Medium (480)';
										break;

									default:
										clean = 'None';
										break;
								}

								if ( this.sidebar.asset.max_download && this.sidebar.asset.max_download.clean() != clean ) {
									self.observers.multiedit.changed.maxdl = clean;
								}

							}

							temp.clean = clean;

						} else if ( type === 'created_on' || type === 'uploaded_on' || type === 'captured_on' || type === 'published_on') {

							var dt = item.val().trim(),
								raw_dt = dt;

							if (dt === 'captured' || dt === 'assign') dt = raw_dt = item.closest('[data-submit]').find('.datepicker').val().trim();

							// captured_on should not be shifted to UTC
							if (type === 'captured_on' && this.sidebar.asset.captured_on.utc() === false) {
								raw_dt += ' GMT';
							}

							var ts = moment(raw_dt).unix();

							switch(type) {

								case 'created_on' :
									msg = 'Date created';
									break;

								case 'uploaded_on' :
									msg = 'Date uploaded';
									break;

								case 'captured_on' :
									msg = 'Date captured';
									break;

								case 'published_on' :
									msg = 'Date published';
									break;

							}

							self.notify().success(msg + ' changed to ' + dt);

							if (type === 'published_on' && self.observers.singleHistory.type() === 'captured') {
								if (this.sidebar.asset.captured_on.utc()) {
									ts = this.sidebar.asset.captured_on.timestamp();
								} else {
									ts = 'captured_on';
								}
							}

							temp[type] = ts;

						} else {

							temp[type] = item.val().trim();

						}

						_asset[((type !== 'multi_history') ? type : _type)] = temp.raw !== undefined ? temp : temp[((type !== 'multi_history') ? type : _type)];

						if (self.classes.channel.library.albumShouldMatchVsibility) _asset['match_album_visibility'] = 1;
						self.classes.channel.library.albumShouldMatchVsibility = null;

						var o = {};

						if (type === 'visibility' && (self.observers.dropdowns.filter.selected() == 'public' || self.observers.dropdowns.filter.selected() == 'unlisted' || self.observers.dropdowns.filter.selected() == 'private') && _asset[type] != self.observers.dropdowns.filter.selected()) {
							$('#content_list li[data-id="' + _asset.id + '"]').remove();
							this.controllers.rewrite_hash({ name: 'selection', val: false});
							self.observers.properties.overall.counts.images(self.observers.properties.overall.counts.images() - 1);
							self.observers.properties.overall.total(self.observers.properties.overall.total() - 1);
						}

						var in_album = false;

						if (type === 'visibility' && this.sidebar.id() !== 'multi') {
							var note = this.notify().wait('Changing visibility');
						}

						if (type === 'visibility' || type === 'published_on' || type === 'multi_history') {
							o.instant = true;

							if (self.observers.sidebar.id() === 'album') {
								o.finished = function(data) {
									if (note) {
										note.success('Visibility updated to ' + data.visibility.raw);
									}
									self.controllers.load_tree();
									self.controllers.load_content(null, true);
									self.controllers.update_item(data);
								};
							} else {
								var matches = self.observers.api_url().match(/^albums\/(.*)\/content/),
									note;

								if (!self.observers.multiHistory.note && type === 'multi_history') self.observers.multiHistory.note = self.notify().wait('Updating');
								if (matches) in_album = matches[1];

								o.finished = function(data) {
									if (type === 'multi_history') {
										self.observers.multiHistory.note && self.observers.multiHistory.note.success('Date ' + _type.split('_')[0] + ' successfully updated for ' + $('#content_list li.selected').length + ' items');
										self.observers.multiHistory.note = null;
									}
									self.controllers.update_item(data);

									if (note) {
										note.success('Visibility updated to ' + data.visibility.raw);

										if (self.observers.api_url().indexOf('content') === 0) {
											$.bbq.pushState( '/library/content' + (data.visibility.raw !== 'public' ? '/' + data.visibility.raw : '') + '/selection:' + data.id, 2 );
										}
									}
								};
							}
						}

						// Handles the sidebar updates for multiselect drawer changes (download|visibility|etc..)
						if (self.observers.selection().length > 1 && (type === 'visibility' || type === 'max_download' || type === 'license')) {
							self.observers.sidebar.asset[type].clean(_asset[type].clean);
							self.observers.sidebar.asset[type].raw(_asset[type].raw);
						}

						self.controllers.update(asset_type, _asset, o, in_album);

					};

					if (asset_type == 'albums' && self.observers.sidebar.asset.visibility.raw() !== 'public') {
						asset_type = self.observers.sidebar.asset.visibility.raw() + '_albums';
					}

					if ( asset_type === 'content' ) {

						$.each( self.observers.selection(), function(k,v) {

							_asset				= $.extend( true, {}, _asset ); // Clone the object
							_asset.id			= v;

							save_asset.call(self.observers);

						});
					} else {
						save_asset.call(self.observers);
					}

				});
		},

		scroll_overlay: function() {

			if (!this.observers.is_loading) {

				var row			= $('#mid_multi').find('.scroll_wrapper').scrollTop() / $('#content_list > li').first().outerHeight(true),
					row_index	= Math.floor(row) * ( this.observers.view() === 'grid' ? this.helpers.viewport_area().col : 1 ),
					date		= new Date(),
					sort		= this.observers.order_by(),
					time		= '',
					str			= '';

				if ( sort === 'created_on' || sort === 'uploaded_on' || sort === 'modified_on' || sort === 'captured_on' || sort === 'published_on' ) {
					time = this.observers.content()[row_index][sort].timestamp * 1000;
					if (time && time > 0) {
						str = moment( date.setTime(time) ).format('MMMM YYYY');
					} else {
						str = 'No info';
					}

				} else if ( sort === 'dimension' ) {
					str = this.observers.content()[row_index].width + ' x ' + this.observers.content()[row_index].height;
				} else if (sort === 'aspect_ratio') {
					str = this.observers.content()[row_index][sort].toFixed(1);
				} else if (typeof this.observers.content()[row_index][sort] === 'number') {
					str = this.observers.content()[row_index][sort].toString();
				} else {
					str = this.observers.content()[row_index][sort].substr(0,1).toUpperCase();
				}

				$('#scroll-tick').removeClass('loading');
				$('#scroll-tick p:last').text(str);
				$('#scroll-tick p:first').text($.trim(helpers.capitalize($.trim(sort.replace(/^koken|_on|_/gi, ' ')))));

				$('#scroll-tick').css({
					/* adding 18px makes it actually centered. why, dunno */
					marginLeft: '-' + ( $('#scroll-tick').width() / 2 ) - 18 + 'px'
				});
			}
		},

		trashed: function(el,parent,e) {

			var items	= {},
				ids		= [],
				self	= this,
				req		= [];

			if ( el.hasClass('restore-all') ) {

				ko.utils.arrayForEach( this.observers.content(), function(item) {
					if ( self.observers.selection().indexOf(item.id) >= 0 ) {
						var type = (item.album_type) ? 'album' : 'content';
						items[type] = items[type] || '';
						items[type] += item.id + ',';
						ids[ids.length] = item.id;
					}
				});

				if (items.album) {
					req[req.length] = { request: 'trash/albums:' + helpers.rtrim( items.album, ',' ) };
				}
				if (items.content) {
					req[req.length] = { request: 'trash/content:' + helpers.rtrim( items.content, ',' ) };
				}

				var note = this.notify().wait('Restoring items');

				this.sync({
					request: req,
					method: 'DELETE',
					context: this,
					finished: function() {
						var total = this.observers.properties.trash.total() - ids.length;
						note.success( ids.length + ' trash items restored');
						if ( total <= 0 ) {
							$.bbq.pushState( '/library', 2 );
						}
						this.controllers.rewrite_hash({ name: 'selection', val: false});
						this.controllers.load_tree();
						this.observers.properties.trash.total(total);
						this.controllers.update_summary();
					}
				});

			} else if ( el.hasClass('empty') ) {

				if ( $('#content_list li.album').length > 0 ) {
					req.push({ request: 'albums/trash' });
				}
				if ( $('#content_list li.content').length > 0 ) {
					req.push({ request: 'content/trash' });
				}

				var note = this.notify().wait('Emptying trash');

				this.sync({
					request: req,
					method: 'DELETE',
					context: this,
					finished: function(data) {
						note.success('Trash emptied');
						this.observers.properties.trash.total(0);
						$.bbq.pushState( '/library', 2 );
						this.controllers.update_summary();
					}
				});

			}

			$('#content_list li.selected').remove();

		},

		share: function(el,parent) {

			var	url = encodeURIComponent(this.observers.sidebar.asset.url()),
				text = this.observers.sidebar.asset.title() && this.observers.sidebar.asset.title().length ? this.observers.sidebar.asset.title() : this.observers.sidebar.asset.filename();

			if ( parent.find('.opt-twitter').length > 0 ) {
				window.open( 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(text) + '&url=' + url, '_blank', 'width=550,height=420' );
			} else if ( parent.find('.opt-google').length > 0 ) {
				window.open( 'https://plus.google.com/share?url=' + url, '_blank', 'width=550,height=550' );
			} else if ( parent.find('.opt-tumblr').length > 0 ) {
				text = '<p><a href="' + this.observers.sidebar.asset.url() + '" title="' + text + '"><strong>' + text + '</strong></a></p>' + (this.observers.sidebar.asset.caption() ? '<p>' + this.observers.sidebar.asset.caption() + '</p>' : '');
				this.cookie('share_to_tumblr', true);
				window.open( this.observers.sidebar.asset.url(), '_blank', 'width=550,height=550' );
			} else {
				window.open( 'http://www.facebook.com/sharer/sharer.php?u=' + url, '_blank', 'width=550,height=420' );
			}

			return false;

		},

		map: function(el,parent) {

			var _lat	= this.observers.sidebar.asset.geolocation.latitude(),
				_long	= this.observers.sidebar.asset.geolocation.longitude(),
				self	= this;

			$.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + _lat + ',' + _long + '&sensor=false', function(data) {
				self.classes.modal.map.show();

				var element = $('<div/>').attr('id','map_container').width(640).height(480);

				$('.map_modal #map_container').remove();
				$('.map_modal .wrap').append(element);
				$('#map_info').text(data.results[0].formatted_address);

				requestAnimationFrame(function() {
					var map = new google.maps.Map(document.getElementById('map_container'), {
						zoom: 13,
						center: new google.maps.LatLng(_lat, _long),
						mapTypeId: google.maps.MapTypeId.ROADMAP
					});

					marker = new google.maps.Marker({
						icon: 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png',
						map: map,
						animation: google.maps.Animation.DROP,
						position: new google.maps.LatLng(_lat, _long)
					});

					$(window).resize();
				});
			});

		},

		toggle_share_modal_option: function(el) {
			el.parent().find('input').attr('checked','checked');
		},

		share_modal_link: function(el,parent) {

			var ob		= this.observers,
				parent	= parent.closest('.modal-tab-view'),
				src		= ob.properties.paths.base + 'index.php?/content/',
				inp		= parent.find('.output'),
				source, width, height, url;

			switch( parent.find('input[name="resize"]:checked').attr('class') ) {

				case 'preset' :
					source = ob.sidebar.asset.presets[parent.find('select option:selected').val()];
					break;

				case 'custom' :
					source = {
						width: parseInt( parent.find('.wid').val(), 10 ),
						height: parseInt( parent.find('.hei').val(), 10 )
					};
					source.url = ob.sidebar.asset.cache_path.prefix() + [ source.width, source.height, 90, 1 ].join('.');
					// For some reason, .not(':checked') doesn't work here
					if ( ! $('#constrain').is(':checked') ) {
						source.url += '.crop';
					}
					source.url += '.' + ob.sidebar.asset.cache_path.extension();
					break;

				case 'orig' :
					source = ob.sidebar.asset.original;
					break;

			}

			width	= ( source.width instanceof Function ) ? source.width() : source.width;
			height	= ( source.height instanceof Function ) ? source.height() : source.height;
			url		= ( source.url instanceof Function ) ? source.url() : source.url;

			src = '<img src="' + url + '" width="' + width + '" height="' + height + '" />';

			inp.attr('value',src).removeAttr('disabled');

			$('.output').show().focus();

		},

		share_modal_link_custom: function(el,parent) {

			var parent = parent.closest('.modal-tab-view'),
				self = this,
				width;

			var constrain_ratio = function() {
				parent.find('.hei').val( Math.round( parent.find('.wid').val() / self.observers.sidebar.asset.aspect_ratio() ) );
			}

			if ( el[0].tagName === 'INPUT' ) parent.find('.custom').attr('checked','checked');

			if ( el.attr('id') === 'constrain' && el.is(':checked') ) constrain_ratio();

			$(document).off('.share_modal_link_custom').on('keyup.share_modal_link_custom', 'li[data-event="share_modal_link_custom"]', function() {
				if ( el.parent().find('#constrain').is(':checked') ) constrain_ratio();
			});

		},

		edit_menu: function(el,parent,e) {

			var atag		= el.closest('a'),
				which		= atag.find('.opt-icon'),
				ids			= this.observers.selection(),
				self		= this,
				fav			= ( parent.find('.fav-type').text().toLowerCase().trim() === 'mark' ) ? true : false,
				feat		= ( parent.find('.feat-type').text().toLowerCase().trim() === 'mark' ) ? true : false,
				cover		= ( parent.find('.feat-cover').text().toLowerCase().trim() === 'mark' ) ? true : false,
				quick		= ( parent.find('.quick-type').text().toLowerCase().trim() === 'add to' ) ? true : false,
				type		= this.observers.selected_type() === 'set' ? 'albums' : 'content',
				method,
				remove_from_mid = function(ids) {

					var newContentArr = [];
					$.each(self.observers.content(), function(i, asset) {
						if (ids.indexOf(asset.id) == -1) {
							newContentArr.push(asset);
						} else {
							$('#content_list li[data-id="' + asset.id + '"]').remove();
						}
					});
					self.observers.content(newContentArr);

					if (self.observers.order_by() === 'manual') {
						$.each( $('#content_list li'), function() {
							var count = ( self.observers.view() === 'list' ) ? $(this).find('.cnt') : $(this).find('.count');
							count.text( $(this).index() + 1 );
							$(this).data('index', $(this).index());
						});
					}

					self.observers.properties.overall.total( Math.max(0, self.observers.properties.overall.total() - ids.length) );
				};

			if ( which.hasClass('opt-cover') ) {

				var id;

				$.each(this.observers.properties.album.selected().album.covers, function(i, cover) {
					if (cover.id == $('#mid-content li.selected').data('id')) {
						id = cover.id;
					}
				});

				if (id) {
					$('.feat-cover').text('Assign');
					this.events.assign_cover($('#mid-content li.selected'));
				} else {
					$('.feat-cover').text('Unassign');
					var _ids = [];
					$('#mid-content li.selected').each(function() {
						_ids.push($(this).data('id'))
					});
					this.events.assign_cover('album', this.observers.properties.album.selected().album.id, _ids.join(','));
				}

			}

			if ( which.hasClass('opt-quick') ) {

				var current = this.observers.quick_collection(),
					updates = [],
					upd_typ = '';

				$.each(ids, function(i, id) {
					id = Number(id);
					var index = $.inArray(id, current);

					if (quick) {
						if (index === -1) {
							self.observers.quick_collection.push(id);
							updates.push('add');
						}
					} else if (index !== -1) {
						// It's possible for quick_collection to have duplicates
						while (index !== -1) {
							self.observers.quick_collection.splice( index, 1 );
							index = $.inArray(id, self.observers.quick_collection());
							updates.push('remove');
						}
						if (self.observers.api_url().match(/content\/\d+,\d/)) {
							$('#list_item_' + id).remove();
							updates.push('remove');
						}
					}
				});

				if ( updates.indexOf('add') < 0 ) {
					upd_type = 'removed';
				} else if ( updates.indexOf('remove') < 0 ) {
					upd_type = 'added';
				} else {
					upd_type = 'mixed';
				}

				if ( upd_type !== 'mixed' ) {
					if ( $('#content_list li.selected').length > 1 ) {
						self.notify().success( $('#content_list li.selected').length + ' items ' + upd_type );
					} else {
						self.notify().success( $('#content_list li.selected').find('.filename').text() + ' ' + upd_type );
					}
				} else {
					self.notify().success( 'Quick collection updated' );
				}

			}

			if ( which.hasClass('opt-remove') || which.hasClass('opt-trash') ) {

				if ( this.observers.is_collection() ) {

					ids = [];
					$.each($('#content_list li.selected'), function(i, asset) {
						ids.push($(this).data('id'));
					});

					var collection = $.grep( self.observers.quick_collection(), function(k,v){
						return $.inArray(k, ids) == -1;
					});

					self.observers.quick_collection(collection);

				}

				// In single view we need to grab the index being removed prior to remove_from_mid
				if (this.observers.view() === 'single') {
					var itemRemovedIndex = $('#content_list li[data-id="' + ids[0] + '"]').data('index'),
						nextId = this.observers.content()[(((itemRemovedIndex+1) >= this.observers.content().length) ? 0 : ++itemRemovedIndex)].id;
				}

				var txt = $('#content_list .selected').find('.' + (type === 'content' ? 'filename' : 'title')).text();

				remove_from_mid(ids);

				if ( which.hasClass('opt-remove') ) {

					this.controllers.rewrite_hash({ name: 'selection', val: false });

					if ( ids.length > 1 ) {
						this.notify().success( ids.length + ' items were removed from ' + (($.param.fragment().indexOf('quick_collection:') === -1) ? this.observers.properties.album.selected().album.title : 'quick collection') );
					} else {
						this.notify().success( txt + ' was removed from ' + (($.param.fragment().indexOf('quick_collection:') === -1) ? this.observers.properties.album.selected().album.title : 'quick collection') );
					}

					if ($.param.fragment().indexOf('quick_collection:') === -1) {

						var _covers = [];
						$.each(self.observers.sidebar.asset.covers(), function(i, cover) {
							if (ids.indexOf(cover.id) === -1) _covers.push(cover);
						});

						requestAnimationFrame(function() {
							self.observers.sidebar.asset.covers(_covers);
						});

						this.sync({
							request: 'albums/' + this.observers.properties.album.selected().album.id + '/content/' + ids.join(','),
							method: 'DELETE',
							context: this
						});
					}

				} else {

					requestAnimationFrame(function() {
						$.each(self.observers.content(), function(i, asset) {
							$('#content_list li[data-id="' + asset.id + '"]')
								.data({
									id: asset.id,
									index: i
								})
								.attr('data-id', asset.id)
								.attr('data-index', i)
								.attr('id', 'list_item_' + asset.id);
						});
					});

					if (this.observers.view() !== 'single') {
						this.controllers.rewrite_hash({ name: 'selection', val: false });
					} else {
						this.observers.properties.overall.total(this.observers.content().length);
						requestAnimationFrame(function() {
							// Using trigger here instead of rewrite_hash because the underlying grid view will get out of whack otherwise
							$('#content_list li[data-id="' + nextId + '"]').trigger('click');
						});
					}

					var note = this.notify();

					if ( ids.length > 1 ) {
						note.wait('Moving items to trash');
					} else {
						note.wait('Moving item to trash');
					}

					this.observers.properties.trash.total(this.observers.properties.trash.total() + ids.length);

					this.sync({
						request: 'trash/' + type + ':' + ids.join(','),
						method: 'POST',
						context: this,
						finished: function(data) {
							if ( ids.length > 1 ) {
								note.success( ids.length + ' items were moved to the Trash');
							} else {
								note.success( txt + ' was moved to the Trash');
							}
							this.controllers.load_content();
							this.observers.properties.trash.total(data.total);
							this.controllers.update_summary();
							if (self.observers.view() !== 'single') {
								this.observers.sidebar.id('fixed');
								if (type === 'albums') {
									this.controllers.load_tree();
								}
							}
						}
					});

				}

				requestAnimationFrame(function() {
					self.observers.properties.overall.total(self.observers.properties.overall.total() - ids.length);
				});

			}

			if ( which.hasClass('opt-fave') || which.hasClass('opt-feat') ) {

				var val = ( which.hasClass('opt-fave') ) ? ( fav ? 1 : 0 ) : ( feat ? 1 : 0 ),
					method = ( val == 0 ) ? 'removed from' : 'added to';

				var data = $.map( ids, function(id) {
					var obj = { id: id };
					obj[(which.hasClass('opt-fave')) ? 'favorite' : 'featured'] = val;
					return obj;
				});

				if ( self.observers.api_url().indexOf('albums/visibility') >= 0 ) {
					if ( this.observers.selection().length > 1 ) {
						this.notify().success( this.observers.selection().length + ' items ' + ( ( which.hasClass('opt-fave') ) ? method + ' Favorites' : method + ' Featured' ) );
					} else {
						this.notify().success( this.observers.sidebar.asset.title() + ' ' + ( ( which.hasClass('opt-fave') ) ? method + ' Favorites' : method + ' Featured' ) );
					}
				} else {
					if ( this.observers.selection().length > 1 ) {
						this.notify().success( this.observers.selection().length + ' items ' + ( ( which.hasClass('opt-fave') ) ? method + ' Favorites' : method + ' Featured' ) );
					} else {
						this.notify().success( $('#content_list li.selected span.' + (type === 'albums' ? 'title' : 'filename')).text() + ' ' + ( ( which.hasClass('opt-fave') ) ? method + ' Favorites' : method + ' Featured' ) );
					}
				}

				if ( (which.hasClass('opt-feat') && this.observers.api_url().match(/\/featured\/?/) && val === 0) || (which.hasClass('opt-fave') && this.observers.api_url().match(/favorites\/?/) && val === 0) ) {

					remove_from_mid(ids);
					this.controllers.rewrite_hash({ name: 'selection', val: false});

				}

				var refreshTree = which.hasClass('opt-feat');

				this.controllers.update(type, data, {
					instant: true,
					finished: function() {
						if (this.observers.api_url() === 'favorites' || this.observers.api_url() === 'content/featured') {
							if (!$('#content_list li[data-which="thumbnail"]').length || $('#content_list li[data-which="thumbnail"]').length === 0) {
								this.observers.view('empty');
							}
						}

						if (this.observers.api_url() === 'albums/featured') {
							if (!$('#content_list li.album.featured.loaded').length || $('#content_list li.album.featured.loaded').length === 0) {
								this.observers.view('empty');
							}
						}

						if (refreshTree) {
							this.controllers.load_tree();
						}
					}
				});

			}

			if ( which.hasClass('opt-rotate-l') || which.hasClass('opt-rotate-r') ) {

				var degree	= ( which.hasClass('opt-rotate-r') ) ? '90' : '-90',
					img		= $('#lib-content-sel img'),
					el		= $('#list_item_' + ids);

				img.parent().addClass('loading');
				img.animate({ opacity: 0 });

				el.removeClass('loaded').addClass('loading');

				this.sync({
					request: 'content/' + ids,
					context: this,
					method: 'PUT',
					data: 'rotate=' + degree,
					finished: function(data) {
						this.controllers.update_item( data, true);
					}
				});

			}

			if ( which.hasClass('opt-dl') ) {
				var asset = this.controllers.get_asset_by_id(ids);
				window.location.href = this.config.paths.base + 'dl.php?src=' + asset.original.relative_url;
			}

			if ( which.hasClass('opt-bg') ) {
				var notify = this.notify().wait('Assigning sign-in background');
				this.sync({
					request: 'settings',
					method: 'PUT',
					data: 'signin_bg=' + ids[0],
					context: this,
					finished: function(data) {
						notify.success(this.observers.sidebar.asset.filename() + ' assigned as sign-in background');
					}
				});
			}
			// Replace action is handled by app.controllers.replace

		},

		reverse_manual: function() {

			var ids = [];

			$.each( $('#content_list li'), function() {
				ids.push( $(this).data('id') );
			});

			$('#content_list li').each(function() {
				$('#content_list').prepend($(this));
			});

			$('#content_list li').each(function() {
				$(this).find('.count').text($(this).index()+1);
				$(this).find('.title.cnt').text($(this).index()+1);
			});

			ids.reverse();
			this.controllers.lazy('#content_list');

			if (this.observers.is_quick_collection()) {
				this.observers.quick_collection(ids);
			} else {

				this.sync({
					request: this.observers.api_url() + '/order:' + ids.join(','),
					method: 'PUT',
					context: this,
					finished: function() {
						this.controllers.load_tree();
					}
				});

			}

		},

		save_changes: function(el,parent,e) {

			var btn = $('#right-col-xtra button:contains("Done")');
			this.observers.drawerOpen = false;

			if (!e.isTrigger && btn.length > 0 && !btn.hasClass('data-submit')) {
				btn.trigger('click');
			} else {
				this.views.close_sidebar_drawer();
			}

		},

		toggle_gear_popout: function(el,parent) {

			var top = parent.closest('li').offset().top - 33;

			this.helpers.outside( parent, function() {
				$('#gear_pop').hide();
				$('.hoverable').removeClass('hoverable');
				$('.edit-hoverable').removeClass('edit-hoverable');
			});

			if ( parent.parent('li').parent().parent('li').length && parent.parent('li').parent().parent('li').data('type') === 'set' ) {
				$('.rm_set').show();
			} else {
				$('.rm_set').hide();
				top += 12;
			}

			if ( parent.closest('li').closest('.public').length <= 0 ) {
				$('.opt-public').closest('li').show();
				$('.opt-list .opt-feat').closest('li').hide();
			} else {
				if ( parent.closest('li').data('featured') === true ) {
					$('.opt-list .opt-feat').text('Unmark as featured');
					$('#gear_pop').data( 'featured', 'unmark' );
				} else {
					$('.opt-list .opt-feat').text('Mark as featured');
					$('#gear_pop').data( 'featured', 'mark' );
				}
				$('.opt-list .opt-feat').closest('li').show();
				$('.opt-public').closest('li').hide();
			}

			$('#gear_pop').data( 'album', parent.closest('li').data('id') ).css( 'top', top ).toggle();
			$('#gear_pop .wedge').css('left','-11px');

			parent.closest('li').find('a.item:first-child').addClass('hoverable');

		},

		gear_hovering: function() {

			$('body')
				.on( 'mouseover', '#content_subnav li:not(.public,.unlisted,.private)', function(e) {
					$('.item-edit').hide();
					$(e.target).closest('li').find('.item-edit:first').show().addClass('edit-hoverable');
					$(e.target).closest('li').find('a.item').first().addClass('hoverable');
				})
				.on( 'mouseout', '#content_subnav li:not(.public,.unlisted,.private)', function(e) {
					if ( $('#gear_pop').css('display') !== 'block' || $(e.target).closest('li').data('id') != $('#gear_pop').data('album') ) {
						$(e.target).closest('li').find('a.item').first().removeClass('hoverable');
						$(e.target).closest('li').find('.item-edit:first').removeClass('edit-hoverable');
					}
				});

		},

		gear_popout: function(el,parent) {

			var li = $('#content_subnav li[data-id="' + $('#gear_pop').data('album') + '"]'),
				koData = ko.dataFor(li.get(0));

			if ( el.hasClass('opt-trash') ) {

				this.models.trash.albums.set( $('#gear_pop').data('album') );
				li.remove();

			} else if ( el.hasClass('opt-remove') ) {

				var set = li.parents('li[data-type="set"]').data('id');

				li.remove();

				this.sync({
					request: 'albums/' + set + '/content/' + $('#gear_pop').data('album'),
					method: 'DELETE',
					context: this,
					finished: function() {
						this.controllers.load_tree();
					}
				});

				if (this.observers.properties.album.selected().album && this.observers.properties.album.selected().album.id === set) {
					var el = $('#list_item_' + $('#gear_pop').data('album'));

					if (el.length) {
						el.remove();
					}
				}

			} else if ( el.hasClass('opt-feat') ) {

				if ( $('#gear_pop').data('featured') === 'mark' ) {

					var data = [{ id: $('#gear_pop').data('album'), featured: 1 }], self = this;

					requestAnimationFrame(function() {
						self.controllers.update('albums', data, {
							instant: true,
							finished: function(items) {
								self.controllers.load_tree();
								self.notify().success( koData.title + ' added to Featured albums' );
								if ( self.observers.api_url().indexOf('albums/featured') === 0 ) {
									self.controllers.load_content();
								}
							}
						});
					});

				} else {

					var data = [{ id: $('#gear_pop').data('album'), featured: 0 }], self = this;

					requestAnimationFrame(function() {
						self.controllers.update('albums', data, {
							instant: true,
							finished: function(items) {
								self.controllers.load_tree();
								self.notify().success( koData.title + ' removed from Featured albums' );
								if ( self.observers.api_url().indexOf('albums/featured') === 0 ) {
									self.controllers.load_content();
								}
							}
						});
					});

				}

			} else if ( el.hasClass('opt-public') ) {

				var self = this;

				var _syncAPI = function(match) {
					var note = self.notify().wait('Changing visibility');
					var params = {
						request: 'albums/' + $('#gear_pop').data('album'),
						method: 'PUT',
						data: 'visibility=public',
						context: self,
						finished: function(data) {
							this.controllers.load_tree();
							if (note) note.success('Visibility changed to Public');
							var url = this.observers.api_url();
							if (url.match(/^albums\/visibility:(public|unlisted|private)/) || (this.observers.properties.album.selected().album && url.indexOf('albums/' + this.observers.properties.album.selected().album.id + '/content') === 0)) {
								this.controllers.load_content(null, true);
							}
						}
					}
					if (match) params.data += '&match_album_visibility=1';
					self.sync(params);
				}

				var allAlbums = self.observers.albums_flat().concat(self.observers.unlisted_albums()),
					selectedAlbum;

				allAlbums.forEach(function(album) {
					if (album.id == $('#gear_pop').data('album')) {
						selectedAlbum = album;
						return false;
					}
				});

				var fromVisibility = koData.visibility;

				this.classes.modal.match_album_visibility.options.hasKeepCancel.keepFn = function() { _syncAPI(true); }
				this.classes.modal.match_album_visibility.options.hasKeepCancel.cancelFn = function() { _syncAPI(false); };
				this.classes.modal.match_album_visibility.show(0, fromVisibility, 'public', (selectedAlbum.album_type !== 'set') ? 'album' : 'set');

			}

			$('#gear_pop').hide();

		},

		select_all: function(type) {

			if ( this.observers.selection().length > 0 || type === 'deselect' ) {

				this.controllers.rewrite_hash({ name: 'selection', val: false});

			} else {

				if (this.observers.passengers.next_page) {
					this.controllers.load_content({ cb: this.events.select_all });
					return;
				}

				var ids = this.observers.content().map(function(item) {
					return item.id;
				});

				var url = ( $.param.fragment().indexOf('trash') < 0 && $.param.fragment().indexOf('albums') < 0 && $.param.fragment().indexOf('/content') < 0 ) ? $.param.fragment() + '/content' : $.param.fragment();

				$.bbq.pushState( url + '/selection:' + ids, 2);

			}

		},

		tag_search: function(el,parent) {

			this.observers.search_filter('tags');
			this.observers.search( parent.text() );

		},

		category_search: function(el,parent) {

			this.observers.search_filter('category');
			this.observers.search( parent.text() );

		},

		title_search: function(el,parent) {

			this.observers.search_filter('title');
			this.observers.search( parent.text() );

		},

		reset_filter: function() {

			$('#three-col-left li.selected a').trigger('click');

		},

		setup_sidebar_pickers: function() {
			var self = this;
			$('#right-col-xtra .datepicker').each(function(i, p) {
				var p = $(p),
					apply_timezone = true;

				if (!isNaN(p.val())) {
					var v = Number(p.val());

					var pad = function(n) {
						return n < 10 ? '0' + n : n;
					};

					if (p.data('no-tz-shift')) {
						v = moment.utc(v*1000);
					} else {
						v = moment(v*1000).tz(self.observers.settings.site_timezone());
					}

					p.val(v.format('YYYY/MM/DD HH:mm:SS'));
				}

				var max = new Date().getTime();

				p.datetimepicker({
					timeFormat: 'HH:mm:ss',
					dateFormat: 'yy/mm/dd',
					maxDate: max,
					showSecond: false
				});
			});

		},

		reset_secret_link: function(el) {
			var type	= this.observers.sidebar.id() === 'content' ? 'content' : 'albums',
				id		= this.observers.sidebar.asset.id();

			this.sync({
				request: type + '/' + id,
				method: 'PUT',
				data: 'reset_internal_id=1',
				context: this,
				finished: function(data) {
					this.controllers.update_item(data);
					this.notify().success('New unlisted site link created');
					$('#' + type + '-link').focus();
				}
			});

		},

		sidebar: function(el,parent,e) {

			if (el.hasClass('edit-link') && !el.hasClass('panel-hover-link') && !el.hasClass('no-drawer')) {

				var type = el.closest('div').parent().find('label').attr('class');

				if (type) {
					this.observers.drawer.id(type);
					this.views.open_sidebar_drawer(el);
					this.events.setup_sidebar_pickers();
				}

			}

		},

		create_collection: function() {

			var o			= this.observers.sheets.create_collection,
				button		= $('#lay-three-col li[data-event="create_collection"] button'),
				origText	= button.text(),
				note = this.notify().wait('Creating'),
				visibility = o.properties.visibility();

			button.addClass('disabled');

			this.sync({
				request: 'albums',
				method: 'POST',
				data: 'title=' + encodeURIComponent(o.properties.title()) + '&album_type=' + o.properties.type() + '&visibility=' + visibility,
				context: this,
				finished: function(data) {

					note.success(o.properties.title() + ' ' + ((data.album_type === 'set') ? 'set' : 'album') + ' created');

					this.controllers.load_tree();
					button.text(origText);

					$('#create_sheet_categories li input:checked').each( function() {
						o.properties.categories.push( $(this).val() );
					});

					var more_properties = ( o.properties.tags().length > 0 || o.properties.categories().length > 0 || o.properties.summary().trim() !== '' || o.properties.description().trim() !== '' ) ? true : false,
						_finish			= function() {
							o.active(false);
							o.validated(false);
							o.reset();
							$.bbq.pushState( '/library/albums/' + data.id + '/content', 2 );
							$(window).off('keyup.sheet');
							$('#create_collection').find('input[type="checkbox"]').each(function() {
								$(this).prop('checked', false);
							});
							setTimeout(function() {
								$('#content_subnav .scroll_wrapper').scrollTop( $('#content_subnav .scroll_content').height() );
							}, 120);
						};

					if (more_properties) {

						var moreData = {
							id: data.id,
							summary: o.properties.summary().length ? o.properties.summary() : this.observers.truncate(o.properties.description(), 252),
							description: o.properties.description(),
							tags: o.properties.tags().join(',')
						};

						this.controllers.update( 'albums', moreData, {
							instant: true,
							context: this,
							finished: function() {
								var categories = o.properties.categories();
								if (categories.length) {
									this.sync({
										request: 'categories/' + categories + '/albums/' + data.id,
										context: this,
										method: 'POST',
										finished: function(data) {
											_finish.call(this);
										}
									});
								} else {
									_finish.call(this);
								}
							}
						});

					} else {
						_finish.call(this);
					}


				}
			});

		},

		toggle_license: function(el,parent) {

			var wrap = $('#license-sub');

			wrap.toggleClass('hide');

			if ( el.is(':checked') ) {

				el.data('val', '');
				el.data('check', false);

			} else {

				wrap.find('input').removeAttr('checked');
				el.data('val', 'all');
				el.data('check', true);

			}

		},

		'album-visibility': function(el,parent) {

			var parent	= el.closest('li'),
				type	= el.closest('li').hasClass('public') ? 'public' : (el.closest('li').hasClass('unlisted') ?  'unlisted' : 'private');

			if ( this.observers.content().length ) {

				parent.find('div').toggle();
				parent.find('a:first').toggleClass('open');

			}

		},

		arrow_toggle: function(el,parent) {

			var id		= parseInt( el.closest('li').find('span').closest('li').data('id'), 10 ),
				open	= this.observers.open_sets(),
				index	= $.inArray(id, open);

			if ( parseInt(ko.dataFor($('#three-col-left li.selected').closest('ol').parent().get(0)), 10) === id ) {
				return false;
			}

			if (index !== -1) {
				this.observers.open_sets.splice(index, 1);
			} else {
				this.observers.open_sets.push(id);
			}

			this.cookie( 'library:open_sets', ( this.observers.open_sets().length >= 1 ) ? this.observers.open_sets().toString() : null );

			this.controllers.events_init();

		},

		arrow_toggle_date: function(el,parent) {

			parent.toggleClass('open');
			parent.closest('li').find('ol').toggle();

		},

		// KO will look for and call these functions after the library sidebar for the template has loaded
		sidebar_callback: {

			album: function() {
				requestAnimationFrame(function() {
					$(window).trigger('resize');
				});
			}

		},

		show_cover: function(data,noShow) {

			var win = $(window),
				coversHeight = ($('#lib-covers li').length / 3)*79,
				self = this;

			if (data) {
				$('#lib-covers li[data-id="' + parseInt(data.id, 10) + '"]').find('img').fadeIn(1000, function() {
					$(this).closest('li').removeClass('loading');
				});
				requestAnimationFrame(function() {
					$(window).trigger('resize');
				});
			}

			$('#lib-covers').height( ($('#lib-covers li').length <= 9) ? '100%' : coversHeight );
			$('#lib-covers [data-event="toggle_visibility"]').on('click', function() {
				if ($('#lib-covers').height() <= 30) {
					$('#lib-covers').height('');
					requestAnimationFrame(function() {
						$('#lib-covers').height($('#lib-covers .scroll_content').height());
					});
				} else {
					$('#lib-covers').height('');
				}
			});

			var h = $('#album_sidebar_info_container').height(),
				sel = $('#album_sidebar_info_container').find('.col-select');

			if (sel.is(':visible')) {
				h -= $('#album_sidebar_info_container').find('.col-select').outerHeight(true) - 1;
			}

			$('#album_side').css('height', h);

			if (this.observers.sidebar.asset.album_type && this.observers.sidebar.asset.album_type() === 'standard' && $('#three-col-left li[data-id="' + this.observers.sidebar.asset.id() + '"]').closest('li[data-type="set"]').length > 0) {
				this.sync({
					request: 'albums/' + $('#three-col-left li[data-id="' + this.observers.sidebar.asset.id() + '"]').closest('li[data-type="set"]').data('id'),
					context: this,
					finished: function(data) {
						$.each(data.covers, function(i,cover) {
							$('#mid-content li[data-id="' + cover.id + '"]').find('.badge.cover-set').show();
						});
					}
				});
			}

			if (this.observers.api_url().indexOf('albums/visibility:') != -1) {
				this.controllers.events_init();
			}

		},

		assign_cover: function(type,set,asset) {

			// Matched when a cover is being deleted
			if (type.context) {
				if (this.observers.properties.album.selected().album.covers.length <= 3) {
					return false;
				}
				var self = this,
					note = this.notify().wait('Deleting cover'),
					del;
				$.each(this.observers.sidebar.asset.covers(), function(i,cover) {
					if (cover.id == type.closest('li').data('id')) {
						del = cover;
					}
				});
				var _ids = [];
				if (!type.hasClass('remcover')) {
					type.each(function() {
						_ids.push($(this).data('id'));
					});
				} else {
					_ids.push(type.closest('li').data('id'));
				}
				this.sync({
					request: 'albums/' + this.observers.properties.album.selected().album.id + '/covers/' + _ids.join(','),
					method: 'DELETE',
					context: this,
					finished: function(data) {
						if (_ids.length <= 1) {
							note.success(del.filename + ' unassigned as cover');
						} else {
							note.success(_ids.length + ' items unassigned as covers');
						}

						var ol = type.closest('ol');

						$('#lib-covers li[data-id="' + type.closest('li').data('id') + '"]').remove();

						this.observers.properties.album.selected().album.covers = data.covers;
						if (this.observers.properties.album.selected().album.covers.length <= 3) {
							$('#lib-covers a.remcover').remove();
						}
						$(window).trigger('resize');
					}
				});
			}

			// Matched when a cover is being reordered
			if (type === 'update') {

				var ids = [],
					self = this,
					idsclone;

				$('#lib-covers li').each(function() {
					ids.push(parseInt($(this).data('id'), 10));
				});

				self.sync({
					request: 'albums/' + self.observers.sidebar.asset.id() + '/covers/' + ids.reverse(),
					method: 'POST',
					context: self,
					finished: function(data) {
						if (this.observers.properties.album.selected().album) this.observers.properties.album.selected().album.covers = data.covers;
					}
				});

			}

			// Matched when a cover is being added to a set
			if (type === 'set') {
				var note = this.notify().wait('Assigning cover');
				this.sync({
					request: 'albums/' + set + '/covers/' + asset,
					method: 'POST',
					context: this,
					finished: function(data) {

						var self = this,
							allCovers = asset.split(',');

						$.each(allCovers, function(i, cover) {
							$('#mid-content li[data-id="' + cover + '"]').find('.badge.cover-set').show();
						});

						if (allCovers.length <= 1) {
							$.each(data.covers, function(i,cover) {
								if (cover.id == asset) {
									note.success(cover.filename + ' assigned as primary cover');
								}
							});
						} else {
							note.success(allCovers.length + ' items assigned as covers');
						}

						this.classes.app.koken.itemsBeingSortedDragged = [];

					}
				});
			}

			// Matched when a cover is being added to an album
			if (type === 'album') {
				var exists = false,
					note = this.notify().wait('Assigning cover');
				$.each( this.observers.sidebar.asset.covers(), function(i,cover) {
					if (parseInt(cover.id, 10) === parseInt(asset, 10)) {
						exists = true;
					}
				});
				if (exists && asset.split && asset.split(',').length <= 1) {
					note.warn('This image has already been assigned as a cover');
					return false;
				}
				this.sync({
					request: 'albums/' + set + '/covers/' + asset,
					method: 'POST',
					context: this,
					finished: function(data) {

						var self = this,
							allCovers = asset.split && asset.split(',');

						if (allCovers && allCovers.length <= 1) {
							$.each(data.covers, function(i,cover) {
								if (cover.id == asset) {
									note.success(cover.filename + ' assigned as primary cover');
								}
							});
						} else if (allCovers) {
							note.success(allCovers.length + ' items assigned as covers');
						} else {
							note.success($('#mid-content li[data-id="' + asset + '"]').find('span.filename').text() + ' assigned as primary cover');
						}

						this.observers.sidebar.asset.covers(data.covers);
						this.observers.properties.album.selected().album.covers = data.covers;
						this.classes.app.koken.itemsBeingSortedDragged = [];

						requestAnimationFrame(function() {
							$(window).trigger('resize');
						});

					}
				});
			}

		},

		cover_draggable: function() {

			var el = $('#lib-covers ol'),
				self = this,
				orig, primary;

			el.sortable({
				containment: '#lib-covers-contain',
				distance: 5, // Adding small distance for pen inputs
				revert: 100,
				start: function(e,ui) {
					orig = ui.helper;
					ui.helper.find('a').hide();
					primary = $('#lib-covers li').eq(0);
					$('.ui-sortable-placeholder').css('visibility','visible');
					$('a[data-event="toggle_dropdown"].selected').parent().trigger('click');
				},
				stop: function(e,ui) {
					if (primary.data('id') != $('#lib-covers li').eq(0).data('id')) {
						if (self.observers.api_url().indexOf('albums/visibility') !== -1) self.controllers.load_content();
						self.notify().success( ko.dataFor($('#lib-covers li[data-id="' + primary.data('id') + '"]').get(0)).filename + ' assigned as primary cover' )
					}
					orig.find('a').css('display','');
				},
				update: function(e,ui) {
					self.events.assign_cover('update');
				}
			});

			el.dblclick(function(e, ui) {
				self.controllers.rewrite_hash({ name: 'selection', val: $(e.target).closest('li').data('id') });
				self.controllers.rewrite_hash({ name: 'view', val: 'single'});
			});

		},

		cover_droppable: function() {

			var el = $('#lib-covers ol'),
				self = this;

			if (this.observers.sidebar.asset.album_type && this.observers.sidebar.asset.album_type() == 'standard' && this.observers.api_url().indexOf('albums/visibility') === -1) {
				el.droppable({
					tolerance: 'pointer',
					hoverClass: 'cover-hover',
					accept: '#content_list li',
					over: function(e,ui) {
						$('.ui-draggable-dragging').find('span.content').css('cursor','copy');
					},
					out: function() {
						$('.ui-draggable-dragging').find('span.content').css('cursor','all-scroll');
					},
					drop: function(e,ui) {
						var id = ui.draggable.data('id'),
							alreadyDropped = false;
						self.observers.isBeingDropped = true;
						el.find('li').each(function() {
							if ($(this).data('id') == id) {
								alreadyDropped = true;
								return;
							}
						});
						if (alreadyDropped) {
							self.notify().warn('This image has already been assigned as a cover');
						} else {
							self.events.assign_cover('album', self.observers.sidebar.asset.id(), ui.draggable.data('id'));
						}
					}
				});
			}

		},

		album_draggable: function() {

			var el = $('.public li').add('.unlisted li').add('.private li');

			el.draggable({
				appendTo: '#three-col-left',
				revert: 'invalid',
				easing: config.easing,
				distance: 10,
				opacity: 0.4,
				axis: 'y',
				helper: 'clone',
				zIndex: 41,
				start: function(e,ui) {

					$(this).css('opacity', 0.2);
					$('a[data-event="toggle_dropdown"].selected').parent().trigger('click');

					// Counts the <li> to determine the necessary padding to keep treeview looking correctly when dragged
					ui.helper.closest('li').css('padding-left', 8 + ( $(this).parents('li').length * 8 ) ).addClass('trashable');

				},
				stop: function() {
					$(this).css('opacity', 1);
				}
			});

		},

		root_droppable: function() {

			var self	= this,
				el		= $('#u_albums').add('#p_albums').add('#pr_albums');

			el.droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {

					var parent_viz = el.parent().parent().attr('id');
					var equal = parent_viz && $(this).closest('li').hasClass(parent_viz.replace('_albums', ''));

					if ( el.hasClass('content') || self.observers.sidebar.id() === 'trash' ) { return false; }
					if ( el.closest('ol').parent().data('type') === 'set' || !equal || el.closest('li').hasClass('album') ) {
						return true;
					}

				},
				drop: function(e,ui) {

					var index		= ui.draggable.closest('li').index(),
						listed		= $(this).closest('li').hasClass('private') ? 'private' : ($(this).closest('li').hasClass('unlisted') ? 'unlisted' : 'public'),
						url_match	= self.observers.api_url().match(/albums\/(\d+)/),
						album_set	= ui.draggable.closest('ol').closest('li').data('id') || ui.draggable.closest('ul').attr('id') === 'content_list' && url_match && url_match[1],
						note, id;

					if (self.classes.app.koken.itemsBeingSortedDragged.length > 0) {
						id = self.classes.app.koken.itemsBeingSortedDragged;
					} else {
						if ( ui.draggable.hasClass('album') && $('#content_list li.selected').length ) {
							id = $.map($('#content_list li.selected'), function(k,v) {
								return $(k).data('id');
							});
						} else {
							id = [ui.draggable.data('id')];
						}
					}

					var shouldBeNotified = true;
					$.each(id, function(i, album) {
						var isInPublic = $('#al-nav li[data-id="' + album + '"]').closest('#public_albums').length;
						if (isInPublic && $(e.target).attr('id') === 'p_albums' || !isInPublic && $(e.target).attr('id') !== 'p_albums') {
							shouldBeNotified = false;
						}
					});

					self.observers.isBeingDropped = true;

					var remove_from_set = function(cb) {
						self.sync({
							request: 'albums/' + album_set + '/content/' + id.join(','),
							method: 'POST',
							data: '_method=delete',
							context: self,
							finished: function() {
								if (this.observers.api_url().match( RegExp('albums\/' + album_set) )) this.controllers.load_content();
								cb.call(self);
							}
						});
					};

					var change_listed = function() {

						var _reqs = [],
							_albumIds = [];
						$.each(id, function(i, _id) {
							_albumIds.push(_id);
							_reqs.push({ request: 'albums/' + _id });
						});

						var _syncAPI = function(match) {
							if (shouldBeNotified) note = self.notify().wait('Changing visibility');
							var params = {
								request: _reqs,
								method: 'PUT',
								data: 'visibility=' + listed,
								context: self,
								finished: function(data) {
									var ids = helpers.unique(id);
									if (note && ids.length > 1) {
										note.success('Visibility changed to ' + data.visibility.raw + ' for ' + ids.length + ' items');
									} else if (note && ids.length === 1) {
										$.each(this.observers.albums(), function(i, album) {
											if (album.id === ids[0]) note.success('Visibility of ' + album.title + ' changed to ' + album.visibility.raw);
										});
									}
									if (note) {
										note.success('Visibility changed to ' + data.visibility.raw);
									}

									// If album is selected update the observers.properties as well
									if (self.observers.properties.album.selected().album && ids.indexOf(self.observers.properties.album.selected().album.id) !== -1) self.observers.properties.album.selected().album = data;

									this.controllers.load_tree();
									if (shouldShowModal) this.controllers.load_content(null, true);
									if (this.observers.selection().length > 0) self.controllers.rewrite_hash({ name: 'selection', val: false});
									if (self.observers.properties.album.selected().album) {
										self.observers.sidebar.asset.visibility.raw(data.visibility.raw);
										self.observers.sidebar.asset.visibility.clean(data.visibility.clean);
									}
									if (this.observers.api_url().match(/^albums\/visibility:(public|unlisted|private)/)) {
										this.controllers.load_content();
									}
								}
							}
							if (match) params.data += '&match_album_visibility=1';

							self.sync(params);
						}

						var shouldShowModal = true,
							allAlbums = self.observers.albums_flat().concat(self.observers.unlisted_albums()).concat(self.observers.private_albums()),
							selectedAlbum;

						function recursive_search(album) {
							if ($.inArray(album.id, _albumIds) !== -1) {
								if (album.count <= 0 || ui.draggable.closest($(e.target).closest('li')).length) {
									shouldShowModal = false;
								} else {
									selectedAlbum = album;
								}
								return false;
							}

							if (album.children) {
								return $.each(album.children, function(j, a) {
									return recursive_search(a);
								});
							}
						}

						$.each(allAlbums, function(i, album) {
							return recursive_search(album);
						});

						if (shouldShowModal) {
							self.classes.modal.match_album_visibility.options.hasKeepCancel.keepFn = function() { _syncAPI(true); };
							self.classes.modal.match_album_visibility.options.hasKeepCancel.cancelFn = function() { _syncAPI(false); };
							self.classes.modal.match_album_visibility.show(0, selectedAlbum.visibility, listed, (!selectedAlbum || selectedAlbum.album_type !== 'set') ? 'album' : 'set');
						} else {
							_syncAPI(false);
						}

					};

					if ( album_set ) {
						remove_from_set( change_listed );
					} else {
						change_listed();
					}

				}
			});

		},

		side_droppable: function() {

			var self = this;

			$('.label-unlisted, .label-private, .label-photo').closest('a').droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {
					// Do not allow drops to happen from the trash view
					if (self.observers.sidebar.id() === 'trash') return false;

					// Only accept content, no albums or sets
					if (!el.hasClass('__content__') && !el.hasClass('content')) return false;

					var item = self.observers.getContentById(el.data('id'));
					if (item) {
						// If item visibility matches droppable visibility disable drop
						if ($(this).find('.label-photo').length && item.visibility.raw === 'public') return false;
						if ($(this).find('.label-unlisted').length && item.visibility.raw === 'unlisted') return false;
						if ($(this).find('.label-private').length && item.visibility.raw === 'private') return false;
					}

					// All other cases we allow
					return true;
				},
				drop: function(e,ui) {
					var items = [],
						finished = [],
						which = ($(e.target).find('.label-unlisted').length) ? 'unlisted' : 'private',
						data = [],
						droppableContext = this;

					if ($(e.target).find('.label-photo').length) which = 'public';

					if ( ! self.classes.app.koken.sortToDrag ) {

						data = $('#content_list li.selected').map( function(i, el) {
							return { id: $(el).data('id'), visibility: { clean: helpers.capitalize(which), raw: which}}
						}).get();

					} else {

						self.observers.isBeingDropped = true;

						$.each( self.classes.app.koken.itemsBeingSortedDragged, function() {
							data.push({ id: this, visibility: { clean: helpers.capitalize(which), raw: which}});
						});

					}

					var note = self.notify().wait('Changing visibility');

					if (self.observers.api_url().indexOf('albums/') === -1) $('#mid-content li.selected').remove();

					requestAnimationFrame(function() {
						self.controllers.update('content', data, {
							instant: true,
							finished: function(item) {
								finished.push(item);

								// Keeps the album count in sync if being dropped from a selected album, ignore for private viz
								if ((self.observers.properties.album.selected().id && $('#al-nav li.selected').data('id')) && self.observers.properties.album.selected().id == $('#al-nav li.selected').data('id') && !$(droppableContext).find('.label-private').length) {
									self.observers.update_album_counts($('#al-nav li.selected').data('id'));
								}

								if (finished.length == data.length) {
									if (finished.length > 1) {
										note.success('Visibility changed to ' + which + ' for ' + finished.length + ' items');
									} else {
										note.success('Visibility changed to ' + which + ' for ' + item.filename);
									}

									if (!$('#mid-content li').length) self.controllers.load_content(null, true);

									self.controllers.rewrite_hash({ name: 'selection', val: false});
								}
							}
						});
					});

					self.classes.app.koken.itemsBeingSortedDragged = [];
				}
			});

			$('.label-quick').closest('a').droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {
					if (el.closest('li').find('.album-stack').length) {
						return false;
					}
					return self.classes.app.koken.sortToDrag === true || el.hasClass('__content__') && self.observers.sidebar.id() !== 'trash' && self.observers.api_url().indexOf('visibility') < 0;
				},
				drop: function(e,ui) {

					var data = [];

					if ( ! self.classes.app.koken.sortToDrag ) {

						data = $('#content_list li.selected').map( function(i, el) {
							return Number($(el).data('id'));
						}).get();

					} else {

						self.observers.isBeingDropped = true;

						$.each( self.classes.app.koken.itemsBeingSortedDragged, function() {
							data.push(Number(this));
						});

					}

					if ( data.length > 1 ) {
						self.notify().success( data.length + ' items added to Quick collection');
					} else {
						self.notify().success( $('#content_list li[data-id="' + data[0] + '"]').find('.filename').text() + ' added to Quick collection' );
					}

					self.observers.quick_collection( self.observers.quick_collection().concat( data ) );

				}
			});

			$('.label-faves').closest('a').droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {
					return $('.lib-nav li.selected').data('type') !== 'set' && self.observers.api_url().indexOf('visibility:') < 0 && self.classes.app.koken.sortToDrag === true || el.hasClass('__content__') && self.observers.sidebar.id() !== 'trash';
				},
				drop: function(e,ui) {

					var data = [],
						hasBeenAdded = false,
						wasSupposedToBeAdded = 0,
						accept = [];

					if ( ! self.classes.app.koken.sortToDrag ) {

						data = $('#content_list li.selected').map( function(i, el) {
							return { id: $(el).data('id'), favorite: 1 };
						}).get();

					} else {

						self.observers.isBeingDropped = true;

						$.each( self.classes.app.koken.itemsBeingSortedDragged, function() {
							data.push({ id: this, favorite: 1 });
						});

					}
					wasSupposedToBeAdded = data.length;

					$.each( data, function(k,v) {
						if ( $('#content_list li[data-id="' + v.id + '"]').find('.badge.fave').css('display') === 'none' ) {
							accept.push(v);
						} else {
							hasBeenAdded = $('#content_list li[data-id="' + v.id + '"]').find('.filename').text();
						}
					});

					data = accept;

					if (hasBeenAdded !== false && data.length === 0) {
						self.notify().warn( ((wasSupposedToBeAdded > 0) ? wasSupposedToBeAdded + ' items' : hasBeenAdded) + ' already in destination and was not added.' );
					}

					requestAnimationFrame(function() {
						self.controllers.update('content', data, {
							instant: true,
							updated: function(items) {
								if (items.length === 1) {
									self.notify().success(items[0].filename + ' added to Favorites');
								} else if (items.length) {
									self.notify().success(items.length + ' items added to Favorites');
								}
							}
						});
					});

					self.classes.app.koken.itemsBeingSortedDragged = [];

				}
			});

			$('.label-featured').closest('a').droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {
					return $('.lib-nav li.selected').data('type') !== 'set' && self.observers.api_url().indexOf('visibility:') < 0 && self.classes.app.koken.sortToDrag === true || el.hasClass('__content__') && self.observers.sidebar.id() !== 'trash';
				},
				drop: function(e,ui) {

					var data = [],
						hasBeenAdded = false,
						numNotAdded = 0;

					if ( ! self.classes.app.koken.sortToDrag ) {

						data = $('#content_list li.selected').map( function(i, el) {
							return { id: $(el).data('id'), featured: 1 };
						}).get();

					} else {

						self.observers.isBeingDropped = true;

						$.each( self.classes.app.koken.itemsBeingSortedDragged, function() {
							data.push({ id: this, featured: 1 });
						});

					}

					$.each( data, function(k,v) {
						if ( $('#content_list li[data-id="' + v.id + '"]').find('.badge.featured').css('display') !== 'none' ) {
							hasBeenAdded = $('#content_list li[data-id="' + v.id + '"]').find('.filename').text();
							numNotAdded++;
						}
					});

					if (hasBeenAdded !== false && data.length <= 1) {
						self.notify().warn( hasBeenAdded + ' already in destination and was not added.' );
					} else if (hasBeenAdded !== false && data.length > 1 && data.length === numNotAdded) {
						self.notify().warn( data.length + ' items already in destination and were not added.' );
					}

					self.models.featured.content.set(data);

					self.classes.app.koken.itemsBeingSortedDragged = [];

				}
			});

			$('.label-album-featured').closest('a').droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {
					if ( el.closest('#public_albums').length <= 0 && el.closest('#mid-content').length <= 0 ) {
						return false;
					}
					if (el.hasClass('__album__') && el.hasClass('unlisted')) {
						return false;
					}
					return $('.lib-nav li.selected').data('type') === 'set' || self.observers.api_url().indexOf('visibility:') > 0 || el.hasClass('__album__') && self.observers.sidebar.id() !== 'trash' || el.data('type') === 'standard' || el.data('type') === 'set';
				},
				drop: function(e,ui) {

					$(ui.draggable).draggable({
						revert: true,
						revertDuration: 0
					});

					var data = [];

					if ( ! self.classes.app.koken.sortToDrag ) {
						if ( ui.helper.hasClass('__album__') ) {
							data = $('#content_list li.selected').map( function(i, el) {
								return { id: $(el).data('id'), featured: 1 };
							}).get();
						} else {
							data = { id: ui.helper.data('id'), featured: 1 }
						}
					} else {
						self.observers.isBeingDropped = true;
						$.each( self.classes.app.koken.itemsBeingSortedDragged, function() {
							data.push({ id: parseInt(this, 10), featured: 1 });
						});
					}

					self.models.featured.albums.set(data);
					self.controllers.load_tree();
					self.classes.app.koken.itemsBeingSortedDragged = [];
					requestAnimationFrame(function() {
						$.each(data, function(i,album) {
							$('#mid-content li[data-id="' + album.id + '"]').addClass('featured');
						});
					});

				}
			});

		},

		album_droppable: function() {

			var self	= this,
				el		= $('.lib-nav li[data-type="standard"]').find('a');

			el.droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				greedy: true,
				accept: function(el) {
					if (el.closest('li').find('.album-stack').length) {
						return false;
					}
					if ( self.classes.app.koken.sortToDrag === true && self.observers.api_url().indexOf('visibility:') < 0 || el.hasClass('__content__') && ! el.closest('li').hasClass('album') && ! el.closest('li').hasClass('set') && self.observers.sidebar.id() !== 'trash' ) {
						return true;
					}
				},
				drop: function(e,ui) {

					if ( $(this).closest('li').offset().top > ( $('#trash_drop').parent().offset().top - 10 ) ) { return; }

					var id = '',
						el = this,
						selected, item;

					if ( !self.classes.app.koken.sortToDrag ) {
						$('#content_list li.selected').each( function(k,v) {
							id += $(v).data('id') + ',';
						});
					} else {
						self.observers.isBeingDropped = true;
						id = self.classes.app.koken.itemsBeingSortedDragged.join(',');
					}

					if ($(this).closest('li').hasClass('selected')) {
						self.observers.isBeingDropped = true;
						var ids = [];
						if (self.classes.app.koken.itemsBeingSortedDragged.length > 0) {
							ids = self.classes.app.koken.itemsBeingSortedDragged;
						} else {
							$('#content_list li.selected').each(function() {
								ids.push( parseInt($(this).data('id'), 10) );
							});
						}
						ids = ids.join(',');
						self.events.assign_cover('album', $(this).closest('li').data('id'), ids);
						return false;
					}

					var __ids = id.split(','),
						shouldShowModal = true,
						imagesBeingMoved = {
							public: [],
							private: [],
							unlisted: []
						};

					var _syncAPI = function(match) {
						var params = {
							request: 'albums/' + $(el).closest('li').data('id') + '/content/' + helpers.rtrim(id,','),
							method: 'POST',
							context: self,
							finished: function(data) {
								this.observers.update_album_counts(data);
								this.observers.get_selected_asset_albums();
								this.controllers.events_init();

								// Only update mid col if they changed visibility
								if (shouldShowModal) {
									this.controllers.rewrite_hash({ name: 'selection', val: false});
									this.controllers.load_content(null, true);
								}
							}
						}
						if (match) params.data = 'match_album_visibility=1';

						self.sync(params);

						imagesBeingMoved = imagesBeingMoved.public.concat(imagesBeingMoved.private, imagesBeingMoved.unlisted)
						var _added = ( !self.classes.app.koken.sortToDrag ) ? imagesBeingMoved.length : self.classes.app.koken.itemsBeingSortedDragged.length;

						self.notify().success( _added + ' items added to "' + $(el).text().trim() + '"' );
						self.classes.app.koken.itemsBeingSortedDragged = [];

						if (self.observers.api_url().indexOf('independent:1') != -1) {
							$('#content_list li.selected').each( function(k,v) {
								$(this).remove();
							});
							self.observers.properties.overall.total($('#content_list li').length);
						}
					}

					$.each(self.observers.content(), function(i, item) {
						if (__ids.indexOf(item.id.toString()) !== -1) imagesBeingMoved[item.visibility.raw].push(item);
					});

					// Ignore modals for private images
					if (imagesBeingMoved.public.length <= 0 && imagesBeingMoved.unlisted.length <= 0 && imagesBeingMoved.private.length > 0) shouldShowModal = false;

					// Ignore modal because only `unlisted` images are being moved to an already unlisted album
					if (imagesBeingMoved.public.length <= 0 && imagesBeingMoved.private.length <= 0 && imagesBeingMoved.unlisted.length > 0 && $(this).closest('.unlisted').length) shouldShowModal = false;

					// Ignore modal because only `public` images are being moved to an already public album
					if (imagesBeingMoved.unlisted.length <= 0 && imagesBeingMoved.private.length <= 0 && imagesBeingMoved.public.length > 0 && $(this).closest('.public').length) shouldShowModal = false;

					if (shouldShowModal) {
						self.classes.modal.match_album_visibility.options.hasKeepCancel.keepFn = function() { _syncAPI(true); }
						self.classes.modal.match_album_visibility.options.hasKeepCancel.cancelFn = function() { _syncAPI(false); };

						if ($(el).closest('.private').length) {
							self.classes.modal.match_album_visibility.show(imagesBeingMoved.public.concat(imagesBeingMoved.unlisted), '', 'private');
						} else if ($(el).closest('.unlisted').length) {
							self.classes.modal.match_album_visibility.show(imagesBeingMoved.public, 'public', 'unlisted');
						} else {
							self.classes.modal.match_album_visibility.show(imagesBeingMoved.unlisted, 'unlisted', 'public');
						}
					} else {
						_syncAPI(false);
					}

				}

			});

		},

		album_set_droppable: function() {

			var self	= this,
				el		= $('.lib-nav li[data-type="set"]').add('#content_list li[data-type="set"]').find('.label-album-set:first').closest('a');

			el.droppable({
				tolerance: 'pointer',
				hoverClass: 'dragover',
				accept: function(el) {

					// Checking if selected album is child of droppable for dropping to make covers
					var droppableId = $(this).closest('li').data('id'),
						isParent = false;

					$('#three-col-left li.selected').parentsUntil('#three-col-left').each(function(i,parent) {
						if ($(parent).data('id') === droppableId) {
							isParent = true;
						}
					});

					// Ensures that parent albums cannot be dropped onto their children
					if (self.observers.api_url().indexOf('visibility') !== -1 && $('#three-col-left li[data-id="' + el.data('id') + '"]').find('li[data-id="' + droppableId + '"]').length) {
						return false;
					}

					// Ensures that albums cannot be dropped onto themselves
					if (el.data('id') === droppableId) {
						return false;
					}

					if (isParent) {
						return true;
					}
					//

					if (
						! $(this).hasClass('arrow-toggle') &&
						el.closest('li').parent().closest('li').data('id') != $(this).closest('li').data('id') &&
						($(this).closest('li').data('type') == 'set' && !el.hasClass('content')) &&

						// Fix for sets showing underneath trash button when scrolling still being active droppables
						$(this).closest('li').offset().top < ( $('#trash_drop').offset().top - 20 )
					) { return true; }
				},
				drop: function(e,ui) {

					var album_set	= $(this).closest('li').data('id'),
						album;

					if (ui.draggable.hasClass('content')) {

						self.observers.isBeingDropped = true;
						var ids = _ids = [];
						if (self.classes.app.koken.itemsBeingSortedDragged.length > 0) {
							ids = self.classes.app.koken.itemsBeingSortedDragged;
						} else {
							$('#content_list li.selected').each(function() {
								ids.push($(this).data('id'));
							});
						}
						_ids = ids;
						ids = ids.join(',');
						if ( _ids.length > 1 ) {
							self.sync({
								request: 'albums/' + album_set,
								context: self,
								finished: function(data) {
									if ( _ids.length > 1 ) {
										var exists = false;
										$.each( data.covers, function(i,cover) {
											if (parseInt(cover.id, 10) === parseInt(ids, 10)) {
												exists = true;
											}
										});
										if (exists) {
											this.notify().warn('This asset already exists as a cover for this album.');
											return false;
										}
									}
									this.classes.modal.cover.show(album_set, ids);
								}
							});
						} else {
							self.classes.modal.cover.show(album_set, ids);
						}

					} else {

						if (self.classes.app.koken.itemsBeingSortedDragged.length > 0) {
							album = self.classes.app.koken.itemsBeingSortedDragged.join(',');
						} else {
							if ( ui.draggable.hasClass('album') && $('#content_list li.selected').length ) {
								album = $.map($('#content_list li.selected'), function(k,v) {
									return $(k).data('id');
								}).join(',');
							} else {
								album = ui.draggable.data('id');
							}
						}

						self.controllers.rewrite_hash({ name: 'selection', val: false});

						self.observers.isBeingDropped = true;

						var _syncAPI = function(match) {
							var params = {
								request: 'albums/' + album_set + '/content/' + album,
								method: 'POST',
								context: self,
								finished: function(data) {

									if (album.split) {
										var _totalDrops = album.split(',').length;
										if (_totalDrops > 1) {
											this.notify().success(_totalDrops + ' collections added to ' + data.album.title);
										} else {
											this.notify().success(ui.draggable.find('span.title').text().trim() + ' added to ' + data.album.title);
										}
									}

									var clone = self.observers.albums().slice(),
										_clone;

									$.each( clone, function(k,v) {
										if ( v && v.id == album && ! v.children ) {
											clone.splice( k, 1 );
										} else if( v && v.children ) {
											_clone = v.children.slice();
											$.each( _clone, function(j,w) {
												if ( w && w.id == album ) {
													_clone.splice(j,1);
												}
											});
											clone[k].children = _clone;
										}
									});

									$.each( clone, function(k,v) {
										if ( v.id == album_set ) {
											clone[k].children = data.albums;
										}
									});

									self.observers.albums(clone);

									this.controllers.load_tree();
									if ( self.observers.properties.album.selected().album && self.observers.properties.album.selected().album.id == album_set ) {
										this.controllers.load_content(album_set,'set');
									} else if (this.observers.api_url().match(/^albums/)) {
										this.controllers.load_content();
									}
									this.controllers.events_init();

								}
							}
							if (match) params.data = 'match_album_visibility=1';
							self.sync(params);
						}

						var dropVisibility = $(this).closest('li.public').length ? 'public' : ($(this).closest('li.unlisted').length ? 'unlisted' : 'private'),
							shouldShowModal = false;

						// If droppable and draggable visibility doesnt match we need to show the modal
						if (dropVisibility !== ui.draggable.data('visibility')) shouldShowModal = true;

						// Check if we are dropping a set and if it has children, if not we can skip the modal
						if (ui.draggable.data('type') === 'set' && !ui.draggable.data('children')) shouldShowModal = false;

						if (shouldShowModal) {
							self.classes.modal.match_album_visibility.options.hasKeepCancel.keepFn = function() {
								ui.draggable.remove();
								_syncAPI(true);
							}
							self.classes.modal.match_album_visibility.options.hasKeepCancel.cancelFn = function() { _syncAPI(false); };
							self.classes.modal.match_album_visibility.show(0, ui.draggable.data('visibility'), dropVisibility, (ui.draggable.data('type') === 'standard') ? 'album' : ui.draggable.data('type'));
						} else {
							ui.draggable.remove();
							_syncAPI(false);
						}

					}

				}
			});

		},

		trash_droppable: function() {

			var self	= this,
				el		= $('#trash_drop');

			el.droppable({
				tolerance: 'pointer',
				accept: function(el) {
					if ( self.observers.sidebar.id() !== 'trash' || el.data('type') ) return true;
				},
				hoverClass: 'dragover',
				drop: function(e,ui) {

					var id			= '',
						ids			= [],
						selected, items, item, collect, ndex;

					self.observers.isBeingDropped = true;

					if ( !self.classes.app.koken.sortToDrag ) {
						items = $('#content_list li.selected');
					} else {
						var selector = '';
						$.each( self.classes.app.koken.itemsBeingSortedDragged, function(k,v) {
							selector += '#content_list li[data-id="' + v + '"],'
						});
						items = $(helpers.rtrim(selector, ','));
					}

					collect	= self.observers.content().slice(0);

					items.each( function(k,v) {

						var item	= $(this),
							id		= item.data('id');

						ndex			= item.index();
						ids[ids.length]	= id;

						$.each( collect, function(a,b) {
							if ( b && b.id == id ) {
								collect.splice(a,1);
							}
						});

					});

					if ( ui.helper.data('which') === 'thumbnail' ) {

						if ($('#mid-content li.selected').length <= 1) {

							var note = self.notify().wait('Moving to trash');

							self.observers.properties.trash.total(self.observers.properties.trash.total() + 1);

							self.sync({
								request: 'trash/content:' + ui.draggable.data('id'),
								method: 'POST',
								context: self,
								finished: function(data) {
									this.controllers.rewrite_hash({ name: 'selection', val: false});
									this.observers.properties.trash.total(data.total);
									this.controllers.update_summary();
									this.load_content(null, true);
									note.success( $('#list_item_' + ui.draggable.data('id')).find('.title.filename').text() + ' was moved to the Trash' );
									if (self.observers.view() !== 'single') this.observers.sidebar.id('fixed');
								}
							});
						} else {
							$('.opt-icon.opt-trash[data-bind]').trigger('click');
						}

					} else {

						var isSingle = false;

						if ( ui.draggable.hasClass('__album__') || ui.draggable.hasClass('album') ) {
							$.each( ids, function(i, id) {
								$('#list_item_' + id).remove();
							});
						} else {

							ui.draggable.remove();
							ids = [ui.draggable.data('id')];
							isSingle = true;
						}

						var msg;
						if ( items.length  > 1 ) {
							msg = items.length + ' items were moved to the Trash';
						} else if ( self.classes.app.koken.sortToDrag ) {
							msg = ui.draggable.find('.title').text().trim() + ' was moved to the Trash';
						} else if ( ui.draggable.data('type') === 'set' ) {
							msg = ui.draggable.find('.label-album-set:first').text().trim() + ' and all child albums were moved to the Trash';
						} else {
							msg = ui.draggable.find('.label-album').text().trim() + ' was moved to the Trash';
						}

						self.notify().success(msg);

						self.sync({
							request: 'trash/albums:' + ids.join(','),
							method: 'POST',
							context: self,
							finished: function(data) {
								this.observers.properties.trash.total(data.total);
								this.controllers.load_tree();
								if ( !isSingle ) {

									if ( !$.isEmptyObject( self.observers.properties.album.selected() ) && self.observers.properties.album.selected().album.id == ui.draggable.data('id') ) {
										$.bbq.pushState( '/library', 2 );
									}

									self.load_content();

								} else if ( !self.classes.app.koken.sortToDrag ) {
									if (this.observers.api_url().indexOf('albums/' + ids[0]) !== -1) {
										$.bbq.pushState( '/library', 2 );
									} else {
										self.load_content();
									}
								}
							}
						});

					}

				}
			});

		},

		scale_thumbnails: function(val) {
			var thumCSS =  $('#thumbnail-styles'),
			  listHW = Math.floor(val * 0.56);

			this.cookie('library:thumbnail_scale', val);

			if (!thumCSS.length) {
				thumCSS = $('<style id="thumbnail-styles"></style>').appendTo(document.head);
			}

			var rules = 'ul.lib-content li a.content, .ui-draggable a.content, ul.lib-content li span.content { width: ' + val + 'px; max-width: ' + val + 'px; height: ' + val + 'px; }\
				ul.lib-content.grid img.media { max-height: ' + val + 'px; }\
				ul.lib-content.list li span.list-cell.tn { width: ' + listHW + 'px; height: ' + listHW + 'px; }';

			thumCSS.text(rules);

			this.controllers.lazy('#content_list','suppress');
		}

	},


	controllers: {

		set_current_nav: function() {
			var lis = $('ol.lib-nav li'),
				trash = $('#trash_drop').parents('li'),
				title = 'Content',
				icon = 'every',
				val = this.observers.api_url();

			trash.removeClass('selected');

			this.observers.is_collection(false);
			this.observers.show_root_site_links(false);

			var plugin_sel = false;
			$.each($('.plugin-library-nav-item'), function(i, li) {
				var $li = $(li),
					$a = $li.find('a:first');

				if (location.hash.indexOf($a.attr('href')) === 0) {
					lis.removeClass('selected');
					$li.addClass('selected');
					plugin_sel = true;
					title = $a.text();
					icon = $a.find('span.icon16').get(0).className.replace('icon16 label-', '');
					return false;
				}
			});

			if (!plugin_sel) {
				if (val.match(/albums\/\d/)) {
					$('ol.lib-nav li:not([data-id])').removeClass('selected');
					return;
				} else if (val.indexOf('albums/visibility:public') === 0) {
					$('ol.lib-nav li:not([data-id], .public)').removeClass('selected');
					title = 'Public';
					icon = 'album-set';
					this.observers.show_root_site_links(true);
				} else if (val.indexOf('albums/visibility:unlisted') === 0) {
					$('ol.lib-nav li:not([data-id], .unlisted)').removeClass('selected');
					title = 'Unlisted';
					icon = 'album-set';
				} else if (val.indexOf('albums/visibility:private') === 0) {
					$('ol.lib-nav li:not([data-id], .private)').removeClass('selected');
					title = 'Private';
					icon = 'album-set';
				} else if (val.indexOf('albums/featured') === 0) {
					lis.removeClass('selected');
					$('#al-nav li:first').addClass('selected');
					title = 'Featured albums';
					icon = 'album-featured';
				} else if (val.indexOf('trash') === 0) {
					lis.removeClass('selected');
					trash.addClass('selected');
					title = 'Trash';
					icon = 'trash';
					this.observers.sidebar.id('trash');
				} else {
					if (this.observers.sidebar.id() !== 'content' && this.observers.sidebar.id() !== 'multi') {
						this.observers.sidebar.id('fixed');
					}
					if (val.indexOf('content/after:') === 0) {
						lis.removeClass('selected');
						$(lis[1]).addClass('selected');
						title = 'Last import';
						icon = 'recent';
					} else if (val.indexOf('favorites') === 0) {
						lis.removeClass('selected');
						$(lis[2]).addClass('selected');
						this.observers.show_root_site_links(this.observers.form_site_link('favorites') !== false);
						title = 'Favorites';
						icon = 'faves';
					} else if (val.indexOf('content/featured') === 0) {
						lis.removeClass('selected');
						$(lis[3]).addClass('selected');
						title = 'Featured content';
						icon = 'featured';
					} else if (val.match(/content\/\d+,\d/)) {
						lis.removeClass('selected');
						$(lis[4]).addClass('selected');
						title = 'Quick collection';
						icon = 'quick';
						this.observers.is_collection(true);
					} else if (val.match(/content\/(unlisted|privat)/)) {
						if (val.indexOf('/private') === -1) {
							lis.removeClass('selected');
							$(lis[5]).addClass('selected');
							title = 'Unlisted content';
							icon = 'unlisted';
						} else {
							lis.removeClass('selected');
							$(lis[6]).addClass('selected');
							title = 'Private content';
							icon = 'private';
						}
					} else if (val.indexOf('content') === 0) {
						lis.removeClass('selected');
						$(lis[0]).addClass('selected');
						this.observers.show_root_site_links(this.observers.form_site_link('contents') !== false);
					}
				}
			}

			this.observers.header.title(title);
			this.observers.header.icon('label-' + icon);
		},

		get_asset_by_id: function(id) {
			return this.observers.content()[ $('#content_list [data-id="' + id + '"]').data('index') ];
		},

		focal_draggable: function() {

			var el = $('#focal_point'),
				self = this;

			el.draggable({
				containment: '#focal_point_wrapper',
				start: function() {
					$('a[data-event="toggle_dropdown"].selected').parent().trigger('click');
				},
				stop: function() {
					var l = parseInt(el.css('left'), 10) + 25,
						t = parseInt(el.css('top'), 10) + 25,
						parent = el.parent(),
						x = Math.round((l / parent.width()) * 100),
						y = Math.round((t / parent.height()) * 100),
						n = self.notify().wait('Assigning focal point');

					self.sync({
						request: 'content/' + self.observers.sidebar.asset.id(),
						method: 'PUT',
						data: 'focal_point={"x":' + x + ',"y":' + y + '}',
						context: self,
						finished: function(data) {
							this.controllers.update_item(data);
							n.success('New focal point assigned');
						}
					});
				}
			});

		},

		update_summary: function() {
			this.sync({
				request: 'events/limit_to:content/content_column:published_on',
				context: this,
				finished: function(data) {
					var years = [],
						y = false,
						m = [];
					$.each( data.events, function(i, date) {
						if (date.year != y) {
							if (y) {
								years.push({ year: y, months: m });
								m = [];
							}
							y = date.year;
						}
						m.push({ month: date.month, year: date.year });
					});

					if (m.length) {
						years.push({ year: y, months: m });
					}
					this.observers.years(years);
				}
			});
		},

		update_item: function(data, rewrite_ui) {
			rewrite_ui = rewrite_ui || false;
			var li = $('#list_item_' + data.id),
				index = li.data('index');

			if (data.uploaded_on instanceof Object === false) {
				var ts = new Date(data.uploaded_on).getTime()/1000;

				data.uploaded_on = {
					datetime: ts,
					timestamp: data.uploaded_on
				}
			}

			if (data.captured_on instanceof Object === false) {
				var ts = new Date(data.captured_on).getTime()/1000;

				data.captured_on = {
					datetime: ts,
					timestamp: data.captured_on
				}
			}

			this.observers.content.splice(index, 1, data);

			if (rewrite_ui) {
				li.replaceWith( this.controllers.create_node(data, index) );
				this.controllers.events_init();
				this.controllers.lazy('#content_list');
			}

			if (this.observers.sidebar.asset.id && this.observers.sidebar.asset.id() == data.id && this.observers.selection().length <= 1) {
				this.map(this.observers.sidebar.asset, data);
				if (this.observers.sidebar.id() === 'content' && rewrite_ui) {
					this.views.asset_multi_view(data);
				}

				$('#container').trigger('selection.loaded', data);
			}

			$('body').fitVids();

		},

		rewrite_hash: function(arr) {

			if (!$.isArray(arr)) {
				arr = [ arr ];
			}

			var frag = $.param.fragment();
			if (frag.match(/^\/library\/?$/)) {
				frag = '/library/content';
			}

			$.each(arr, function(i, obj) {
				var replace = obj.replace || [ obj.name ];

				if ($.isArray(obj.val)) {
					obj.val = obj.val.join(',');
				}

				$.each( replace, function(i, str) {
					frag = frag.replace(RegExp('\/' + str + ':[^\/]+'), '');
				});

				if (obj.name !== 'none' && obj.val) {
					frag += '/' + obj.name + ':' + obj.val;
				}
			});

			$.bbq.pushState( frag, 2 );
		},

		mid_scroll_finish: function() {
			if (this.observers.passengers.next_page && !this.observers.is_loading) {
				this.controllers.load_content();
			}
		},

		save: function() {

			var q	= this.observers.passengers.queue,
				c	= 0,
				now = +new Date,
				req = [];

			for (var i in q) {

				var item = q[i];

				// Don't update until the item has been untouched for 3s
				if ((now - item.last) > 2000) {

					delete(this.observers.passengers.queue[i]);

					this.controllers._save(item);
				}

				c++;

			}

			if (c === 0) {
				clearInterval(this.observers.passengers.interval);
			}
		},

		_save: function(item, cb, context) {

			if (item.type === 'unlisted_albums' || item.type === 'private_albums' || item.type === 'albums_flat') {
				item.type = 'albums';
			}

			var params = {
				context: context || this,
				finished: function(data) {
					if (cb) {
						cb.apply(this, [ data ]);
					}
				}
			};

			if (Object.keys(item).length === 3 && (item.hasOwnProperty('featured') || item.hasOwnProperty('favorite'))) {
				params.request = (item.hasOwnProperty('featured') ? item.type + '/featured' : 'favorites') + '/' + item.id;
				params.method = ((item.featured && item.featured === 1) || (item.favorite && item.favorite === 1)) ? 'POST' : 'DELETE';
			} else {
				params.request = item.type + '/' + item.id + (item.in_album ? '/context:' + item.in_album : '');
				params.method = 'PUT';
				var props = [];

				for (var i in item) {
					if (i !== 'type' && i !== 'id' && i !== 'last' && item[i] !== null) {
						var val = item[i]['raw'] || item[i]['timestamp'] || item[i];
						if (val === true) {
							val = 1;
						} else if (val === false) {
							val = 0;
						}
						props.push(i + '=' + encodeURIComponent(val));
					}
				}

				params.data = props.join('&');

				if (item.type === 'content' && item.hasOwnProperty('title')) {
					var self = this;
					params.finished = function(data) {
						self.controllers.update_item(data);
					};
				}
			}

			this.sync(params);

		},

		update: function(type, data, o, in_album) {

			in_album = in_album || false;

			var ob		= this.observers,
				self	= this,
				updated	= [],
				o		= $.extend({ instant: false, updated: false, finished: false, sidebar: true}, o);

			if (!$.isArray(data)) {
				data = [ data ];
			}

			$.each( data, function(index, item) {

				item.type = type;
				if (in_album) {
					item.in_album = in_album;
				}

				if (o.instant) {
					self.controllers._save(item, o.finished, o.context);
				} else {
					var key = type + '-' + item.id;

					item.last = +new Date;

					if (ob.passengers.queue[key]) {
						ob.passengers.queue[key] = $.extend(ob.passengers.queue[key], item);
					} else {
						ob.passengers.queue[key] = item;
					}
				}

				var el = $('#list_item_' + item.id),
					d;

				if (type !== 'content' && item.title) {
					$('.lib-nav li[data-id="' + item.id + '"]').find('span.replace-title:first').text(item.title);
				}

				if (el.length && el.hasClass(type.replace('s', ''))) {
					var badges = [ 'featured', 'favorite' ];
					d = $.extend({}, ob.content()[el.data('index')], item);

					$.each( badges, function(i, badge) {
						if (item.hasOwnProperty(badge)) {
							if (item[badge]) {
								el.addClass(badge);
							} else {
								if (self.observers.api_url().match( RegExp('\/' + badge + 's?:1\/?') )) {
									$('#list_item_' + item.id).remove();
									self.controllers.rewrite_hash({ name: 'selection', val: false });
								} else {
									el.removeClass(badge);
								}
							}
						}
					});

					if (item.visibility && item.visibility.raw !== 'unlisted') {
						el.removeClass('unlisted');
					}

					if (item.visibility && item.visibility.raw === 'unlisted') {
						el.addClass('unlisted');
					}

					if (item.visibility && item.visibility.raw !== 'private') {
						el.removeClass('private');
					}

					if (item.visibility && item.visibility.raw === 'private') {
						el.addClass('private');
					}

					if (type !== 'content' && item.title) {
						el.find('.ttle').text(item.title);
					}

					if (type !== 'content' && item.visibility && self.observers.api_url().indexOf('visibility:' + item.visibility.raw) !== -1) {
						$('#list_item_' + item.id).remove();
						self.controllers.rewrite_hash({ name: 'selection', val: false });
					}

					// This is used to show notifications for multiple item edits since we dont update the sidebar
					var selected	= self.observers.multiedit.selected,
						msg			= '',
						tot			= 0;

					if ( selected() <= 0 ) {
						selected( $('#content_list li.selected') );
					}

					if ( selected().length > 0 ) {
						selected( selected.splice(1) );
					}

					if ( selected().length <= 0 ) {

						tot = $('#content_list li.selected').length;

						if ( self.observers.multiedit.changed.tags ) {
							self.notify().success( 'Tag ' + self.observers.multiedit.changed.tags[0] + ' added to ' + tot + ' items' );
						}

						if ( self.observers.multiedit.changed.maxdl ) {
							self.notify().success( 'Max download changed to ' + self.observers.multiedit.changed.maxdl + ' for ' + tot + ' items' );
						}

						if ( self.observers.multiedit.changed.license ) {
							self.notify().success( 'License changed to ' + self.observers.multiedit.changed.license + ' for ' + tot + ' items' );
						}

						if ( self.observers.multiedit.changed.visibility ) {
							self.notify().success( 'Visibility changed to ' + self.observers.multiedit.changed.visibility + ' for ' + tot + ' items' );
						}

						self.observers.multiedit.changed = {};

					}
					self.controllers.update_item(d);
				} else {
					if (type === 'albums') {
						type = 'albums_flat';
					}
					var obs = ob[type](),
						copy = ko.utils.arrayFirst( obs, function(i) {
							return item.id == i.id;
						});
					d = $.extend({}, copy, item);

					if (self.observers.sidebar.id() === 'album' && type.indexOf('albums') !== -1 && item.id === self.observers.sidebar.asset.id()) {
						self.map(self.observers.sidebar.asset, d);
					}

					if (self.observers.properties.album.selected().album && self.observers.properties.album.selected().album.id === item.id) {
						var a = $.extend({}, self.observers.properties.album.selected().album, d);
						a = $.extend({}, self.observers.properties.album.selected(), {album: a});
						self.observers.properties.album.selected(a);
					}
				}

				updated.push(d);

			});

			clearInterval(ob.passengers.interval);
			ob.passengers.interval = window.setInterval(function() { self.controllers.save.call(self); }, 1000);

			if (o.updated) {
				o.updated.apply(self, [ updated ]);
			}

			if (this.observers.selection().length > 1 && (data[0].categories || data[0].tags)) {
				this.observers.selection.valueHasMutated();
			}

		},

		events_init: function() {

			this.interfaces.app.controllers.upload.cleanup();

			if (this.observers.is_uploadable()) {

				var self = this;

				var buildUploader = function() {
					var uploader;

					uploader = self.classes.channel.library.uploader = $.extend(self.config.uploader, {}, true);

					uploader.properties.filters = [
						{
							title		: "Image files",
							extensions	: "jpg,gif,png,jpeg"
						},
						{
							title		: "Videos",
							extensions	: "mp4,m4v"
						}
					];

					uploader.properties.browse_button	= 'mid-content';
					uploader.properties.drop_element	= 'mid-content';
					uploader.properties.url				= self.config.paths.base_relative + 'api.php?/content';

					uploader.instance					= new plupload.Uploader(uploader.properties);
					uploader.instance.context			= self;
					uploader.instance.uploaded			= [];

					uploader.instance.bind('PostInit', function( up, params ) {

						up.disableBrowse(true);

						if ( up.features.chunks ) {
							up.settings.max_file_size	= plupload.parseSize('5gb');
							up.settings.chunk_size		= self.system.upload_limit;
						} else {
							up.settings.max_file_size	= self.system.upload_limit;
						}

					});

					uploader.instance.bind('QueueChanged', function( up, e ) {
						if (up.files && up.files.length > 0 && !self.classes.channel.library.isUploading) {
							self.controllers.upload.init.call(self, uploader);
						}
					});

					return uploader;
				}

				if (this.classes.channel.library.uploader) this.classes.channel.library.uploader.instance.refresh();

				if (!self.classes.channel.library.uploaderIsBeingInitialized) {
					self.classes.channel.library.uploaderIsBeingInitialized = true;
					buildUploader().instance.init();
				}

			}

			// Event initialization only
			this.events.album_draggable.call(this);
			this.events.cover_draggable.call(this);
			this.events.cover_droppable.call(this);
			this.events.root_droppable.call(this);
			this.events.album_droppable.call(this);
			this.events.album_set_droppable.call(this);
			this.events.trash_droppable.call(this);
			this.events.side_droppable.call(this);

			var ob		= this.observers,
				self	= this;

			$('#search_form input').autocomplete({
				select: function(event, ui) {
					self.observers.search(ui.item.label);
				},
				source: function(req, cb) {
					var term = req.term;
					var matches = [];
					switch( ob.search_filter() ) {
						case 'tags':
							$.each(ob.tags(), function(i, t) {
								if (t.title.match(RegExp(term, 'gi'))) {
									matches.push(t.title);
								}
							});
							cb.apply(this, [ matches ]);
							break;

						case 'filename':
							self.sync({
								request: 'content/search:' + term + '/search_filter:filename/limit:10',
								context: self,
								finished: function(data) {
									$.each(data.content, function(i,c) {
										matches.push(c.filename);
									});
									cb.apply(this, [ matches ]);
								}
							});
							break;

						case 'category':
							$.each(ob.categories(), function(i, t) {
								if (t.title.match(RegExp(term, 'gi'))) {
									matches.push(t.title);
								}
							});
							cb.apply(this, [ matches ]);
							break;
					}
				},
				appendTo: '#search_form'
			}).off('.asearch').on('keydown.asearch', function(e) {
				if ( e.which === 13 ) {
					self.observers.search($(e.target).val());
				}
			});

		},

		create_node: function(content, index) {
			var str,
				klass = [],
				album	= this.observers.properties.album.selected().album,
				cover_ids = [];

			if (album && album.id) {
				cover_ids = album.covers.map(function(img) {
					return img.id;
				});
			}

			if (content.featured) {
				klass.push('featured');
			}

			if ($.inArray(content.id, this.observers.selection()) !== -1) {
				klass.push('selected');
			}

			if (content.album_type) {

				if (content.visibility && content.visibility.raw !== 'public') {
					klass.push(content.visibility.raw);
				}

				var ttl = (content.title !== null) ? content.title : '',
					published = (content.published_on ? this.observers.format_date( content.published_on.timestamp, 'MMM D YYYY' ) : ''),
					modified = this.observers.format_date( content.modified_on.timestamp, 'MMM D YYYY' ),
					created = this.observers.format_date( content.created_on.timestamp, 'MMM D YYYY' ),

				str = '<li id="list_item_' + content.id + '" class="album ' + klass.join(' ') + '" data-id="' + content.id + '" data-index="' + index + '">\
					<a class="album-stack" href="#" title="' + content.title + '">\
						<span class="stack stack1">\
							<img class="media" data-src="' + (content.covers.length > 1 ? content.covers[1].presets.small.cropped[this.config.retina ? 'hidpi_url' : 'url'] : 'images/no-preview.png') + '" width="70" height="70" style="opacity:0.8 !important;" />\
						</span>\
						<span class="stack stack2">\
							<span class="badge-count">' + content.counts.total + '</span>\
							<span data-event="badges" class="img-badges">\
								<span class="badge featured"></span>\
								<span class="badge unlisted"></span>\
								<span class="badge private"></span>\
							</span>\
							<img class="media" data-src="' + (content.covers.length > 0 ? content.covers[0].presets.small.cropped[this.config.retina ? 'hidpi_url' : 'url'] : 'images/no-preview.png') + '" width="70" height="70" />\
						</span>\
						<span class="stack stack3">\
							<img class="media" data-src="' + (content.covers.length > 2 ? content.covers[2].presets.small.cropped[this.config.retina ? 'hidpi_url' : 'url'] : 'images/no-preview.png') + '" width="70" height="70" style="opacity:0.8 !important;" />\
						</span>\
					</a>\
					<span class="count">' + (index + 1) + '</span>\
					<span class="title ttle">' + ttl + '</span>\
					<span class="title published_on">' + published + '</span>\
					<span class="title modified_on">' + modified + '</span>\
					<span class="title created_on">' + created + '</span>\
				</li>';
			} else {

				var w = content.presets ? Math.min(content.presets.small.width, 100) : 70,
					h = content.presets ? Math.min(content.presets.small.height, 100) : 70,
					hr = Math.min(h/w, 1).toFixed(2),
					wr = Math.min(w/h, 1).toFixed(2),
					style = 'style="width: calc(100% * ' + wr + '); min-height: calc(100% * ' + hr + ');"';

				if (content.favorite) {
					klass.push('favorite');
				}

				if (content.visibility.raw === 'unlisted') {
					klass.push('unlisted');
				} else if (content.visibility.raw === 'private') {
					klass.push('private');
				}

				if (cover_ids.length) {
					var v = $.inArray(content.id, cover_ids);
					if (v === 0) {
						klass.push('cover-top');
					} else if (v > 0) {
						klass.push('cover');
					}
				}

				content.koken_rating_average = content.koken_rating_average ? +(content.koken_rating_average * 10).toFixed(1) : '';

				var ttl = content.title.length ? content.title : content.filename,
					published = (content.published_on ? this.observers.format_date( content.published_on.timestamp, 'MMM D YYYY' ) : ''),
					uploaded = this.observers.format_date( content.uploaded_on.timestamp, 'MMM D YYYY' ),
					captured = this.observers.format_date( content.captured_on.timestamp, 'MMM D YYYY', content.captured_on.timestamp.utc );
					modified = this.observers.format_date( content.modified_on.timestamp, 'MMM D YYYY' ),

				str = '<li data-id="' + content.id + '" id="list_item_' + content.id + '" class="content ' + klass.join(' ') + '" data-index="' + index + '" data-which="thumbnail"> \
					<span class="grid-data">\
						<span class="content">\
							<span ' + style + ' class="img-wrap">\
								' + ((content.duration && content.duration.clean) ? '<span class="vid_time">' + content.duration.clean + '</span>' : '') + '\
								<span data-event="badges" class="img-badges">\
									<span class="badge fave"></span>\
									<span class="badge featured"></span>\
									<span class="badge unlisted"></span>\
									<span class="badge private"></span>\
								</span>\
								<img class="media" data-url="' + content.presets.medium[this.config.retina ? 'hidpi_url' : 'url'] + '" />\
							</span>\
						</span>\
						<span class="count">' + (index + 1) + '</span>\
						<span class="title filename">' + content.filename + '</span>\
						<span class="title visibility">' + content.visibility.clean + '</span>\
						<span class="title published_on">' + published + '</span>\
						<span class="title uploaded_on">' + uploaded + '</span>\
						<span class="title modified_on">' + modified + '</span>\
						<span class="title captured_on">' + captured + '</span>\
						<span class="title dimension">' + content.width + ' x ' + content.height + '</span>\
						<span class="title ttle">' + ttl + '</span>\
						<span class="title aspect_ratio">' + content.aspect_ratio + '</span>\
						<span class="title koken_rating_count">' + content.koken_rating_count + '</span>\
						<span class="title koken_rating_average">' + content.koken_rating_average + '</span>\
					</span>\
					<span class="list-cell tn">\
						<span class="tn-wrap"><img class="media" /></span>\
					</span>\
					<span class="list-cell fn">' + ( ttl.length ? ttl + '<br>' : '' ) + content.filename + '</span>\
					<span class="list-cell">\
						<span class="title visibility">' + content.visibility.clean + '</span>\
						<span class="title published_on">' + published + '</span>\
						<span class="title uploaded_on">' + uploaded + '</span>\
						<span class="title modified_on">' + modified + '</span>\
						<span class="title captured_on">' + captured + '</span>\
						<span class="title dimension">' + content.width + ' x ' + content.height + '</span>\
						<span class="title ttle">' + ttl + '</span>\
						<span class="title aspect_ratio">' + content.aspect_ratio + '</span>\
						<span class="title koken_rating_count">' + content.koken_rating_count + '</span>\
						<span class="title koken_rating_average">' + content.koken_rating_average + '</span>\
						<span class="title cnt">' + (index + 1) + '</span>\
					</span>\
					<span class="list-cell vis">' + content.visibility.clean + '</span>\
				</li>';

			}

			return str;
		},

		load_content: function(obj, restart) {

			restart = restart || false;
			obj = obj || { load_until: false, cb: false };
			this.observers.is_loading = true;

			var url					= this.observers.api_url() + '/limit:50',
				timer				= +new Date(),
				loaded_from_cache	= false;

			if (this.observers.passengers.next_page && !restart) {

				var start = (this.observers.passengers.next_page*50) - 50,
					end = Math.min(start+50, this.observers.properties.overall.total());

				$('#scroll-tick p:last').text(start + ' - ' + end);
				$('#scroll-tick p:first').text('Loading');
				$('#scroll-tick').addClass('loading');
				$('#scroll-tick').css({
					marginLeft: '-' + ( $('#scroll-tick').width() / 2 ) + 'px'
				});

				url += '/page:' + this.observers.passengers.next_page;

			}

			this.observers.loadstamp = timer;

			if ( this.config.cache && this.internalcache[url] ) {

				var parts = url.split('/'),
					val;

				$.each( this.internalcache, function(k,v) {

					var _parts = k.split('/');

					if ( parts[0] === _parts[0] && parts[1] === _parts[1] ) {
						val = v;
					}

				});

				$('#content_list').hide().html(val.src).show();
				$('#content_list').closest('.scroll_wrapper').scrollTop(val.scroll);

				loaded_from_cache = true;

			}

			this.cache(url);

			// This prevents loading spinners when the user has no content uploaded.
			if (!this.observers.properties.hasUploadedContent) {
				this.observers.loading(false);
			} else if (!this.observers.passengers.next_page || restart) {
				this.observers.loading(true);
			}

			if (this.classes.channel.library.firstLoad) {
				this.classes.channel.library.firstLoad = false;
				this.observers.loading(true);
			}

			this.sync({ context: this, request: [
				{
				request: url,

				finished: function(data) {

					if ( data.total > 0 ) {
						this.observers.properties.hasUploadedContent = true;
					}

					// If it errors for any reason, jump back to the default view
					if ( data.error ) {
						$.bbq.pushState( '/library', 2 );
						return;
					}

					this.observers.firstTime(false);

					if (timer !== this.observers.loadstamp) {
						return;
					}

					var xhr_queue	= [],
						pages		= data.pages,
						_data		= [],
						self		= this,
						i,

					done = function(d, first, page) {

						if (data.trash) {
							d = d.map( function(item) {
								return item.data;
							});
						}

						var fragment = [];

						$.each( d, function(i, content) {
							new self.klass.asset(content);
							fragment.push( self.controllers.create_node( content, i + (page - 1)*50 ) );
						});

						if (first) {
							self.observers.properties.overall.total(data.total);
							if (data.counts) {
								$.each(data.counts, function(n, c) {
									self.observers.properties.overall.counts[n](c);
								});
							}

							self.observers.content(d);

							if ( !self.config.cache || !loaded_from_cache ) {
								$('#content_list').html(fragment.join(''));
							}

							if (obj.load_until) {
								var ids = $.map( d, function(c) {
									return c.id;
								});
								if ($.inArray( obj.load_until, ids) === -1) {
									if (self.observers.passengers.next_page) {
										self.controllers.load_content( obj );
									} else {
										self.controllers.rewrite_hash({ name: 'selection', val: false });
									}
									return;
								} else {
									obj.load_until = false;
								}
							}

							self.classes.scrollbar.mid_multi.reset();
							self.controllers.lazy('#content_list');
							self.views.album_fan();
							self.controllers.events_init();

							if ( obj.cb && typeof obj.cb === 'function' ) { obj.cb.call(self, d); }

							if (self.observers.view() === 'single') {
								if (d[0].album_type) {
									self.observers.view('grid');
								} else {
									self.controllers.rewrite_hash({ name: 'selection', val: (self.observers.selection().length > 0) ? self.observers.selection() : [d[0]] });
								}
							}
						} else {
							self.observers.content( self.observers.content().concat( d ) );

							requestAnimationFrame(function() {

								if ( !self.config.cache || !loaded_from_cache ) {
									$('#content_list').append(fragment.join(''));
								}

								if (obj.load_until) {
									var ids = $.map( d, function(c) {
										return c.id;
									});

									if ($.inArray( obj.load_until, ids) === -1) {
										if (self.observers.passengers.next_page) {
											self.controllers.load_content( obj );
										} else {
											self.controllers.rewrite_hash({ name: 'selection', val: false });
										}
										return;
									}
								}

								self.controllers.lazy('#content_list');
								self.views.album_fan();
								self.controllers.events_init();

								if ( obj.cb && typeof obj.cb === 'function' ) { obj.cb.call(self, d); }

							});
						}

					};

					if (data.page === 1) {

						if (data.album) {
							this.observers.properties.album.selected(data);

							this.observers.order_by(data.album.sort.by);
							this.observers.order_direction(data.album.sort.direction);
						} else {
							this.observers.properties.album.selected({});

							if (data.sort) {
								this.observers.order_by(data.sort.by);
								this.observers.order_direction(data.sort.direction);
							}
						}

						if (this.observers.search().length || url.match(/search:[^\/]/)) {
							$('#multi-search-head').show();
							this.observers.sidebar.id('search');
							this.observers.header.title('Search');
							this.observers.header.icon('label-search');
						} else {
							$('#multi-search-head').hide();
						}

						if (data.total === 0) {

							$.each(self.observers.properties.overall.counts, function(n) {
								self.observers.properties.overall.counts[n](0);
							});

							self.observers.properties.overall.total(0);

							this.observers.content( [] );

							if ( obj.cb && typeof obj.cb === 'function' ) { obj.cb.apply(self); }

						} else {

							done(data.content || data.albums || data.trash, true, data.page);

						}

					} else {

						done(data.content || data.albums || data.trash, false, data.page);

					}

					if (data.pages > 1 && data.pages != data.page) {
						this.observers.passengers.next_page = data.page + 1;

						requestAnimationFrame(function() {
							if (self.observers.view() !== 'single' && $('#content_list li:last-child').offset().top < $(window).height()) {
								self.controllers.load_content( obj );
							}
						});
					} else {
						this.observers.passengers.next_page = false;
					}

					this.observers.is_loading = false;

				}

			}]});
		}

	},

	views: {

		album_fan: function() {

			var stacks	= $('#content_list a.album-stack'),
				index	= 0,
				loaded	= 0,
				total	= 0,
				self	= this,


			finish = function(li) {
				li.addClass('loaded');

				var a = self.observers.content()[index];
				if (a) a.loaded = true;

				loaded = 0;
				index++;
				load();
			},

			load = function() {
				var imgs	= stacks.eq(index).find('img');
					total	= imgs.length;

				imgs.bind( 'load', function() {
					$(this).fadeIn();
					loaded++;
					if (loaded === total) {
						finish($(this).parents('li'));
					}
				});

				imgs.each(function(i, img) {
					$(img).attr('src', $(img).data('src'));
				});
			};

			load();
		},

		open_sidebar_drawer: function(el) {

			var head	= el.closest('#content_sidebar_info_container').find('.col-select'),
				sidebar = $('#right-col-xtra'),
				label	= '',
				self	= this;

			this.observers.drawerOpen = el;

			switch( this.observers.drawer.id() ) {
				case 'iptc_edit':
					label = 'IPTC';
					break;
				case 'exif_edit':
					label = 'EXIF';
					break;
				case 'license_edit':
					label = 'Edit license';
					break;
				case 'maxdl_edit':
					label = 'Edit max download size';
					break;
				case 'site_link_edit':
					label = 'Edit site link';
					break;
				case 'captured_uploaded_edit':
					label = 'Edit date captured';
					break;
				case 'multi_history':
					var _type = el.closest('.col-row').data('type');
					this.observers.multiHistory.drawer(_type);
					if (_type === 'published_on') {
						label = 'Edit date published';
					} else if (_type === 'uploaded_on') {
						label = 'Edit date uploaded';
					} else if (_type === 'captured_on') {
						label = 'Edit date captured';
					}
					break;
				case 'history_published_edit':
					label = 'Edit date published';
					break;
				case 'history_uploaded_edit':
					label = ( this.observers.sidebar.id() === 'content' ) ? 'Edit date uploaded' : 'Edit date created';
					break;
				case 'visibility_edit':
					label = 'Edit visibility';
					break;
				case 'album_visibility_edit':
					label = 'Edit album visibility';
					break;
				case 'tags_edit':
					label = 'Edit tags';
					break;
				case 'category_edit':
					label = 'Edit category';
					break;
			}

			if (this.observers.drawer.id() === 'history_published_edit') {
				$('#right-col-data [name="pub_on"]').on('click', function() {
					self.observers.singleHistory.type($(this).val());
				});
			}

			if (this.observers.drawer.id() === 'history_published_edit') {
				if (this.observers.singleHistory.type() === 'assign') {
					$('#date_pub_single').prop('checked', false);
					$('#date_new_single').prop('checked', true);
				}
			}

			if (this.observers.multiHistory.drawer() === 'published_on' || this.observers.multiHistory.drawer() === 'captured_on' || this.observers.multiHistory.drawer() === 'uploaded_on') {
				if (this.observers.multiHistory.type() === 'assign') {
					$('#date_new').prop('checked', true);
					$('#date_shift').prop('checked', false);
					$('#date_pub').prop('checked', false);
				}
				$('#right-col-data [name="multi_history"]').on('click', function() {
					self.observers.multiHistory.type($(this).val());
				});
				$('#right-col-data input[type="number"]').on('change', function() {
					self.observers.multiHistory.date[$(this).data('type')]($(this).val());
				});
			}

			if ( this.observers.drawer.id() === 'category_edit' ) {

				this.pubsub( 'category.save', function(e,data) {

					var ids = (self.observers.selection().length > 0) ? self.observers.selection() : [self.observers.sidebar.asset],
						idsToSend = [],
						catsToAdd = [],
						catsToDelete = [],
						asset_type;

					ids.forEach(function(item) {
						idsToSend.push((item.id) ? item.id() : item);
						asset_type = (this.observers.sidebar.id() === 'album' || $( '#list_item_' + idsToSend[idsToSend.length - 1]).find('.album-stack').length > 0 ) ? 'albums' : 'content';
					}.bind(self));

					$('div#category_edit_drawer').find('input:checked').each(function(idx, item) {
						// For some reason, dupes can get into this and can cause problems with deletes
						if ($(item).is(':visible')) {
							catsToAdd.push(parseInt($(item).closest('div[data-id]').data('id'), 10));
						}
					}.bind(self));

					$('div#category_edit_drawer').find('input:checkbox').each(function() {
						if ($(this).prop('indeterminate') == false && $(this).prop('checked') == false) catsToDelete.push(parseInt($(this).attr('id').replace('content_category_',''), 10));
					});

					if (catsToDelete.length) {
						self.observers.selection_categories('Loading...');

						self.sync({
							request: 'categories/' + catsToDelete.join(',') + '/' + asset_type + '/' + idsToSend.join(','),
							method: 'DELETE',
							context: self,
							finished: function() {
								if (!catsToAdd.length) this.observers.get_selected_asset_categories(ids);
							}
						});
					}

					if (catsToAdd.length) {
						self.observers.selection_categories('Loading...');

						self.sync({
							request: 'categories/' + catsToAdd.join(',') + '/' + asset_type + '/' + idsToSend.join(','),
							method: 'PUT',
							context: self,
							finished: function() {
								this.observers.get_selected_asset_categories(ids);
							}
						});
					}

					self.views.close_sidebar_drawer();

				});

				if (this.observers.sidebar.asset.categories) {
					// Reset all the checkboxes
					$('#right-col-xtra').find('.col-row input').prop('checked',false);

					// Loop through and activate necessary checkboxes
					if ($.isArray(this.observers.selection_categories())) {
						this.observers.categories().forEach(function(allCategory) {
							this.observers.selection_categories().forEach(function(selectedCategory) {
								if ((typeof selectedCategory == 'object' ? selectedCategory.id : selectedCategory) == allCategory.id ) $('#right-col-xtra').find('.col-row[data-id="' + allCategory.id + '"] input').attr('checked', 'checked');
							});
						}.bind(this));
					}
				}

			}

			if ( this.observers.drawer.id() === 'tags_edit' ) {

				this.classes.autocomplete.library.tags($('#enterTagsInput input'), '#enterTagsInput', $('#tags_edit_drawer').find('button.create-tag'));

				this.pubsub( 'tags.delete', function(e, data) {
					var	asset_type = self.observers.sidebar.id() === 'album' ? 'albums' : 'content';
					self.sync({
						request: asset_type + '/' + self.observers.sidebar.asset.id(),
						context: self,
						finished: function(updatedData) {
							this.observers.sidebar.asset.tags(updatedData.tags);
							this.observers.content().forEach(function(item) {
								item.tags = $.grep(item.tags, function(tag) {
									return tag.id !== data.id;
								})
							});
						}
					});
				});

				this.pubsub( 'tags.save', function(e, data) {

					var ids = (self.observers.selection().length) ? self.observers.selection() : [self.observers.sidebar.asset],
						idsToSend = [],
						tagsToAdd = [];

					ids.forEach(function(item) {
						idsToSend.push((item.id) ? item.id() : item);
						asset_type = (this.observers.sidebar.id() === 'album' || $( '#list_item_' + idsToSend[idsToSend.length - 1]).find('.album-stack').length > 0 ) ? 'albums' : 'content';
					}.bind(self));

					self.sync({
						request: asset_type + '/' + idsToSend.join(','),
						method: 'PUT',
						data: 'tags=' + data.data,
						context: self,
						finished: function(data) {
							this.observers.sidebar.asset.tags(data.tags);
							this.observers.content().forEach(function(item) {
								if (item.id == data.id) item.tags = data.tags;
							});
						}
					});

				});

				var hasBeenEdited = false;
				$('div#tags_edit_drawer').on('click', 'input:checkbox', function(e) {
					hasBeenEdited = true;
				});

				this.pubsub('tags.multisave', function(e, data) {
					if (self.observers.selection().length > 1) {
						if (data && data.type === 'create') {
							$.each(data.data.split(','), function(i, tag) {
								self.observers.sidebar.asset.tags.push({title: tag});
								$('#tags_edit_drawer label:contains(' + tag + ')').parent().find('input').prop('checked', true);
							});
							hasBeenEdited = true;
						} else {
							if (!hasBeenEdited) return;
							hasBeenEdited = false;

							var toBeAdded = [],
								toBeRemoved = [],
								toBeDetermined = [],
								tagsCommonAcrossAll = [];

							$('div#tags_edit_drawer').find('input:checkbox').each(function() {
								if ( $(this).prop('indeterminate') == false && $(this).prop('checked') == false ) {
									toBeRemoved.push($(this).parent().find('label').text());
								} else if ( $(this).prop('indeterminate') == false && $(this).prop('checked') == true ) {
									toBeAdded.push($(this).parent().find('label').text());
								} else {
									toBeDetermined.push($(this).parent().find('label').text());
								}
							});

							toBeAdded.forEach(function(tag) {
								tagsCommonAcrossAll.push({title: tag});
							});

							if (toBeRemoved.length) {
								var sidebarTags = [];
								self.observers.sidebar.asset.tags().forEach(function(tag) {
									if (toBeRemoved.indexOf(tag.title) === -1) sidebarTags.push(tag);
								});
								self.observers.sidebar.asset.tags(sidebarTags);
							}

							var note = this.notify().wait('Updating tags');

							$.each(self.observers.selection(), function(i, asset) {

								var item = self.controllers.get_asset_by_id(asset),
									dataType = (self.observers.sidebar.id() === 'album' || $('#content_list li.album').length) ? 'albums' : 'content',
									toBeAddedClone = toBeAdded.slice();

								// Add back indeterminate tags to the toBeAdded tags array
								item.tags.forEach(function(tag) {
									if (toBeDetermined.indexOf(tag.title) !== -1) toBeAddedClone.push(tag.title);
								});

								self.sync({
									request: dataType + '/' + item.id,
									method: 'PUT',
									data: 'tags=' + self.helpers.unique(toBeAddedClone).join(','),
									context: self,
									finished: function(data) {
										this.observers.content().forEach(function(item) {
											if (item.id == data.id) {
												item.tags = data.tags;
											}
										});
										if (!data.tags.length) self.observers.selectionHasTags(false);
										if (note) {
											note.success('Tags updated for ' + self.observers.selection().length + ' items');
											delete note;
										}
									}
								});

							});

							self.observers.tagsCommonAcrossAll(tagsCommonAcrossAll);

						}
					}
				});

			}

			sidebar.find('.col-top-panel:first h5').text(label).closest('.col-top-panel').css('zIndex','1');

			this.observers.drawer.state('open');

			if (this.observers.drawer.id() === 'site_link_edit') {
				var input = $('[data-submit="site_link"]').find('input[type="text"]'),
					hidden = $('[data-submit="site_link"]').find('input[type="hidden"]');

				input.val(hidden.val());

				input.off('keyup.slug').on('keyup.slug', function(e) {

					if (e.which === 13) {
						$(this).parents('[data-submit]').find('button').trigger('click');
						return;
					}

				    var start = this.selectionStart,
				    	end = this.selectionEnd;

					input.val(input.val().toLowerCase().replace(/\s/g, '-'));

					this.setSelectionRange(start, end);
				});
			}

			$(window).trigger('resize');

			sidebar.show().animate({
				right: '0px'
			}, {
				easing: config.easing,
				complete: function() {
					if (self.observers.drawer.id() === 'tags_edit') {
						$('#tags_edit_drawer input[placeholder="Enter tags"]').focus();
					}
					if (self.observers.drawer.id() === 'category_edit') {
						$('#category_edit_drawer input[placeholder="Enter categories"]').focus();
					}

					$('[data-submit="site_link"] input[type="text"]').focus();
				}
			});

			$('input.field').off('.drawer_e').on('keydown.drawer_e', function(e) {
				if ( e.which === 13 ) {
					// Fix for sheets losing ENTER priority
					if ($(e.target).closest('.ui-sheet').length >= 1) {
						return false;
					}
					if ($(e.target).val() !== '' && !$(e.target).hasClass('autocompleting')) {
						$(e.target).parent().next().find('button').length && $(e.target).parent().next().find('button').trigger('click');
						$(e.target).parent().next().find('button').length && $(e.target).val('').focus();
					}
				}
			});

		},

		close_sidebar_drawer: function() {

			var self = this;

			this.observers.drawer.state('closed');

			$('#right-col-xtra').animate({
				right: '-250px'
			}, {
				easing: config.easing,
				complete: function() {
					self.observers.multiHistory.reset();
					self.observers.singleHistory.reset();
					$(this).height(1).hide();
				}
			});

		},

		asset_multi_view: function(asset) {

			this.observers.sidebar.id('content');

			var sel		= $('#lib-content-sel'),
				img		= sel.find('img'),
				self	= this,
				w, h, path;

			if (asset.html) {
				img.hide();
				sel.find('.mejs-container').remove();
				sel.fitVids();
				sel.css('height', sel.find('div').height());

				if (this.observers.view() === 'single') {
					this.views.asset_single_view();
				}
				return;
			}

			if (this.observers.sidebar.last.id === asset.id &&
				this.observers.sidebar.last.modified === asset.modified_on.timestamp)
			{
				sel.height(this.observers.sidebar.last.height);
				this.views.asset_single_view()
				return;
			}

			if ( asset.visibility.raw === 'private' ) {
				$('#mid-menu-top [data-menu="share_dropdown_menu"]').addClass('disabled');
			} else {
				$('#mid-menu-top [data-menu="share_dropdown_menu"]').removeClass('disabled');
			}

			if (asset.file_type === 'image') {

				if ( asset.aspect_ratio > (219/165) ) {
					w = 219;
					h = Math.round( 219 / asset.aspect_ratio );
				} else {
					w = Math.round( 165 * asset.aspect_ratio );
					h = 165;
				}

				path = asset.presets.medium[this.config.retina ? 'hidpi_url' : 'url'];

			} else {

				w = 219;
				h = 165;
				path = asset.original.url;

			}

			sel.addClass('loading');

			if ( this.observers.sidebar.last.id ) {
				sel.height(this.observers.sidebar.last.height);
			} else {
				sel.height(h);
			}

			if (asset.file_type === 'image') {
				sel.find('.mejs-container').remove();
				img.show();
				if (path !== img.attr('src')) {

					img.attr( 'width', w )
						.attr( 'height', h )
						.css({ opacity: 0 })
						.unbind('load')
						.bind( 'load', function() {
							sel.stop().animate({
								height: $(this).height() + 'px'
							}, {
								duration: 300,
								complete: function() {
									sel.removeClass('loading');
									img.animate({ opacity: 1 }, 300);
									$(window).trigger('library.resize');
								}
							});
						})
						.attr( 'src', path );
				}

			} else {
				img.hide();

				if (sel.find('video') && sel.find('video').attr('src') !== path) {

					sel.find('.mejs-container').remove();
					var vid = $('<video/>')
								.attr('width', w)
								.attr('height', h)
								.attr('src', path);

					vid.appendTo(sel);

					sel.removeClass('loading');
					sel.animate({
						height: h +'px'
					});

					sel.stop().animate({
						height: h + 'px'
					}, {
						duration: 300,
						complete: function() {
							$(window).trigger('library.resize');
						}
					});

					vid.mediaelementplayer({
						pluginPath: 'js/',
						videoWidth: w,
						videoHeight: h,
						flashName: 'flashmediaelement.swf'
					});
				}
			}

			this.observers.sidebar.last = {
				id: asset.id,
				width: w,
				height: h,
				modified: asset.modified_on.timestamp
			};

			if (this.observers.view() === 'single') {
				this.views.asset_single_view();
			}

		},

		asset_position_focal: function() {
			var id		= this.observers.selection()[0],
				index	= $('#list_item_' + id).data('index'),
				asset = this.observers.content()[index],
				img = $('#mid-single-wrap').find('img');

			var wrap_offset = $('#mid-single-wrap').offset();
			var img_offset = img.offset();

			if (img.length) {
				$('#focal_point_wrapper').css({
					width: img.width(),
					height: img.height(),
					top: img_offset.top - wrap_offset.top,
					left: img_offset.left - wrap_offset.left
				});

				$('#focal_point').css({
					top: img.height() * (asset.focal_point.y / 100) - 25,
					left: img.width() * (asset.focal_point.x / 100) - 25
				});
			}
		},

		asset_single_view: function() {

			if ( this.observers.selection().length ) {

				var id		= this.observers.selection()[0],
					index	= $('#list_item_' + id).data('index'),
					asset = this.observers.content()[index];

				if (asset) {

					var mid_single = $('#mid-single-img'),
						mid_div	= $('#mid-single-wrap'),
						dims,
						self = this;

					mid_single.addClass('loading');
					mid_div.children().not('#focal_point_wrapper,#focal_point,#custom_single_html,.kspinner').remove();

					if (asset.html) {
						mid_single.removeClass('loading');
						mid_single.fitVids();
					}
					else if ( asset.file_type === 'image' ) {

						$('<img/>').css({ opacity: 0 })
							.bind('load', function() {
								mid_single.removeClass('loading');
								$(this).animate({ 'opacity': 1 });

								self.asset_position_focal();
							})
							.appendTo(mid_div);

					} else {

						mid_single.removeClass('loading');

						dims = this.helpers.viewport_area();

						var vid = $('<video/>')
									.attr('src', asset.original.url)
									.attr('poster', asset.presets ? asset.presets.medium[this.config.retina ? 'hidpi_url' : 'url'] : '');

						$('<div/>')
							.attr('id', 'mid-single-video')
							.append(vid)
							.prependTo(mid_div);

							vid.mediaelementplayer({
								pluginPath: 'js/',
								enableAutosize: true,
								videoWidth: '100%',
								videoHeight: '100%',
								flashName: 'flashmediaelement.swf',
								success: function() {
									self.asset_resize(asset);
								}
							});

					}

					this.asset_resize(asset);
				}

			}

		},

		asset_resize: function(asset) {
			if (!asset) {
				var id		= this.observers.selection()[0],
					index	= $('#list_item_' + id).data('index');

				asset = this.observers.content()[index];

				if (!asset) { return; }
			}

			if (asset.html) {

				var el = $('#custom_single_html').children().first(),
					h = el.outerHeight(),
					top = (($('#custom_single_html').height() - h) / 2);

				el.css('top', top);

				if (h === 0) {
					var self = this;
					setTimeout(function() {
						self.asset_resize(asset);
					}, 100);
					return;
				}

			} else if (asset.file_type === 'image') {
				var dims	= this.asset_dimensions(asset);

				var w = dims.width,
					h = dims.height;

				this.asset_position_focal();

				var img = $('#mid-single-img img'),
					src;

				if (asset.presets) {
					var self = this;
					$.each(asset.presets, function(i, p) {
						if (p.width > w && p.height > h) {
							src = p[self.config.retina ? 'hidpi_url' : 'url'];
							return false;
						} else {
							src = p[self.config.retina ? 'hidpi_url' : 'url'];
						}
					});
				} else {
					src = this.config.fallback_previews[asset.file_type];
				}

				img.attr('src', src);

				this.controllers.focal_draggable();
			} else {
				this.observers.focal_point(false);
			}

		},

		asset_dimensions: function(asset) {

			var dims		= this.helpers.viewport_area(),
				mid_w		= dims.width - 60,
				mid_h		= dims.height - 60,
				mid_aspect	= mid_w / mid_h,
				h			= mid_h,
				aspect		= asset.aspect_ratio === null ? 1 : asset.aspect_ratio,
				w			= Math.round( h * aspect );

			if ( aspect >= mid_aspect ) {
				w = mid_w;
				h = Math.round( w / asset.aspect_ratio );
			}

			if (asset.width && asset.width < w) {
				w = asset.width;
				h = Math.round( w / asset.aspect_ratio );
			}

			if (asset.height && asset.height < h) {
				h = asset.height;
				w = Math.round( h * aspect );
			}

			return { mid_w: mid_w, mid_h: mid_h, width: w, height: h };

		}

	},

	models: function() {

		var self = this;

		return {

			albums: {

				covers: {

					set: function(val,cb) {

						self.sync({
							request: 'albums/' + val.album + '/covers/' + val.ids,
							method: val.type,
							context: self,
							finished: function(data) {
								if ( data !== null ) {
									self.observers.properties.album.selected().album.covers = data.covers;
								} else {
									if (val.rmId) {
										var cov = [];
										$.each( self.observers.properties.album.selected().album.covers, function(k,v) {
											if ( v.id != val.rmId ) {
												cov.push(v);
											}
										});
										self.observers.properties.album.selected().album.covers = cov;
									}
									$.each( val.ids, function(i, id) {
										if (self.observers.api_url().match(/covers:1/)) {
											$('#list_item_' + id).remove();
											self.controllers.rewrite_hash({ name: 'selection', val: false });
										} else {
											$('#list_item_' + id).removeClass('cover');
										}
									});
								}
								if ( cb && typeof cb === 'function' ) { cb.call(self,data); }
							}
						});

					}

				}

			},

			featured: {

				content: {

					set: function(val,cb) {

						var accept = [];

						$.each( val, function(k,v) {
							if ( $('#content_list li[data-id="' + v.id + '"]').find('.badge.featured').css('display') === 'none' && $('#content_list li[data-id="' + v.id + '"]').find('.badge.featured').css('display') === 'none' ) {
								accept.push(v);
							}
						});

						val = accept;

						if ( val.length > 0 ) {

							requestAnimationFrame( function() {
								self.controllers.update('content', val, {
									instant: true,
									updated: function(items) {

										if (items.length === 1) {
											self.notify().success(items[0].filename + ' added to Featured content');
										} else if (items.length) {
											self.notify().success(items.length + ' items added to Featured content');
										}
										if ( cb && typeof cb === 'function' ) { cb.call(self,false); }
									}
								});
							});

						}

					}

				},

				albums: {

					set: function(val,cb) {

							self.controllers.update('albums', val, {
								instant: true,
								updated: function(items) {
									if (items.length === 1) {
										self.notify().success('"' + items[0].title + '" added to Featured albums');
									} else if (items.length) {
										self.notify().success(items.length + ' albums added to Featured albums');
									}
									if ( self.observers.api_url().indexOf('albums/featured') === 0 ) {
										requestAnimationFrame( function() {
											self.controllers.load_content();
										});
									}
									if ( cb && typeof cb === 'function' ) { cb.call(self,false); }
								}
							});

					}

				}

			},

			trash: {

				albums: {

					set: function(val,cb) {

						var album = $('#content_subnav li[data-id="' + val + '"]');

						self.sync({
							request: 'trash/albums:' + val,
							method: 'POST',
							context: self,
							finished: function(data) {
								this.observers.properties.trash.total(data.total);
								this.notify().success( album.find('.item').text() + ' was moved to the Trash');
								if (this.observers.api_url().indexOf('albums/' + val) !== -1) {
									$.bbq.pushState( '/library', 2 );
								} else {
									this.controllers.load_content();
								}
								if ( cb && typeof cb === 'function' ) { cb.call(this,false); }
							}
						});

					}

				}

			}

		};

	},

	observers: function() {

		var self = this,
		cookies = {};

		cookies.search_filter = ( this.cookie('library:search_filter') !== null ) ? this.cookie('library:search_filter') : 'tags';
		cookies.open_sets = ( this.cookie('library:open_sets') !== null ) ? $.map( this.cookie('library:open_sets').split(','), function(k,v) { return parseInt(k,10); }) : [];
		cookies.thumbnail_scale = ( this.cookie('library:thumbnail_scale') !== null ) ? parseInt(this.cookie('library:thumbnail_scale'), 10) : 100;

		return {

			is_uploadable: ko.observable(false),
			is_sortable: ko.observable(false),
			is_quick_collection: ko.observable(false),
			isContent: ko.observable(true),
			show_root_site_links: ko.observable(false),
			default_sort: ko.observable('published_on'),
			sortable: ko.observable(false),
			session: self.session(),
			api_url: ko.observable(''),
			selection: ko.observableArray([]),
			view: ko.observable('grid'),
			thumbnail_scale: ko.observable(cookies.thumbnail_scale),
			order_by: ko.observable('published_on'),
			order_direction: ko.observable('desc'),
			loading: ko.observable(false),
			search_filter: ko.observable(cookies.search_filter),
			search: ko.observable(''),
			quick_collection: ko.observableArray( self.persist('library.quick_collection') ? self.persist('library.quick_collection').split(',').map( function(id) { return Number(id); }) : [] ),
			year: ko.observable(false),
			month: ko.observable(false),
			years: ko.observableArray([]),
			firstTime: ko.observable(true),
			is_collection: ko.observable(false),
			isBeingDropped: false,
			drawerOpen: false,
			pluginPath: ko.observable(''),
			focal_point: ko.observable(false),
			multiTagsBeingPushed: ko.observableArray([]),
			tagsCommonAcrossAll: ko.observableArray([]),
			selectionHasTags: ko.observable(false),
			trashRestoreDisabled: ko.observable('disabled'),

			singleHistory: {
				type: ko.observable('assign'),
				reset: function() {
					this.type('assign');
				}
			},
			multiHistory: {
				note: null,
				drawer: ko.observable(''),
				type: ko.observable('assign'),
				date: {
					years: ko.observable(0),
					months: ko.observable(0),
					days: ko.observable(0),
					hours: ko.observable(0),
					minutes: ko.observable(0)
				},
				getNow: function() {
					return moment(Date()).format('YYYY/MM/DD HH:mm:SS');
				},
				reset: function() {
					this.date.years(0);
					this.date.months(0);
					this.date.days(0);
					this.date.hours(0);
					this.date.minutes(0);
					this.type('assign');
					this.drawer('');
				}
			},

			next_item: function() {
				$('object, embed').remove();
				var new_index = this.observers.properties.content.selected.index() + 1;
				if (new_index < this.observers.content().length) {
					var item  = this.observers.content()[ new_index ];
					new_index = item.id;
					this.controllers.rewrite_hash({ name: 'selection', val: new_index });
				} else if (this.observers.content().length < this.observers.properties.overall.total()) {
					var self = this;
					this.controllers.load_content({
						cb: function(data) {
							self.controllers.rewrite_hash({ name: 'selection', val: data[0].id });
						}
					});
				}

			},

			prev_item: function() {
				$('object, embed').remove();
				var new_index = this.observers.properties.content.selected.index() - 1;
				if (new_index >= 0) {
					var item  = this.observers.content()[ new_index ];
					new_index = item.id;
					this.controllers.rewrite_hash({ name: 'selection', val: new_index });
				}
			},

			loadstamp: 0,
			is_loading: false,
			passengers: {
				queue: {},
				interval: false,
				next_page: false
			},
			header: {
				title: ko.observable('Content'),
				icon: ko.observable('label-every'),
				collection: ko.observable('Content')
			},
			properties: {
				hasUploadedContent: false,
				collections: {
					selected: ko.observable('Everything')
				},
				paths: self.config.paths,
				overall: {
					total: ko.observable(0),
					counts: {
						images: ko.observable(0),
						videos: ko.observable(0),
						audio: ko.observable(0),
						albums: ko.observable(0),
						sets: ko.observable(0),
						total: ko.observable(0)
					}
				},
				content: {
					selected: {
						id: ko.observable([]),
						index: ko.observable(-1),
						total: ko.observable(0),
						text: ko.observable('Select all')
					}
				},
				album: {
					selected: ko.observable({})
				},
				multi: {
					selected: ko.observable({}),
					labels: ko.observable({})
				},
				trash: {
					total: ko.observable(0)
				}
			},
			open_sets: ko.observableArray(cookies.open_sets),
			isMultiAlbum: ko.observable(false),
			sidebar: {
				id: ko.observable('fixed'),
				last: {},
				hasgeo: ko.observable(false),
				asset: {
					title: ko.observable(''),
					album_type: ko.observable(false),
					tags: ko.observableArray([]),
					categories: {
						count: ko.observable(0),
						url: ko.observable('')
					},
					max_download: {
						clean: ko.observable(''),
						raw: ko.observable('')
					},
					source: {
						url: ko.observable(false)
					},
					license: {
						clean: ko.observable(''),
						raw: ko.observable('')
					},
					visibility: {
						clean: ko.observable(''),
						raw: ko.observable('')
					},
					url: ko.observable(false),
					html: ko.observable(false)
				},
				multi: {
					tags: {
						common: ko.observableArray([]),
						all: ko.observableArray([])
					},
					categories: ko.observableArray([])
				}
			},
			dropdowns: {
				search: {
					items: ko.observableArray([
						{
							title: 'Search by tag',
							'class': 'opt-tags',
							event: 'search_filter_change'
						},
						{
							title: 'Search by category',
							'class': 'opt-category',
							event: 'search_filter_change'
						},
						{
							title: 'Search by file name',
							'class': 'opt-filename',
							event: 'search_filter_change'
						}
					]),
					id: ko.observable('search'),
					active: ko.observable(false),
					wedge: {
						offset: '10px'
					},
					onload: function() {
						if ( this.observers.api_url().indexOf('albums') >= 0 ) {
							$('#search_dd li:last .w span').text('Search by title').removeClass('opt-filename').addClass('opt-title');
						} else {
							$('#search_dd li:last .w span').text('Search by file name').removeClass('opt-title').addClass('opt-filename');
						}
					}
				},
				edit: {
					featured: function(c) {

						var selected	= c.selection().slice(),
							content		= c.content(),
							ret			= true;

						$.each( selected, function(k,v) {
							$.each( content, function(j,w) {
								if ( w.id == v ) {
									if ( w.featured == 0 ) {
										ret = false;
										return false;
									}
								}
							});
						});

						return (ret) ? 'Unmark' : 'Mark';

					},

					favorite: function(c) {

						var selected	= c.selection().slice(),
							content		= c.content(),
							ret			= true;

						$.each( selected, function(k,v) {
							$.each( content, function(j,w) {
								if ( w.id == v ) {
									if ( w.favorite == 0 ) {
										ret = false;
										return false;
									}
								}
							});
						});

						return (ret) ? 'Unmark' : 'Mark';

					},

					quick: function(c) {

						var selected	= c.selection().slice(),
							quick		= c.quick_collection(),
							ret			= true;

						$.each( selected, function(k,v) {
							if ($.inArray(v, quick) === -1) {
								ret = false;
								return false;
							}
						});

						return (ret) ? 'Remove from' : 'Add to';

					},

					covers: function(c,bool) {

						if ( ! c.properties.album.selected().album ) { return ''; }

						var selected	= c.selection(),
							content		= c.properties.album.selected().album.covers,
							ret			= [];

						$.each(self.observers.properties.album.selected().album.covers, function(i, cover) {
							if ($('#mid-content li.selected').data('id') == cover.id) {
								ret.push(cover)
							}
						});

						if ( content.length > 3 ) {
							$('#al-covers').show();
						} else {
							if ( ret.length > 0 ) {
								$('#al-covers').hide();
							} else {
								$('#al-covers').show();
							}
						}

						if ( c.properties.album.selected().album.album_type == 'set' ) {
							$('#al-covers').hide();
						}

						if (content.length <= 3 && ret.length > 0) {
							$('#al-covers').hide();
						}

						return ( ret.length > 0 ) ? 'Unassign' : 'Assign';

					},

					items: ko.observableArray([
						{
							title	: 'Rotate left',
							desc	: 'Rotate original counter-clockwise',
							'class'	: 'opt-rotate-l',
							bind	: "visible: selected_type() === 'content' && selection().length === 1 && sidebar.asset.file_type && sidebar.asset.file_type() != 'video' && sidebar.asset.file_type() != 'audio'"
						},
						{
							title	: 'Rotate right',
							desc	: 'Rotate original clockwise',
							'class'	: 'opt-rotate-r',
							bind	: "visible: sidebar.asset && selected_type() === 'content' && selection().length === 1 && sidebar.asset.file_type && sidebar.asset.file_type() != 'video' && sidebar.asset.file_type() != 'audio'"
						},
						{
							title	: 'Replace original',
							desc	: 'Replace original with new upload',
							id		: 'replace_content',
							'class'	: 'opt-replace',
							bind	: "visible: selected_type() === 'content' && selection().length === 1 && (!sidebar.asset.html || !sidebar.asset.html())"
						},
						{
							title	: 'Download original',
							desc	: 'Download original file to hard drive',
							'class'	: 'opt-dl',
							bind	: "visible: selected_type() === 'content' && selection().length === 1 && (!sidebar.asset.html || !sidebar.asset.html())"
						},
						{
							html	: '<span class="feat-type bind" data-bind="text: dropdowns.edit.featured(observers)"></span></span> as featured',
							desc	: 'Mark selection as featured',
							'class'	: 'opt-feat',
							bind	: "visible: selected_type() !== ''"
						},
						{
							html	: '<span class="fav-type bind" data-bind="text: dropdowns.edit.favorite(observers)"></span></span> as favorite',
							desc	: 'Mark selection as a favorite',
							'class'	: 'opt-fave',
							bind	: "visible: selected_type() === 'content'"
						},
						{
							html	: '<span class="feat-cover bind" data-bind="text: dropdowns.edit.covers(observers)"></span></span> as cover',
							desc	: 'Add selection to album covers',
							'class'	: 'opt-cover',
							id		: 'al-covers',
							bind	: "visible: selected_type() === 'content' && selection().length == 1 && properties.album.selected().album"
						},
						{
							title	: 'Display at sign-in',
							desc	: 'Use selection as background image',
							'class'	: 'opt-bg',
							bind	: "visible: sidebar.asset && selected_type() === 'content' && selection().length === 1 && sidebar.asset.file_type && sidebar.asset.file_type() != 'video' && sidebar.asset.file_type() != 'audio'"
						},
						{
							title	: 'Remove from album',
							desc	: 'Remove selection from this album',
							'class'	: 'opt-remove',
							bind	: "visible: selected_type() === 'content' && properties.album.selected().album"
						},
						{
							title	: 'Remove from set',
							desc	: 'Remove album from this set',
							'class'	: 'opt-remove',
							bind	: "visible: selected_type() === 'set' && properties.album.selected().album"
						},
						{
							title	: 'Remove from collection',
							desc	: 'Remove selection from this collection',
							'class'	: 'opt-remove',
							bind	: "visible: selected_type() === 'content' && is_collection()"
						},
						{
							title	: 'Move to trash',
							desc	: 'Move selection to trash',
							'class'	: 'opt-trash'
						}
					]),
					onload: function() {
						if ( $('.opt-replace').closest('li').css('display') !== 'none' ) {
							this.interfaces.app.controllers.replace.init.call(self);
						}
					},
					event: ko.observable('edit_menu'),
					id: ko.observable('edit'),
					active: ko.observable(false),
					offset: '8px',
					wedge: {
						offset: '10px'
					}
				},
				sort: {
					items: ko.observableArray([
						{
							title	: 'Manual',
							desc	: 'User defined sort order',
							bind	: "visible: sortable() === true || api_url().match(/^albums\\/\\d+\\/content/), css: { check: order_by() === 'manual' }, click: function() { change_order_by('manual'); }"
						},
						{
							title	: 'Aspect ratio',
							desc	: 'Sort by aspect ratio',
							bind	: "visible: selected_type() != 'set', css: { check: order_by() === 'aspect_ratio' }, click: function() { change_order_by('aspect_ratio'); }"
						},
						{
							title	: 'Date captured',
							desc	: 'Sort by date captured',
							bind	: "visible: selected_type() != 'set', css: { check: order_by() === 'captured_on' }, click: function() { change_order_by('captured_on'); }"
						},
						{
							title	: 'Date created',
							desc	: 'Sort by date created',
							bind	: "visible: selected_type() === 'set', css: { check: order_by() === 'created_on' }, click: function() { change_order_by('created_on'); }"
						},
						{
							title	: 'Date modified',
							desc	: 'Sort by date modified',
							bind	: "css: { check: order_by() === 'modified_on' }, click: function() { change_order_by('modified_on'); }"
						},
						{
							title	: 'Date published',
							desc	: 'Sort by date published',
							bind	: "visible: !api_url().match(/\\/(unlisted|privat)/), css: { check: order_by() === 'published_on' }, click: function() { change_order_by('published_on'); }"
						},
						{
							title	: 'Date uploaded',
							desc	: 'Sort by date uploaded',
							bind	: "visible: selected_type() != 'set', css: { check: order_by() === 'uploaded_on' }, click: function() { change_order_by('uploaded_on'); }"
						},
						{
							title	: 'Dimension',
							desc	: 'Sort by dimension',
							bind	: "visible: selected_type() != 'set', css: { check: order_by() === 'dimension' }, click: function() { change_order_by('dimension'); }"
						},
						{
							title	: 'Filename',
							desc	: 'Sort by filename',
							bind	: "visible: selected_type() != 'set', css: { check: order_by() === 'filename' }, click: function() { change_order_by('filename'); }"
						},
						{
							title	: 'Title',
							desc	: 'Sort by title',
							bind	: "visible: sortable() === true, css: { check: order_by() === 'title' }, click: function() { change_order_by('title'); }"
						},
						{
							title	: 'insert_break'
						},
						{
							title	: 'Ascending',
							desc	: 'Sort results ascending',
							bind	: "visible: order_by() !== 'manual', css: { check: order_direction() === 'asc' }, click: function() { change_order_direction('asc'); }"
						},
						{
							title	: 'Descending',
							desc	: 'Sort results descending',
							bind	: "visible: order_by() !== 'manual', css: { check: order_direction() === 'desc' }, click: function() { change_order_direction('desc'); }"
						}
					]),
					id: ko.observable('sort'),
					active: ko.observable(false),
					offset: '42px',
					position: ko.observable('above'),
					wedge: {
						offset: '10px'
					}
				},
				filter: {
					items: ko.observableArray([
						{
							title	: 'None',
							desc	: 'No filters',
							bind	: "css: { check: dropdowns.filter.selected() === 'none' }, click: function() { dropdowns.filter.selected('none'); }"
						},
						{
							title	: 'Covers',
							desc	: 'Show album cover images only',
							bind	: "visible: properties.album.selected().album, css: { check: dropdowns.filter.selected() === 'covers' }, click: function() { dropdowns.filter.selected('covers'); }"
						},
						{
							title	: 'Public',
							desc	: 'Show public content only',
							bind	: "visible: properties.album.selected().album && order_by() !== 'published_on', css: { check: dropdowns.filter.selected() === 'public' }, click: function() { dropdowns.filter.selected('public'); }"
						},
						{
							title	: 'Unlisted',
							desc	: 'Show unlisted content only',
							bind	: "visible: properties.album.selected().album && order_by() !== 'published_on', css: { check: dropdowns.filter.selected() === 'unlisted' }, click: function() { dropdowns.filter.selected('unlisted'); }"
						},
						{
							title	: 'Private',
							desc	: 'Show private content only',
							bind	: "visible: properties.album.selected().album && order_by() !== 'published_on', css: { check: dropdowns.filter.selected() === 'private' }, click: function() { dropdowns.filter.selected('private'); }"
						},
						{
							title	: 'Independent',
							desc	: 'Show content not in a collection',
							bind	: "visible: !properties.album.selected().album, css: { check: dropdowns.filter.selected() === 'independent' }, click: function() { dropdowns.filter.selected('independent'); }"
						},
						{
							title	: 'Photo',
							desc	: 'Show photos only',
							bind	: "css: { check: dropdowns.filter.selected() === 'photo' }, click: function() { dropdowns.filter.selected('photo'); }"
						},
						{
							title	: 'Video',
							desc	: 'Show videos only',
							bind	: "css: { check: dropdowns.filter.selected() === 'video' }, click: function() { dropdowns.filter.selected('video'); }"
						}
					]),
					selected: ko.observable('none'),
					title: ko.observable(''),
					id: ko.observable('filter'),
					active: ko.observable(false),
					offset: '42px',
					wedge: {
						offset: '10px'
					}
				},
				share: {
					items: ko.observableArray([
						{
							title	: 'Twitter',
							event	: 'share',
							'class'	: 'opt-twitter'
						},
						{
							title	: 'Facebook',
							event	: 'share',
							'class'	: 'opt-facebook'
						},
						{
							title	: 'Google+',
							event	: 'share',
							'class'	: 'opt-google'
						},
						{
							title	: 'Tumblr',
							event	: 'share',
							'class'	: 'opt-tumblr'
						},
						{
							title	: 'Embed',
							'class'	: 'opt-embed',
							bind	: "click: function() { this.classes.modal.share_asset.show(); }, visible: sidebar.asset.file_type() === 'image'"
						}
					]),
					id: ko.observable('share'),
					active: ko.observable(false),
					offset: '82px',
					wedge: {
						offset: '10px'
					}
				}
			},
			drawer: {
				id: ko.observable('default_edit'),
				state: ko.observable('closed'),
				template: function(c) {
					return c.drawer.id();
				}
			},
			content_type: function(c) {
				if ( c.album_type ) {
					return 'mid_multi_album_item';
				} else if ( c.data ) {
					return 'mid_multi_trash_item';
				} else {
					return 'mid_multi_content_item';
				}
			},
			album_class: function(data) {

				var type = '';

				if ( data.album_type === 'smart' ) {
					type = '-smart';
				} else if ( data.album_type === 'set' ) {
					type = '-set';
				}

				return 'icon16 label-album' + type;

			},
			// Selected assets need to call a separate API request to get the albums
			// they are a part of, these get pushed into the `selection_albums` observable
			selection_albums: ko.observable([]),
			get_selected_asset_albums: function() {
				this.selection_albums('Loading...');
				this.sync({
					request: 'content/' + this.sidebar.asset.id() + '/albums/context',
					context: this,
					finished: function(data) {
						if (data.albums.length) {
							this.selection_albums(data.albums);
						} else {
							this.selection_albums('- none -');
						}
					}
				});
			},
			//
			selection_categories: ko.observable([]),
			multiselection_categories: [],
			get_selected_asset_categories: function(val) {
				this.selection_categories('Loading...');
				if ($.isArray(val) && val.length > 1) {
					this.sync({
						request: ((this.sidebar.asset.album_type && this.sidebar.asset.album_type()) ? 'albums' : 'content') + '/' + val.join(',') + '/categories',
						context: this,
						finished: function(data) {
							this.multiselection_categories = data;

							if (!data.common.length && data.mixed.length) {
								this.selection_categories('- mixed -');
								return;
							}

							if (!data.common.length && !data.mixed.length) {
								this.selection_categories('- none -');
								return;
							}

							if (data.common.length) {
								var commonCats = [];
								$.each(this.observers.categories(), function(idx, cat) {
									if (data.common.indexOf(cat.id) !== -1) commonCats.push(cat.title);
								});
								this.selection_categories(commonCats.join(', '));
							} else {
								this.selection_categories('- error -');
							}
						}
					})
				} else if (this.sidebar.asset.categories.url && this.sidebar.asset.categories.url().length) {
					this.sync({
						request: this.sidebar.asset.categories.url(),
						context: this,
						finished: function(data) {
							if (data.categories.length) {
								this.selection_categories(data.categories);
							} else {
								this.selection_categories('- none -');
							}
						}
					});
				}
			},
			has_tag: function(t) {
				return $.inArray(t, this.sidebar.asset.tags()) === -1;
			},
			is_closed: function(item) {
				var check = $.inArray(item.id, this.open_sets());
				if (check !== -1) { return false; }
				var current = this.properties.album.selected().album;
				if ( $('#three-col-left .selected').closest('ol').parent().data('id') == item.id ) { return true; }
				if (!current || current.level == 1) { return true; }
				if ( item.album_type === 'set' ) {
					var isChild = false;
					$.each(item.children, function(i,child) {
						if (current && child.id === current.id) {
							isChild = true;
						}
					});
					if (!isChild) { return true; }
				}
				if (parseInt(current.left_id, 10) > parseInt(item.left_id, 10) && parseInt(current.level, 10) > parseInt(item.level, 10)) { return false; }
				return true;
			},
			selected_type: ko.observable('content'),
			is_cover: function(d) {
				var album	= this.properties.album.selected().album,
					v		= -1;
				if (album && album.id) {
					var cover_ids = album.covers.map(function(img) {
						return img.id;
					});
					v = $.inArray(d.id, cover_ids);
				}
				if (v === -1) {
					return ' hide';
				} else if (v === 0) {
					return '-top';
				} else {
					return '';
				}
			},
			in_array: function(needle, haystack) {
				return $.inArray(needle, haystack) !== -1;
			},
			exif_label: function() {
				var exif = $.extend(true, {}, this.sidebar.asset.exif());
				var fields = [ 'iso_speed_ratings', 'focal_length', 'aperture', 'exposure' ];
				var out = [];
				$.each(exif, function(i, obj) {
					if ($.inArray(obj.key, fields) !== -1) {
						if (obj.key === 'aperture') {
							obj.clean = obj.clean.replace('f', '<span class="fstop">f</span>');
						} else if (obj.clean) {
							obj.clean = obj.clean.replace(' ', '&nbsp;');
						}
						out.push( obj.clean || obj.raw);
					}
				});
				return out.join(' &nbsp;');
			},
			sheets: {
				create_collection: {
					active: ko.observable(false),
					id: ko.observable('create_collection'),
					validated: ko.observable(),
					category: ko.observable(),
					tag: ko.observable(),
					onload: function(el) {
						el.find('input:first').focus();
						this.classes.autocomplete.library.tags($('#cc_tag_input'), $('#cc_tag_input').parent(), $('#cc_tag_input').parent().find('button'));

						this.observers.sheets.create_collection.properties.visibility(this.observers.settings.uploading_default_album_visibility());

						// Reset collection type to album on sheet load
						this.observers.sheets.create_collection.collection.call(this.observers.sheets.create_collection.properties, 0);
					},
					reset: function() {
						this.properties.type(0);
						this.validated(null);
						for ( var k in this.properties ) {
							if ( k !== 'type' ) {
								this.properties[k].reset();
							}
						}
					},
					properties: {
						type: ko.observable(0),
						title: ko.observable(''),
						summary: ko.observable(''),
						description: ko.observable(''),
						tags: ko.observableArray([]),
						categories: ko.observableArray([]),
						visibility: ko.observable('public')
					},
					collection: function(type) {
						this.type(type);
						if (type === 0) {
							$('#create_collection').find('li[data-event="create_collection"] button').text('Create album');
						} else {
							$('#create_collection').find('li[data-event="create_collection"] button').text('Create set');
						}
						if ( this.title().trim().length <= 0 ) {
							$('#create_collection').find('input:first').focus();
						}
					},
					add_tag: function(data) {

						var self	= this.interfaces.library.observers,
							t		= ( typeof data.tag === 'function' ) ? this.helpers.parse_tag_input( ko.utils.unwrapObservable( $('#cc_tag_input').val() ) ) : [data.title];

						$.each( t, function(o) {
							self.sheets.create_collection.properties.tags.push(this.toString());
						});

						self.sheets.create_collection.properties.tags( self.helpers.unique( self.sheets.create_collection.properties.tags() ) );
						self.sheets.create_collection.tag('');

					},
					remove_tag: function(data) {

						var self = this.interfaces.library.observers;

						$.each( self.sheets.create_collection.properties.tags(), function(i,tag) {
							if ( tag === data ) {
								self.sheets.create_collection.properties.tags.splice(i,1);
								return false;
							}
						});

					}
				},
				import_content: {
					active: ko.observable(false),
					id: ko.observable('import_content'),
					validate: false,
					onload: function(sheet) {
						// TODO: Cleanup this selectors nightmare

						var self = this,
							initUpload = function() {
								self.interfaces.app.controllers.upload.cleanup();
								self.interfaces.app.controllers.upload.init.call(self.interfaces.library);
							},
							_selected = $.param.fragment(),
							lastSelected;

						var setSelected = function(name, plugin) {
							if (lastSelected) $('#import_content').removeClass(lastSelected);
							$('#import_content').addClass(lastSelected = name.toLowerCase());

							// Update scrollbars
							if (plugin && plugin.activated) {
								requestAnimationFrame(function() {
									switch(plugin.php_class_name) {
										case 'KokenVimeo':
											self.classes.scrollbar['vimeo-browse-middle-scroll'] && self.classes.scrollbar['vimeo-browse-middle-scroll'].update();
											break;
										case 'KokenInstagram':
											self.classes.scrollbar['inst-browse-middle-scroll'] && self.classes.scrollbar['inst-browse-middle-scroll'].update();
											break;
									}
								});
							}
						}

						$('#upload_visibility').val(self.observers.settings.uploading_default_visibility());

						// Used when route includes instagram to prevent flashing of elements caused by a trigger
						if (_selected.indexOf('instagram') !== -1) {
							$('.option_square_grid').find('.selected').removeClass('selected');
							$('.option_square_grid').find('a.import_inst').closest('li').addClass('selected');

							$('#import_content_url').hide();
							$('#import_upload_content').hide();
							$('.section_selected').hide();

							requestAnimationFrame(function() {
								setSelected('kokeninstagram');
								$('section#import_content_inst').addClass('section_selected').show();
							});
						}
						//

						if (this.observers.properties.album.selected().album && this.observers.properties.album.selected().album.album_type === 'standard') {
							self.observers.shouldUploadToAlbum(true);
							if (self.interfaces.app.controllers.upload.instance) self.interfaces.app.controllers.upload.instance.toAlbum = true;
							$('.al-display', sheet).show();
							$('.al-title', sheet).text('Add to ' + self.observers.truncate(this.observers.properties.album.selected().album.title, 25) + ' after import');
							$('.al-display', sheet).off('.rm').on('click.rm', function(e) {
								if (self.interfaces.app.controllers.upload.instance) self.interfaces.app.controllers.upload.instance.toAlbum = $(this).find('input').is(':checked');
								self.observers.shouldUploadToAlbum($(this).find('input').is(':checked'));
							});
						} else {
							if (self.interfaces.app.controllers.upload.instance) self.interfaces.app.controllers.upload.instance.toAlbum = false;
							self.observers.shouldUploadToAlbum(false);
							$('.al-display', sheet).hide();
						}

						$('#upload_visibility', sheet).off('.uv').on('change.uv', function(e) {
							if (self.interfaces.app.controllers.upload.instance && self.interfaces.app.controllers.upload.instance.settings.multipart_params) self.interfaces.app.controllers.upload.instance.settings.multipart_params.visibility = $(this).find('option:selected').val();
						});

						$('#upload_queued_num').text('0');
						$('#upload-drop-start').show();
						$('#upload-drop-queued').hide();

						// Import service button events
						$('#import_content').off('.importservice').on('click.importservice', '.option_square_grid li', function() {
							// Show/hide selected states for import buttons
							$(this).closest('ul').find('.selected').removeClass('selected');
							$(this).addClass('selected');

							$('.import_sheet_sections input').val('');
							$('button.import').addClass('disabled');
							$('.valerror').removeClass('valerror');
							$('.form-error-msg').hide();
							self.observers.valid(false);

							// Show/hide sections
							$('#import_content_plugin_disabled').hide();
							$('#import_content_plugin_activate').hide();
							$('.section_selected').hide();
							$('#import_content_url').hide();
							$('#import_upload_content').hide();

							var a = $(this).find('a'),
								php = a.data('plugin-php'),
								plugin = false;

							if (php) {
								plugin = self.observers.hasPlugin('php_class_name', php) || {};
							}

							if (plugin.php_class_name) {
								setSelected(plugin.php_class_name, plugin);
							} else if ($(this).find('a').attr('class') === 'import_link') {
								setSelected('importlink');
							} else {
								setSelected('');
							}

							if ($('section.' + a.attr('class')).length) {
								$('section.' + a.attr('class')).show().addClass('section_selected');
							} else if (plugin && !plugin.activated) {
								if (plugin.activated === false) {
									$('#import_content_plugin_activate .title').text(a.data('title'));
									$('#import_content_plugin_activate').show();
									$('#import_content_plugin_activate a').data('php', php);
								} else {
									var	url = a.data('plugin');
									var bttn = $('#import_content_plugin_disabled a');
									$('#import_content_plugin_disabled .title').text(a.data('title'));
									if (url) {
										bttn.attr('href', url).show();
									} else {
										bttn.hide();
									}
									$('#import_content_plugin_disabled').show();
								}
							} else if ($(this).find('a').attr('class') === 'import_link') {
								self.classes.form_validation.library.valid('import_content_url', true);
								$('#import_content_plugin_disabled').hide();
								$('#import_content_plugin_activate').hide();
								$('#import_content_url').show();
							} else {
								$('#import_upload_content').show();
								$('#import_content_plugin_disabled').hide();
								$('#import_content_plugin_activate').hide();
								$('#import_content_url').hide();
								if (self.interfaces.app.controllers.upload.instance.files.length > 0) {
									$('button.import').removeClass('disabled');
									return;
								}
								initUpload();
							}

							self.classes.form_validation.library.valid('import_content', true);

							$(window).trigger('resize');
							return false;
						});

						// Import button click event
						$('#import_content_url input').val('').focus();
						$('#import_content .import').off('.importev').on('click.importev', function(e) {
							if ($(this).hasClass('disabled')) {
								e.preventDefault();
								return false;
							}

							if ($('#import_upload_content:visible').length > 0) return;
							$(this).addClass('disabled');

							var kguid = $('#import_content .option_square_grid li.selected').data('kguid');
							if (!kguid) {
								var stamp = Math.floor(new Date().getTime() / 1000),
									url = $('#import_content_url input').val(),
									n = self.notify().wait('Importing ' + url);

								self.sync({
									request: 'content',
									method: 'POST',
									context: this,
									data: 'from_url=' + encodeURIComponent(url) + '&visibility=' + $('#upload_visibility').val(),
									finished: function(data) {
										if (data.error) {
											n.warn('Error importing file from URL. Check the URL and try again');
										} else {
											n.success('Import complete');

											if ($('#import_tar_coll:checked:visible').length) {
												self.sync({
													request: 'albums/' + self.observers.properties.album.selected().album.id + '/content/' + data.id,
													method: 'POST',
													context: self,
													finished: function(data) {
														this.controllers.load_content(null, true);
														$('#import_content .modal-close').trigger('click');
													}
												});
											} else {
												$.bbq.pushState( '/library/content/order_by:uploaded_on/visibility:any/after:' + stamp + '/selection:' + data.id + '/view:single', 2 );
											}

										}
									}
								});
							} else {
								$.each(self.plugins[kguid].events, function(i, ev) {
									if (ev._type === 'import') {
										ev._callback.call(self.plugins[kguid]);
									}
								});
							}
							return false;
						});

						initUpload();
					},
					onunload: function() {
						$('#upload_visibility option:eq(0)').prop('selected', true);
						$('#import_upload_content').find('.open').removeClass('open');
						$('#import_upload_content [data-event="toggle_visibility"]').next().hide();
						this.classes.channel.library.isUploading = false;
						this.controllers.events_init();
					}
				}
			},
			multiedit: {
				selected: ko.observableArray([]),
				changed: {}
			},
			content: ko.observableArray([]),
			getContentById: function(id) {
				var found;
				self.observers.content().forEach(function(item) {
					if (item.id == id) {
						found = item;
						return false;
					}
				});
				return found;
			},
			content_loading: false
		};

	}

};
	_Koken.prototype.login = {

	init: function() {
		var self = this;
		window.setTimeout(function() {
			if (location.hash === '#/reset') {
				location.hash = '';
				self.observers.message_state('after_reset');
				self.observers.change_state('after_reset');
			} else {
				self.observers.change_state('login');
			}
		}, location.hash === '#/reset' ? 500 : 0);

		var message_interval;

		this.observers.message_state.subscribe( function() {
			clearTimeout(message_interval);
			message_interval = setTimeout(function() {
				$('#help').removeClass('active');
			}, 5000);
		}, this);
	},

	observers: function() {

		return {

			state: ko.observable(''),
			message_state: ko.observable(''),
			message_interval: false,

			change_state: function(new_state) {
				if ($('#login_form').get(0)) {
					$('#login_form')[0].reset();
					$('#reset_form')[0].reset();
					if (new_state === 'after_reset') {
						$('#help').addClass('active');
					} else {
						$('#help').removeClass('active');
					}
					this.observers.state(new_state);

					if (new_state === 'reset' || new_state === 'login') {
						$('#' + new_state + '_form input').first().focus();
					}
				}
			},

			retrieve_password: function() {
				this.observers.change_state('reset');
			},

			back_to_login: function() {
				this.observers.change_state('login');
			},

			reset: function() {

				this.sync({
					request: 'users/reset_password',
					context: this,
					data: $('form#reset_form').serialize(),
					finished: function(data) {
						if ( data && data.success ) {
							this.observers.message_state('reset_init');
							this.observers.change_state('login');
						} else {
							this.observers.message_state('reset_fail');
							$('#reset_form')[0].reset();
							$('#reset_form input:first').focus();
						}
						$('#help').addClass('active');
					}
				});

			},

			login: function() {

				$('#login_submit').addClass('disabled').text('Signing in…');

				this.sync({
					request: 'sessions',
					context: this,
					data: $('#login_form').serialize(),
					finished: function(data) {
						if (data.error) {
							$('#login_submit').removeClass('disabled').text('Sign in');
							this.observers.message_state('login_incorrect');
							$('#help').addClass('active');
							$('#login_form input[name="password"]').val('');
							$('#login_form input:first').focus();
						} else {
							location.reload();
						}
					}
				});

			}

		};

	}

};
	_Koken.prototype.settings = {

	init: function() {

		var self = this;

		// Start listening for sliders
		new this.klass.slider_number_toggle('settings');
		// Start validation listeners
		new this.klass.form_validation('settings');

		// TODO: Namespace all keyboard shortcuts
		$(window).off('keydown');

		$('.help-toggle').bind( 'click', function() {

			var self = $(this);

			if ( ! self.hasClass('open') ) {
				self.addClass('open');
				$('div',this).slideDown();
			} else {
				$('div',this).slideUp( 250, function() {
					self.removeClass('open');
				});
			}

			return false;

		});

		$(document).off('.pluginSettings').on('input.pluginSettings change.pluginSettings', '#plugin_settings .row', function(e) {

			var preview = $(this).find('span.display_value');

			if (preview.length) {
				preview.html($(this).find('input').val());
			}

			$.each(self.observers.edit_plugin_data(), function(i, option) {
				if (option.dependencies) {
					var strict = option.dependencies.evaluate === 'all',
						pass = false;

					$.each(option.dependencies.conditions, function(i, condition) {
						var input = $('#plugin-edit-' + condition.option);
						if (condition.equals !== undefined) {
							pass = condition.equals === ( input.attr('type') === 'checkbox' ? input.is(':checked') : input.val());
						} else if (condition.not !== undefined) {
							pass = condition.not !== ( input.attr('type') === 'checkbox' ? input.is(':checked') : input.val());
						}
						if (pass && !strict || !pass && strict) {
							return false;
						}

					});

					$('#plugin-edit-' + option.key).parents('.row')[pass ? 'show' : 'hide']();
				}
			});

			$('input:visible').trigger('keyup');
		});

		$(document).on('keyup', '.plural', function() {
			var parent = $(this).parents('li'),
				options = parent.find('option'),
				stat = parent.find('.static'),
				val = $(this).val(),
				singular = val.singularize();

			parent.find('.singular').val( singular.length ? singular : val ).trigger('keydown');
		});

		this.observers.console_url( this.config.paths.admin );
		this.observers.base_url( this.config.paths.base );

		this.observers.settings.site_url.subscribe(function(val) {
			this.observers.settings.__site_url_has_changed(true);
			var base = window.location.protocol + '//' + window.location.host + '/';
			if (val.indexOf(base) === -1) {
				this.observers.settings.site_url(base);
			}
		}, this);

		this.observers.state.view.subscribe(function(val) {

			switch(val) {
				case 'console' :
					this.observers.state.view_clean('Console');
					break;
				case 'administrator' :
					this.observers.state.view_clean('Administrator');
					break;
				case 'importing' :
					this.observers.state.view_clean('Importing');
					break;
				case 'publishing' :
					this.observers.state.view_clean('Site publishing');
					break;
				case 'photos' :
					this.observers.state.view_clean('Image publishing');
					break;
				case 'applications' :
					this.observers.state.view_clean('Applications');
					break;
				case 'system' :
					this.observers.state.view_clean('System');
					break;
				case 'plugins' :
					this.observers.state.view_clean('Plugins');
					break;
				case 'email' :
					this.observers.state.view_clean('Email');
					break;
			}

			if (val !== 'plugins' && val !== 'plugins_edit') {
				var self = this;
				self.observers.valid(false);
				$(document)
					.off('.settings_change')
					.on('input.settings_change change.settings_change', '.rangeinput input, select,:checkbox, input[type="radio"], input[type="number"]', function(evt) {
						if (evt.originalEvent) {
							self.observers.valid(true);
						}
					});
			}
		}, this);
		this.observers.state.view.valueHasMutated(); // Forcing handlers in subscribe to setup

		this.observers.settings.image_use_defaults.subscribe(function(val) {
			if (val) {
				var quality_defaults = {
						tiny: 80,
						small: 80,
						medium: 85,
						medium_large: 85,
						large: 85,
						xlarge: 90,
						huge: 90
					},
					sharpening_defaults = {
						tiny: 0.7,
						small: 0.6,
						medium: 0.6,
						medium_large: 0.6,
						large: 0.6,
						xlarge: 0.3,
						huge: 0
					};

				$.each(quality_defaults, function(name, value) {
					self.observers.settings['image_' + name + '_quality'](value);
				});

				$.each(sharpening_defaults, function(name, value) {
					self.observers.settings['image_' + name + '_sharpening'](value);
				});
			}
		}, this);

		var tz = [];
		$.each(moment.tz._zones, function(i, t) {
			var offset = moment().tz(t.name).zone()/60;
				offset_label = '';

			if (offset > 0) {
				offset_label = ' (-' + offset + ' GMT)';
			} else if (offset < 0) {
				offset_label = ' (+' + Math.abs(offset) + ' GMT)';
			}

			offset_label = offset_label.replace('.5', ':30').replace('.75', ':45');

			tz.push({
				value: t.name,
				label: t.name.replace('_', ' ') + offset_label
			});
		});

		this.observers.timezones = tz;

		this.sync({
			request: 'auth',
			context: this,
			finished: function(data) {
				this.observers.applications(data.applications);
			}
		});

		this.observers.settings.use_default_labels_links.subscribe(function(val) {
			if (val) {
				var defaults = {
					content: {
						singular: 'Content',
						plural: 'Content',
						url: 'slug'
					},
					album: {
						singular: 'Album',
						plural: 'Albums',
						url: 'slug'
					},
					set: {
						singular: 'Set',
						plural: 'Sets'
					},
					essay: {
						singular: 'Essay',
						plural: 'Essays',
						url: 'date+slug'
					},
					page: {
						singular: 'Page',
						plural: 'Pages',
						url: 'slug'
					},
					tag: {
						singular: 'Tag',
						plural: 'Tags'
					},
					category: {
						singular: 'Category',
						plural: 'Categories'
					},
					timeline: {
						singular: 'Timeline',
						plural: 'Timeline'
					},
					home: 'Home'
				};

				$.each(defaults, function(key, info) {
					var o = self.observers.url_data[key];
					if (key === 'home') {
						o(info);
					} else {
						o.singular(info.singular);
						o.plural(info.plural);
					}
					if (info.url) {
						o.url(info.url);
					}
				});
			}
		}, this);

		$(document).on('change', 'select.update-observer', function() {
			self.observers.url_data[$(this).attr('data-obs')].url( $(this).val() );
		});

		$(document).trigger('settings.loaded');

	},

	events: {

		clear_image_cache: function() {
			var n = this.notify();
			n.wait('Clearing image cache');
			this.sync({
				request: 'content/cache',
				method: 'DELETE',
				context: this,
				finished: function(data) {
					n.success('Image cache cleared');
					try {
						localStorage.clear();
					} catch (e) {}
				}
			});
		},

		clear_system_cache: function() {
			var n = this.notify();
			n.wait('Clearing system caches');
			this.sync({
				request: 'update/migrate/schema',
				method: 'POST',
				context: this,
				finished: function(data) {
					this.sync({
						request: 'system/clear_caches',
						method: 'POST',
						context: this,
						finished: function(data) {
							n.success('System caches cleared');
							try {
								localStorage.clear();
							} catch (e) {}
						}
					});
				}
			});
		},

		build_image_cache: function() {
			var _this = this;
			var n = this.notify();
			_n = n.wait('Building image cache');

			(function _next(page) {
				this.sync({
					request: 'content/cache/page:' + page,
					method: 'POST',
					context: this,
					finished: function(data) {
						if (data.next_page) {
							var pct = parseInt((data.next_page - 1) / data.total_pages * 100);

							_n.wait('Building image cache ' + pct + '% complete');
							_next.call(this, data.next_page);
						} else {
							n.success('Image cache built');
						}
					}
				});
			}).call(this, 1);
		},

		create_api_token: function() {
			var o = this.observers.sheets.new_api_token,
				notify = this.notify();

			notify.wait('Creating');

			this.sync({
				request: 'auth',
				method: 'POST',
				data: "name=" + o.name() + '&role=' + o.role(),
				context: this,
				finished: function(data) {
					notify.success('Token successfully created');
					this.events.toggle_sheet('new_api_token');
					this.observers.applications( data.applications );
				}
			});
		},

		toggle_plugin: function(el) {
			this.controllers.toggle_plugin(ko.dataFor(el[0]));
		},

		deny_app_access: function() {
			var cb = this.observers.application_request.callback();

			if (cb === 'oob') {
				$.bbq.pushState('/settings/applications', 2);
			} else {
				location.href = cb;
			}
		},

		revoke_app_access: function(el, parent) {
			var d = ko.dataFor(el[0]),
				notify = this.notify();

			notify.wait('Revoking');

			this.sync({
				request: 'auth/' + d.id,
				method: 'DELETE',
				context: this,
				finished: function(data) {
					this.observers.applications( data.applications );
					notify.success(d.name + ' access has been revoked');
				}
			});
		},

		grant_app_access: function() {

			var props = [];
			$.each( this.observers.application_request, function(i, obs) {
				if (i === 'callback' || i === 'active') return;
				props.push( i + '=' + obs() );
			});

			props.push('user_id=' + this.session().user.id);

			this.sync({
				request: 'auth/grant',
				method: 'POST',
				data: props.join('&'),
				context: this,
				finished: function(data) {
					var cb = this.observers.application_request.callback();
					this.observers.applications( data.applications );
					if (cb === 'oob') {
						$.bbq.pushState('/settings/applications/granted', 2);
					} else {
						location.href = cb;
					}
				}
			});
		},

		send_test_email: function() {
			var delivery_address = this.observers.settings.email_delivery_address(),
				sender = this.observers.settings.email_handler();

			var n = this.notify().wait('Sending test email');

			this.sync({
				request: 'settings/test_email',
				method: 'POST',
				data: 'delivery_address=' + encodeURIComponent(delivery_address) + '&sender=' + sender,
				finished: function() {
					n.success('Test email sent to ' + delivery_address);
				}
			});
		},

		save_plugin: function() {
			var payload = [],
				valid = true,
				new_data = [],
				self = this;

			$.each( this.observers.edit_plugin_data(), function(i, data) {
				var val;
				if (data.type === 'hidden') {
					val = data.value;
				} else {
					var input = $('#plugin-edit-' + data.key);
					val = input.val();

					if (input.attr('type') === 'checkbox') {
						val = input.is(':checked');
					} else {
						val = val || '';
						val = val.toString().trim();
					}
				}

				new_data.push( $.extend({}, data, { value: val }));
				payload.push(data.key + '=' + encodeURIComponent(val));
			});

			if (valid) {
				var plugin = ko.utils.arrayFirst( this.observers.plugins(), function(item) {
					return item.path == self.observers.edit_plugin().path;
				});

				plugin.data = new_data;

				var notify = this.notify();

				notify.wait('Saving');

				this.sync({
					request: 'plugins/' + this.observers.edit_plugin().id,
					method: 'PUT',
					data: payload.join('&'),
					context: this,
					finished: function(data) {
						if (data && data.error) {
							this.observers.edit_plugin_error(data.error);
							notify.warn('Settings did not save.');
						} else {
							plugin.setup = true;
							this.observers.edit_plugin_error(false);
							notify.success('Settings successfully saved');
							location.hash = '#/settings/plugins';

							this.sync({
								request: 'settings',
								context: this,
								finished: function(data) {
									this.observers.reset_settings(data);
								}
							});
						}
					}
				});
			}
		},

		save: function() {
			var props = [],
				self = this,
				valid = true;

			$.each( this.observers.settings, function(i, obs) {
				if (i === '__site_url_has_changed') return true;

				var val = obs();
				if (i === 'site_url') {
					if (self.observers.settings.__site_url_has_changed()) {
						val = val.replace(window.location.protocol + '//' + window.location.host, '');
					} else {
						return true;
					}
				}
				if (!/object object/i.test(val)) {
					props.push( i + '=' + encodeURIComponent(val) );
				}
			});

			$.each(this.observers.url_data, function(key, data) {
				if (data.plural && data.plural().toLowerCase() === 'admin') {
					self.notify().warn('"Admin" is a reserved path and cannot be used for URLs. Please choose another label.');
					valid = false;
				}
			});

			if (!valid) { return; }

			props.push('url_data=' + encodeURIComponent(JSON.stringify(this.unmap(this.observers.url_data))));

			var notify = this.notify();
			notify.wait('Saving');

			this.sync({
				request: 'settings',
				method: 'PUT',
				data: props.join('&'),
				context: this,
				finished: function(data) {

					if (data.error) {
						this.notify().warn(data.error);
						$('#Publishing input:first').get(0).focus();
					} else {
						notify.success('Settings successfully saved');
						this.observers.reset_settings(data);
						this.observers.valid(false);
						try {
							localStorage.clear();
						} catch (e) {}
					}

				}
			});
		},

		save_user: function() {

			var old = $('#oldpw').val(),
				new_pwd = $('#pw1').val(),
				confirm_pwd = $('#pw2').val(),
				save_password = false;

			var save = $.proxy( function() {

				var props = [];
				$.each( this.observers.user, function(i, obs) {
					if (/_on$/.test(i)) return;

					if (typeof obs() === 'string') {
						props.push( i + '=' + encodeURIComponent(obs()) );
					}
				});

				if (save_password) {
					props.push('password=' + encodeURIComponent(save_password));
				}

				var notify = this.notify();
				notify.wait('Saving');

				this.sync({
					request: 'users/' + this.observers.user.id(),
					method: 'PUT',
					data: props.join('&'),
					context: this,
					finished: function(data) {
						notify.success('Administrator information saved');
						var old = this._session;
						old.user = data;
						this.session( old );
						this.map( this.observers.user, data);
						this.observers.valid(false);
					}
				});
			}, this);

			if (old.length || new_pwd.length || confirm_pwd.length) {
				if (!old.length) {
					$('#pwd_error').text('To change your password you must first enter your current password.').show();
					$('#oldpw')[0].focus();
					return;
				}

				if (!new_pwd.length) {
					$('#pwd_error').text('Please enter a new password.').show();
					$('#pw1')[0].focus();
					return;
				}

				if (new_pwd !== confirm_pwd) {
					$('#pwd_error').text('Your new password must match in both new password fields.').show();
					$('#pw2')[0].focus();
					return;
				}

				this.sync({
					request: 'users/verify_password',
					method: 'POST',
					data: 'password=' + encodeURIComponent(old),
					context: this,
					finished: function(data) {
						if ( data && data.error ) {
							$('#pwd_error').text('The current password you entered is not correct.').show();
							$('#oldpw')[0].focus();
						} else {
							$('#pwd_error').hide();
							save_password = new_pwd;
							save();
						}
					}
				});
			} else {
				$('#pwd_error').hide();
				save();
			}

		},

		help: function(el,parent) {

			if ( ! parent.hasClass('open') ) {
				parent.addClass('open');
				parent.parent().find('div').slideDown();
			} else {
				parent.parent().find('div').slideUp( 250, function() {
					parent.removeClass('open');
				});
			}

		},

		toggle_license: function(el,parent) {

			var commons = $('#commons_group');

			if ( el.is(':checked') ) {
				this.observers.settings.uploading_default_license('n,n');

				// Because KO keeps wanting to override this checkbox with its own internal checking we are circumventing it
				if (this.observers.settings.uploading_default_license().substr(0,1) === 'n' || this.observers.settings.uploading_default_license() == 'all') {
					$('#l_n2').prop('checked', true);
				} else {
					$('#l_n2').prop('checked', false);
				}
			} else {
				this.observers.settings.uploading_default_license('all');
			}

		},

		commons_change: function(el, parent) {
			this.observers.settings.uploading_default_license(
				parent.find('input[name=lic2]:checked').val() + ',' + parent.find('input[name=lic3]:checked').val()
			);
		}

	},

	router: function() {

		return {

			'(console|administrator|importing|publishing|photos|applications|plugins|email|system|cache|advanced)(\/(.*))?': function(matches) {

				if (matches[1] === 'system' && this.observers.settings.disable_system_info && this.observers.settings.disable_system_info()) {
					$.bbq.pushState('/settings', 2);
					return;
				}

				if (matches[1] === 'applications' && matches[2]) {
					if (matches[2] === '/granted') {
						this.observers.application_request.active(false);
						this.observers.application_request.complete(true);
					} else {
						var r = /([a-z_]+):([^\/]+)/g;

						while (( param = r.exec(matches[2])) !== null ) {
							this.observers.application_request[param[1]]( decodeURIComponent(param[2]) );
						}
						this.observers.application_request.active(true);
						this.observers.application_request.complete(false);
					}
				} else {
					this.observers.application_request.active(false);
					this.observers.application_request.complete(false);
				}

				if (matches[1] === 'plugins' && matches[2]) {
					if (matches[2].indexOf('/install/') === 0 && /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/.test(matches[2])) {
						this.observers.state.view('plugins');

						var self = this,
							uri = matches[2].replace('/install/', '').split(':'),
							slug = uri[0],
							guids = uri[1].split('/'),
							guid = guids[0],
							purchase = guids.length > 1 ? '&purchase=' + guids[1] : '',
							path = 'plugins/' + slug + '-' + guid,
							n = '&new=1';

						$.each(this.observers.user_plugins(), function(i, plugin) {
							if (plugin.koken_store_guid === guid) {
								path = 'plugins/' + plugin.path + (plugin.id ? '&id=' + plugin.id : '');
								n = '';
								return false;
							}
						});

						setTimeout( $.proxy(function() {
							this.classes.modal.install_plugin.show();

							this.sync({
								request: 'update/plugin',
								method: 'POST',
								context: this,
								data: 'local_path=' + path + '&guid=' + guid + '&uuid=' + this.observers.settings.uuid() + n + purchase,
								finished: function(data) {
									if (data.done) {
										this.sync({
											request: 'plugins',
											context: this,
											finished: function(d) {
												this.observers.process_plugin_data(d, true);
												// Not using bbq here as it doesn't have a replaceState
												// and we don't want the install URL in the history
												// TODO: still ends up in lastHash, which can cause probs.
												history.replaceState('', '', '#/settings/plugins');
												this.classes.modal.install_plugin.hide();
												this.notify().success(data.info.name + ' installed');

												this.store_api({
													action: 'plugin_installed',
													guid: guid,
													version: data.info.version
												});
											}
										});
									} else {
										// TODO
									}
								}
							});
						}, this), 500);

						return;
					} else {
						var plugin = ko.utils.arrayFirst( this.observers.plugins(), function(item) {
							return item.path == matches[2].substr(1);
						});
						if (plugin) {
							this.observers.edit_plugin(plugin);
							this.observers.edit_plugin_data(plugin.data || []);
							this.observers.edit_plugin_error(false);

							// Check if plugin has validations to determine if the save button
							// should be enabled/disabled on page load
							var doesNotHaveValidations = true;
							$.each(plugin.data, function(i,plugin) {
								if (plugin.validation) {
									doesNotHaveValidations = false;
								}
							});
							this.observers.valid(doesNotHaveValidations);
						} else {
							location.hash = '#/settings/plugins';
						}
					}
				} else {
					this.observers.edit_plugin(false);
					this.observers.edit_plugin_data([]);
				}

				if (matches[1] === 'plugins' && matches[2]) {
					this.observers.state.view('plugins_edit');
					$('#container').trigger('settings.edit.' + plugin.php_class_name);
					if ($('#plugin_settings input').length) {
						$('#plugin_settings input:first').trigger('change');

						$('input[type="ccolor"]').spectrum({
							showInput: true,
							hide: function(color) {
								$(this).trigger('change');
							}
						});

						if ($('.plugin-upload').length) {
							this.interfaces.app.controllers.plugin_upload.init.call(this.interfaces.settings, $('.plugin-upload'));
						}
					}
				} else {
					this.observers.state.view(matches[1]);
				}

				$(window).trigger('resize');

			}

		};

	},

	observers: function() {

		return {

			date: function(format, timestamp) {
				var that = this,
					jsdate, f, formatChr = /\\?([a-z])/gi,
					formatChrCb,
					// Keep this here (works, but for code commented-out
					// below for file size reasons)
					//, tal= [],
					_pad = function (n, c) {
						if ((n = n + '').length < c) {
							return new Array((++c) - n.length).join('0') + n;
						}
						return n;
					},
					txt_words = ["Sun", "Mon", "Tues", "Wednes", "Thurs", "Fri", "Satur", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				formatChrCb = function (t, s) {
					return f[t] ? f[t]() : s;
				};
				f = {
					// Day
					d: function () { // Day of month w/leading 0; 01..31
						return _pad(f.j(), 2);
					},
					D: function () { // Shorthand day name; Mon...Sun
						return f.l().slice(0, 3);
					},
					j: function () { // Day of month; 1..31
						return jsdate.getDate();
					},
					l: function () { // Full day name; Monday...Sunday
						return txt_words[f.w()] + 'day';
					},
					N: function () { // ISO-8601 day of week; 1[Mon]..7[Sun]
						return f.w() || 7;
					},
					S: function () { // Ordinal suffix for day of month; st, nd, rd, th
						var j = f.j();
						return j < 4 | j > 20 && ['st', 'nd', 'rd'][j%10 - 1] || 'th';
					},
					w: function () { // Day of week; 0[Sun]..6[Sat]
						return jsdate.getDay();
					},
					z: function () { // Day of year; 0..365
						var a = new Date(f.Y(), f.n() - 1, f.j()),
							b = new Date(f.Y(), 0, 1);
						return Math.round((a - b) / 864e5) + 1;
					},

					// Week
					W: function () { // ISO-8601 week number
						var a = new Date(f.Y(), f.n() - 1, f.j() - f.N() + 3),
							b = new Date(a.getFullYear(), 0, 4);
						return _pad(1 + Math.round((a - b) / 864e5 / 7), 2);
					},

					// Month
					F: function () { // Full month name; January...December
						return txt_words[6 + f.n()];
					},
					m: function () { // Month w/leading 0; 01...12
						return _pad(f.n(), 2);
					},
					M: function () { // Shorthand month name; Jan...Dec
						return f.F().slice(0, 3);
					},
					n: function () { // Month; 1...12
						return jsdate.getMonth() + 1;
					},
					t: function () { // Days in month; 28...31
						return (new Date(f.Y(), f.n(), 0)).getDate();
					},

					// Year
					L: function () { // Is leap year?; 0 or 1
						var j = f.Y();
						return j%4==0 & j%100!=0 | j%400==0;
					},
					o: function () { // ISO-8601 year
						var n = f.n(),
							W = f.W(),
							Y = f.Y();
						return Y + (n === 12 && W < 9 ? -1 : n === 1 && W > 9);
					},
					Y: function () { // Full year; e.g. 1980...2010
						return jsdate.getFullYear();
					},
					y: function () { // Last two digits of year; 00...99
						return (f.Y() + "").slice(-2);
					},

					// Time
					a: function () { // am or pm
						return jsdate.getHours() > 11 ? "pm" : "am";
					},
					A: function () { // AM or PM
						return f.a().toUpperCase();
					},
					B: function () { // Swatch Internet time; 000..999
						var H = jsdate.getUTCHours() * 36e2,
							// Hours
							i = jsdate.getUTCMinutes() * 60,
							// Minutes
							s = jsdate.getUTCSeconds(); // Seconds
						return _pad(Math.floor((H + i + s + 36e2) / 86.4) % 1e3, 3);
					},
					g: function () { // 12-Hours; 1..12
						return f.G() % 12 || 12;
					},
					G: function () { // 24-Hours; 0..23
						return jsdate.getHours();
					},
					h: function () { // 12-Hours w/leading 0; 01..12
						return _pad(f.g(), 2);
					},
					H: function () { // 24-Hours w/leading 0; 00..23
						return _pad(f.G(), 2);
					},
					i: function () { // Minutes w/leading 0; 00..59
						return _pad(jsdate.getMinutes(), 2);
					},
					s: function () { // Seconds w/leading 0; 00..59
						return _pad(jsdate.getSeconds(), 2);
					},
					u: function () { // Microseconds; 000000-999000
						return _pad(jsdate.getMilliseconds() * 1000, 6);
					},

					// Timezone
					e: function () { // Timezone identifier; e.g. Atlantic/Azores, ...
						// The following works, but requires inclusion of the very large
						// timezone_abbreviations_list() function.
			/*              return that.date_default_timezone_get();
			*/
						throw 'Not supported (see source code of date() for timezone on how to add support)';
					},
					I: function () { // DST observed?; 0 or 1
						// Compares Jan 1 minus Jan 1 UTC to Jul 1 minus Jul 1 UTC.
						// If they are not equal, then DST is observed.
						var a = new Date(f.Y(), 0),
							// Jan 1
							c = Date.UTC(f.Y(), 0),
							// Jan 1 UTC
							b = new Date(f.Y(), 6),
							// Jul 1
							d = Date.UTC(f.Y(), 6); // Jul 1 UTC
						return 0 + ((a - c) !== (b - d));
					},
					O: function () { // Difference to GMT in hour format; e.g. +0200
						var tzo = jsdate.getTimezoneOffset(),
							a = Math.abs(tzo);
						return (tzo > 0 ? "-" : "+") + _pad(Math.floor(a / 60) * 100 + a % 60, 4);
					},
					P: function () { // Difference to GMT w/colon; e.g. +02:00
						var O = f.O();
						return (O.substr(0, 3) + ":" + O.substr(3, 2));
					},
					T: function () { // Timezone abbreviation; e.g. EST, MDT, ...
						// The following works, but requires inclusion of the very
						// large timezone_abbreviations_list() function.
			/*              var abbr = '', i = 0, os = 0, default = 0;
						if (!tal.length) {
							tal = that.timezone_abbreviations_list();
						}
						if (that.php_js && that.php_js.default_timezone) {
							default = that.php_js.default_timezone;
							for (abbr in tal) {
								for (i=0; i < tal[abbr].length; i++) {
									if (tal[abbr][i].timezone_id === default) {
										return abbr.toUpperCase();
									}
								}
							}
						}
						for (abbr in tal) {
							for (i = 0; i < tal[abbr].length; i++) {
								os = -jsdate.getTimezoneOffset() * 60;
								if (tal[abbr][i].offset === os) {
									return abbr.toUpperCase();
								}
							}
						}
			*/
						return 'UTC';
					},
					Z: function () { // Timezone offset in seconds (-43200...50400)
						return -jsdate.getTimezoneOffset() * 60;
					},

					// Full Date/Time
					c: function () { // ISO-8601 date.
						return 'Y-m-d\\TH:i:sP'.replace(formatChr, formatChrCb);
					},
					r: function () { // RFC 2822
						return 'D, d M Y H:i:s O'.replace(formatChr, formatChrCb);
					},
					U: function () { // Seconds since UNIX epoch
						return jsdate / 1000 | 0;
					}
				};
				this.date = function (format, timestamp) {
					that = this;
					jsdate = (timestamp == null ? new Date() : // Not provided
						(timestamp instanceof Date) ? new Date(timestamp) : // JS Date()
						new Date(timestamp * 1000) // UNIX timestamp (auto-convert to int)
					);
					return format.replace(formatChr, formatChrCb);
				};
				return this.date(format, timestamp);
			},
			console_url: ko.observable(''),
			base_url: ko.observable(''),
			applications: ko.observableArray([]),
			edit_plugin: ko.observable(false),
			edit_plugin_error: ko.observable(false),
			edit_plugin_data: ko.observableArray([]),
			date_options: [
				'F j, Y',
				'j F Y',
				'Y/m/d',
				'm/d/Y',
				'd/m/Y'
			],
			time_options: [
				'g:i a',
				'g:i A',
				'H:i'
			],
			state: {
				view: ko.observable('console'),
				view_clean: ko.observable('Console')
			},

			application_request: {
				name: ko.observable(''),
				role: ko.observable(''),
				callback: ko.observable(''),
				nonce: ko.observable(''),
				active: ko.observable(false),
				complete: ko.observable(false)
			},

			sheets: {
				new_api_token: {
					active: ko.observable(false),
					id: ko.observable('new_api_token'),
					name: ko.observable(''),
					role: ko.observable('read'),
					reset: function() {
						this.name('');
						this.role('read');
					}
				}
			},

			date_is_custom: ko.observable(false),
			time_is_custom: ko.observable(false),

			custom_time_change: function(obs, el) {
				obs.time_is_custom(true);
				var val = $(el.target).val();

				if (val.length) {
					obs.settings.site_time_format( val );
					$('#custom_time_format_preview').text( obs.date(val) );
				}
			},

			time_change: function(obs, el) {
				var val = $(el.target).val(),
					custom = $('#custom_time_format').val() || '';
				if (val === 'custom') {
					if (custom.length) {
						obs.settings.site_time_format( custom );
					}
					$(el.target).parents('ol').find('li:last').show();
					obs.time_is_custom(true);
				} else {
					$(el.target).parents('ol').find('li:last').hide();
					obs.settings.site_time_format( val );
					obs.time_is_custom(false);
				}
			},

			custom_date_change: function(obs, el) {
				obs.date_is_custom(true);
				var val = $(el.target).val();

				if (val.length) {
					obs.settings.site_date_format( val );
					$('#custom_date_format_preview').text( obs.date(val) );
				}
			},

			date_change: function(obs, el) {
				var val = $(el.target).val(),
					custom = $('#custom_date_format').val() || '';

				if (val === 'custom') {
					if (custom.length) {
						obs.settings.site_date_format( custom );
					}
					$(el.target).parents('ol').find('li:last').show();
					obs.date_is_custom(true);
				} else {
					$(el.target).parents('ol').find('li:last').hide();
					obs.settings.site_date_format( val );
					obs.date_is_custom(false);
				}
			},
			remove_selected: function(a) {
				// Stupid hack to remove empty selected statements in inputs that KO insists on putting in
				$('[selected=""]').removeAttr('selected');
			}
		};

	}

};

	_Koken.prototype.site = {

	init: function() {

		var self		= this,
			firstRun	= true;

		// Start listening for sliders
		new this.klass.slider_number_toggle('site');
		// Start validation listeners
		new this.klass.form_validation('site');
		// Start autocomplete
		new this.klass.autocomplete('site');

		// TODO: Namespace all keyboard shortcuts
		$(window).off('keydown');

		$(window).on('keydown', function(e) {

			if (e.target.tagName === 'INPUT') return;

			switch( e.which ) {

				case 83 :
					if ( e.metaKey || e.ctrlKey ) {
						$('a[data-state="settings_panel"]').first().trigger('click');
						e.preventDefault();
					}
					break;

				case 39 :
					$('a.fwd').trigger('click');
					break;

				case 37 :
					$('a.back').trigger('click');
					break;

			}

		});

		// Exposing functions to window for access from iframe
		window.__koken__ = {
			shortcuts: function(e) {
				var ev = $.Event('keydown',e);
				$(window).trigger(e);
			},
			panel: function() {
				$('#siteui-panel-settings').removeClass('move_offscreen');
			}
		};

		var _self = this;

		var run_expand = function(val) {

			var self = this;

			if (!val) {

				var panel	= $('#siteui-panel-settings'),
					left	= parseInt( panel.css('left'), 10 );

				if ( !isNaN(left) && ( left + panel.width() ) > ( $('#siteui-iframe').width() - 200 ) ) {
					panel.css({
						left: 'auto',
						right: 0
					});
				}
			}

			if (val) {
				$('#app').addClass('site-expand');
			} else {
				$('#app').removeClass('site-expand');
			}

			if (_self.classes.editor && _self.classes.editor.css) {
				requestAnimationFrame(function() {
					self.classes.editor.css.editorWindow.focus();
					self.classes.editor.css.editorWindow.resize(true);
				});
			}

		};

		var isNavBeingDragged;
		$('body')
			.on( 'mouseover', '.lib-nav li', function(e) {
				if (isNavBeingDragged) { return; }
				$(e.target).closest('li').find('.item-edit').show().addClass('edit-hoverable');
				$(e.target).closest('li').find('a.item').first().addClass('hoverable');
			})
			.on( 'mouseout', '.lib-nav li', function(e) {
				if (isNavBeingDragged) { return; }
				if ( $('#gear_pop').css('display') !== 'block' || $(e.target).closest('li').attr('id') != $('#gear_pop').data('id') ) {
					$(e.target).closest('li').find('.item-edit').show().removeClass('edit-hoverable');
					$(e.target).closest('li').find('a.item').first().removeClass('hoverable');
				}
			})
			.on( 'mousedown', '.lib-nav li', function(e) {
				isNavBeingDragged = true;
			})
			.on ( 'mouseup', '.lib-nav li', function(e) {
				isNavBeingDragged = false;
			});

		function settings_visibility(skip_deps) {
			skip_deps = skip_deps || false;
			var els = skip_deps ? $('.group-wrap:not(.dependent)') : $('.group-wrap');
			els.show().each( function(i, g) {
				var el = $(g);
				if (!el.find('div.theme_option').filter(function() {
					return $(this).css('display') !== 'none';
				}).length) {
					el.hide();
				} else {
					el.show();
				}
			});
			if (!$('#site-inspector .scroll_content div.group-wrap:visible').length) {
				$('#empty_settings').show();
			} else {
				$('#empty_settings').hide();
			}
			$(window).trigger('resize');
		}

		$(document).off('previewdomready').on('previewdomready', function(e) {

			var win = $(document.getElementById('siteui-iframe').contentDocument),
				pulses = win.find('[data-pulse-group]');

			if ( self.cookie('editor:state') && self.cookie('editor:state') === 'on' && $('.ace_editor').length <= 0 ) {
				$('a.button-ui[data-event="toggle_css_editor"]').trigger('click');
			}

			$.each(pulses, function(i, pulse) {

				var gear = $('<div/>').on('click', function() {

					self.observers.state.settings_panel(false);
					win.find('[data-pulse-group] div.cover').removeClass('active');

					if ( self.observers.sitepanel_pulse.title() == $(pulse).data('pulse-group') ) {
						self.observers.sitepanel_pulse.title('');
						self.observers.sitepanel_pulse.active( false );
					} else {
						self.observers.sitepanel_pulse.active( true );
						self.observers.sitepanel_pulse.id( $(pulse).attr('id') );
						self.observers.sitepanel_pulse.title( $(pulse).data('pulse-group') );
						$(this).parent().addClass('active');

						var $K = document.getElementById('siteui-iframe').contentWindow.$K,
							overrides = $K.pulse.groups[$(pulse).data('pulse-group')];

						self.observers.override_pulse_settings(overrides);

						check_dependencies();
					}

					return false;
				});

				$(pulse).find('div.cover').remove();

				$('<div/>')
					.addClass('cover')
					.append(gear)
					.appendTo(pulse);

			});
		});

		function check_dependencies() {

			settings_visibility();

			$('div.plugin-enabler').each(function(i, div) {
				var $div = $(div);
				if ($div.find('input').is(':checked')) {
					$div.siblings('div.theme_option').show();
				} else {
					$div.siblings('div.theme_option').hide();
				}
			});

			if ($('#pulse_option_transition_type').is(':visible')) {
				var val = $('#pulse_option_transition_type').val();
				$('#pulse_option_transition_easing').parents('.theme_option')[ $.inArray( val, self.observers.pulse_transitions_that_ease() ) === -1 ? 'hide' : 'show' ]();
			}

			$('div.dependent').each( function(i, d) {

				var data = ko.dataFor(d),
					el = $(d),
					show = true;

				if (data.setting.scope && $.inArray(self.observers.state.loaded_page().path, data.setting.scope) === -1) {
					show = false;
				} else if (data.setting.dependencies && data.setting.dependencies.conditions) {
					var all = data.setting.dependencies.evaluate === "all";

					$.each( data.setting.dependencies.conditions, function(i, d) {

						var c = $('#' + data.prefix + 'option_' + d.option);

						if (!c.length) {
							c = $('#' + data.prefix + 'option___scoped_' + self.observers.state.loaded_page().path.replace('.', '-') + '_' + d.option);
							if (!c.length) {
								return;
							}
						}

						val = c.val();
						if (c.attr('type') === 'checkbox') {
							val = c.is(':checked');
						}

						var compare = ( d.hasOwnProperty('equals') && d.equals ) || ( d.hasOwnProperty('not') && d.not ) || ( d.hasOwnProperty('greater_than') && d.greater_than ) || ( d.hasOwnProperty('less_than') && d.less_than );

						if (compare === 'true') {
							compare = true;
						} else if (compare === 'false') {
							compare = false;
						}

						if (c.is(':visible') && (d.hasOwnProperty('equals') && val == compare ||
														d.hasOwnProperty('not') && val != compare ||
														d.hasOwnProperty('greater_than') && val > compare ||
														d.hasOwnProperty('less_than') && val < compare)) {
							show = true;
						} else {
							show = false;
						}

						if (show && !all || !show && all) {
							return false;
						}
					});

				}

				if (show) {
					el.show();
				} else {
					el.hide();
				}
			});


			settings_visibility(true);
		}

		$(document).off('previewready').on('previewready', function(e, url) {

			$('.sp-container').remove();
			url = url || false;
			var siteframe	= $('#siteui-iframe'),
				iframe		= document.getElementById('siteui-iframe').contentWindow;

			if (url) {
				if (self.observers.state.iframe.cache) {
					self.observers.state.iframe.history.push(url);
					if ( self.observers.state.iframe.updateloc && ! firstRun ) {
						self.observers.state.iframe.location( self.observers.state.iframe.location() + 1 );
						self.observers.state.iframe.historycache().push(url);
					}
					if ( firstRun ) {
						self.observers.state.iframe.historycache([url]);
						firstRun = false;
					}
					self.observers.state.iframe.updateloc = true;
				}
				// Need to force a switch here so that path updates on a page refresh (settings change, etc)
				self.observers.state.iframe.path(false);
				self.observers.state.iframe.path( url.replace(/&rand=[0-9]+/, '') );
			}

			check_dependencies();

			siteframe.show();
			$('#siteui-frame').removeClass('loading');

			$( iframe.document ).bind('click', function() {

				// Hide the gear menu if its open when the iframe is clicked
				if ( $('.lib-nav').find('.hoverable').length > 0 ) {
					$(document).trigger('click.outside');
				}

				if ( self.observers.state.iframe.path() !== url ) {
					self.observers.state.iframe.cache = true;
				}

			});

			new self.klass.colorpicker('site', $('input[type="ccolor"]'));

			$('#siteui-panel-settings').draggable({
				handle: '.panel-handle h5',
				iframeFix: true,
				containment: 'parent'
			});

			$('#siteui-panel-pulse .modal-close').on('click', function() {
				self.observers.sitepanel_pulse.active(false);
			});

			$('#siteui-panel-pulse').draggable({
				handle: '.panel-handle h5',
				iframeFix: true,
				containment: 'parent'
			});

		});

		$(document).off('.siteinspector').on('keydown.siteinspector', '#site-inspector input', function(e) {
			if (e.which === 13) {
				$(this).blur();
				return false;
			}
		});

		var range_listen = false;

		// Listen for site settings and save when changed
		$(document).off('.settings').on('input.settings change_trigger.settings change.settings mouseup.settings colorupdate.settings', 'div.theme_option input, div.theme_option select', function(e) {

			if ( $(e.target).attr('type') === 'text' && e.type !== 'change_trigger' ) {
				var el = $(e.target);

				if (el.data('timeout_id')) {
					clearTimeout(el.data('timeout_id'));
				}

				el.data('timeout_id', setTimeout(function() {
					el.trigger('change_trigger');
				}, 2000));
			}

			if (Object.keys(self.observers.site.settings_flat).length === 0) return;

			clearTimeout(range_listen);
			if (e.type === 'mouseup' && (e.target.tagName.toLowerCase() === 'select' || (e.target.tagName.toLowerCase() === 'input' && e.target.type === 'checkbox'))) {
				return;
			}

			// If an input with type="number" stop
			if ( $(e.target).attr('type') === 'number' ) {
				return;
			}

			var n = $(this).attr('name'),
				win = document.getElementById('siteui-iframe').contentDocument,
				live_updates = n === '__style' ? 'reload' : self.observers.site.live_updates && (!self.observers.site.settings_flat[n] || !self.observers.site.settings_flat[n].reload_preview) && self.observers.site.live_updates[n] && self.observers.site.live_updates[n](),
				save = true,
				li = $(this).parents('div.theme_option'),
				is_pulse = $(this).attr('id') && $(this).attr('id').indexOf('pulse') === 0,
				v;

			if (this.type === 'checkbox') {
				var data = ko.dataFor(this);
				if (data.setting.values) {
					v = $(this).is(':checked') ? data.setting.values['true'] : data.setting.values['false'];
				} else {
					v = $(this).is(':checked');
				}
			} else {
				v = $(this).val();
			}

			if (n === '__style') {

				var current = self.observers.site.style.key(),
					current_style_defaults = [],
					new_style_variables;

				$.each(self.observers.site.styles(), function(i, style) {
					if (style.key === v) {
						new_style_variables = style.variables || {};
					} else if (style.key === current) {
						current_style_defaults = style.variables || {};
					}
				});

				$.each(self.observers.styled_settings, function(i, setting) {

					var el = $('[name=' + setting + ']'),
						el_val = el.val();
						val = false,
						current_style_default = current_style_defaults[setting] || false;

					if (current_style_default && typeof(current_style_default) === 'string' && ((m = current_style_default.match(/^#([a-z0-9]{3})$/)))) {
						current_style_default += m[1];
					}

					if (el.length && (m = el_val.match(/^#([a-z0-9]{3})$/))) {
						el_val += m[1];
					}

					if (new_style_variables[setting] !== undefined) {
						val = new_style_variables[setting];
					} else if (!current_style_default || el_val === current_style_default || Number(el_val) === current_style_default) {
						val = el.data('default-value');
					}

					if (val !== false) {

						if (el.is(':checkbox')) {
							if (val === true || val === el.data('true-val')) {
								el.attr('checked', true);
							} else {
								el.attr('checked', false);
							}
						} else {
							if (typeof val === 'string' && val.indexOf('rgba') === 0) {
								var alpha,
									color = val.replace('rgba', 'rgb').replace(/,\s?([0-9\.]+)\)$/, function(match) {
										alpha = match.replace(/[^0-9\.]/g, '')*100;
										return ')';
									});

								var alphaInput = el.parents('.theme_option').find('.coloralpha');
								alphaInput.val(alpha);
								alphaInput.siblings('span.display_value').text(alpha);
								el.spectrum('set', color);
							} else {
								if (el.attr('type') === 'ccolor') {
									el.spectrum('set', val);
								} else {
									el.val(val);
									if (el.siblings('span.display_value')) {
										el.siblings('span.display_value').text(val);
									}
								}
							}
						}

						self.observers.site.settings_send[setting] = val;

					}

				});

			}

			// Mouseup only for range elements
			if (e.type === 'mouseup' && this.type != 'range') {
				if (live_updates) {
					save = false;
				} else {
					return;
				}
			}

			if (e.type === 'colorupdate') {
				save = false;
			}

			if ($(this).siblings('span.display_value').length) {
				$(this).siblings('span.display_value').html(v);
			}

			if (li.find('.coloralpha').length && li.find('input[type="ccolor"]').length) {
				var root = li.find('input[type="ccolor"]');
				is_pulse = root.attr('id').indexOf('pulse_') === 0;
				var r = root.spectrum('get').toRgbString().replace('rgb(', 'rgba(');
				v = r.substr(0, r.length-1) + ',' + li.find('.coloralpha').val() / 100 + ')';
				n = root.attr('name');
				live_updates = self.observers.site.live_updates && self.observers.site.live_updates[n] && self.observers.site.live_updates[n]();
			}

			if (live_updates && typeof live_updates === 'object') {

				var style_text = '', lb_style_text = '';

				$.each( live_updates, function(i, obj) {

					var template = obj.template.replace(/\[?\$([a-z_0-9]+)\]?/g, function() {

						var field = arguments[1];

						if (n === field) {
							return v;
						} else {
							return $('#option_' + field).val();
						}

					}).replace(/to_rgb\(#([0-9a-zA-z]{3,6})\)/g, function() {
						var color = arguments[1];
						if (color.length === 3) {
							color = color + color;
						}
						return parseInt(color.substr(0, 2), 16) + ',' + parseInt(color.substr(2, 2), 16) + ',' + parseInt(color.substr(4, 2), 16);
					});

					if (obj.lightbox) {
						lb_style_text += obj.selector + ' {' + obj.property + ': ' + template + '}';
					} else {
						style_text += obj.selector + ' {' + obj.property + ': ' + template + '}';
					}

				});

				self.controllers.live_update_css(style_text, true);
				self.controllers.live_update_css(lb_style_text, true, true);

			} else if (self.observers.site.settings_flat[n] && !self.observers.site.settings_flat[n].reload_preview && $(win).find('[data-' + n + ']').length) {

				live_updates = true;

				var clean_v;

				$.each($(win).find('[data-' + n + ']'), function(i, control) {
					var condition = $(control).data('control-condition').replace(/__setting.([a-z\-_]+)/g, function(m) {
						var el = $('#option_' + m.replace('__setting.', '')),
							_v;

						if (el.attr('type') === 'checkbox') {
							var data = ko.dataFor(el.get(0));
							if (data.setting.values) {
								_v = el.is(':checked') ? data.setting.values['true'] : data.setting.values['false'];
							} else {
								_v = el.is(':checked');
							}
						} else {
							_v = el.val();
						}

						if (_v !== true && _v !== false) {
							_v = "'" + _v + "'";
						}

						return _v;
					});

					if (eval(condition)) {
						$(control).css('display', 'inline');
					} else {
						$(control).css('display', 'none');
					}
				});

			}

			if (live_updates) {
				setTimeout(function() {
					// Okkaaaaaay...for some reason we have to use the iframe's copy of jQuery to trigger the resize
					document.getElementById('siteui-iframe').contentWindow.$(document.getElementById('siteui-iframe').contentWindow).trigger('resize');
				}, 0);
			}

			if (save) {
				if (is_pulse) {
					self.observers.site.pulse_settings_group = self.observers.sitepanel_pulse.title();
					self.observers.site.pulse_settings_send[n] = v;
					live_updates = 'pulse';
				} else {
					var s = self.observers.site.settings_flat[n];
					if (s.send_as) {
						$('[data-send-as="' + s.send_as() + '"]').each(function(i, input) {
							var el = $(input);
							if (el.attr('type') === 'checkbox') {
								var data = ko.dataFor(input),
									checked;
								if (data.setting.values) {
									checked = v === data.settings.values[v ? 'true' : 'false'];
								} else {
									checked = v;
								}
								el.attr('checked', v);
							} else {
								el.val(v);
								if (el.siblings('span.display_value')) {
									el.siblings('span.display_value').text(v);
								}
							}
						});
					}
					self.observers.site.settings_send[s.send_as ? s.send_as() : n] = v;
				}
			}

			// Only continue to save for ranges if the drag is finished
			if (this.type === 'range' && save) {
				clearTimeout(range_listen);
				range_listen = setTimeout(function() {
					self.events.save(false, live_updates);
				}, 1000);
			} else if (save) {
				self.events.save(false, live_updates);
			}

		});

		$(document).on('change', '#edit_link_data select.filter', function(e) {

			var s = $(e.target),
				val = s.val(),
				p = s.parents('ol');

			p.find('select:not(.filter),input:not(.filter),').hide();
			p.find('.filter_' + val).show();

		});

		this.events.refresh_site_data();

		this.controllers.fetch_themes(function() {
			if (this.observers.state.type() === 'themes') {
				this.controllers.load_theme_previews();
			}
		});

		this.controllers.load_albums_full();

		this.sync({
			request: 'text/type:page',
			context: this,
			finished: function(data) {
				this.observers.pages( data.text );
			}
		});

		this.sync({
			request: 'drafts',
			context: this,
			finished: function(data) {
				this.events.parse_draft_data(data);
			}
		});

		this.observers.sitepanel_pulse.active.subscribe(function(val) {
			if (val) {
				$('#siteui-panel-pulse').removeClass('move_offscreen');
			} else {
				$('#siteui-panel-pulse').addClass('move_offscreen');
				var win = $(document.getElementById('siteui-iframe').contentDocument);
				win.find('[data-pulse-group] div.cover').removeClass('active');
				this.observers.sitepanel_pulse.title('');
			}
		}, this);

		this.observers.sheets.source_filter.filter.subscribe( function(val) {
			setTimeout( function() {
				$(window).trigger('resize');
			}, 0);
		});

		$(document).on('change', '#source_filter_filter', function() {
			self.observers.sheets.source_filter.filter($(this).val());
		});

		this.observers.sheets.source_filter.type.subscribe( function(val) {
			if (val === 'archive' || val === 'index') {
				if (val === 'archive') {
					this.observers.sheets.source_filter.filter('date');
					this.observers.sheets.source_filter.index_route = this.observers.sheets.source_filter.route;
					this.observers.sheets.source_filter.route = {};
					this.observers.sheets.source_filter.current(false);
				} else {
					requestAnimationFrame(function() {
						self.observers.sheets.source_filter.filter('none');
					});
					this.observers.sheets.source_filter.route = this.observers.sheets.source_filter.index_route;
				}
				this.observers.sheets.source_filter.category(0);
				this.observers.sheets.source_filter.tags([]);
				this.observers.sheets.source_filter.year(0);
				this.observers.sheets.source_filter.month(0);
			}
		}, this);

		this.observers.sheets.source_filter.tags.subscribe(function(val) {
			if (val.length <= 0) {
				var self = this;
				requestAnimationFrame(function() {
					self.classes.autocomplete.site.tags($('#source_filter input.widefilter_tag'), $('#source_filter input.widefilter_tag').parent(), $('#source_filter [data-event="add_filter_tag"]'));
				});
			}
		}, this);

		this.observers.sheets.source_filter.order_by.subscribe( function(val) {
			if (val.match(/_on$/) || val === 'count') {
				this.observers.sheets.source_filter.order_direction('desc');
			} else {
				this.observers.sheets.source_filter.order_direction('asc');
			}
		}, this);

		this.observers.state.preview.subscribe( function(val) {

			var frame = $('#preview-frame'),
				wrap = $('#preview-frame-wrap'),
				thumb = $('#preview-thumb-' + val),
				top = $('#siteui-lcol-themes'),
				self = this,
				speed = 400;

			if (val) {

				var shade = $('<div />').attr('id', 'theme-preview-shade').appendTo('#theme-selection'),
					spriteCoords = {
						top: thumb.offset().top - 54,
						left: thumb.offset().left
					},
					sprite = thumb
						.clone()
						.css({
							position: 'absolute',
							top: spriteCoords.top,
							left: spriteCoords.left
						})
						.data('coords', spriteCoords)
						.on('load', function() {
							requestAnimationFrame(function() {
								shade.animate({
									opacity: 1
								}, speed);
							});
						})
						.attr('id', 'theme-preview-sprite')
						.appendTo($('#themes-browse'));

				$('#theme-preview-info .theme-info').css('margin-top', thumb.height() + 33);

				frame.off('load').on('load', function() {
					if (frame.attr('src') === 'templates/blank.html') {
						setTimeout(function() {
							var css = {
								left: 20
							};
							// Necessary due to an odd issue in animated-enhanced
							// https://github.com/benbarnett/jQuery-Animate-Enhanced/issues/97
							if (spriteCoords.top !== 103) {
								css.top = 103;
							}
							$('#theme-preview-sprite').animate(css, speed);
							$('#theme-preview-wrap').animate({ left: 0 }, speed, function() {
								frame.attr('src', self.config.paths.base + 'preview.php?/&preview=' + val);
							});
						}, 100);
					}
				});

				frame.attr('src', 'templates/blank.html');

			} else {
				$('#theme-preview-shade').animate({opacity: 0}, speed, function() {
					$(this).remove();
				});

				var coords = $('#theme-preview-sprite').data('coords');

				if (parseInt($('#theme-preview-sprite').css('top'), 10) === coords.top) {
					coords = {
						left: coords.left
					};
				}

				$('#theme-preview-sprite').animate(coords, speed, function() {
					$(this).remove();
				});

				frame.off('load');
				$('#theme-preview-wrap').animate({ left: -$(window).width() }, speed, function() {
					frame.attr('src', 'templates/blank.html');
					$(this).css('left', '-100%');
				});
			}

		}, this);

		this.observers.state.type.subscribe( function(val) {

			if (val === 'themes') {
				$('#theme-preview-shade').add('#theme-preview-sprite').remove();
				this.controllers.load_theme_previews();
				if ( this.observers.state.expand() ) {
					this.observers.state.expand(false);
				}
				return;
			}
			var iframe = $('#siteui-iframe'),
				current = iframe.attr('src') || '';

			if (val === 'live') {
				iframe.attr('src', this.config.paths.base).show();
			} else if (current.indexOf('/preview.php?/') === -1) {
				iframe.attr('src', this.config.paths.base + 'preview.php?/');
			}

			if (val === 'draft' && this.cookie('editor:state') && this.cookie('editor:state') === 'on' && $('#docked_editor').css('display') !== 'block') {
				$('.item[data-event="toggle_css_editor"]').trigger('click');
			}

		}, this);

		this.observers.state.expand.subscribe( run_expand, this );

		if ( this.observers.state.expand() === true ) {
			run_expand(true);
		}

		this.observers.state.sidebar.subscribe( function(val) {
			setTimeout( function() {
				$(window).trigger('resize');
				settings_visibility();
			}, 0);
		});

		this.observers.site.custom_css.subscribe(function(val) {
			if (this.classes.editor && this.classes.editor.css.editorWindow.getValue() !== val) {
				this.classes.editor.css.editorWindow.autosave = false;
				this.classes.editor.css.editorWindow.setValue(val);
				this.classes.editor.css.editorWindow.autosave = true;
				var self = this;
				requestAnimationFrame(function() {
					self.classes.editor.css.editorWindow.gotoLine(1);
				});
			}
		}, this);

		this.observers.state.editor_type.subscribe(function(val) {

			var self = this;

			this.controllers.live_update_css( self.classes.editor.css.editorWindow.getValue() );

			if ( val === 'dock' ) {
				$('#docked_editor').empty();
				$('#siteui-css-editor-frame').appendTo('#docked_editor');
			} else {
				$('#undocked_editor').empty();
				$('#siteui-css-editor-frame').appendTo('#undocked_editor');
			}

			requestAnimationFrame(function() {
				self.classes.editor.css.editorWindow.focus();
				self.classes.editor.css.editorWindow.resize(true);
			});

		}, this);

		this.observers.state.settings_panel.subscribe(function(val) {
			if (val) {
				self.observers.sitepanel_pulse.active(false);
				$('#siteui-panel-pulse').addClass('move_offscreen');
			}
		}, this);

		this.observers.state.iframe.path.subscribe( function(val) {

			if (!val) return;

			val = val.replace( RegExp('^' + this.helpers.rtrim( this.config.paths.base, '/') + '(/(index|preview).php\\?)?'), '').replace(/&.*=.*$/, '');

			if (this.observers.state.type() != 'themes') {
				location.hash = '/site/' + this.observers.state.type() + '/' + this.observers.state.sidebar() + '/url:' + ( val || '/' );
			}

			if (this.observers.state.type() === 'draft') {
				var self = this,
					t;

				this.observers.state.iframe.rel_path( val );

				if (val === '/') {
					$.each(this.observers.site.navigation.items(), function(i, obj) {
						if (obj.front) {
							val = obj.path;
							return false;
						}
					});
				}

				val = val.replace(/\/[a-z_]+:[^\/]+/g, '').replace(/\/$/, '') + '/';

				$.each(this.observers.site.routes(), function(i, obj) {
					if (obj.template && obj.template.indexOf('redirect:') === 0) return true;
					var p = '^/?' + obj.path.substring(1).replace(/\:[a-z_-]+/g, function(match) {
						if (match === ':year') {
							return '[0-9]{4}';
						} else if (match === ':month' || match === ':day') {
							return '[0-9]{1,2}';
						} else if (match === ':id') {
							return '(?:(?:[0-9]+)|(?:[0-9a-z]{32}))';
						} else {
							return '[^/]+';
						}
					}).replace(/\?P<[^>]+>/, '').replace(/\/\$$/, '/?$').replace(/\$$/, '') + '$';

					if (val.match(RegExp(p))) {
						t = obj;
					}
				});

				if (!t) {
					t = {
						template: val.replace(/\//g, ''),
						path: val
					};
				}

				if (t) {

					$.each( this.observers.site.templates(), function(i, obj) {
						if (obj.path === t.template) {
							if (t.source) {
								obj.info.source = t.source;
							}
							if (t.filters) {
								obj.info.filters = t.filters;
							}

							if (obj.info.source) {
								var label = '', hash, filters = [];
								if (obj.info.source.collection) {
									label = 'All public ' + obj.info.source.collection;

									if (obj.info.source.featured) {
										label.replace('public', 'featured');
									}

									if (obj.info.source.category) {
										var cat = ko.utils.arrayFirst(self.observers.categories(), function(item) {
											return item.id == obj.info.source.category;
										});
										label += ' in category "' + cat.title + '"';
									} else if (obj.info.source.tag) {
										label += ' tagged with "' + obj.info.source.tag + '"';
									}
								}

								obj.info.source_label = label;
							}
							self.observers.state.loaded_route(t);
							self.observers.state.loaded_page(obj);

							var is_sub_archive = false,
								current_page_nav =  $('.lib-nav .selected').length;

							if (obj.info.source === 'timeline') {
								var matches = self.observers.state.iframe.rel_path().match(RegExp(t.path.substr(1).replace(':year', '([0-9]{4})').replace(':month', '([0-9]{1,2})')));
								is_sub_archive = matches && matches.length > 1 && !isNaN(matches[1]);
							}

							self.observers.sheets.source_filter.available(
								(obj.info.source === 'timeline' && (!t.section || (is_sub_archive && current_page_nav))) ||
								(
									$.inArray(obj.info.source, ['contents', 'favorites', 'featured_albums', 'albums', 'sets', 'album', 'set', 'essays', 'tags', 'categories']) !== -1 &&
									(!t.section || $.inArray(obj.info.source, ['contents', 'favorites', 'albums', 'album', 'set', 'sets', 'essays']) === -1)
								) ||
								( $.inArray(obj.info.source, [ 'tag', 'category', 'archive' ]) !== -1 && ( current_page_nav || t.path.indexOf(':') === -1 ))
							);
							return false;
						}
					});

				}
			}

		}, this );

		this.observers.sheets.edit_nav.active.subscribe( function(val) {
			if ( val === true ) {
				var self = this;
				$('#primary_links').sortable({
					axis: 'y',
					placeholder: 'links-place',
					containment: 'parent',
					cancel: '#primary_links .head',
					handle: '.drag',
					items: 'li.listed',
					update: function() {
						var nav = [];
						$('#primary_links li').not('.head').each( function(k,v) {
							nav.push(ko.dataFor(v));
						});
						// Necessary for some reason, otherwise the table gets funky
						self.observers.site.navigation_draft([]);
						self.observers.site.navigation_draft(nav);
					}
				});
			}
		}, this);

		this.observers.site.templates.subscribe( function(val) {

			var index = [],
				special = [],
				special_paths = [],
				archives = [],
				tmp = {},
				self = this;

			this.observers.site.has_page_template(false);

			$.each( val, function(i, template) {
				tmp[template.path] = template;
				if (template.info.source === 'page') {
					self.observers.site.has_page_template(true);
				}
			});

			this.observers.sheets.source_filter.templates.supported_archives([]);

			$.each( this.observers.site.templates(), function(i, template) {

				if (/.rss$/.test(template.path)) return true;

				template.info.path = template.path;

				if (template.path === 'timeline' || (template.info.source && $.inArray(template.info.source, [ 'albums', 'contents', 'essays', 'featured_albums', 'featured_content', 'favorites']) !== -1)) {
					if (template.path === 'timeline') {
						template.info.source = 'timeline';
					// If template path === source, it is a core section, so skip. UNLESS featured, which aren't core
					} else if (template.path !== template.info.source || template.info.source.indexOf('featured') === 0) {
						index.push(template.info);
					}
				} else if ($.inArray(template.path, [ 'archive.essays', 'archive.contents', 'archive.albums']) !== -1) {
					self.observers.sheets.source_filter.templates.supported_archives.push( template.path.match(/archive\.(essays|contents|albums)/)[1] );
				} else if (template.path === 'date') {
					self.observers.sheets.source_filter.templates.supported_archives.push('date');
				} else if (!template.info.source) {
					special.push(template.info);
					special_paths.push(template.info.path);
				}

			});

			this.observers.site.templates_grouped.indexes( index.reverse() );
			this.observers.site.templates_grouped.specials( special );
			this.observers.site.templates_grouped.special_paths( special_paths );

		}, this);

	},

	controllers: {

		live_update_css: function(cssText, append, lightbox) {

			append = append || false;
			lightbox = lightbox || false;

			var win = $(document.getElementById('siteui-iframe').contentDocument);

			if (lightbox) {
				var lb = win.find('#k-lightbox-iframe');
				if (lb.length) {
					win = $(lb.get(0).contentDocument);
				} else {
					return;
				}
			}

			var	style = append ? win.find('style.__live') : win.find('#koken_custom_css');

			if (!style.length) {
				style = $('<style/>').addClass('__live');
				win.find('#koken_custom_css').before(style);
			}

			if (append) {
				cssText = style.text() + cssText;
			}

			style.text( cssText );
		},

		load_theme_previews: function() {
			var intId = setInterval(function() {
				if (!$('#themes-browse li.loading').length) return;
				clearInterval(intId);
				$('#themes-browse li.loading').each(function(i, li) {
					var img = $(li).find('img:first');
					if (img.attr('src') && img.attr('src').indexOf('data:') === -1) return;
					img.bind('load', function() {
						$(this).animate({opacity: 1}, 400, function() {
							$(li).removeClass('loading');
						});
					});
					img.attr('src', img.data('src'));
				});
			}, 20);
		},

		parse_filters: function(filters) {
			var obj = {};
			$.each(filters, function(i, f) {
				if (f.indexOf('=') === 0) {
					obj[f] = true;
				} else {
					var bits = f.split('=');
					obj[bits[0]] = bits[0] === 'tags' ? bits[1].split(',') : bits[1];
				}
			});
			return obj;
		}

	},

	events: {

		toggle_css_editor: function() {

			if ( this.observers.state.mid_col() === 'preview' && $('#undocked_editor').html() !== '' && this.classes.editor ) {
				this.observers.state.mid_col('custom_css');
				this.cookie( 'editor:state', 'on' );
			} else if ( this.observers.state.mid_col() === 'preview' && this.observers.state.editor_type() === 'dock' ) {
				this.observers.state.mid_col('custom_css');
				this.cookie( 'editor:state', 'on' );
				new this.klass.editor('css', this.observers.site.custom_css(), 'siteui-css-editor');
			} else {
				this.cookie( 'editor:state', 'off' );
				this.observers.state.mid_col('preview');
			}

			if (this.observers.state.mid_col() === 'custom_css') {
				var self = this,
					id;
				this.classes.editor.css.editorWindow.focus();
				this.classes.editor.css.editorWindow.clearSelection();
				this.classes.editor.css.editorWindow.gotoLine(1);
				this.classes.editor.css.editorWindow.on('change', function(e) {
					if (!self.classes.editor.css.editorWindow.autosave) return;
					clearTimeout(id);
					var val = self.classes.editor.css.editorWindow.getValue();
					self.observers.site.custom_css(val);
					if (self.observers.state.editor_type() === 'dock') {
						self.controllers.live_update_css(val);
					}
					id = setTimeout(function() {
						self.events.save(false, true);
					}, 1000);
				});
			}

			this.classes.editor.css.editorWindow.resize(true);

		},

		toggle_editor_theme: function() {

			var editor = this.classes.editor.css.editorWindow,
				kokentheme = 'ace/theme/koken',
				kokenlighttheme = 'ace/theme/kokenlight';

			if ( editor.getTheme() === kokentheme ) {
				$('.ace_scrollbar').css('background', '');
				$('.ace_scrollbar').css('background', '#fff');
				this.cookie( 'editor:theme', '"' + kokenlighttheme + '"' );
				editor.setTheme(kokenlighttheme);
			} else {
				$('.ace_scrollbar').css('background', '');
				$('.ace_scrollbar').css('background', '#141414');
				this.cookie( 'editor:theme', '"' + kokentheme + '"' );
				editor.setTheme(kokentheme);
			}

			editor.focus();

		},

		check_for_custom_styling: function() {
			var style = this.observers.site.style,
				self = this,
				custom = false;

			if (!style) return;

			if (style.variables) {
				$.each(style.variables, function(i, v) {
					if (self.observers.site.settings_flat[i] && self.observers.site.settings_flat[i].value() != v()) {
						custom = true;
						return false;
					}

				});

				this.observers.site.custom_style(custom);
				// Needed to make Style <select> to update with the proper selected style
				this.observers.site.styles.valueHasMutated();
			}
		},

		manage_filter_tag: function(el, parent) {
			var d = ko.dataFor(el[0]),
				o = this.observers.sheets.source_filter.tags,
				tag = d.title || d;

			if ($.inArray(tag, o()) === -1) {
				o.push(tag);
			} else {
				o.remove(tag);
			}

		},

		add_filter_tag: function(el, parent) {
			var tag = el.parents('ul').find('input').val(),
				o = this.observers.sheets.source_filter.tags;

			if (tag.length && $.inArray(tag, o()) === -1) {
				o.push(tag);
			}

			el.parents('ul').find('input').val('');
		},

		apply_filters: function(el, parent) {

			var o = this.observers.sheets.source_filter,
				r = o.current() ? this.observers.state.loaded_route() : o.route,
				new_route = o.current() ? $.extend({}, r) : $.extend(r, {
					path: '/' + this.observers.make_url_safe(o.label()) + '/'
				}),
				replace = o.current() && $('.lib-nav .selected').length ? ko.dataFor($('.lib-nav .selected').get(0)) : false,
				type = o.type(),
				url_data = this.observers.site.url_data[ o.source().replace(/s$/, '') ];


			if (o.type() === 'index' &&
					o.display() === 'all' &&
					o.section() &&
					url_data && url_data.order &&
					url_data.order().toLowerCase() === o.order_by() + ' ' + o.order_direction() &&
					o.filter() !== 'none' &&
					$.inArray(o.source(), this.observers.sheets.source_filter.templates.supported_archives()) !== -1) {
				type = 'archive';
			}

			if (o.source() === 'timeline') {

				var m = o.month() > 0 ? ( o.month() < 10 ? '0' + o.month() : o.month() ) : false;
				this.events._manage_link({
					label: o.current() ? $('.lib-nav .selected').find('span.icon16').text() : o.label(),
					allow_label: true,
					auto: 'timeline',
					year: o.year(),
					month: m,
					path: this.observers.urls['timeline'] + o.year() + ( m ? '/' + m : '' ) + '/'
				}, false, replace);

			} else if (o.current() && o.section()) {

				this.observers.site.url_data_send = {
					'source': o.source(),
					'order': 'order',
					'value': o.order_by() + ' ' + o.order_direction()
				};

			} else if (type === 'archive') {

				var key = (o.filter() === 'date' ? 'archive' : o.filter()) + '_' + o.source(),
					path = this.observers.urls[key].replace(/(\(\?\:)|(\)\?)/g, ''),
					obj = {
						label: o.current() ? $('.lib-nav .selected').find('span.icon16').text() : o.label(),
						auto: key
					};

				if (o.filter() === 'date') {
					obj.month = o.month() === 'any' ? '' : o.month();
					obj.year = o.year();
					obj.day = '';

					if (!isNaN(obj.month) && obj.month < 10) {
						obj.month = '0' + obj.month;
					}
				} else if (o.filter() === 'category') {
					obj.id = o.category();
				} else {
					obj.slug = o.tags().join(',');
				}

				obj.path = path.replace(/\:[a-z_]+/g, function(match) {
					var m = match.replace(':', '');
					return obj[m];
				}).replace(/\/+$/, '/');

				this.events._manage_link(obj, false, replace);

			} else {

				new_route.source = o.is_album() ? (o.source() === 'contents' ? 'album' : 'set' ) : o.source();
				new_route.filters = new_route.filters || [];
				if (o.is_album() && r.filters) {
					$.each(r.filters, function(i, f) {
						if (f && f.match(/^id=/)) {
							new_route.filters = [ f ];
							return false;
						}
					});
				}

				if (o.display() === 'all') {
					new_route.filters = $.grep(new_route.filters, function(filter) {
						var f = filter.split('=')[0];
						return $.inArray(f, ['featured', 'favorites', 'category', 'tags', 'year', 'month']) === -1;
					});
				} else {
					new_route.filters.push( o.display() );
				}

				if (o.filter() === 'category') {
					new_route.filters.push('category=' + o.category());
				} else if (o.filter() === 'tag') {
					new_route.filters.push('tags=' + o.tags().join(','));
				} else if (o.source() === 'archives') {
					new_route.filters.push('year=' + o.year());
					if (o.month() != 'any') {
						new_route.filters.push('month=' + o.month());
					}
				}

				if (o.type() === 'index') {
					new_route.filters.push('order_by=' + o.order_by());
					new_route.filters.push('order_direction=' + o.order_direction());
				}

				if (o.current()) {
					this.observers.site.routes.remove( r );
				}

				var filterTmp = [];
				var filterObj = {};

				// Weed out duplicates
				// Last in take precedence
				$.each(new_route.filters, function(i, f) {
					if (f.indexOf('=') === -1) {
						filterTmp.push(f);
					} else {
						var parts = f.split('=');
						filterObj[parts[0]] = parts[1];
					}
				});

				$.each(filterObj, function(key, value) {
					filterTmp.push(key + '=' + value);
				});

				// Remove dupes from filters
				new_route.filters = $.grep(filterTmp, function(el, index) {
			        return index === $.inArray(el, filterTmp);
			    });

				new_route = this.events.push_route(new_route);

			}

			if (o.current() || type === 'archive' || o.source() === 'timeline') {
				this.events.save.call(this);
				this.events.toggle_sheet(el, parent);
			} else {

				this.events._manage_link({
					label: o.label(),
					path: new_route.path
				}, false);

				this.events.save_nav(el, parent);
			}

		},

		filter_current: function(el, parent) {
			var o = this.observers.sheets.source_filter,
				obj = this.observers.state.loaded_page().info,
				c = false;

			if (obj.path.indexOf('archive') !== 0 && obj.path !== 'timeline') {
				o.set( obj, this.observers.state.loaded_route(), this.observers.site.url_data );
			} else {

				if ($('.lib-nav .selected').length) {
					c = ko.dataFor($('.lib-nav .selected').get(0));
				}

				var filters = false;
				if (obj.filters) {
					filters = this.controllers.parse_filters(obj.filters);
				}

				if (obj.path === 'timeline') {
					o.source('timeline');
					o.type('timeline');
				} else {
					if (filters && filters.members) {
						o.source(filters.members);
					}
					o.type('archive');
				}

				o.display('all');
				o.category(0);
				o.month('any');
				o.year(o.years[0]);
				o.tags([]);

				if (c && c.auto) {
					var parts = c.auto.split('_');
					o.filter( parts[0].indexOf('archive') === 0  ? 'date' : parts[0]);
					if (o.filter() === 'date' || o.source() === 'timeline') {
						o.year(c.year);
						o.month(c.month || 'any');
					} else if (o.filter() === 'category') {
						o.category(c.id);
					} else {
						o.tags(c.slug.split(','));
					}
				} else {
					o.filter(obj.source);
					if (obj.source === 'tag') {
						o.tags( filters.id.split(',') );
					} else if (obj.source === 'archive' || obj.source === 'timeline') {
						o.year( filters.year );
						if (filters.month) {
							o.month(filters.month);
						}
					} else {
						o.category( filters.id );
					}
				}
			}
			o.current(true);
			this.events.toggle_sheet(el, parent);
		},

		toggle_gear_popout: function(el,parent) {

			var group	= parent.closest('ol'),
				top		= parent.closest('li').position().top + 50,
				self	= this;

			parent.closest('li').find('a.item').addClass('hoverable');
			$('#gear_pop').data( 'id', el.closest('li').attr('id') );

			this.events.update_state( '', 'gear' );

			this.helpers.outside( $('#gear_pop'), function() {
				self.events.update_state( '', 'gear' );
				$('.lib-nav').find('.hoverable').removeClass('hoverable');
				$('.lib-nav').find('.edit-hoverable').removeClass('edit-hoverable');
			});

			$('#gear_pop li').show();

			if ( group.hasClass('primary') ) {
				var data = ko.dataFor(parent[0]);
				$.each( this.observers.site.navigation.items(), function(k,v) {
					if ( v.path === data.path && v.front === true ) {
						$('#gear_pop li.front').hide();
						$('#gear_pop li.delete').hide();
						if (v.hide) {
							$('#gear_pop li.hide-home').hide();
							$('#gear_pop li.show-home').show();
						} else {
							$('#gear_pop li.hide-home').show();
							$('#gear_pop li.show-home').hide();
						}
						top += 13;
						return false;
					} else {
						$('#gear_pop li.hide-home').hide();
						$('#gear_pop li.show-home').hide();
					}
				});
				if (data.path.indexOf('http://') === 0 || (data.auto && data.auto === 'profile')) {
					$('#gear_pop li.front').hide();
					top += 13;
				}
			} else {
				$('#gear_pop li.front').hide();
				$('#gear_pop li.hide-home').hide();
				$('#gear_pop li.show-home').hide();
				top += 13;
			}

			$('#gear_pop').css( 'top', top ).data('ref',parent);

		},

		gear_menu: function(el,parent) {

			var data	= $('#gear_pop').data(),
				group	= data.ref.closest('ol'),
				link = ko.dataFor($('#' + data.id)[0]),
				ref, items;

			if ( el.hasClass('opt-edit') ) {
				this.events.edit_link(data.ref,data.ref);
			}

			if ( el.hasClass('opt-front') ) {

				var front = false;
				$.each( this.observers.site.navigation.items(), function(k,v) {
					if ( v.path === link.path ) {
						v.front = true;
						front = v;
					} else {
						v.front = false;
						delete(v.hide);
					}
				});

				if (front) {
					this.observers.site.navigation.items.remove(front);
					this.observers.site.navigation.items.unshift(front);
				}

				$.each( this.observers.site.navigation.groups(), function(k,v) {
					var front = false;
					$.each(v.items, function(i, item) {
						if ( item.path === link.path ) {
							front = i;
							item.front = true;
						} else {
							item.front = false;
						}
					});
					if (front) {
						var push = v.items[front];
						v.items.splice(front, 1);
						v.items.unshift(push);
					}
				});

				this.events.save_nav();

			}

			if ( el.hasClass('opt-delete') ) {

				var selectedLabel = $('.lib-nav .selected .in span:first').text();

				var label;
				if ( group.hasClass('primary') ) {
					$.each( this.observers.site.navigation.items(), function(k,v) {
						if ( v.path === link.path ) {
							ref = k;
							label = link.label;
						}
					});
					this.observers.site.navigation.items.splice( ref, 1 );
				} else {
					$.each( this.observers.site.navigation.groups(), function(k,v) {
						if ( ( 'group-' + v.key ) === group.attr('id') ) {
							$.each( v.items, function(j,w) {
								if ( w.path === link.path ) {
									ref = j;
									label = link.label;
								}
							});
							v.items.splice(ref,1);
							return false;
						}
					});
				}

				if (selectedLabel === label) this.observers.state.iframe.path(this.config.paths.base  + 'preview.php?/');

				this.events.save_nav();
				this.notify().success('Link to "' + label + '" removed');

			}

			if ( el.hasClass('opt-hide') || el.hasClass('opt-show') ) {

				var replace = false;

				$.each( this.observers.site.navigation.items(), function(k,v) {
					if ( v.path === link.path ) {
						replace = v;
					}
				});

				var n = $.extend({}, replace),
					msg;

				if (n.hide) {
					delete(n.hide);
					msg = 'Front link set to show in navigation';
				} else {
					n.hide = true;
					msg = 'Front link hidden from navigation';
				}

				this.observers.site.navigation.items.replace( replace, n );
				this.notify().success(msg);

				this.events.save_nav();

			}

			this.events.update_state( '', 'gear' );

			$('.lib-nav').find('.hoverable').removeClass('hoverable');
			$('.lib-nav').find('.edit-hoverable').removeClass('edit-hoverable');

		},

		navigation_sort: function() {

			var self		= this,
				sortables	= $('ol.lib-nav').not('.template_nav,.single');

			sortables.sortable({
				axis: 'y',
				containment: 'parent',
				items: 'li:not(.create_new)',
				update: function(e,ui) {

					var current_nav, index, clone, key = '', new_nav = [];

					if ( $(e.target).hasClass('primary') ) {
						current_nav = self.observers.site.navigation.items();
					} else {
						$.each( self.observers.site.navigation.groups(), function(k,v) {
							if ( $(e.target).attr('id') === 'group-' + v.key ) {
								current_nav = v.items;
								index = k;
								key = v.key + '_';
								return false;
							}
						});
					}

					$.each( $(this).sortable('toArray'), function(k,v) {
						new_nav.push( current_nav[parseInt( v.replace( key + 'nav_item_', '' ), 10 )] );
					});

					if ( $(e.target).hasClass('primary') ) {
						self.observers.site.navigation.items([]);
						self.observers.site.navigation.items(new_nav);
					} else {
						self.observers.site.navigation.groups()[index].items = new_nav;
					}

					self.events.save_nav();

				}
			});

		},

		theme_view: function() {
			location.hash = '/site/draft/theme/url:' + this.observers.state.iframe.rel_path();
		},

		page_view: function() {
			location.hash = '/site/draft/page/url:' + this.observers.state.iframe.rel_path();
		},

		iframe_loaded: function() {
			if (this.observers.state.type() === 'live') {
				this.observers.state.iframe.path( document.getElementById('siteui-iframe').contentWindow.location.href );
			}
		},

		nav_from_link: function(el) {
			var data = ko.dataFor(el[0]);
			if (/^https?:\/\//.test(data.path)) {
				window.open(data.path);
			} else {
				this.events.refresh( data.front ? '/' : data.path );
			}
		},

		reset_link_form: function() {
			$('select.filter_list').val('off').trigger('change');
			$('select.collection_albums').val('default').trigger('change');
			$('select.collection_content').val('default').trigger('change');
			$('select.single_album').val(0).trigger('change');
			$('input.single_tag').val('').trigger('change');
			this.observers.sheets.edit_nav.link_to_edit.front(false);
		},

		link_to_current: function(el, parent) {
			var loaded = this.observers.state.loaded_page(),
				route = this.observers.state.loaded_route(),
				current_path = this.observers.state.iframe.rel_path(),
				page = {
					template: loaded.path
				},
				lbl = $('#link_to_current_label').val(),
				link,
				album_content = false;

			if (route.section) {
				var type,
					matches;

				$.each(this.observers.site.urls, function(i, url) {
					url = url();
					if (i === 'timeline') {
						url += ':year(/:month)?/';
					}
					var regex = url.replace(':slug', '((?!lightbox)\\d*[\\-_a-z][\\-_a-z0-9]*)')
										.replace(':id', '((?:[0-9]+)|(?:[0-9a-z]{32}))')
										.replace(':year', '([0-9]{4})')
										.replace(':month', '([0-9]{1,2})')
										.replace(':day', '([0-9]{1,2})');
					matches = current_path.match(RegExp('^' + regex + '(?:lightbox/)?$'));
					if (matches) {
						type = i;
						return false;
					}
				});

				if (!type) {
					var content_url = this.observers.site.url_data.content.plural().toLowerCase() + '/:' + ( this.observers.site.url_data.content.url().indexOf('slug') !== -1 ? 'slug' : 'id') + '/';
					var album = this.observers.site.urls.album() + content_url;
					var regex = album.replace(/:(?:album_)?slug/g, '((?!lightbox)\\d*[\\-_a-z][\\-_a-z0-9]*)')
										.replace(/:(?:album_)?id/g, '((?:[0-9]+)|(?:[0-9a-z]{32}))')
										.replace(':year', '([0-9]{4})')
										.replace(':month', '([0-9]{1,2})')
										.replace(':day', '([0-9]{1,2})');
					matches = current_path.match(RegExp('^' + regex + '(?:lightbox/)?$'));

					if (matches) {
						type = 'content';
						album_content = true;
					}
				}

				if (type) {
					link = {
						label: lbl,
						allow_label: true,
						path: current_path,
						auto: type
					};

					if (/\/lightbox\/$/.test(current_path)) {
						link.lightbox = true;
					}

					if (matches) {
						if (type.indexOf('archive') === 0 || type === 'timeline') {
							link.year = matches[1];
							link.month = matches[2] || false;
							link.day = matches[3] || false;
						} else {
							link.id = matches.pop();
							if (album_content) {
								link.album_id = matches.pop();
							}
						}
					}
				} else {
					this.events.toggle_sheet(el, parent);
					return false;
				}
			} else {
				$.each( this.observers.site.routes(), function(i, p) {

					if (p.template === loaded.path) {

						var _magics = [],
							_p = p.path.replace(/\:[a-z_]+/g, function(match) {
								var m = match.replace(':', '');
								_magics.push(m);
								return '([^/]+)';
							});

						_p = RegExp(_p);

						if (_p.test(current_path)) {

							var matches = current_path.match(_p),
								match_data = {};

							$.each(_magics, function(i, name) {
								match_data[name] = matches[i+1];
							});

							if (p.variables) {
								page.variables = p.variables;
							}

							if (loaded.info.source) {
								page.source = loaded.info.source;
								page.filters = loaded.info.filters || [];

								$.each(match_data, function(key, val) {
									page.filters.push(key + '=' + val);
								});
							}

							return false;
						}
					}

				});

				page.path = '/' + this.observers.make_url_safe(lbl.toLowerCase()) + '/';
				page = this.events.push_route(page);

				link = {
					label: lbl,
					path: page.path
				};
			}

			if (this.observers.site.navigation.groups().length) {
				$('input[data-current-group]:checked').each( function() {
					var group = ko.dataFor(this);
					group.items.push(link);
				});
			}

			if ($('#current_link_primary').is(':checked') || !$('#current_link_primary').is(':visible')) {
				this.observers.site.navigation.items.push(link);
			}

			this.events.save_nav(el, parent);

			$('#link_to_current_label').val('');
			$('input[data-current-group]:checked').attr('checked', false);
			$('#current_link_primary').attr('checked', true);
		},

		update_link: function(el, parent) {
			var	link = this.observers.sheets.edit_nav.link_to_edit,
				updated = {
					label: link.label(),
					path: link.path(),
					front: link.front()
				};

			if (link.auto && link.auto()) {
				updated.auto = link.auto();
				if (link.id && link.id()) {
					updated.id = link.id();
				}
				updated.allow_label = true;
			}

			this.events._manage_link(updated, false, link.clone);
			this.events.save_nav(el, parent);
		},

		edit_link: function(el, parent) {

			var clone = ko.dataFor(el[0]),
				data = $.extend({}, clone),
				group = $(el).closest('a').data('group'),
				page, template, source = false,
				index;

			if (!data.front) {
				data.front = false;
			}

			if (!data.id) {
				data.id = false;
			}

			if (!data.auto) {
				data.auto = false;
			}

			if (!group) {
				group = false;
			}

			this.observers.site.navigation.active.group( group );

			data.checked = data.target && data.target === '_blank' ? true : false;
			data.is_new = false;

			$.each(this.observers.site.routes(), function(i, p) {
				if (p.path === data.path) {
					page = p;
					return false;
				}
			});

			if (page) {
				if (page.source) {
					source = $.extend({}, page.source);
				} else {
					$.each( this.observers.site.templates(), function(i, t) {
						if (t.path === page.template) {
							if (t.info.source) {
								source = $.extend({}, t.info.source);
							}
							return false;
						}
					});
				}
			}

			if (source && source.collection) {
				data.source = source;
			} else {
				this.observers.sheets.edit_nav.link_to_edit.source.collection(false);
			}

			this.map( this.observers.sheets.edit_nav.link_to_edit, $.extend({}, data) );
			this.observers.sheets.edit_nav.link_to_edit.clone = clone;
			this.events.toggle_sheet(el, parent);

			this.observers.valid(true);

			if (data.source) {
				if (source.featured || source.filter === 'featured') {
					$('select.collection_filter').val('featured');
				} else if (source.type) {
					$('select.collection_filter').val(source.type);
				} else {
					$('select.collection_filter').val('default');
				}

				if (source.category) {
					$('select.filter_list').val('category').trigger('change');
					$('select.filter_category').val( source.category );
				} else if (source.tag) {
					$('select.filter_list').val('tag').trigger('change');
					$('input.filter_tag').val( source.tag );
				} else {
					$('select.filter_list').val('off').trigger('change');
				}
			}
		},

		add_custom_link: function(el, parent) {
			var clean = {
				label: '',
				path: '',
				checked: false,
				is_new: true
			};
			this.map( this.observers.sheets.edit_nav.link_to_edit, clean );
			this.observers.sheets.edit_nav.link_to_edit.clone = false;
			this.events.toggle_sheet(el, parent);
			$('#create_custom_link input:first').focus();
		},

		create_custom_link: function(el, parent) {
			var obj = this.observers.sheets.edit_nav.link_to_edit,
				info = {
					label: obj.label(),
					target: obj.checked() ? '_blank' : false,
					custom: true,
					path: obj.path()
				};

			if (info.path.indexOf(this.config.paths.base) === 0) {
				info.path = '/' + info.path.replace(RegExp(this.config.paths.base + '((index|preview).php\\?/)?'), '');
				delete(info.custom);
			} else if (!/^(mailto|https?)/.test(info.path)) {
				info.path = 'http://' + info.path;
			}

			this.events._manage_link(info, false, obj.clone);
			this.events.save_nav(el, parent);
		},

		edit_nav_group: function(el, parent) {
			var items;
			var group = $(el).parents('li').data('group');

			if (group) {
				$.each( this.observers.site.navigation.groups(), function(i, g) {
					if (g.key === group) {
						items = g.items;
						return false;
					}
				});
			} else {
				items = this.observers.site.navigation.items();
				group = false;
			}

			this.observers.site.navigation.active.group(group);

			var map = {},
				used = [],
				used_albums = [],
				used_pages = [],
				used_sections = [],
				supported_sections = [],
				rss = [],
				pages = this.observers.site.routes(),
				self = this;

			$.each( ['albums', 'sets', 'contents', 'essays', 'favorites', 'tags', 'categories', 'timeline'], function(i, section) {
				if (self.observers.site.urls[section]) {
					supported_sections.push(section);
				}
			});

			$.each( pages, function(i, page) {
				map[page.path] = page;
			});

			this.observers.site.navigation.active.profiles([]);

			$.each( items, function(i, item) {
				if (item.auto && (item.auto === 'album' || item.auto === 'set' || item.auto === 'page') && item.id) {
					if (item.auto === 'page') {
						used_pages.push(item.id);
					} else {
						used_albums.push(item.id);
					}
				} else if (item.auto && item.auto === 'profile') {
					self.observers.site.navigation.active.profiles.push(item.id);
				} else if (item.auto && item.auto === 'rss') {
					rss.push(item.id);
				} else if (item.auto && (item.auto === 'timeline' || /s$/.test(item.auto))) {
					used_sections.push(item.auto);
				} else if (item.path) {
					var info = map[item.path];
					if ($.inArray(( info ? info.path : item.path.substr(1) ), self.observers.site.templates_grouped.special_paths()) !== -1) {
						used.push( info ? info.path: item.path.substr(1) );
					}
				}
			});

			this.observers.site.navigation.active.internals( used );
			this.observers.site.navigation.active.rss( rss );
			this.observers.site.navigation.active.albums( used_albums );
			this.observers.site.navigation.active.sections( used_sections );
			this.observers.site.navigation.active.supported_sections( supported_sections );
			this.observers.site.navigation.active.pages( used_pages );
			this.events.toggle_sheet(el, parent);
		},

		toggle_link_specials: function(el, parent) {
			var data = ko.dataFor(el[0]),
				active = $.inArray(data.path, this.observers.site.navigation.active.internals()) !== -1,
				info = {
					path: '/' + data.path + '/',
					label: data.name
				};

			this.events._manage_link(info, active);

			if (active) {
				this.observers.site.navigation.active.internals.remove(data.path);
			} else {
				this.observers.site.navigation.active.internals.push(data.path);
			}
		},

		_manage_link: function(info, remove, replace) {
			var group = this.observers.site.navigation.active.group(),
				replace = replace || false,
				index,
				label = info.label;

			if (group) {
				$.each( this.observers.site.navigation.groups(), function(i, g) {
					if (g.key === group) {
						group = g;
						index = i;
						return false;
					}
				});
			}

			if (remove) {
				if (group) {
					group.items = ko.utils.arrayFilter( group.items, function(item) {
						if (item.path === info.path) {
							label = item.label;
						}

						if (info.auto) {
							if (info.auto !== item.auto) return true;

							if (info.id) {
								return info.id !== item.id;
							} else {
								return info.auto !== item.auto;
							}
						} else {
							return item.path !== info.path;
						}
					});
				} else {
					this.observers.site.navigation.items.remove( function(item) {
						if (item.path === info.path) {
							label = item.label;
						}

						if (info.auto) {
							return item.auto === info.auto && (!item.id || item.id === info.id);
						} else {
							return item.path === info.path;
						}
					});
				}
			} else {
				if (replace) {
					if (group) {
						var _index = ko.utils.arrayIndexOf( group.items, replace );
						group.items.splice(_index, 1, info);
					} else {
						this.observers.site.navigation.items.replace( replace, info );
					}
				} else {
					if (group) {
						group.items.push(info);
					} else {
						this.observers.site.navigation.items.push(info);
					}
				}
			}

			if (group) {
				this.observers.site.navigation.groups.replace( this.observers.site.navigation.groups()[index], $.extend({}, group) );
			}

			if (!remove && !info.path.match(/\.rss$/)) {
				this.observers.refresh_destination = info.front ? '/' : info.path.replace(/\(.*\/$/, '/');
			}

			this.notify().success('Link to "' + label + '" ' + ( remove ? 'removed' : (replace ? 'updated' : 'created') ) );

		},

		add_index_link: function(el, parent) {

			var data = {};
			if (parent.data('index')) {
				data.source = parent.data('index');
				data.path = parent.data('index');
			} else {
				data = ko.dataFor(el[0]);
			}

			this.observers.sheets.source_filter.current(false);

			this.observers.sheets.source_filter.set( {
				source: data.source,
				sheet_title: data.name,
				filters: [],
				label: ''
			} );

			this.observers.sheets.source_filter.route = {
				template: data.template || data.path,
				filters: data.filters || []
			};

			this.events.toggle_sheet(el, parent);

			this.observers.valid(false);

		},

		add_archive_link: function(el, parent) {
			var o = this.observers.sheets.source_filter;

			o.sheet_title('Add ' + ( el.data('type') === 'archive' ? 'date' : el.data('type') ) + ' archive');
			o.display('all');
			o.filter('none');
			o.category(0);
			o.month('any');
			o.year(o.years[0]);
			o.tags([]);
			o.label('');
			o.route = {};
			o.current(false);
			o.type('archive');
			o.filter('tag');

			this.events.toggle_sheet(el, parent);
		},

		toggle_link_sections: function(el, parent) {
			var section = el.data('section'),
				singular = section === 'categories' ? 'category' : section.replace(/s$/, ''),
				active = $.inArray(section, this.observers.site.navigation.active.sections()) !== -1,
				info = {
					auto: section,
					label: this.observers.site.url_data[singular].plural(),
					path: this.observers.site.urls[section]()
				};

			if (active) {
				this.observers.site.navigation.active.sections.remove(section);
			} else {
				this.observers.site.navigation.active.sections.push(section);
			}

			this.events._manage_link(info, active);
		},

		toggle_link_rss: function(el, parent) {
			var type = el.data('type'),
				active = $.inArray(type, this.observers.site.navigation.active.rss()) !== -1,
				info = {
					auto: 'rss',
					id: type,
					label: this.observers.site.url_data[type].plural() + ' RSS',
					path: '/feed/' + type + ( type === 'essay' ? 's' : '' ) + '/recent.rss'
				};

			if (active) {
				this.observers.site.navigation.active.rss.remove(type);
			} else {
				this.observers.site.navigation.active.rss.push(type);
			}

			this.events._manage_link(info, active);
		},

		toggle_link_profiles: function(el, parent) {
			var service = $(el).data('service'),
				service_key = service.toLowerCase(),
				active = $.inArray(service_key, this.observers.site.navigation.active.profiles()) !== -1,
				info = {
					auto: 'profile',
					id: service_key,
					label: service === 'Google' ? 'Google+' : service,
					allow_label: true
				};

			switch(service_key) {
				case 'twitter':
					info.path = 'https://twitter.com/' + this.observers.user.twitter();
					break;

				default:
					info.path = this.observers.user[service_key]();
					break;
			}

			if (active) {
				this.observers.site.navigation.active.profiles.remove(service_key);
			} else {
				this.observers.site.navigation.active.profiles.push(service_key);
			}

			this.events._manage_link(info, active);
		},

		toggle_link_pages: function(el, parent) {
			var data = ko.dataFor(el[0]),
				active = this.observers.multi_in_array([ data.id, data.slug ], this.observers.site.navigation.active.pages()) !== -1,
				info = {
					auto: 'page',
					id: data.id,
					label: data.title
				};

			if (active) {
				if ($.inArray(data.slug, this.observers.site.navigation.active.pages()) === -1) {
					this.observers.site.navigation.active.pages.remove(data.id);
				} else {
					info.id = data.slug;
					this.observers.site.navigation.active.pages.remove(data.slug);
				}
			} else {
				var path = this.observers.site.urls.page();
				path = path.replace(/\:[a-z_]+/, function(match) {
					var m = match.replace(':', '');
					return data[m];
				});
				info.path = path;
				this.observers.site.navigation.active.pages.push(data.id);
			}

			this.events._manage_link(info, active);
		},

		toggle_link_albums: function(el, parent) {
			var data = ko.dataFor(el[0]),
				active = this.observers.multi_in_array([ data.id, data.slug ], this.observers.site.navigation.active.albums()) !== -1,
				info = {
					auto: data.album_type === 'set' ? 'set' : 'album',
					id: data.id,
					label: data.title
				};

			if (active) {
				if ($.inArray(data.slug, this.observers.site.navigation.active.albums()) === -1) {
					this.observers.site.navigation.active.albums.remove(data.id);
				} else {
					info.id = data.slug;
					this.observers.site.navigation.active.albums.remove(data.slug);
				}
			} else {
				var url = this.observers.site.urls[info.auto],
					path;
				if (!url) {
					path = '#';
				} else {
					path = this.observers.site.urls[info.auto]();
					path = path.replace(/\:[a-z_]+/, function(match) {
						var m = match.replace(':', '');
						return data[m];
					});
				}
				info.path = path;
				this.observers.site.navigation.active.albums.push(data.id);
			}

			this.events._manage_link(info, active);
		},

		push_route: function(route) {
			var routes = this.observers.site.routes(),
				index = $.inArray(route, routes);
			if (index === -1) {
				var i = 0,
					valid_path = route.path,
					existing_paths = $.map( routes, function(r, i) {
						return r.path;
					});

				if (valid_path === '/admin') {
					valid_path += '-1';
					i++;
				}

				while($.inArray(valid_path, existing_paths) !== -1) {
					i++;
					valid_path = route.path.replace(/\/$/, '') + '-' + i + '/';
				}

				route.path = valid_path;
				this.observers.site.routes.push(route);
				return route;
			} else {
				return routes[index];
			}
		},

		save_nav: function(el, parent) {

			var n = this.observers.site.navigation;

			$.each(n.items(), function(i, item) {
				if (item.auto) {
					delete(item.path);
					if (item.auto.indexOf('_') === -1 && !item.allow_label) {
						delete(item.label);
					}
				}
			});

			$.each(n.groups(), function(i, group) {
				$.each(group.items, function(n, item) {
					if (item.auto) {
						delete(item.path);
						if (item.auto.indexOf('_') === -1 && !item.allow_label) {
							delete(item.label);
						}
					}
				});
			});

			this.events.save.call(this);

			if ( el && parent ) {
				this.events.toggle_sheet(el, parent);
			}

		},

		change_link_type: function(el) {
			var t = el.closest('li').data('type');
			this.observers.sheets.edit_nav.link_to_edit.path('');
			this.observers.sheets.edit_nav.link_type( t );
		},

		change_nav_filter: function(v) {
			this.observers.sheets.edit_nav.filter(v);
		},

		do_revert: function() {
			this.events.save('all', false);
		},

		revert: function() {
			this.classes.modal.confirm_site_revert.show();
		},

		revert_settings: function() {
			this.classes.modal.confirm_site_revert_settings.show();
		},

		do_revert_settings: function() {
			this.events.save('settings', false);
		},

		publish: function(el,override) {

			if ( this.observers.published_theme().toLowerCase() != this.observers.drafts.current.theme.path().toLowerCase() && override !== true ) {
				this.classes.modal.theme_change.show();
				return;
			}

			var notify = this.notify();
			notify.wait('Publishing');
			el.text('Publishing…');
			el.parents('div').addClass('saving');

			this.sync({
				request: 'site/publish/' + this.observers.site.draft_id(),
				method: 'POST',
				context: this,
				finished: function(data) {
					el.parents('div').removeClass('saving');
					this.observers.drafts.current.modified_on( Math.floor( +new Date() / 1000 ) );
					notify.success('Site successfully published');
					el.text('No changes');
					el.addClass('disabled');
					var self = this;
					window.setTimeout(function() {
						self.observers.site.published(true);
					}, 2000);

					this.sync({
						request: 'drafts',
						context: this,
						finished: function(data) {
							this.events.parse_draft_data(data);
							this.sync({
								request: 'site',
								finished: function(data) {
									this.map( this.observers.default_links, data.default_links);
								},
								context: this
							});
						}
					});

				}
			});
		},

		refresh_site_data: function(new_draft) {

			new_draft = new_draft || false;
			this.events.navigation_sort();

			this.sync({
				request: 'site/draft:true',
				context: this,
				finished: function(data) {
					if (data.error) {
						this.observers.error(true);
					} else {
						this.observers.error(false);
						this.observers.site.settings_flat = {};
						this.observers.site.urls = {};
						this.map( this.observers.site, data );

						if (new_draft) {
							this.observers.state.preview(false);
							location.hash = '/site/draft/theme/url:/';
							this.events.refresh('/', true);
						}

						var styled_settings = [];
						if (data.styles.length) {
							$.each(data.styles, function(i, style) {
								if (style.variables) {
									$.each(style.variables, function(key, val) {
										if ($.inArray(key, styled_settings) === -1) {
											styled_settings.push(key);
										}
									});
								}
							});
						}

						this.observers.styled_settings = styled_settings;

						this.events.check_for_custom_styling();
						this.observers.site_loaded(true);
						this.events.check_for_custom_styling();
						$(window).trigger('resize');
					}
				}
			});

		},

		init_draft: function(el,override) {

			var p = ko.dataFor(el[0]).path,
				self = this,
				draftExists = false;

			$.each(this.observers.drafts.history(), function(i,draft) {
				if (draft.theme.path === p) {
					draftExists = true;
				}
			});

			if (draftExists && override != true) {
				self.classes.modal.draft_exists.show();
				return false;
			}

			var notify = this.notify();
			notify.wait('Assigning theme');

			this.sync({
				request: 'drafts',
				method: 'POST',
				data: 'theme=' + p + '&refresh=true',
				context: this,
				finished: function(data) {
					notify.success('Theme successfully assigned');
					this.events.parse_draft_data(data);
					this.events.refresh_site_data(true);
				}
			});
		},

		reset_nav: function(el, override) {

			if (override !== true) {
				this.classes.modal.reset_links.show();
				return;
			}

			if ($('#create_link').is(':visible')) {
				this.events.toggle_sheet('create_link');
			}

			this.sync({
				request: 'drafts',
				method: 'POST',
				data: 'theme=' + this.observers.drafts.current.theme.path() + '&refresh=nav',
				context: this,
				finished: function(data) {
					this.notify().success('Site links successfully reset');
					this.events.parse_draft_data(data);
					this.events.refresh_site_data();
					this.observers.state.preview(false);
					location.hash = '/site/draft/theme/url:/';
					this.events.refresh('/');
				}
			});
		},

		switch_draft: function(el,cb) {

			var p = typeof el === 'string' ? el : ko.dataFor(el[0]).theme.path;

			var note = this.notify().wait('Assigning draft');

			this.sync({
				request: 'drafts',
				method: 'POST',
				data: 'theme=' + p,
				context: this,
				finished: function(data) {
					this.events.parse_draft_data(data);
					this.events.refresh('/');
					this.events.refresh_site_data();
					note.success(p.slice(0,1).toUpperCase() + p.slice(1) + ' assigned as current draft');
					if (cb && typeof cb === 'function') { cb(); }
				}
			});

		},

		delete_draft: function(el,override) {

			var d = ko.dataFor(el[0]),
				p = d.theme.path;

			el.closest('ol .selected').removeClass('selected');
			el.closest('li').addClass('selected');

			if ( override !== true ) {
				this.classes.modal.delete_draft.show();
				return;
			}

			this.observers.drafts.history.remove(d);

			var note = this.notify().wait('Deleting draft');

			this.sync({
				request: 'drafts',
				method: 'DELETE',
				data: 'theme=' + p,
				context: this,
				finished: function() {
					// Otherwise the modal won't resize if necessary
					$(window).trigger('resize');
					note.success(p.slice(0,1).toUpperCase() + p.slice(1) + ' deleted from saved drafts');
				}
			});
		},

		parse_draft_data: function(data) {
			var current, saved = [], self = this;

			$.each(data, function(i, draft) {
				if (draft.published) {
					self.observers.published_theme( draft.theme.path );
				}
				if (draft.active) {
					current = draft;
				} else {
					saved.push( draft );
				}
			});
			this.observers.drafts.all( data );
			this.map( this.observers.drafts.current, current );
			this.observers.drafts.history( saved );
		},

		resize: {

			iframe: function(c,w) {

				if (c.observers.state.expand()) {
					$('#siteui-wrap').height( w.height() );
				}

				var h = $('#siteui-wrap').height();

				if (this.attr('id').match(/siteui\-iframe/)) {
					h -= 28;
				}

				this.height( h );
			},

			settings_position: function(c,w) {

				var panel = this.parent(),
					_h = panel.height() + 60,
					_w = panel.width() + 20,
					ph = panel.parent().height(),
					pw = panel.parent().width();


				var top = parseInt( panel.css('top'), 10 ),
					vdiff = ph - ( _h + top ),
					left = parseInt( panel.css('left'), 10 ),
					hdiff = pw - ( _w + left );

				if (top > 0 && vdiff <= 0) {
					panel.css('top', Math.max(0, top + vdiff));
				}

				if (left > 0 && hdiff <= 0) {
					panel.css('left', Math.max(0, left + hdiff));
				}

			}

		},

		refresh: function(path, force) {
			force = force || false;
			if (this.observers.state.type() !== 'live') {
				if (this.observers.refresh_destination) {
					path = this.observers.refresh_destination;
					this.observers.refresh_destination = false;
				}
				path = (typeof path === 'string') ? this.config.paths.base  + 'preview.php?' + path : this.observers.state.iframe.path();

				var iframe = document.getElementById('siteui-iframe');

				if (iframe.contentWindow.$K && !force) {
					iframe.contentWindow.$K.loadUrl(path);
				} else {
					$(iframe).attr('src', path);
				}
			}
		},

		history: function(el,parent) {

			var loc		= this.observers.state.iframe.location,
				cache	= this.observers.state.iframe.historycache,
				src;

			if ( el.closest('li').find('.icon-solo-thin').hasClass('site-bwd') ) {

				if ( loc() == 0 ) { return; }

				loc( loc() - 1 );

			}

			if ( el.closest('li').find('.icon-solo-thin').hasClass('site-fwd') ) {

				if ( this.observers.state.iframe.history().length <= 1 || this.observers.state.iframe.history().length == loc() ) { return; }

				loc( loc() + 1 );

			}

			src = this.observers.state.iframe.historycache()[loc()];

			if (src) {

				if ( this.observers.state.iframe.history().length == loc() ) {

					cache = cache();
					cache.push(src);

					this.observers.state.iframe.historycache(cache);

				}

				this.observers.state.iframe.updateloc = false;

				$('#siteui-iframe').attr( 'src', src );

			}

		},

		subnavigation: function(el,parent) {

			var id = el.closest('li').attr('id');

			$('#' + $('.current').attr('id')).hide();
			$('.current',parent).removeClass('current');
			$('#' + id).addClass('current').show();

			this.events.ui_init.call(this);

		},

		update_state: function(el,parent) {

			var data	= typeof parent === 'string' && parent || parent.data('state'),
				state	= this.observers.state[data],
				type	= typeof state(),
				out		= ( type === 'string' ) ? el.text().trim().toLowerCase().replace( ' ', '_' ) : !state();

			this.cookie( 'site:state:' + data, '"' + out + '"' );

			state(out);

		},

		view_change: function(el) {
			this.observers.state.view(el.text().toLowerCase());
		},

		save: function(revert, live_updates) {

			var self	= this,
				vis		= [],
				bttn	= $('div#siteui-publish-buttons'),
				data;

			var site_inspector_scrollTop = $('#site-inspector .scroll_wrapper')[0].scrollTop;

			$('ol.lib-nav').each( function() {
				if ( $(this).css('display') !== 'none' && $(this).attr('id') ) {
					vis[vis.length] = $(this).attr('id');
				}
			});

			this.observers.state.left_col_vis(vis);

			revert = revert || false;

			if (revert) {
				var revertNote, revertCompleteMessage;
				if (revert === 'all') {
					revertNote = this.notify().wait('Reverting');
					revertCompleteMessage = 'Draft successfully reverted to live site.';
				} else {
					revertNote = this.notify().wait('Resetting theme');
					revertCompleteMessage = 'Draft successfully reset to theme defaults.';
				}
				data = 'data={"revert": "' + revert + '"}';
			} else {
				var d = $.extend({}, this.unmap(this.observers.site), true),
					only = [ 'custom_css', 'navigation', 'routes', 'settings_send', 'url_data_send', 'pulse_settings_send', 'pulse_settings_group' ];

				$.each(d, function (i, obj) {
					if ($.inArray(i, only) === -1) {
						delete(d[i]);
					}
				});

				data = 'data=' + encodeURIComponent(JSON.stringify(d));
			}

			bttn.addClass('saving');
			this.sync({
				request: 'site/draft:' + this.observers.site.draft_id(),
				method: 'PUT',
				data: data,
				context: this,
				finished: function(data) {
					bttn.removeClass('saving');
					this.observers.drafts.current.modified_on( Math.floor( +new Date() / 1000 ) );
					// Need this to force re-render of page_type settings, to update dependencies
					this.observers.state.main_menu('display');

					if (revert) {
						this.observers.state.loaded_page( this.observers.state.loaded_page() );
					} else {
						delete(data.settings);
					}

					if (this.observers.site.style) {
						this.observers.site.style.variables = {};
					}

					delete(data.custom_css);

					this.map( this.observers.site, data );
					this.events.check_for_custom_styling();

					if (live_updates) {
						var win = $(document.getElementById('siteui-iframe').contentDocument);

						if (live_updates === 'reload') {
							var sheet = win.find('#koken_settings_css_link');
							sheet.after(sheet.clone());
							sheet.attr('id', null);

							if (win.find('#k-lightbox-iframe').length) {
								var lightbox = $(win.find('#k-lightbox-iframe').get(0).contentDocument)

								sheet = lightbox.find('#koken_settings_css_link');
								sheet.after(sheet.clone());
								sheet.attr('id', null);
							}
						} else if (live_updates === 'pulse') {
							var $K = document.getElementById('siteui-iframe').contentWindow.$K,
								group = $K.pulse.groups[ this.observers.site.pulse_settings_group ],
								pulses = win.find('div[data-pulse-group=' + this.observers.site.pulse_settings_group + ']');

							group = $.extend( group, data.pulse_groups[ this.observers.site.pulse_settings_group || {} ] );

							this.observers.override_pulse_settings(group);

							$.each(pulses, function(i, pulse) {
								$K.pulse.register({ id: $(pulse).attr('id'), options: {} });
							});
						}
						$(document).trigger('previewready');
					} else {
						this.events.refresh();
					}

					this.observers.site.settings_send = {};
					this.observers.site.pulse_settings_send = {};

					$('#site-inspector .scroll_wrapper')[0].scrollTop = site_inspector_scrollTop;

					if (revertNote) revertNote.success(revertCompleteMessage);

				}
			});
		},

		update_link_filters: function(data, e) {
			var val = data.link_to_edit.page_type(),
				page_select = $(e.target),
				container = page_select.parents('ul'),
				klass;

			container.children('li').not(':first').hide();
			container.children('li:nth-child(2)').find('select, input').hide();

			$.each(this.observers.site.templates(), function(i, p) {
				if (p.path === val) {
					if (p.info.source) {
						if (p.info.source.collection) {
							container.children('li').show();
							klass = '.collection_' + p.info.source.collection;
							container.find(klass).show();
						} else if (p.info.source.single) {
							container.children('li').not(':last').show();
							klass = '.single_' + p.info.source.single;
							container.find(klass).show();
						}
					}
					page_select.parent().find('span').text( p.info.description );
					return false;
				}
			});
		}

	},

	router: function() {

		return {

			'themes\/install\/([^\:]+)\:([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})(?:\/(\\d+))?$': function(matches) {
				this.observers.state.type('themes');

				var self = this,
					slug = matches[1],
					guid = matches[2],
					purchase = matches[3] ? '&purchase=' + matches[3] : '',
					path = 'themes/' + slug + '-' + guid,
					n = '&new=1';

				$.each(this.observers.themes(), function(i, theme) {
					if (theme.koken_store_guid === guid) {
						path = 'themes/' + theme.path;
						n = '';
						return false;
					}
				});

				setTimeout( $.proxy(function() {
					this.classes.modal.install_theme.show();

					this.sync({
						request: 'update/plugin',
						method: 'POST',
						context: this,
						data: 'local_path=' + path + '&guid=' + guid + '&uuid=' + this.observers.settings.uuid() + n + purchase,
						finished: function(data) {
							if (data.done) {
								this.controllers.fetch_themes(function() {
									this.controllers.load_theme_previews();
									this.classes.modal.install_theme.hide();
									this.notify().success(data.info.name + ' installed');

									// Not using bbq here as it doesn't have a replaceState
									// and we don't want the install URL in the history
									history.replaceState('', '', '#/site/themes');
								});


								this.store_api({
									action: 'plugin_installed',
									guid: guid,
									version: data.info.version
								});
							} else {
								// TODO
							}
						}
					});
				}, this), 500);


			},

			'themes\/?(preview:([^\/]+)\/?)?': function(matches) {

				this.observers.state.type('themes');

				if (matches[1]) {

					var self = this, t;

					// Wait for list to be rendered before transitioning to preview
					var interval = setInterval( function() {
						if ($('#themes-browse li').length > 1 && !$('#themes-browse li.loading').length) {
							$.each(self.observers.themes(), function(i, theme) {
								if (theme.path === matches[2]) {
									t = theme;
									return false;
								}
							});
							setTimeout(function() {
								self.observers.state.preview_obj(t);
								self.observers.state.preview(matches[2]);
							}, 0);
							clearInterval(interval);
						}
					}, 10);

				} else {
					this.observers.state.preview(false);
				}

			},

			'(draft|live)\/?((theme|page)\/?)?(url:(.*)$)?': function(matches) {

				this.observers.sitepanel_pulse.active(false);

				this.observers.state.type(matches[1]);
				this.observers.state.preview(false);

				if (matches[1] === 'draft' && matches[2]) {
					this.observers.state.sidebar(matches[3]);
				}

				if (matches[1] === 'live') {
					this.observers.state.mid_col('preview');
				}

				if (matches[4]) {
					var iframe = $('#siteui-iframe'),
						url = matches[5] || '/',
						current = this.observers.state.iframe.path().split('preview.php?')[1];

					if (current != url) {
						this.events.refresh(url);
					}
				}

			},

			'default': function() {
				$.bbq.pushState('/site/draft/theme/url:/', 2);
			}

		};
	},

	observers: function() {

		var cookies = {};
		var self = this;

		cookies.settings_panel	= ( this.cookie('site:state:settings_panel') === null ) ? false : this.helpers.bool( this.cookie('site:state:settings_panel') );
		cookies.settings_panel_docked	= ( this.cookie('site:state:settings_panel_docked') === null ) ? false : this.helpers.bool( this.cookie('site:state:settings_panel_docked') );
		cookies.expand			= ( this.cookie('site:state:expand') === null ) ? false: this.helpers.bool( this.cookie('site:state:expand') );
		cookies.pages			= ( this.cookie('site:state:pages') === null ) ? true : this.helpers.bool( this.cookie('site:state:pages') );

		return {
			refresh_destination: false,
			empty: ko.observable(1), // Reason for the one is so we can do a strict bool check up in the router, line 2180ish
			error: ko.observable(false),
			pages: ko.observableArray([]),
			site: {
				custom_style: ko.observable(false),
				has_page_template: ko.observable(false),
				title: ko.observable(''),
				tagline: ko.observable(''),
				draft_id: ko.observable(0),
				navigation_groups: ko.observableArray([]),
				navigation: {
					items: ko.observableArray([]),
					groups: ko.observableArray([]),
					active: {
						internals: ko.observableArray([]),
						albums: ko.observableArray([]),
						rss: ko.observableArray([]),
						sections: ko.observableArray([]),
						supported_sections: ko.observableArray([]),
						pages: ko.observableArray([]),
						profiles: ko.observableArray([]),
						group: ko.observable('')
					}
				},
				navigation_draft: ko.observableArray([]),
				settings: ko.observableArray([]),
				settings_flat: {},
				settings_send: {},
				url_data_send: {},
				url_data: {
					home: ko.observable(false)
				},
				custom_css: ko.observable(''),
				pulse_settings_send: {},
				routes: ko.observableArray([]),
				templates: ko.observableArray([]),
				templates_grouped: {
					indexes: ko.observableArray([]),
					specials: ko.observableArray([]),
					special_paths: ko.observableArray([])
				},
				styles: ko.observableArray([]),
				published: ko.observable(true)
			},
			published_theme: ko.observable(''),
			site_loaded: ko.observable(false),
			sitepanel_pulse: {
				active: ko.observable(false),
				title: ko.observable(''),
				id: ko.observable('')
			},
			drafts: {
				all: ko.observableArray([]),
				history: ko.observableArray([]),
				current: {
					theme: {
						name: ko.observable(''),
						path: ko.observable(''),
						preview: ko.observable(''),
						author: {
							name: ko.observable(''),
							link: ko.observable('')
						}
					},
					published: ko.observable(false),
					active: ko.observable(false),
					created_on: ko.observable(0),
					modified_on: ko.observable(0)
				}
			},
			sheets: {
				draft_history: {
					active: ko.observable(false),
					id: ko.observable('draft_history')
				},
				create_link_template: {
					active: ko.observable(false),
					id: ko.observable('create_link_template')
				},
				create_link: {
					active: ko.observable(false),
					id: ko.observable('create_link')
				},
				edit_link_data: {
					active: ko.observable(false),
					id: ko.observable('edit_link_data'),
					onload: function() {
						$('#edit_link_data li input').focus().off('.edit_link_data').on('keyup.edit_link_data', function(e) {
							if ( e.which === 13 ) {
								$('#edit_link_data li button[data-event="update_link"]').trigger('click');
							}
						});
						this.interfaces.app.observers.valid(true);
					}
				},
				current_page_link: {
					active: ko.observable(false),
					id: ko.observable('current_page_link'),
					onload: function() {
						var title = (document.getElementById('siteui-iframe').contentDocument.title || '').replace(RegExp('[^a-zA-Z]+' + this.observers.site.title()), '');
						$('#current_page_link #link_to_current_label').val(title).trigger('keyup').focus().off('keydown').on('keydown', function(e) {
							if ( e.which === 13 ) {
								$('#current_page_link li button[data-event="link_to_current"]').trigger('click');
							}
						});
						this.observers.valid(true);
					}
				},
				create_custom_link: {
					active: ko.observable(false),
					id: ko.observable('create_custom_link')
				},
				edit_nav: {
					active: ko.observable(false),
					id: ko.observable('edit_nav'),
					link_type: ko.observable('internal'),
					filter: ko.observable('All'),
					reset: function() {
						$('#sheetview-edit-nav').css({
							left: '0px',
							opacity: 1
						}).parent()
						.find('#sheetview-create-link').css('left', '680px');
					},
					link_to_edit: {
						label: ko.observable(''),
						path: ko.observable(''),
						checked: ko.observable(false),
						groups: ko.observableArray( ['Primary'] ),
						is_new: ko.observable(true),
						source: {
							collection: ko.observable(false)
						},
						clone: {},
						page_type: ko.observable(''),
						front: ko.observable(false)
					}
				},
				source_filter: {
					available: ko.observable(false),
					active: ko.observable(false),
					id: ko.observable('source_filter'),
					onload: function() {
						$('#source_filter li input:first').focus().off('click').on('keydown', function(e) {
							if ( e.which === 13 ) {
								$('#source_filter li button[data-event="apply_filters"]').trigger('click');
							}
						});
						this.classes.autocomplete.site.tags($('#source_filter input.widefilter_tag'), $('#source_filter input.widefilter_tag').parent(), $('#source_filter [data-event="add_filter_tag"]'));
					},
					source: ko.observable(''),
					is_album: ko.observable(false),
					is_orderable: ko.observable(true),
					display: ko.observable('all'),
					filter: ko.observable('none'),
					order_by: ko.observable(''),
					order_direction: ko.observable(''),
					category: ko.observable(0),
					tags: ko.observableArray([]),
					custom_source_keys: ko.observableArray([]),
					current: ko.observable(true),
					label: ko.observable(''),
					type: ko.observable('index'),
					section: ko.observable(false),
					route: {},
					index_route: {},
					month: ko.observable('any'),
					year: ko.observable(''),
					years: function() {
						var now = +new Date().getFullYear(),
							arr = [];

						while (now >= 2000) {
							arr.push(now);
							now--;
						}

						return arr;
					}(),
					tag_option: ko.observable('any'),
					sheet_title: ko.observable(''),
					templates: {
						supported_archives: ko.observableArray([])
					},
					set: function(obj, route, url_data) {
						var source = obj.source;
						if (source === 'album') {
							source = 'contents';
							this.is_album(true);
						} else if (source === 'set') {
							source = 'albums';
							this.is_album(true);
						} else {
							this.is_album(false);
						}

						if ($.inArray(source, [ 'favorites', 'featured_albums', 'featured_content', 'featured_essays', 'contents', 'albums', 'sets', 'essays', 'tags', 'categories', 'timeline']) !== -1) {
							var title, order_set = false;
							if ($.inArray(source, ['tags', 'categories', 'archives']) !== -1) {
								this.type('archives');
								title = source === 'archives' ? 'date' : source;
							} else {
								this.type('index');
								if (source === 'contents') {
									title = 'content';
								}
								else
								{
									title = source;
								}
							}

							this.is_orderable( (source === 'contents' && this.is_album()) || ( source.indexOf('featured_') === 0 || source === 'favorites' || source === 'albums' ) || ( source === 'essays' && this.display === 'featured' ) );

							this.sheet_title('Add new ' + (obj.sheet_title || title.replace('_', ' ')) + ' page');
							this.source(source);
							this.display('all');
							this.filter('none');
							this.category(0);
							this.month('any');
							this.year(this.years[0]);
							this.tags([]);
							this.section(route && route.section);

							if (this.section() && source !== 'timeline') {

								var order_type = 'order',
									order = url_data[ source === 'categories' ? 'category' : source.replace(/s$/, '') ][order_type]().toLowerCase().split(' ');

								this.order_by(order[0]);
								this.order_direction(order[1]);

							} else {

								switch(source) {
									case 'albums':
									case 'featured_albums':
									case 'featured_content':
									case 'featured_essays':
									case 'favorites':
										this.order_by('manual');
										this.order_direction('asc');
										break;

									case 'contents':
									case 'essays':
										this.order_by('published_on');
										this.order_direction('desc');
										break;

									case 'sets':
										this.order_by('title');
										this.order_direction('desc');
										break;
								}

								if (obj.filters) {
									var order_direction = false;

									$.each(obj.filters, function(i,f) {
										if (f === 'featured') {
											this.display('featured');
										} else if (f === 'favorites') {
											this.display('favorites');
										} else if ($.inArray(f, self.observers.user_custom_sources_keys[source]()) !== -1) {
											this.display(f);
										} else if (f.indexOf('tags=') === 0) {
											this.filter('tag');
											this.tags( f.split('tags=')[1].split(',') );
										} else if (f.indexOf('order_') === 0 || f.indexOf('category=') === 0 || f.indexOf('month=') === 0 || f.indexOf('year=') === 0) {
											var bits = f.split('=');

											if (bits[0] === 'order_by') {
												order_set = true;
											} else if (bits[0] === 'order_direction') {
												// See below
												order_direction = bits[1];
												return;
											}

											if (typeof this[bits[0]] === 'function') {
												this[bits[0]](bits[1]);
											}
											if (f.indexOf('category=') === 0) {
												this.filter('category');
											}

											if (f.indexOf('month=') === 0 || f.indexOf('year=') === 0) {
												this.filter('archive');
											}
										}
									}.bind(this));

									// Set this here to prevent order_by subscriber from trampling user value
									if (order_direction) {
										this.order_direction(order_direction);
									}
								}

								if (!order_set && (source === 'favorites' || source === 'contents' || source === 'albums' || source.indexOf('featured_') === 0) && (this.display() !== 'all' || this.is_album())) {
									this.order_by('manual');
								}

							}

							this.label( obj.label || '');

						}
					}
				}
			},
			state: {
				pages: ko.observable(cookies.pages),
				edit: ko.observable('edit_theme'),
				expand: ko.observable(cookies.expand),
				left_col: ko.observable('settings'),
				left_col_vis: ko.observable([]),
				right_col: ko.observable('info'),
				mid_col: ko.observable('preview'),
				main_menu: ko.observable('display'),
				add_link: ko.observable(false),
				view: ko.observable('customize'),
				gear: ko.observable(false),
				themes: ko.observable('installed'),
				create_link: ko.observable(false),
				create_custom_link: ko.observable(false),
				edit_link_data: ko.observable(false),
				current_page_link: ko.observable(false),
				source_filter: ko.observable(false),
				type: ko.observable('draft'),
				loaded_page: ko.observable({}),
				loaded_route: ko.observable({}),
				settings_panel: ko.observable(cookies.settings_panel),
				settings_panel_docked: ko.observable(cookies.settings_panel_docked),
				preview: ko.observable(false),
				preview_obj: ko.observable({}),
				sidebar: ko.observable('theme'),
				editor_type: ko.observable('dock'),
				iframe: {
					cache: true,
					path: ko.observable(''),
					rel_path: ko.observable(''),
					history: ko.observableArray([]),
					fwd: ko.observableArray([]),
					updateloc: true,
					location: ko.observable(0),
					historycache: ko.observableArray([])
				}
			},

			top_content: ko.observable(0),
			top_essay: ko.observable(0),

			override_pulse_settings: function(overrides) {

				function set_val(el, val) {

					if (el.attr('type') === 'checkbox') {
						el.attr('checked', val);
					} else {
						el.val(val);
						if (el.siblings('span.display_value')) {
							el.siblings('span.display_value').text(val);
						}
					}

					if (el.attr('type') === 'ccolor' && el.parents('.theme_option').find('.coloralpha').length) {
						var alpha = el.parents('.theme_option').find('.coloralpha'),
							value = val.match(/\,\s?([\d\.]+)\)$/)[1]*100;

						alpha.val(value);
						alpha.siblings('span.display_value').text(value);
					}

				}

				var settings = {};

				$.each($('#siteui-panel-pulse input[data-default-value], #siteui-panel-pulse select[data-default-value]'), function(i, el) {
					settings[$(el).attr('id')] = $(el).data('default-value');
				});

				if (overrides.width || overrides.height) {
					$('#pulse_option_size').parents('.theme_option').hide();
				}

				$.each(overrides, function(i, setting) {
					settings['pulse_option_' + i] = setting;
				});

				$.each(settings, function(i, setting) {
					set_val($('#' + i), setting);
				});

				new this.klass.colorpicker('site', $('input[type="ccolor"]'));

				var $K = document.getElementById('siteui-iframe').contentWindow.$K;

				$('#pulse_option_link_to').find('option[value="album"]').remove();

				if (this.observers.sitepanel_pulse.active() && $K.pulse.refs[this.observers.sitepanel_pulse.id()]) {
					if ($K.pulse.refs[this.observers.sitepanel_pulse.id()].options().can_link_to_album) {
						$('#pulse_option_link_to').append(
							$('<option/>').attr({
									value: 'album',
									selected: overrides.link_to === 'album'
								})
								.text('Load album page')
						);
					}
				}
			},

			page_data_label: function(d) {
				var capitalize = function(str) {
					return str.charAt(0).toUpperCase() + str.slice(1);
				};
				if (d.collection) {
					return capitalize( d.collection ) + ' list';
				} else if (d.single) {
					return 'Single ' + d.single;
				}
			},

			album_id: function(path) {
				var p = this.site.routes[path].source;

				if (!p) {
					return 0;
				} else if (p.single && p.id) {
					return p.id;
				}
				return 0;
			},

			selected_options: function(path) {
				var p = this.site.routes[path].source;

				if (!p) {
					return 'default';
				} else if (p.featured) {
					return 'featured';
				} else if (p.category) {
					return 'category';
				} else if (p.tag) {
					return 'tag';
				}
			}

		};
	}

};

	_Koken.prototype.store = {

	mailbox: false,

	exit: function() {
		$(window).off('message');
	},

	init: function() {
		var self = this,
			// Keep track of iframe src outside of observer
			// Helps with back/fwd button without causing refreshes
			current_iframe_src = '';

		// Store sends messages back in the form of a payload object
		// Useing e.originalEvent because jQuery overwrites original event
		$(window).off('message').on('message', function(e) {
			// lastClickedElement doesn't pick up clicks from inside the iframe,
			// so we have to empty it here to keep the hashchange event from
			// bouncing right back to /store.
			self.classes.app.koken.lastClickedElement = {};

			var data = e.originalEvent.data;

			switch(data.action) {
				case 'force_store':
					location.href = self.config.paths.store + data.url;
					break;

				case 'loaded':
					if (data.uuid !== self.observers.settings.uuid()) {
						self.mailbox.postMessage({
							resetContext: true
						}, self.config.paths.store);
					} else {
						current_iframe_src = data.url.replace(self.config.paths.store + '/', '');
						if (self.observers.history.moving) {
							self.observers.history.moving = false;
						} else {
							self.observers.history.entries(self.observers.history.entries.slice(0, self.observers.history.index()+1));
							self.observers.history.entries.push(current_iframe_src);
							self.observers.history.index(self.observers.history.entries().length-1);
						}
						self.observers.store_url(current_iframe_src);
						self.observers.title(data.title);
					}
					break

				case 'observers':
					$.each(data.observers, function(i, value) {
						if (self.observers[i]) {
							self.observers[i](value);
						}
					});
					break;

				case 'ping':
					self.mailbox.postMessage({
						uuid: self.observers.settings.uuid(),
						path: self.config.paths.base_relative,
						php: self.system.php_version,
						version: self.system.version,
						image_processing: self.system.image_processing_support
					}, self.config.paths.store);
					self.observers.pinged(true);
					break;

				case 'notification':
					self.notify()[data.type](data.message);
					break;

				case 'update':
					self.controllers.update_products(data.guid);
					break;

				case 'update_koken':
					self.events.install_release();
					break;

				case 'cookie':
					// A message is posted from the store here when the cookie has not been set properly.
					// For Chrome, we immediately show a walkthrough on how to enable 3rd party cookies,
					// as redirecting to the store then back won't help. So Chrome users never get here.

					// For everything else, try a redirect. This will work for Safari as well as
					// Firefox if the 3rd party cookie setting is set to "From visited". If Firefox
					// is set to "Never", it won't work and when it bounces back we'll show a walkthrough
					// on how to enable 3rd party cookies via exceptions.

					// FFFFFFFUUUUUUUUUUU
					var redirect = location.href.replace(/\/(cookie_return\/?)?$/, '') + '/cookie_return';
					location.href = self.config.paths.store + '/sing?redirect=' + encodeURIComponent(redirect);
					break;
				default:
					$.bbq.pushState( data.url, 2 );
					break;
			}
		});

		this.observers.store_url.subscribe(function(val) {
			$.bbq.pushState('/store/' + val, 2);
			if (val !== current_iframe_src && this.observers.pinged()) {
				this.mailbox.postMessage({
					navigate: '/' + val
				}, self.config.paths.store);
			}
		}, this);

		this.observers.state.expand.subscribe(function(val) {
			if (val) {
				$('#app').addClass('site-expand');
			} else {
				$('#app').removeClass('site-expand');
			}
		}, this );

		var init_url = this.config.paths.store + '/';
		if (!this.observers.pinged()) {
			init_url += 'create_context?to=/' + (location.hash.indexOf('cookie_return') !== -1 ? '&cookietry=1' : '');
		}

		setTimeout(function() {
			self.mailbox = document.getElementById('store-iframe').contentWindow;
			$('#store-iframe').attr('src', init_url	+ self.observers.store_url());
		}, 0);
	},

	controllers: {
		update_products: function(guids) {

			this.classes.modal.update_products.show();

			var notification,
				single = guids.length === 1;

			if (!single) {
				notification = guids.length + ' products updated';
			}

			var refresh_themes = false,
				refresh_plugins = false,
				total = guids.length;

			var next = $.proxy(function() {
				var guid = guids.shift(),
					path = false,
					name;

				this.classes.modal.update_products.progress((total-guids.length)/total);

				$.each(this.observers.themes(), function(i, theme) {
					if (theme.koken_store_guid === guid) {
						path = 'themes/' + theme.path;
						refresh_themes = true;
						name = theme.name;
						return false;
					}
				});

				if (!path) {
					$.each(this.observers.user_plugins(), function(i, plugin) {
						if (plugin.koken_store_guid === guid) {
							path = 'plugins/' + plugin.path + (plugin.id ? '&id=' + plugin.id : '');
							refresh_plugins = true;
							name = plugin.name;
							return false;
						}
					});
				}

				if (single) {
					notification = name + ' updated';
				}

				if (path) {
					$('#product_update_label').text('Please wait. "' + name + '" is being updated.')
					this.sync({
						request: 'update/plugin',
						method: 'POST',
						context: this,
						data: 'local_path=' + path + '&guid=' + guid + '&uuid=' + this.observers.settings.uuid(),
						finished: function(data) {
							if (data.done) {

								this.observers.update_count( this.observers.update_count() - 1 );

								this.store_api({
									action: 'plugin_installed',
									guid: guid,
									version: data.info.version
								}, $.proxy(function() {
									if (guids.length) {
										next();
									} else {
										if (refresh_plugins) {
											this.sync({
												request: 'plugins',
												context: this,
												finished: function(d) {
													this.observers.process_plugin_data(d, true);
												}
											});
										}

										if (refresh_themes) {
											this.controllers.fetch_themes();
										}

										this.classes.modal.update_products.hide();

										this.notify().success(notification);

										this.mailbox.postMessage({
											navigate: '/updates'
										}, this.config.paths.store);
									}
								}, this));
							} else {
								var self = this;
								this.classes.modal.update_products_fail.show();
								$(document)
									.on( 'click', '#clear_error', function() {
										self.classes.modal.update_products_fail.hide();
									});
							}
						}
					});
				}
			}, this);

			next();
		}
	},

	events: {
		history: function(el, parent) {
			if ($(parent).hasClass('back')) {
				var index = this.observers.history.index() - 1;
			} else {
				var index = this.observers.history.index() + 1;
			}

			this.observers.history.moving = true;
			this.observers.history.index(index);

			this.mailbox.postMessage({
				navigate: '/' + this.observers.history.entries()[index]
			}, this.config.paths.store);
		},

		update_state: function(el,parent) {

			var data	= typeof parent === 'string' && parent || parent.data('state'),
				state	= this.observers.state[data],
				type	= typeof state(),
				out		= ( type === 'string' ) ? el.text().trim().toLowerCase().replace( ' ', '_' ) : !state();

			if (data === 'pages') {
				this.cookie( 'store:state:' + data, '"' + out + '"' );
			}

			state(out);

		},
	},

	observers: function() {
		var cookies = {
			pages: ( this.cookie('store:state:pages') === null ) ? true : this.helpers.bool( this.cookie('store:state:pages') )
		};

		return {
			state: {
				expand: ko.observable(false),
				pages: ko.observable(cookies.pages)
			},
			store_url: ko.observable(''),
			cart_count: ko.observable(0),
			updates_count: ko.observable(0),
			username: ko.observable('Account'),
			support_link: ko.observable(''),
			pinged: ko.observable(false),
			title: ko.observable('Koken Store'),
			history: {
				entries: ko.observableArray([]),
				index: ko.observable(0),
				moving: false
			}
		}
	},

	router: function() {

		return {

			'default': function(url) {
				url = url || '';
				this.observers.store_url(url);
			}

		}

	}

};
	_Koken.prototype.text = {

	exit: function() {
		this.controllers.end_autosave();
	},

	init: function() {

		var self = this;

		// TODO: Namespace all keyboard shortcuts
		$(window).off('keydown');

		$(window).on('text.resize', function() {
			$('#rcol-entry-edit').css('height', $('#three-col-right').height() - ( $('#text_feat_img').is(':visible') ? $('#text_feat_img').height() : 0 ) - 30);

			var head = $('#three-col-right').find('.col-select'),
				sidebar = $('#right-col-xtra');

			if ( head.length <= 0 || !head.is(':visible') ) {
				head = $('#three-col-right div').eq(1);
				sidebar.css({
					marginTop: 0 + 'px',
					height: ( ( $(window).height() - head.height() ) - head.offset().top ) - 19 + 'px'
				});
			} else {
				sidebar.css({
					marginTop: ( head.height() + 29 ) + 'px',
					height: ( ( $(window).height() - head.height() ) - head.offset().top ) - 46 + 'px'
				});
			}
		});

		$(document).on('change.custom-fields', 'select.fields-data, input[type="checkbox"]', function() {
			var data = self.observers.current_shortcode_data();
			self.observers.current_shortcode_data([]);
			self.observers.current_shortcode_data(data);
		});

		// Start listening for sliders
		new this.klass.slider_number_toggle('text');
		// Start validation listeners
		new this.klass.form_validation('text');
		// Start autocomplete
		new this.klass.autocomplete('text');

		$(document)
			.off('.text-editor')
			.on('click.text-editor', '#entry-edit', function(e) {
				// Ignore header and toolbar
				if (e.target.nodeName !== 'DIV') {
					$('.entry-elem').removeClass('selected');
					$('p.media-row').not(':last-child').filter(function() { return $(this).children().length === 1 && e.target !== this; }).remove();
				}
			})
			.on('click.text-editor', '.entry-elem', function(e) {
				var t = $(e.target);

				if (t.hasClass('entry-elem')) {
					$('.entry-elem').not(t).removeClass('selected');
					t.toggleClass('selected');
				}
			})
			.on('click.text-editor', '.redactor_btn_formatting', function() {
				if ( parseInt( $('.redactor_air').css('top'), 10 ) > $(window).height() - 300 ) {
					$('.redactor_dropdown').css( 'top', parseInt( $('.redactor_dropdown').css('top'), 10 ) - 300 );
				}
			})
			.on('click.text-editor', '.elem-title', function(e) {

				var p = $(this).parent('div'),
					preview = p.find('.elem-preview');

				if (preview.children().length) {
					if (preview.is(':visible') && (preview.hasClass('slideshow') || preview.find('video'))) {
						preview.replaceWith($('<div/>').addClass('elem-preview'));
					}
					preview.toggle();
				} else {
					var info = self.controllers.parse_shortcode(p.find('.raw-code').text());

					if (info.params.id && info.params.media_type) {

						p.addClass('loading');
						$.get(self.config.paths.base + 'api.php?/content/' + info.params.id, function(data) {
							if (data.file_type === 'image') {
								$('<img/>')
									.attr('src', data.presets.medium_large[self.config.retina ? 'hidpi_url' : 'url'])
									.bind('load', function() {
										p.removeClass('loading');
										preview.toggle();
									})
									.appendTo(preview);
							} else {
								preview.toggle();
								if (data.original && data.original.url) {
									var v = $('<video/>').attr({
										src: data.original.url,
										controls: true,
										width: '100%',
										preload: 'metadata'
									}).appendTo(preview);

									v.mediaelementplayer({
										pluginPath: 'js/',
										flashName: 'flashmediaelement.swf'
									});
								} else if (data.content_html) {
									$(data.content_html).width('100%').height(400).appendTo(preview);
								} else if (data.html) {
									$(data.html).width('100%').height(400).appendTo(preview);
								}
								p.removeClass('loading');
							}
						});

					} else if (info.params.album || info.params.content) {

						var id = 'pulse-' + $('#edit-area').children().index(p),
								target = $('<span/>').attr('id', id).appendTo(preview);

						preview.addClass('slideshow');
						p.addClass('loading');

						var script = document.createElement('script');
						script.src = self.config.paths.base + 'app/site/themes/common/js/pulse.js';
						script.onload = function() {
							var opts = {
								size: '3:2',
								dataUrl: self.config.paths.base + 'api.php?/',
								link_to: 'advance',
								autostart: true
							};

							if (info.params.content) {
								opts.dataUrl += 'content/' + info.params.content;
							} else if (info.params.album) {
								opts.dataUrl += 'albums/' + info.params.album + '/content';
							}

							p.removeClass('loading');
							preview.toggle();

							Pulse('#' + id, opts);
						};
						document.getElementsByTagName('head')[0].appendChild(script);

					} else if (info.name === 'koken_oembed') {
						p.addClass('loading');
						self.sync({
							request: 'text/oembed_preview',
							method: 'POST',
							data: 'url=' + encodeURIComponent(info.params.endpoint + ( info.params.endpoint.indexOf('?') === -1 ? '?' : '&' ) + 'url=' + info.params.url),
							context: self,
							finished: function(data) {
								if (data.html) {
									var html = $(data.html);
									html.appendTo(preview);
									preview.toggle();
									preview.fitVids();
									p.removeClass('loading');
								} else {
									$('<img/>')
										.attr('src', data.url)
										.bind('load', function() {
											p.removeClass('loading');
											preview.toggle();
										})
										.appendTo(preview);
								}
							}
						});
					} else if (info.params.filename) {
						p.addClass('loading');
						$('<img/>')
							.attr('src', info.params.filename.indexOf('http') === 0 ? info.params.filename : self.config.paths.base + 'storage/custom/' + info.params.filename)
							.bind('load', function() {
								p.removeClass('loading');
								preview.toggle();
							})
							.appendTo(preview);
					} else {
						$(this).parent().find('a[data-event="edit_shortcode"]').trigger('click');
					}

				}

				e.preventDefault();

			});

		this.sync({
			request: 'favorites',
			context: this,
			finished: function(data) {
				this.observers.sheets.shortcode_collection.favorites( $.map(data.content, function(c) { return c.id; }) );
			}
		});

		if (this.observers.settings.last_upload()) {
			this.sync({
				request: 'content/after:' + this.observers.settings.last_upload() + '/order_by:uploaded_on',
				context: this,
				finished: function(data) {
					this.observers.sheets.shortcode_collection.last_upload( $.map(data.content, function(c) { return c.id; }) );
				}
			});
		}

		if (this.persist('library.quick_collection') && this.persist('library.quick_collection').length > 1) {
			this.observers.sheets.shortcode_collection.quick_collection( this.persist('library.quick_collection').split(',').map( function(id) { return Number(id); }) );
		}

		function trip_sheet_resize() {
			requestAnimationFrame(function() {
				$(window).trigger('resize');
			});
		}

		this.observers.sheets.shortcode_media.editing.subscribe(trip_sheet_resize);
		this.observers.sheets.insert_link.state.type.subscribe(trip_sheet_resize);

		this.observers.page_to_edit.__featured_image.subscribe(function(val) {
			if (!val) {
				setTimeout(function() {
					$(window).trigger('resize');
				}, 250);
				return;
			}
			var src = val.presets.medium[this.config.retina ? 'hidpi_url' : 'url'];
			requestAnimationFrame(function() {
				var i = new Image();
				i.onload = function() {
					var img = $('#text_feat_img img'),
						h,
						css = {};
					if (i.width > i.height) {
						css = {
							width: 219,
							height: 219/(i.width/i.height)
						};
					} else {
						css = {
							width: 'auto',
							height: 219
						};
					}

					css.opacity = 0;

					img.css(css);

					setTimeout(function() {
						img.attr('src', src).css('opacity', 1);
						$(window).trigger('resize');
						img.parents('.loading').removeClass('loading');
					}, 500);
				};
				i.src = src;
			});
		}, this);

		this.observers.current_shortcode_data.subscribe(function() {
			this.controllers.setup_custom_shortcode_sort();
		}, this);

		this.observers.current_shortcode.subscribe(function(val) {

			this.observers.current_shortcode_data([]);

			var obj = {};

			if (this.observers.to_replace) {
				var code = this.observers.to_replace.find('.raw-code').text();
				obj = this.controllers.parse_shortcode(code).params;
			}

			var data = [],
				self = this;

			if (val.data) {
				$.each(val.data, function(key, info) {
					var info_clone = $.extend(info, { key: key });
					if (obj.hasOwnProperty(key)) {
						info_clone.value = self.controllers.unescape_code(obj[key]);
						if (/^(fields_array|recaptcha)$/.test(info.type)) {
							info_clone.value = JSON.parse(info_clone.value);
						}
					} else if (info.type === 'link') {
						info_clone.value = 'false';
					} else if (key === 'recaptcha') {
						// let defaults passthrough
					} else if (self.observers.to_replace) {
						delete(info_clone.value);
					}
					data.push( info_clone );
				});
			}

			this.observers.current_shortcode_data(data);

		}, this);

		this.observers.sheets.shortcode_media.isreplacing.subscribe( function(val) {
			if ( val === true ) {

				this.observers.sheets.shortcode_media.isreplacing(false);

				var self = this;

				if ( this.observers.sheets.shortcode_media.loaded() ) {
					var cp = $('#sheetview-img-insert .lib-content').empty().closest('section').parent().html();
					$('#sheetview-img-insert').empty().html(cp);
				}

				this.observers.sheets.shortcode_media.selected.view('/content');
				this.controllers.load_content( '.lib-content', 'content', '#sheetview-img-insert', self.observers.sheets.shortcode_media.type(), 'public' );
				this.observers.sheets.shortcode_media.loaded(true);

			}
		}, this);

		this.observers.theme.subscribe(function(val) {

			var klass = '';

			this.cookie( 'text:theme', '"' + val + '"' );

			switch(val) {
				case 0:
					klass = 'light';
					break;
				case 1:
					klass = 'dark';
					break;
			}

			$('#entry-edit-area').removeAttr('class').addClass(klass);

		}, this);

		this.observers.sheets.shortcode_media.active.subscribe(function(val) {
			if ( val === true ) {

				var self = this,
					type = this.observers.sheets.shortcode_media.type(),
					libUrl = $('.head-link.library-interface').attr('href').replace('#/library', ''),
					firstRun = true,
					loadOverridden = false,
					navItems = ['albums','after','last_upload','favorites','featured','quick_collection'];

				$.each(navItems, function(i, item) {
					if (libUrl.indexOf(item) !== -1) {
						var urlToLoad;
						if (item === 'albums') {
							urlToLoad = libUrl;
						} else if (item === 'after') {
							urlToLoad = 'last_upload';
						} else {
							urlToLoad = $('#view_select').find('option[class="' + item + '"]').val();
						}
						loadOverridden = true;
						self.observers.sheet_media_chosen.primary((item !== 'after') ? item : 'last_upload');
						self.observers.sheets.shortcode_media.selected.view(item);
						self.controllers.load_content('.lib-content', urlToLoad, '#sheetview-img-insert', type, 'public');
						return false;
					}
				});

				$('#view_select').off('change').on('change', function(e) {

					var sel	= $(this).find('option:selected'),
						url	= sel.val();

					self.observers.sheet_media_chosen.primary( sel.attr('class') );
					self.observers.sheets.shortcode_media.selected.view(url);

					$('#container').trigger('sheet.insert_media');

					if (url === 'public_albums') {
						$('#album_select').on('change', function(e) {
							var url	= $(this).find('option:selected').val();
							self.observers.sheet_media_chosen.secondary( $(this).find('option:selected').attr('data-id') );
							self.controllers.load_content( '.lib-content', url, '#sheetview-img-insert', type, 'public' );
							self.classes.scrollbar['sheetview-img-insert'].reset();
						});
					} else if (!loadOverridden) {
						if ( $('#sheetview-img-insert li').length > 0 ) $('#sheetview-img-insert ul').empty();
						$('#album_select option.none').prop('selected', 'selected');
						self.observers.sheet_media_chosen.secondary('0');
						self.controllers.load_content( '.lib-content', url, '#sheetview-img-insert', type, 'public' );
					}

					loadOverridden = false;

					if (url === 'content') $('#album_select').hide();

					requestAnimationFrame(function() {
						if (firstRun && libUrl.indexOf('albums') >= 0) {
							firstRun = false;
							$('#album_select')
								.find('option[value="' + libUrl + '"]')
								.prop('selected', 'selected');
						}
					});

				});

			}
		}, this);

		this.observers.sheets.shortcode_media.type.subscribe( function(val) {
			this.observers.sheets.shortcode_media.loaded(false);
		}, this);

		this.controllers.fetch_drafts();

		this.observers.selection.subscribe(function(val) {

			if (val) {

				var index = $('#entry-' + val).data('index'),
					item = this.observers.list()[index];

				this.controllers.load_edit(item);

			} else if (this.observers.last_edit) {

				var el = $('#entry-' + this.observers.last_edit);
				$('#edit-area').destroyEditor();
				$('#entry-edit').remove();
				self.observers.view('list');
				this.events.close_sidebar_drawer();

			}

			this.observers.last_edit = val;
		}, this);

		var excerpt_timer;
		this.observers.page_to_edit.excerpt.subscribe(function(val) {
			if ( this.observers.firstPageLoad() ) { this.observers.firstPageLoad(false); return false; }
			var self = this;
			clearTimeout(excerpt_timer);
			excerpt_timer = window.setTimeout(function() {
				clearTimeout(excerpt_timer);
				$('#entries li[id="entry-' + self.observers.page_to_edit.id() + '"] .entry-text .entry-p p').text(self.observers.truncate(val, 150));
				$.each( self.observers.list(), function(k,v) {
					if ( v.id === self.observers.page_to_edit.id() ) {
						self.observers.list()[k].excerpt = val;
						return false;
					}
				});
				self.sync({
					request: 'text/' + self.observers.page_to_edit.id(),
					method: 'PUT',
					data: 'excerpt=' + val,
					context: self
				});
			}, 1000);
		}, this);

		this.observers.page_to_edit.draft_title.subscribe(function(val) {
			var current = $('#entry-edit #entry-title h2');
			if (current.is(':visible') && current.text() !== val) {
				$('#entry-edit #entry-title h2').text(val);
				this.observers.hasChanges(true);
				this.controllers.start_autosave();
			}
		}, this);

		this.observers.state.subscribe(function(val) {
			this.controllers.rewrite_hash({ name: 'state', val: val === 'draft' ? val : false });
		}, this);

		this.observers.drawer.state.subscribe( function(val) {
			if ( val === 'closed' ) {
				this.events.close_sidebar_drawer();
			}
		}, this);

		var	old_title = '';

		$(document)
			.off('.textedit')
			.on('keyup.textedit', '#edit-area', function(e) {

				var scrollOffset = $(this).closest('#entry-scroll').find('.scroll_wrapper').get(0).scrollHeight - (parseInt($(this).closest('#entry-scroll').find('.scroll_track').height(), 10) + parseInt($(this).closest('#entry-scroll').find('.scroll_wrapper').scrollTop(), 10 ));

				if ( scrollOffset <= 100 && scrollOffset > 0 ) {
					$(this).closest('#entry-scroll').find('.scroll_wrapper').get(0).scrollTop = $(this).closest('#entry-scroll').find('.scroll_wrapper').get(0).scrollHeight;
				}

				self.observers.hasChanges(true);

				$(this).find('p').each(function(i, p) {
					if ($.trim(p.textContent).length === 0 && $(p).html() === '<br>') {
						$(p).addClass('media-row').html('<br />');
					} else {
						$(p).removeClass('media-row');
					}
				});

				var sel = $('.entry-elem.selected');

				if (sel.length && e.keyCode === 13) {
					var p = $('<p/>').addClass('media-row').html('<br />');
					sel.before(p);
					window.Selection.setSelection(window, p.get(0));
					sel.removeClass('selected');
				}

				self.controllers.start_autosave();
			})
			.on('focus.textedit', '#entry-title h2', function(e) {
				// Save title in case user escapes while typing
				old_title = $('#entry-title h2:first').text();
			})
			.on('paste.textedit', '#entry-title h2', function(e) {
				var s = $(this);
				requestAnimationFrame(function() {
					s.text( s.text() );
				});
			})
			.on('keyup.textedit keydown.textedit', '#entry-title h2:first', function(e) {

				var tgt = $('#entry-title h2:first');

				if (e.type === 'keydown') {
					if (e.keyCode === 27) {
						// ESC
						tgt.text( old_title );
						tgt.blur();
					} else if (e.keyCode === 13) {
						// RETURN
						tgt.blur();
						e.preventDefault();
					}
				} else {
					self.observers.page_to_edit.draft_title( tgt.text() );
				}

				if (e.keyCode !== 13 && e.keyCode !== 27) {
					self.observers.hasChanges(true);
					self.controllers.start_autosave();
				}

			})
			.on('dblclick.textedit', '#media-browse-content li', function(e) {
				$('button[data-event="insert_photo"]').trigger('click');
			});

		this.controllers.load_albums_full();

	},

	controllers: {
		setup_custom_shortcode_sort: function() {
			var self = this;

			requestAnimationFrame(function() {
				$('.fields-data-sortable').sortable({
					handle: 'td:first-child',
					stop: function() {
						var $el = $(this);
						var key = $el.attr('data-field');

						var newData = $.map($el.find('tr.fields-data-row'), function(tr) {
							return [ko.dataFor(tr)];
						});

						var shortData = self.observers.current_shortcode_data();
						$.each(shortData, function(i, field) {
							if (field.key === key) {
								field.value = newData;
								return false;
							}
						});

						self.observers.current_shortcode_data([]);
						self.observers.current_shortcode_data(shortData);
					}
				});
			});
		},

		fetch_drafts: function() {
			this.sync({
				request: 'text/drafts/limit:5/order_by:modified_on',
				context: this,
				finished: function(data) {
					this.observers.drafts( data.text );
				}
			});
		},

		featured_from_oembed: function(obj) {
			this.sync({
				request: 'text/oembed_preview',
				method: 'POST',
				data: 'url=' + encodeURIComponent(obj.endpoint + ( obj.endpoint.indexOf('?') === -1 ? '?' : '&' ) + 'url=' + obj.url),
				context: this,
				finished: function(data) {
					if (data.type !== 'photo' && (data.large_thumbnail_url || data.thumbnail_url)) {
						this.events.save_feature(data.large_thumbnail_url || data.thumbnail_url);
					} else if (data.url) {
						this.events.save_feature(data.url);
					}
				}
			});
		},

		featured_order: function() {
			var self = this;
			$('#entries').sortable({
				distance: 10,
				axis: 'y',
				handle: '.move',
				containment: '#entry-list-all',
				update: function(a) {
					var order = [],
						note = self.notify().wait('Reordering featured essays');
					$('#entries li').each(function(i, el) {
						order.push($(el).data('id'));
					});
					self.sync({
						request: 'text/featured/order:' + order.join(','),
						method: 'PUT',
						finished: function() {
							note.success('Featured essay order updated');
						}
					});
				}
			});
		},

		escape_code: function(str) {
			return encodeURIComponent(str.replace(/"/gm, '__quot__').replace(/</gm, '__lt__').replace(/>/gm, '__gt__').replace(/\r?\n\r?/gm, '__n__').replace(/\[/gm, '__lb__').replace(/\]/gm, '__rb__').replace(/%/gm, '__perc__'));
		},

		unescape_code: function(str) {
			return decodeURIComponent(str).replace(/(__quot__)/gm, '"').replace(/(__lt__)/gm, '<').replace(/(__gt__)/gm, '>').replace(/(__n__)/gm, "\n").replace(/(__lb__)/gm, "[").replace(/(__rb__)/gm, "]").replace(/(__perc__)/gm, '%');
		},

		start_autosave: function() {
			clearTimeout(this.observers.autosave_timeout_id);
			var self = this;
			this.observers.autosave_text_id = this.observers.page_to_edit.id();
			this.observers.autosave_timeout_id = setTimeout(function() {
				if (self.observers.autosave_text_id === self.observers.page_to_edit.id()) {
					self.events.save();
				}
			}, 7000);
		},

		end_autosave: function() {
			this.observers.autosave_text_id = false;
			if (this.observers.autosave_timeout_id) {
				clearTimeout(this.observers.autosave_timeout_id);
				this.observers.autosave_timeout_id = false;
			}
		},

		init_copiers: function() {

			var self = this;

			$('a.copier').each( function(i, a) {
				$(a).off().zclip({
					copy: $(a).parent().parent().find('textarea').val(),
					path: 'js/ZeroClipboard.swf',
					afterCopy: function() { self.notify().success('Link copied to clipboard'); }
				});
			});
		},

		mid_scroll_finish: function() {
			if (this.observers.next_page && !this.observers.is_loading) {
				this.controllers.load_text();
			}
		},

		create_node: function(item, index) {

			var klass	= [],
				pub		= ( item.published ) ? 'Published' : 'Draft',
				self	= this,
				order	= $.inArray(this.observers.order_by(), ['manual', 'title']) !== -1 ? 'published_on' : this.observers.order_by(),
				ts		= item[order].timestamp*1000;

			if (!item.published) {
				klass.push('draft');
			} else if (item.content !== item.draft && item.published) {
				klass.push('edited');
			}

			var getCatsTopics = function(item,type) {
				if (item[type].count === 0) {
					return '<span class="none">- none -</span>';
				} else {
					this.sync({
						request: item[type].url,
						context: this,
						finished: function(data) {
							var res = (type === 'categories') ? data.categories : data.albums,
								out = '';
							if (res.length) {
								res.forEach(function(_item) {
									out += _item.title + ', ';
								});
								out = self.helpers.rtrim( out, ', ');
							} else {
								out = '<span class="none">- none -</span>';
							}
							$('.' + type + '_' + item.id).html(out);
						}
					});
					return 'Loading ...';
				}
			}.bind(this);

			return '<li class="' + klass.join(' ') + '" id="entry-' + item.id + '" data-index="' + index + '" data-id="' + item.id + '">\
				<span class="list-cell date">\
					<span class="day">\
						' + moment(ts).format('DD') + '\
					</span>\
					<span class="myd">\
						<span class="month-year">\
							' + moment(ts).format('MMM') + ' ' + moment(ts).format('YYYY') + '\
						</span>\
						<span class="day-name">\
							' + moment(ts).format('dddd') + '\
						</span>\
					</span>\
				</span>\
				<span class="list-cell title" data-event="edit_page">\
					<span class="entry-text-h">\
						<h2>' + item.draft_title + '</h2>\
						<span class="text-badge draft">Draft</span>\
						<span class="text-badge edited">Edited</span>\
						<span class="entry-date">' + this.helpers.capitalize(order.replace('_', ' ')) + ' ' + moment(ts).format('MMMM DD YYYY') + ' at ' + moment(ts).format('h:mm a') + '</span>\
						<span class="entry-p">\
							<p>' + this.observers.truncate(item.excerpt, 170) + '</p>\
						</span>\
					</span>\
				</span>\
				<span class="list-cell cat">\
					<strong>Category</strong>\
					<span class="categories_' + item.id + '">' + getCatsTopics(item,'categories') + '</span>\
				</span>\
				<span class="list-cell top">\
					<strong>Topic</strong>\
					<span class="topics_' + item.id + '">' + getCatsTopics(item,'topics') + '</span>\
				</span>\
				<span class="list-cell bttns">\
				' + ( item.page_type === 'essay' && this.observers.api_url() === 'text/featured/render:0' ? '\
					<a href="#" class="text-bttn move" title="Reorder"></a>' : '') +
					(  item.page_type === 'essay' && item.published ? '<a href="#" data-event="toggle_featured" class="text-bttn feat' + ( item.featured ? ' active' : '' ) + '" title="Feature this essay"></a>' : '') +
					'<a href="#" class="text-bttn del" data-event="delete_from_list" title="Delete"></a>\
				</span>\
			</li>';
		},

		load_text: function(obj, restart) {

			restart = restart || false;

			this.observers.is_loading = true;

			obj = obj || { load_until: false, cb: false };
			var url = this.observers.api_url();

			if (this.observers.next_page && !restart) {
				url += '/page:' + this.observers.next_page;
			}

			this.sync({
				request: url,
				context: this,
				finished: function(data) {

					if (data.error) {
						this.observers.loading(false);
						return;
					}

					var fragment = [],
						self = this;

					if (data.page === 1 && data.sort && this.observers.is_sortable()) {
						this.observers.order_by(data.sort.by);
						this.observers.order_direction(data.sort.direction);
					}

					$.each( data.text, function(i, text) {
						fragment.push( self.controllers.create_node( text, i + (data.page - 1)*100 ) );
					});

					if (data.page === 1) {
						this.observers.list( data.text );
						this.observers.total( data.total );
						this.observers.calendar_data = data.dates;
						var years = [];
						$.each( data.dates, function(i, m) {
							var months = $.map(m, function(count, month) {
								return { month: month, count: count, year: i };
							});
							years.push({ year: i, months: months.reverse() });
						});

						this.observers.years(years.reverse());
						this.observers.years.valueHasMutated();
					} else {
						this.observers.list( this.observers.list().concat(data.text) );
					}

					requestAnimationFrame(function() {

						if (data.page === 1) {

							$('#entries').html(fragment.join(''));

							self.observers.loading(false);

						} else {
							$('#entries').append(fragment.join(''));
						}

						fragment = [];

						if (data.page < data.pages) {
							self.observers.next_page = data.page + 1;
						} else {
							self.observers.next_page = false;
						}

						if (obj.load_until) {
							var ids = $.map( data.text, function(c) {
								return c.id;
							});
							if ($.inArray( obj.load_until, ids) === -1) {
								if (self.observers.next_page) {
									self.controllers.load_text( obj );
								} else {
									self.controllers.rewrite_hash('selection', false);
								}
								return;
							}
						}

						if (typeof obj.cb === 'function') {
							obj.cb();
						}

						self.observers.is_loading = false;

					});

				}
			});

		},

		rewrite_hash: function(arr) {

			if (!$.isArray(arr)) {
				arr = [ arr ];
			}

			var frag = $.param.fragment();

			$.each(arr, function(i, obj) {
				var replace = obj.replace || [ obj.name ];

				if ($.isArray(obj.val)) {
					obj.val = obj.val.join(',');
				}

				$.each( replace, function(i, str) {
					frag = frag.replace(RegExp('\/' + str + ':[^\/]+'), '');
				});

				if (obj.name !== 'none' && obj.val) {
					frag += '/' + obj.name + ':' + obj.val;
				}
			});

			$.bbq.pushState( frag, 2 );
		},

		text_wrap: function(content) {
			var self = this,
				shortcode_keys = Object.keys(this.observers.user_shortcodes()).concat(['koken_oembed', 'read_more']).join('|');

			content = content || '';

			return content.replace(RegExp('\\[(' + shortcode_keys + ').*?\\]', 'g'), function(t) {
				return self.controllers.shortcode_wrap(t);
			});
		},

		parse_shortcode: function(code) {
			if (code === '[read_more]') return { name: 'read_more', params: {} };
			var matches = code.match(/\[([a-z_]+)\s(.*)\]/),
				re = RegExp('([a-z_]+)="([^"]+)?"', 'g'),
				obj = {
					name: matches[1],
					params: {}
				};

			if (matches[2]) {
				while((m = re.exec(matches[2])) !== null) {
					obj.params[m[1]] = m[2] || '';
				}
			}

			return obj;
		},

		shortcode_wrap: function(params) {

			var key,
				param_arr = [];

			if (typeof params === 'string') {
				var obj = this.parse_shortcode(params);
				params = obj.params;

				if (obj.name === 'koken_oembed') {
					key = 'koken_oembed';
				} else {
					key = obj.name;
				}
			} else {
				var plugin = this.observers.current_shortcode();
				key = plugin.key;
			}

			$.each(params, function(key, val) {
				param_arr.push(key + '="' + val + '"');
			});

			if (key === 'read_more') {
				params.label = params.label || 'Read more';
			}

			var code = '[' + key + ' ' + param_arr.join(' ') + ']';

			var icon = key === 'koken_oembed' ? this.observers.user_shortcodes_icons['koken_oembed_' + params.oembed] : this.observers.user_shortcodes_icons[key];

			return '<div contenteditable="false" unselectable="on" class="entry-elem' + (key === 'read_more' ? ' read-more' : '') + '">\
				<span class="mod-links">\
					<ul>' + ((key === 'koken_oembed' && $.inArray(params.oembed, this.observers.user_oembed_thumbs) !== -1) || params.media_type && params.media_type === 'image' || params.filename ? '\
						<li>\
							<a href="#" class="bttn feature" title="Assign as featured image" data-event="assign_feature"></a> \
						</li>' : '') + (key !== 'koken_oembed' ? '\
						<li>\
							<a href="#" class="bttn edit" title="Edit content" data-event="edit_shortcode"></a>\
						</li>' : '') + '\
						<li>\
							<a href="#" class="bttn remove" title="Remove content" data-event="remove_shortcode"></a>\
						</li>\
					</ul>\
				</span>\
				<span class="icon" style="background-image: url(' + icon + ')"></span>\
				<a href="#" class="elem-title">' + decodeURIComponent(params.label) + '</a>\
				<a style="display:none" class="raw-code" href="#">' + code + '</a>\
				<div class="elem-preview"></div>\
			</div>';

		},

		text_unwrap: function() {

			var c = $('#edit-area').clone();

			// Unwrap shortcode formatting
			c.find('div[contenteditable=false]').each(function(i, div) {
				$(div).replaceWith( $(div).find('a.raw-code').text() );
			});

			c.find('p:empty').remove();
			c.find('p.media-row').remove();

			// Convert non-blanking spaces to actual spaces for save to database
			c = c.html().replace( '&nbsp;', ' ' );

			return c;

		},

		load_edit: function(item) {

			if ($('#entry-edit').length) {
				$('#entry-edit').off().remove();
			}

			item = $.extend({}, item);

			var is_new = item.draft === null;

			this.controllers.end_autosave();

			item.draft = this.controllers.text_wrap(item.draft);

			this.events._map_page_to_edit(item, true);
			this.observers.page_to_edit.__loaded(true);
			this.observers.sheets.create_text.wasCreated(false);

			$('#entry-edit-tmpl')
				.clone()
				.attr('id', 'entry-edit')
				.css({
					display: 'block',
					position: 'absolute',
					marginTop: 30
				})
				.appendTo($('#entry-list-all').parent().parent());

			this.controllers.init_copiers();
			this.observers.view('edit');

			$('#excerpt-text').focus(function() {
				$(this).parent().find('.field-links').show();
			}).blur(function() {
				$(this).parent().find('.field-links').hide();
			});

			var _airButtons = ['formatting','|','bold','italic','|','unorderedlist','orderedlist','outdent','indent','|','klink'],
				_buttonsCustom = {},
				self = this,
				obs = this.observers;

			var save_selection = function() {
				var r = $('#edit-area').data('redactor'),
					p = r.getSelection();

				obs.to_replace = $(p.anchorNode);
			}

			var media_sheet = function(t) {
				save_selection();
				obs.load_content.selected.content([]);
				obs.sheets.shortcode_media.type(t);
				obs.sheets.shortcode_media.album(0);
				self.events.toggle_sheet('shortcode_media');
			};

			var album_sheet = function() {
				save_selection();
				obs.sheets.shortcode_collection.state.selected('');
				obs.sheets.shortcode_collection.state.selected_text('');
				$('#album_sheet_topic').attr('checked', false);
				self.events.toggle_sheet('shortcode_collection');
			};

			var upload_sheet = function(o,e) {
				save_selection();
				self.events.toggle_sheet('shortcode_upload');
				obs.sheets.shortcode_upload._reset();
				self.interfaces.app.controllers.text_upload.init.call(self.interfaces.text);
				o.saveSelection();
			};

			var custom_sheet = function(data) {
				save_selection();
				obs.sheets.shortcode_custom.fields(data.fields);
				obs.sheets.shortcode_custom.data(data);
				self.events.toggle_sheet('shortcode_custom');
			};

			$.each( this.observers.user_shortcodes(), function(i,plugin) {

				if (plugin.essays_only && item.page_type !== 'essay') return;
				if (plugin.pages_only && item.page_type !== 'page') return;

				var shortcode = 'kshortcode' + i,
					styleEl = $('head').find('style'),
					styleOut = '';

				plugin.key = i;

				styleOut += 'body .redactor_air.media .redactor_toolbar li a.redactor_btn_' + shortcode + ' { display: block; background-image: url(' + plugin.icon + '); background-repeat: no-repeat !important; }';
				styleOut += 'body .redactor_air .redactor_toolbar li a.redactor_btn_' + shortcode + ' { display: none; }';

				if ( styleEl.length <= 0 ) {
					$('<style/>').attr({
						type: 'text/css'
					}).append(styleOut).appendTo('head');
				} else {
					styleEl.append(styleOut);
				}

				_buttonsCustom[shortcode] = {
					title: plugin.title,
					callback: function(o,e) {
						self.observers.to_replace = false;
						self.observers.current_shortcode(plugin);

						if ( plugin.media && $.inArray(plugin.media, [ 'video', 'photo', 'collection', 'upload' ]) !== -1) {
							if ( plugin.media === 'video' || plugin.media === 'photo' ) {
								media_sheet(plugin.media);
							}
							if ( plugin.media === 'collection' ) {
								album_sheet();
							}
							if ( plugin.media === 'upload' ) {
								upload_sheet(o,e);
							}
						} else if (plugin.key === 'read_more') {
							$('.entry-elem.read-more').remove();
							self.events.insert_shortcode({ label: 'Read more'});
						} else {
							custom_sheet(plugin.data);
						}

						self.observers.hasChanges(true);
					}
				};

				_airButtons.push(shortcode);

			});

			_buttonsCustom.klink = {
				title: 'Add link',
				callback: function(obj, ev, key) {
					var s = self.observers.sheets['insert_link'],
						p = obj.getSelection();
					s._reset();
					if (p && p.anchorNode && p.anchorNode.parentNode.tagName === 'A') {
						var a = p.anchorNode.parentNode;
						if ($(a).attr('data-koken-album-id')) {
							s.state.type('internal');
							s.state.album(Number($(a).attr('data-koken-album-id')));
							if ($(a).attr('data-koken-lightbox')) {
								s.state.lightbox(true);
							}
							s.state.album_type($(a).attr('data-koken-album-type') || 'standard');
						}
						s.state.url( a.href.replace(/lightbox\/?$/, '') );
						if (a.target === '_blank') {
							s.state.new_window(true);
						}
						s.state.existing(true);
						self.observers.link.replace = a;
					} else {
						s.state.existing(false);
						self.observers.link.replace = false;
					}
					obj.saveSelection();
					self.observers.link.redactor = obj;
					self.events.toggle_sheet('insert_link');
				}
			};

			$('#edit-area')
				.redactor({
					callback: function(obj) {
						$('#edit-area p:empty').remove();
						if (!$('#edit-area p').length) {
							$('#edit-area').append('<p class="media-row"><br /></p>');
						}
						self.events.shim_paragraph(obj);
					},
					observeImages: false,
					focus: true,
					air: true,
					convertDivs: false,
					airButtons: _airButtons,
					buttonsCustom: _buttonsCustom
				})
				.on('paste', function() {
					requestAnimationFrame(function() {

						var scrollOffset = $('#edit-area').closest('#entry-scroll').find('.scroll_wrapper').get(0).scrollHeight - (parseInt($('#edit-area').closest('#entry-scroll').find('.scroll_track').height(), 10) + parseInt($('#edit-area').closest('#entry-scroll').find('.scroll_wrapper').scrollTop(), 10 ));

						if ( scrollOffset <= 100 && scrollOffset > 0 ) {
							$('#edit-area').closest('#entry-scroll').find('.scroll_wrapper').get(0).scrollTop = $('#edit-area').closest('#entry-scroll').find('.scroll_wrapper').get(0).scrollHeight;
						}

						$.each($('p.media-row a'), function(i, row) {
							var link = $(this).text();
							$(this).parent().text(link);
						});

						$.each(self.observers.user_oembeds(), function(key, info) {
							if (info.regex) {

								var regex = RegExp('<p class="media-row">(' + info.regex + ')(<br>)?</p>'),
									str = $('#edit-area').html(),
									matches;

								if ( regex.test(str) ) {

									matches = str.match(regex);
									self.observers.current_shortcode($.extend(info, { key: 'koken_oembed' }));
									var obj = {
										oembed: key,
										label: info.title,
										url: matches[1].replace('<br>', ''),
										endpoint: info.endpoint
									};

									self.events.insert_shortcode(obj);

									if (!self.observers.page_to_edit.__featured_image() && info.has_thumbnail) {
										self.controllers.featured_from_oembed(obj);
									}
								}
							}
						});

						// Check for image URL paste
						var html = $('#edit-area').html(),
							image_regex = RegExp('<p class="media-row">(http://.*\.(?:gif|jpe?g|png))(<br>)?</p>');

						if (image_regex.test(html)) {
							var matches = html.match(image_regex);
							self.observers.current_shortcode({ key: 'koken_upload' });
							var obj = {
								filename: matches[1],
								label: matches[1].replace(/^https?:\/\//, '')
							};
							self.events.insert_shortcode(obj);

							if (!self.observers.page_to_edit.__featured_image()) {
								self.events.save_feature(obj.filename);
							}
						}
					});
				});

			var self = this;
			$('#edit-area').off('.editor').on('keyup.editor', function() {
				self.events.update_editor();
			});

			window.setTimeout(function() {
				$('#edit-area').data('redactor').air.hide();
			}, 50);

			$(window).trigger('resize');

		}

	},

	events: {

		shortcode_remove_field: function(el) {
			var shortData = this.observers.current_shortcode_data();

			var key = el.attr('data-field');

			$.each(shortData, function(i, field) {
				if (field.key === key) {
					field.value.splice(el.attr('data-index'), 1);
					return false;
				}
			});

			this.observers.current_shortcode_data([]);
			this.observers.current_shortcode_data(shortData);
		},

		shortcode_add_field: function(el) {
			var data = ko.dataFor(el.get(0));

			var shortData = this.observers.current_shortcode_data();

			var filler = [];
			$.each(data.setting.fields, function(i) {
				filler.push('');
			});

			$.each(shortData, function(i, field) {
				if (field.key === data.setting.key) {
					field.value.push(filler);
					return false;
				}
			});

			this.observers.current_shortcode_data([]);
			this.observers.current_shortcode_data(shortData);

			requestAnimationFrame(function() {
				$(".fields-data:text:visible:last").focus();
			});
		},

		undo_draft: function() {
			var content = this.observers.page_to_edit.content();
			this.observers.page_to_edit.draft(content);
			this.observers.page_to_edit.draft_title(this.observers.page_to_edit.title());
			$('#edit-area').html(this.controllers.text_wrap(content));
			var n = this.notify().wait('Removing draft changes');
			this.events.save(n);
		},

		open_in_site: function(ret) {
			ret = ret === true;
			var p = this.observers.page_to_edit,
				url = p.url();

			if (this.observers.hasChanges()) {
				this.events.save($('<div/>').attr('data-action', 'preview'));
			} else {
				if (!p.published() || p.content() !== p.draft() || p.draft_title() !== p.title()) {
					url += (url.indexOf('?') === -1 ? '?' : '&') + 'preview_draft=' + p.internal_id();
				}
				if (ret) {
					return url;
				}
				window.open(url);
			}
		},

		delete_from_list: function(el) {
			this.observers.list_index_to_delete = el.parents('li').data('index');
			this.classes.modal.delete_text.show();
		},

		toggle_featured: function(el) {
			var item = this.observers.list()[ el.parents('li').data('index') ];
				featured = !item.featured,
				n = this.notify().wait((featured ? 'Adding ' : 'Removing') + ' feature');

			this.sync({
				request: 'text/featured/' + item.id,
				method: featured ? 'POST' : 'DELETE',
				context: this,
				finished: function(data) {
					var copy = $.extend(true, {}, item);
					copy.featured = featured;
					n.success( item.title + ' ' + ( featured ? 'added to' : 'removed from') + ' Featured essays' );
					if (this.observers.api_url().indexOf('text/featured') === 0) {
						this.observers.next_page = false;
						this.controllers.load_text();
					} else {
						var el = $('#entry-' + copy.id);
						if (el.length) {
							el.replaceWith( this.controllers.create_node( copy, el.data('index') ));
							var index = el.data('index');
							this.observers.list.splice(index, 1, copy);
						}
					}
				}
			});

		},

		update_editor: function() {
			if ( this.classes.editor && this.classes.editor.text ) {
				var shortcode_keys = Object.keys(this.observers.user_shortcodes()).concat(['koken_oembed']).join('|'),
					val = this.controllers.text_unwrap().replace(RegExp('(\\[(' + shortcode_keys + ')[^\\]]+\\])', 'g'), "\n\n$1").replace(/(?:(?:\r\n|\r|\n)\s*){2}/g, "\n\n").replace(/^\n*/, ''),
					_editor = this.classes.editor.text.editorWindow;

				if (_editor) {
					_editor.setValue(val);
					_editor.getSession().setScrollTop(0);
					_editor.clearSelection();
				}
			}
		},

		toggle_html: function(el,parent) {

			var shortcode_keys = Object.keys(this.observers.user_shortcodes()).concat(['koken_oembed']).join('|'),
				val = this.controllers.text_unwrap().replace(RegExp('(\\[(' + shortcode_keys + ')[^\\]]+\\])', 'g'), "\n\n$1").replace(/(?:(?:\r\n|\r|\n)\s*){2}/g, "\n\n").replace(/^\n*/, ''),
				self = this;

			if (this.classes.editor && this.classes.editor.text && this.classes.editor.text.editorWindow) {
				this.classes.editor.text.editorWindow.destroy();
				delete this.classes.editor.text;
			}

			this.events.toggle_editor();

			$('#container').find('.ui-sheet').each(function() {
				self.interfaces[self.interfaces.active.id].observers.sheets[$(this).attr('id')].active(false);
			});

			if (this.observers.editor_on()) {
				new this.klass.editor('text', val, 'html-edit-area');

				var _editor = this.classes.editor.text.editorWindow;
				_editor.getSession().setMode('ace/mode/html');
				_editor.getSession().setUseWrapMode(true);
				_editor.getSession().setWrapLimitRange(null,100);

				$('#html-edit-area').off('.editor').on('keyup.editor', function() {
					self.controllers.start_autosave();
					self.observers.page_to_edit.draft(self.classes.editor.text.editorWindow.getValue());
					$('#edit-area').html(self.controllers.text_wrap(self.classes.editor.text.editorWindow.getValue()));
					self.observers.hasChanges(true);
				});

				_editor.resize();
				_editor.focus();
				_editor.clearSelection();
				requestAnimationFrame(function() {
					_editor.gotoLine(1);
				});

				$('#docked_editor [data-event="leave_edit"]').hide();
			} else {
				$('#edit-area').off('.editor')
			}

		},

		toggle_editor: function() {
			this.observers.editor_on(!this.observers.editor_on());
			if (this.observers.editor_on()) {
				$('a[data-event="toggle_html"]').addClass('selected');
				$('#docked_editor').show();
				$('#entry-edit-area').addClass('docked');
				if (this.observers.text_edit_full()) {
					this.events.toggle_editor_view();
				}
			} else {
				$('a[data-event="toggle_html"]').removeClass('selected');
				$('#docked_editor').hide();
				$('#entry-edit-area').removeClass('docked');
				if ($('#entry-edit-area').css('display') === 'none') {
					this.events.toggle_editor_view();
				}
			}
		},

		toggle_editor_view: function(el,parent) {

			if ($('#entry-edit-area').css('display') !== 'none') {
				parent && parent.find('.bicon_sm').removeClass('dock_up').addClass('dock_down');
				$('#entry-edit-area').hide();
				$('#docked_editor').height('100%');
				$('#entry-list-bar').hide();
				$('#entry-edit').css('margin-top','0px');
				$('#docked_editor [data-event="leave_edit"]').show();
				$('#html-edit-area').height('100%');
				this.classes.editor.text.editorWindow && this.classes.editor.text.editorWindow.resize();
			} else {
				parent && parent.find('.bicon_sm').removeClass('dock_down').addClass('dock_up');
				$('#entry-edit-area').show();
				$('#docked_editor').height('30%');
				$('#entry-list-bar').show();
				$('#entry-edit').css('margin-top','30px');
				$('#docked_editor [data-event="leave_edit"]').hide();
				$('#html-edit-area').height('');
				this.classes.editor.text.editorWindow && this.classes.editor.text.editorWindow.resize();
			}

			if (el) {
				this.observers.text_edit_full(!this.observers.text_edit_full());
			}

		},

		toggle_editor_theme: function() {
			var editor = this.classes.editor.text.editorWindow,
				kokentheme = 'ace/theme/koken',
				kokenlighttheme = 'ace/theme/kokenlight';

			$('style#ace-tm,style#ace-koken').remove();

			if ( editor.getTheme() === kokentheme ) {
				this.cookie( 'editor:theme', '"' + kokenlighttheme + '"' );
				$('.ace_scrollbar').css('background', '');
				$('.ace_scrollbar').css('background', '#fff');
				editor.setTheme(kokenlighttheme);
			} else {
				this.cookie( 'editor:theme', '"' + kokentheme + '"' );
				$('.ace_scrollbar').css('background', '');
				$('.ace_scrollbar').css('background', '#141414');
				editor.setTheme(kokentheme);
			}

			editor.focus();
		},

		toggle_theme: function() {
			if ( this.observers.theme() != 1 ) {
				this.observers.theme(1);
			} else {
				this.observers.theme(0);
			}
			if (this.observers.editor_on()){
				$('#docked_editor').show();
				$('#entry-edit-area').addClass('docked');
			} else {
				$('#docked_editor').hide();
				$('#entry-edit-area').removeClass('docked');
				if ($('#entry-edit-area').css('display') === 'none') {
					this.events.toggle_editor_view();
				}
			}
		},

		delete_text: function() {

			var view = this.observers.view(),
				item = view === 'list' ? this.observers.list()[ this.observers.list_index_to_delete ] : this.observers.page_to_edit,
				id = view === 'list' ? item.id : item.id(),
				title = view === 'list' ? item.title : item.title();

			var note = this.notify().wait('Deleting ' + title);

			this.sync({
				request: 'text/' + id,
				method: 'DELETE',
				context: this,
				finished: function() {
					if (view === 'list') {
						this.observers.next_page = false;
						this.controllers.load_text();
					} else {
						this.observers.api_url('');
						$.bbq.pushState( '/text/type:' + this.observers.type(), 2 );
					}
					note.success( title + ' has been deleted' );
					this.controllers.fetch_drafts();
				}
			});

		},

		browser_valid: function(el,parent) {
			parent.closest('.sheet-wrap').find('input').trigger('keyup');
		},

		nav: function(el,parent) {

			el = el.closest('li').find('.icon16');

			if ( el.hasClass('label-drafts') ) {
				this.observers.state('draft');
			}

			if ( el.hasClass('label-essays') ) {
				this.observers.state('');
			}

			if ( el.hasClass('label-pages') ) {
				this.observers.state('pages');
			}

		},

		toggle_expand: function(el,parent) {

			this.observers.expand( ! this.observers.expand() );
			$('#app').toggleClass('text-expand');

		},

		toggle_grid: function(el,parent) {

			this.observers.grid( el.closest('li').attr('class') );
			this.cookie( 'text:view', el.closest('li').attr('class') );

		},

		toggle_topic: function(el) {
			var	data = ko.dataFor(el[0]),
				is_topic = this.observers.is_topic(data.id),
				note;

			note = this.notify().wait( (is_topic) ? 'Removing topic' : 'Adding topic' );

			this.sync({
				request: 'text/' + this.observers.page_to_edit.id() + '/topics/' + data.id,
				method: is_topic ? 'DELETE' : 'POST',
				context: this,
				finished: function(d) {
					note.success( (is_topic) ? data.title + ' removed as topic' : data.title + ' added as topic' );
					this.events._map_page_to_edit(d);
					this.observers.get_selected_topics();
				}
			});

			if (!is_topic && data.covers.length && !this.observers.page_to_edit.__featured_image()) {
				this.events.save_feature(data.covers[0].id);
			}
		},

		create_text: function() {

			var ob = this.observers.sheets.create_text;

			$('li[data-event="create_text"] button').addClass('disabled');

			if ( ob.title().trim().length <= 0 ) {
				this.notify().warn('Title field cannot be left blank');
				return;
			}

			var n = this.notify().wait('Creating "' + ob.title() + '"');

			this.sync({
				request: 'text',
				method: 'POST',
				data: 'title=' + encodeURIComponent(ob.title()) + '&page_type=' + ob.type(),
				context: this,
				finished: function(data) {
					this.observers.sheets.create_text.wasCreated(true);
					n.success('"' + ob.title() + '" added');
					ob.title('');
					ob.type('essay');
					ob.active(false);
					this.observers.api_url('');
					$.bbq.pushState('/text/' + (data.page_type === 'page' ? 'type:page' : 'drafts') + '/selection:' + data.id, 2);
					$(window).off('keyup.sheet');
					this.controllers.fetch_drafts();
				}
			});

		},

		arrow_toggle: function(el,parent) {

			var nav		= parent.closest('.lib-nav'),
				open	= [],
				narr	= '';

			parent.parent().find('ol').toggle();
			parent.toggleClass('open');
			nav.find('li[data-event="sort_year"] a.open').each(function() {
				open.push($(this).next().text().trim());
			});
			narr = open.join(',');
			this.cookie( 'text:nav', narr );
			$.each(open,function(k,v) {
				open[k] = parseInt(v,10);
			});
			this.observers.open_year(open);

		},

		clear_filters: function(el) {
			$('#entry-list-filter').hide();
			this.controllers.rewrite_hash([
				{ name: 'selection', val: false },
				{ name: 'month', val: false },
				{ name: 'year', val: false },
				{ name: 'state', val: false }
			]);
		},

		sort_year: function(el, parent) {
			var data = ko.dataFor(el[0]);

			if (parent.hasClass('selected')) {
				this.controllers.rewrite_hash([
					{ name: 'selection', val: false },
					{ name: 'month', val: false },
					{ name: 'year', val: false }
				]);
			} else {
				this.controllers.rewrite_hash([
					{ name: 'selection', val: false },
					{ name: 'month', val: false },
					{ name: 'year', val: data.year }
				]);
			}
		},

		sort_month: function(el, parent) {
			var data = ko.dataFor(el[0]),
				p = el.parent();

			if (parent.hasClass('selected')) {
				this.controllers.rewrite_hash([
					{ name: 'month', val: false },
					{ name: 'year', val: false }
				]);
			} else {
				this.controllers.rewrite_hash([
					{ name: 'selection', val: false },
					{ name: 'month', val: data.month },
					{ name: 'year', val: data.year }
				]);
			}
		},

		leave_edit: function(canLeave) {

			var self = this;

			var leave = function() {

				self.controllers.end_autosave();

				if (!self.observers.sheets.create_text.wasCreated()) {
					self.controllers.rewrite_hash({ name: 'selection', val: false });
				}

				if ( self.observers.expand() ) {
					self.events.toggle_expand();
				}

				self.events.close_sidebar_drawer();
				self.observers.hasChanges(false);

			};

			if ( canLeave === true ) { leave(); return; }

			if ( ! this.observers.hasChanges() ) {
				leave();
			} else {
				this.classes.modal.unsaved_changes.show();
			}

			$('#entry-list-bar').show();
		},

		edit_shortcode_media: function() {
			this.events.toggle_sheet('shortcode_edit');

			switch(this.observers.current_shortcode().media) {
				case 'photo':
				case 'video':
					this.observers.sheets.shortcode_media.type(this.observers.current_shortcode().media);
					this.events.toggle_sheet('shortcode_media');
					break;

				case 'upload':
					this.events.toggle_sheet('shortcode_upload');
					break;

				case 'collection':
					this.events.toggle_sheet('shortcode_collection');
					break;
			}
		},

		edit_shortcode: function(el, parent) {
			var wrap = el.parents('div[contenteditable=false]'),
				code = wrap.find('.raw-code').text(),
				obj = this.controllers.parse_shortcode(code),
				self = this,
				plugin;

			this.observers.to_replace = wrap;

			if (obj.name === 'koken_oembed') {

			} else {
				$.each(this.observers.user_shortcodes(), function(key, info) {
					if (key === obj.name) {
						if (info.title_alt) {
							requestAnimationFrame(function() {
								$('.sheet-title').text(info.title_alt);
							});
						}
						if (info.description_alt) {
							requestAnimationFrame(function() {
								$('.sheet-desc').text(info.description_alt);
							});
						}
						plugin = $.extend(true, {}, info);
						self.observers.current_shortcode(plugin);
						self.observers.current_shortcode.valueHasMutated();
						return false;
					}
				});

				if (plugin.media) {
					switch(plugin.media) {
						case 'collection':
							$('#shortcode_option_link_to').find('option[value="album"]').remove();
							if (obj.params.album) {
								var value = '';
								$.each(this.observers.current_shortcode_data(), function(i, info) {
									if (info.key === 'link_to') {
										value = info.value;
										return false;
									}
								});
								$('#shortcode_option_link_to').append(
									$('<option/>').attr({
											value: 'album'
										})
										.text('Load album page')
								);

								$('#shortcode_option_link_to').find('option[value="false"]:first-child').attr('value', 'default');
								$('#shortcode_option_link_to').val(value || 'default');
								self.observers.sheets.shortcode_collection.state.selected('album-' + obj.params.album);
								var a = ko.utils.arrayFirst( self.observers.albums_flat(), function(item) {
									return item.id == obj.params.album;
								});
								self.observers.sheets.shortcode_collection.state.selected_text(a.title);
								self.observers.sheets.shortcode_edit.selected(a.title);
							} else if (obj.params.content) {
								self.observers.sheets.shortcode_collection.state.selected(obj.params.content.split(','));
								self.observers.sheets.shortcode_collection.state.selected_text('Mixed content');
								self.observers.sheets.shortcode_edit.selected('Mixed content');
							}
							break;
						case 'upload':
							self.observers.sheets.shortcode_upload.filename(obj.params.filename);
							self.observers.sheets.shortcode_edit.selected(obj.params.filename);
							self.observers.sheets.shortcode_upload.state('ready');
							self.interfaces.app.controllers.text_upload.init.call(self.interfaces.text);
							break;
						case 'photo':
						case 'video':
							self.observers.load_content.selected.content([ Number(obj.params.id) ]);
							self.observers.sheets.shortcode_edit.selected(obj.params.label);

							var album = obj.params.link === 'album';
							self.observers.sheets.shortcode_edit.link(obj.params.link || 'none');
							self.observers.sheets.shortcode_edit.albums([]);
							this.observers.sheets.shortcode_edit.album(obj.params.album || 0);
							this.observers.sheets.shortcode_edit.custom_url(obj.params.custom_url || '');
							self.sync({
								request: 'content/' + obj.params.id + '/albums',
								context: self,
								finished: function(data) {
									this.observers.sheets.shortcode_edit.albums(data.albums);
									if (album) {
										this.observers.sheets.shortcode_edit.link('album');
									}
								}
							});
							break;
					}
				}

				if (plugin.data) {
					self.events.toggle_sheet('shortcode_edit');
				} else if (plugin.media) {
					self.events.toggle_sheet('shortcode_' + plugin.media);
				}
			}


		},

		remove_shortcode: function(el) {
			el.parents('div[contenteditable=false]').remove();
			var clean = this.controllers.text_unwrap();
			$('#edit-area').html( this.controllers.text_wrap( clean ) );
			this.events.shim_paragraph();
			this.observers.hasChanges(true);
		},

		save: function(el, parent, e, unpublish) {

			this.controllers.end_autosave();

			var c = encodeURIComponent(this.controllers.text_unwrap()),
				t = $('#entry-edit #entry-title h2').text(),
				self = this,
				index,
				publish = false,
				preview = false,
				update = false,
				auto = !el,
				revert = el && el.warn,
				notify;

			unpublish = unpublish || false;

			if (!unpublish && !auto && !revert) {
				// Using el.data() here seems to get cached and lead to inconsistent notifications
				publish = el.attr('data-action') && el.attr('data-action') === 'publish',
				update = el.attr('data-action') && el.attr('data-action') === 'update',
				preview = el.attr('data-action') && el.attr('data-action') === 'preview',
				unpublish = el.attr('data-action') && el.attr('data-action') === 'unpublish';

				if (unpublish) {
					this.classes.modal.unpublish_essay.show();
					return;
				}
			}

			if (publish && el.data('publish') != 'true') {
				this.classes.modal.text_live.show();
				return false;
			}

			if (auto) {
				this.observers.is_autosaving(true);
			} else if (revert) {
				notify = el;
			} else if (el.data && el.data('publish')) {
				el.data('publish','false');
			}

			if (!auto && !revert) {
				notify = this.notify().wait(publish || update ? 'Publishing' : 'Saving');
			}

			if (unpublish) {
				this.events.close_sidebar_drawer();
			}

			this.observers.hasChanges(false);

			this.sync({
				request: 'text/' + this.observers.page_to_edit.id() + '/render:0',
				data: (publish || update ? 'content' : 'draft') + '=' + c + '&' + (publish || update ? 'title' : 'draft_title')  + '=' + encodeURIComponent(t) + ( publish ? '&published=1' : '' ) + ( unpublish ? '&unpublish=1' : '' ),
				context: this,
				method: 'PUT',
				finished: function(data) {
					if (!auto) {
						if (revert) {
							notify.success('Draft changes removed');
						} else {
							if ( publish || update ) {
								notify.success('"' + data.title + '" has been published');
							} else {
								notify.success('"' + data.draft_title + '" has been saved' + ( unpublish ? ' as a draft' : '' ));
							}
						}
					}
					this.observers.is_autosaving(false);
					if (preview) {
						location.href = this.events.open_in_site(true);
					}
					this.observers.firstPageLoad(true);
					this.events._map_page_to_edit(data);
					if (publish || unpublish) {
						this.controllers.fetch_drafts();
					}
				}
			});
		},

		_map_page_to_edit: function(data, silent) {

			silent = silent || false;

			if (silent || (this.observers.page_to_edit.id && data.id === this.observers.page_to_edit.id())) {

				this.map( this.observers.page_to_edit, data );

				if (
					!data.featured_image ||
					!this.observers.page_to_edit.__featured_image().presets || data.featured_image.presets.medium.url !== this.observers.page_to_edit.__featured_image().presets.medium.url
				) {
					this.observers.page_to_edit.__featured_image(data.featured_image);
				}
			}

			if (!silent) {
				var el = $('#entry-' + data.id);
				if (el.length) {
					var index = el.data('index');
					el.replaceWith( this.controllers.create_node( data, index ) );
					this.observers.list.splice(index, 1, data);
				}

				if (this.observers.drafts().length) {
					var self = this,
						draft = ko.utils.arrayFirst( this.observers.drafts(), function(item) {
							return item.id === self.observers.page_to_edit.id();
						});

					if (draft) {
						var dindex = ko.utils.arrayIndexOf( this.observers.drafts(), draft );
						this.observers.drafts.splice(dindex, 1, data);
					}
				}
			}

		},

		toggle_internal_link: function(el, parent) {
			var data = ko.dataFor(el[0]);
			this.observers.sheets.insert_link.state.album(data.id);
			this.observers.sheets.insert_link.state.album_type(data.album_type);
			this.observers.sheets.insert_link.state.url(data.url);
			this.observers.valid(true);
		},

		insert_collection: function(el, parent) {
			var o = this.observers.sheets.shortcode_collection.state,
				sel = o.selected(),
				params = {};

			if (typeof sel === 'string' && (matches = sel.match(/album-(\d+)/))) {
				params.album = matches[1];
				params.label = o.selected_text();

				if ($('#album_sheet_topic').is(':checked')) {
					this.sync({
						request: 'text/' + this.observers.page_to_edit.id() + '/topics/' + matches[1],
						method: 'POST',
						context: this,
						finished: function(data) {
							this.events._map_page_to_edit(data);
						}
					});
				}
			} else if (typeof sel === 'string') {
				params.content = this.observers.sheets.shortcode_collection[sel]().join(',');
				params.label = 'Mixed content';
			} else {
				params.label = 'Mixed content';
				params.content = sel.join(',');
			}

			if (this.observers.sheets.shortcode_collection[sel] && this.observers.sheets.shortcode_collection[sel]().length <= 1 && (sel === 'favorites' || sel === 'quick_collection' || sel === 'last_upload')) {
				this.notify().warn('You need to have 2 or more images in ' + sel.replace('_', ' ') + ' to insert this slideshow');
			} else {
				this.events.insert_shortcode(params);
				this.events.toggle_sheet(el, parent);

				if (!this.observers.page_to_edit.__featured_image()) {
					if (params.album) {
						this.sync({
							request: 'albums/' + params.album,
							context: this,
							finished: function(data) {
								if (data.covers.length) {
									this.events.save_feature( data.covers[0].id );
								}
							}
						});
					} else {
						this.events.save_feature( this.observers.sheets.shortcode_collection[sel]()[0] );
					}
				}
			}

		},

		insert_custom: function(el, parent) {
			var params = {
				label: this.observers.current_shortcode().media_row_label || 'Code'
			},
				self = this;

			$('.shortcode-settings input, .shortcode-settings select, .shortcode-settings textarea').not('.fields-data').each(function() {
				var $el = $(this),
					val = self.controllers.escape_code($el.val());

				params[$el.attr('name')] = val;
			});

			$.each(this.observers.current_shortcode_data(), function(i, field) {
				if (/^(fields_array|recaptcha)$/.test(field.type)) {
					params[field.key] = self.controllers.escape_code(JSON.stringify(field.value));
				}
			});

			this.events.insert_shortcode(params);
			this.events.toggle_sheet(el, parent);
		},

		insert_shortcode: function(params) {

			var replace = this.observers.to_replace,
				shortcode = this.controllers.shortcode_wrap(params),
				r = $('#edit-area').data('redactor');

			if (!replace) {
				var p = r.getSelection();
				replace = $(p.anchorNode);
			}

			if ( shortcode ) {
				replace.replaceWith($(shortcode));
			}

			this.observers.to_replace = false;

			$('div.entry-elem').each(function(i, div) {
				$(div).attr('contenteditable', false).siblings('br').remove();
				if ($(div).parent().get(0).nodeName.toLowerCase() === 'p') {
					$(div).unwrap();
				}
			});

			this.events.shim_paragraph();
			this.observers.hasChanges(true);
			this.events.update_editor();
			this.controllers.start_autosave();
		},

		shim_paragraph: function(r) {
			r = r || $('#edit-area').data('redactor');
			r.air.hide();
			var last = $('#edit-area').children().last();
			if (!last.length || last.get(0).tagName !== 'P') {
				var empty = $('<p class="media-row"></p>').html('<br />');
				empty.appendTo('#edit-area');
			}
			r.setFocusNode($('#edit-area p:last')[0]);
		},

		update_shortcode: function(el, parent) {
			var code = this.observers.to_replace.find('.raw-code').text(),
				obj = this.controllers.parse_shortcode(code),
				self = this;

			$('.shortcode-settings input:visible, .shortcode-settings select:visible, .shortcode-settings textarea:visible').not('.fields-data').each(function() {
				var $el = $(this),
					val = self.controllers.escape_code($el.val());

				if ($el.attr('type') === 'checkbox') {
					val = $el.is(':checked') ? 'true' : 'false';
				}

				obj.params[$el.attr('name')] = val;
			});

			$.each(this.observers.current_shortcode_data(), function(i, field) {
				if (/^(fields_array|recaptcha)$/.test(field.type)) {
					obj.params[field.key] = self.controllers.escape_code(JSON.stringify(field.value));
				}
			});

			this.events.insert_shortcode(obj.params);
			this.events.toggle_sheet(el, parent);

		},

		insert_upload: function(el, parent) {
			var o = this.observers.sheets.shortcode_upload,
				params = {
					filename: o.filename(),
					label: o.filename()
				};


			if (!this.observers.to_replace) {
				$('#edit-area').data('redactor').restoreSelection();
			}

			this.events.insert_shortcode(params);
			this.events.toggle_sheet(el, parent);

			if (!this.observers.page_to_edit.__featured_image()) {
				this.events.save_feature(o.filename());
			}
		},

		insert_media: function(el, parent) {

			var self = this,
				currRow = $('p.media-row');

			$.each(this.observers.load_content.selected.content(), function(i) {
				if (i > 0) {
					currRow.after($('<p class="media-row" />'));
				}
			});

			$.each(this.observers.load_content.selected.content(), function(i, id) {

				var media = ko.utils.arrayFirst( self.observers.load_content.content(), function(item) {
						return item.id === id;
					}),
					params = {
						label: media.filename,
						id: media.id,
						media_type: media.file_type
					};

				if (media.filename === 'Vimeo') {
					params.label = (media.title.length <= 0) ? 'Vimeo video' : media.title;
				}

				if (media.filename === 'Instagram') {
					params.label = (media.title.length <= 0) ? 'Instagram photo' : media.title;
				}

				if (self.observers.sheets.shortcode_media.selected.view() === 'public_albums') {
					params.link = 'album';
					params.album = $('#album_select').val().match(/\/albums\/(\d+)/)[1];
				}

				requestAnimationFrame(function() {
					if (!self.observers.to_replace) {
						self.observers.to_replace = $($('p.media-row').get(0));
					}
					self.events.insert_shortcode(params);
				});

			});

			this.events.toggle_sheet(el, parent);

			if (!this.observers.page_to_edit.__featured_image()) {
				this.events.save_feature(this.observers.load_content.selected.content()[0]);
			}
		},


		assign_feature: function(el, parent) {
			var code = el.parents('.entry-elem').find('.raw-code').text(),
				obj = this.controllers.parse_shortcode(code);

			if (obj.name === 'koken_oembed') {
				this.controllers.featured_from_oembed(obj.params);
			} else {
				var matches = el.parents('.entry-elem').find('.raw-code').text().match(/id="([0-9]+)"/);

				if (!matches) {
					matches = el.parents('.entry-elem').find('.raw-code').text().match(/filename="([^"]+)"/);
				}

				if (matches) {
					this.events.save_feature(matches[1]);
				}
			}
		},

		save_feature: function(feature) {
			var n = this.notify().wait('Assigning feature image');
			this.sync({
				request: 'text/' + this.observers.page_to_edit.id() + '/feature' + ( !isNaN(feature) ? '/' + feature : '' ),
				method: 'POST',
				data: isNaN(feature) ? 'file=' + feature : false,
				context: this,
				finished: function(data) {
					this.observers.page_to_edit.__featured_image(data.featured_image);
					n.success('Featured image assigned');
				}
			});
		},

		delete_feature: function() {
			var n = this.notify().wait('Removing feature image');
			this.sync({
				request: 'text/' + this.observers.page_to_edit.id() + '/feature/' + this.observers.page_to_edit.__featured_image().id,
				method: 'DELETE',
				context: this,
				finished: function(data) {
					this.observers.page_to_edit.__featured_image(false);
					n.success('Featured image removed');
				}
			});
		},

		clear_link: function() {
			var l = this.observers.link,
				r = $(l.replace);

			l.redactor.restoreSelection();
			r.replaceWith(r.text());

			this.events.toggle_sheet('insert_link');
		},

		insert_link: function(el, parent) {

			var data = this.observers.sheets.insert_link;

			if (data.state.url().length <= 0) {
				var inp_val = parent.closest('.ui-sheet').find('input[type="text"]').val();
				if (inp_val.length <= 0) {
					this.notify().warn('Please enter a valid url to be inserted');
					return;
				}
				data.state.url(inp_val);
			}

			var url = data.state.url();
				a = $('<a/>');

			if (!/^(mailto|https?)/.test(url)) {
				url = 'http://' + url;
			}

			if (data.state.type() === 'internal') {
				a.attr('data-koken-album-id', data.state.album());
				a.attr('data-koken-album-type', data.state.album_type());
				url = url.replace(/lightbox\/?$/, '');
				if (data.state.lightbox() && data.state.album_type() === 'standard') {
					a.attr('data-koken-lightbox', true);
					url += 'lightbox/';
				}
				if ($('#new_link_topic').is(':checked')) {
					this.sync({
						request: 'text/' + this.observers.page_to_edit.id() + '/topics/' + data.state.album(),
						method: 'POST',
						context: this,
						finished: function(data) {
							this.events._map_page_to_edit(data);
						}
					});
				}
			}

			a.attr('href', url);

			if (data.state.new_window()) {
				a.attr('target', '_blank');
			} else {
				a.attr('target', null);
			}

			var l = this.observers.link;

			l.redactor.restoreSelection();

			if (l.replace) {
				a.text($(l.replace).text());
				$(l.replace).replaceWith(a);
			} else {
				a.text( l.redactor.getSelectedHtml() );
				$('#edit-area').insertHtml( $('<div/>').append(a).html() );
			}

			this.events.toggle_sheet(el, parent);

			$('#edit-area').trigger('keyup');
		},

		edit_page: function(el) {
			var id = el.parents('li').data('id');
			this.controllers.rewrite_hash({ name: 'selection', val: id });
		},

		sidebar: function(el,parent) {

			var id = el.data('drawer');

			if (id) {

				switch(id) {

					case 'edit_categories' :
						this.observers.drawer.title('Edit categories');
						break;

					case 'edit_tags' :
						this.observers.drawer.title('Edit tags');
						break;

					case 'edit_timestamp' :
						this.observers.drawer.title('Edit date published');
						break;

					case 'edit_created' :
						this.observers.drawer.title('Edit date created');
						break;

					case 'text_status_edit' :
						this.observers.drawer.title('Edit status');
						break;

					case 'site_link_edit':
						this.observers.drawer.title('Edit site link');
						break;
				}

				this.observers.drawer.template(id);
				this.events.open_sidebar_drawer(el);

			}

		},

		open_sidebar_drawer: function(el) {

			if ( this.observers.drawer.state() === 'closed' ) {

				var	sidebar = $('#right-col-xtra'),
					self	= this;

				this.observers.drawer.state('open');

				if ( el.data('drawer') === 'edit_categories' ) {
					this.pubsub( 'category.save', function(e,data) {

						var catsToAdd = [],
							catsToDelete = [];

						$('div#category_edit_drawer').find('input:checkbox').each(function(i, item) {
							var id = parseInt($(item).closest('div[data-id]').data('id'), 10);

							if (!$(item).is(':visible') || isNaN(id)) return;

							if ($(item).is(':checked')) {
								$.inArray(id, catsToAdd) === -1 && catsToAdd.push(id);
							} else {
								$.inArray(id, catsToDelete) === -1 && catsToDelete.push(id);
							}
						}.bind(self));

						if (catsToDelete.length) {
							self.observers.selected_cats('Loading...');

							self.sync({
								request: 'categories/' + catsToDelete.join(',') + '/essays/' + self.observers.page_to_edit.id(),
								method: 'DELETE',
								context: self,
								finished: function() {
									if (!catsToAdd.length) this.observers.get_selected_cats();
								}
							});
						}

						if (catsToAdd.length) {
							self.observers.selected_cats('Loading...');

							self.sync({
								request: 'categories/' + catsToAdd.join(',') + '/essays/' + self.observers.page_to_edit.id(),
								method: 'PUT',
								context: self,
								finished: function() {
									this.observers.get_selected_cats();
								}
							});
						}

						self.events.close_sidebar_drawer();
					});

					var curr_cats = this.observers.selected_cats();
					$('#right-col-xtra').find('.col-row input').prop('checked',false);
					if ($.isArray(curr_cats)) {
						$.each(this.observers.categories(), function(i, cat) {
							$.each(curr_cats, function(j,cc) {
								if ( cc.id == cat.id ) {
									$('#right-col-xtra').find('.col-row:eq('+ i +') input').prop('checked',true);
								}
							});
						});
					}

				}

				if ( el.data('drawer') === 'edit_tags' ) {
					this.classes.autocomplete.text.tags($('#enterTagsInput input'), '#enterTagsInput', $('#tags_edit_drawer').find('button.create-tag'));
					this.pubsub( 'tags.delete', function(e, data) {
						self.sync({
							request: 'text/' + self.observers.page_to_edit.id(),
							context: self,
							finished: function(updatedData) {
								this.observers.page_to_edit.tags(updatedData.tags);
								this.observers.list().forEach(function(item) {
									item.tags = $.grep(item.tags, function(tag) {
										return tag.id !== data.id;
									})
								});
							}
						});
					});

					this.pubsub( 'tags.save', function(e,data) {

						var tags = [], s = this;
						data = data.data.split(',');

						$.each( data, function(j,w) {
							tags = s.models.tags.add( w, tags );
						});

						self.sync({
							request: 'text/' + self.observers.page_to_edit.id(),
							method: 'PUT',
							data: 'tags=' + tags.join(','),
							context: self,
							finished: function(data) {
								this.observers.page_to_edit.tags(data.tags);

								var el = $('#entry-' + data.id);
								if (el.length) {
									var index = el.data('index');
									this.observers.list.splice(index, 1, data);
								}
							}
						});

					});
				}

				if ( el.data('drawer') === 'edit_timestamp' ) {
					this.pubsub( 'timestamp.save', function(e,data) {

						var dt = data.trim(),
							ts = moment(dt).unix();

						var note = self.notify().wait('Changing timestamp');

						self.observers.page_to_edit.published_on.datetime(dt);
						self.observers.page_to_edit.published_on.timestamp(ts);
						self.observers.page_to_edit.published(true);

						self.sync({
							request: 'text/' + self.observers.page_to_edit.id(),
							method: 'PUT',
							data: 'published_on=' + ts,
							finished: function() {
								note.success('Date published changed to ' + dt)
							}
						});

					});
				}

				if ( el.data('drawer') === 'edit_created' ) {
					this.pubsub( 'timestamp_created.save', function(e,data) {

						var dt = data.trim(),
							ts = moment(dt).unix();

						var note = self.notify().wait('Changing timestamp');

						self.observers.page_to_edit.created_on.datetime(dt);
						self.observers.page_to_edit.created_on.timestamp(ts);
						self.observers.page_to_edit.published(true);

						self.sync({
							request: 'text/' + self.observers.page_to_edit.id(),
							method: 'PUT',
							data: 'created_on=' + ts,
							finished: function() {
								note.success('Date created changed to ' + dt)
							}
						});

					});
				}

				if (el.data('drawer') === 'site_link_edit') {
					var input = $('[data-submit="site_link"]').find('input[type="text"]'),
						hidden = $('[data-submit="site_link"]').find('input[type="hidden"]');

					input.val(hidden.val());

					input.off('keyup.slug').on('keyup.slug', function(e) {

						if (e.which === 13) {
							$(this).parents('[data-submit]').find('button').trigger('click');
							return;
						}

					    var start = this.selectionStart,
					    	end = this.selectionEnd;

						input.val(input.val().toLowerCase().replace(/\s/g, '-'));

						this.setSelectionRange(start, end);
					});
				}

				sidebar.show().animate({
					right: '0px'
				}, {
					easing: config.easing,
					complete: function() {
						$('#tags_edit_drawer input[placeholder="Enter tags"]').focus();
						$('#category_edit_drawer input[placeholder="Enter categories"]').focus();
						$('[data-submit="site_link"] input[type="text"]').focus();
					}
				});

				$(window).trigger('resize');

				$('input.field').off('keydown.drawer_e').on('keydown.drawer_e', function(e) {
					if ( e.keyCode === 13 ) {
						if ($(e.target).parents(['data-submit="site_link"']).length) return;

						// Fix for sheets losing ENTER priority
						if ($(e.target).closest('.ui-sheet').length >= 1) {
							return false;
						}
						if (!$(e.target).hasClass('autocompleting')) {
							$(e.target).parent().next().find('button').trigger('click');
							$(e.target).val('').focus();
						}
					}
				});

			}

		},

		save_and_close: function() {
			var btn = $('#right-col-data button:contains("Done")');
			if (btn.length) {
				btn.trigger('click');
			} else {
				this.events.close_sidebar_drawer();
			}
		},

		close_sidebar_drawer: function() {

			this.observers.drawer.state('closed');

			$('#right-col-xtra').animate({
				right: '-240px'
			}, {
				easing: config.easing,
				complete: function() {
					$(this).height(1);
				}
			});

		}

	},

	router: function() {

		return {

			'default': function(url) {

				if (!url) {
					$.bbq.pushState('/text/type:essay', 2);
					return;
				}

				this.observers.dropdowns.sort.active(false);
				this.observers.dropdowns.filter.active(false);

				url = 'text/' + url + '/render:0';

				var final_url = url,
					regex = /\/(selection|type|month|year|state|order_by|order_direction):([^\/]+)/g,
					non_api = [ "selection" ],
					matches,
					observers = {
						"selection": false,
						"type": 'essay',
						"year": false,
						"month": false,
						"state": 'none'
					},
					self = this,
					obj = {
						cb: function() {
							for (var i in observers) {
								if (i.indexOf('order_') === 0 && self.observers.is_sortable()) continue;
								self.observers[i]( observers[i] );
							}
						},
						load_until: false
					};

				this.observers.is_sortable(
					url.indexOf(':page') === -1 && url.indexOf('featured') === -1 && url.indexOf('year') === -1 && url.indexOf('drafts') === -1
				);

				var order_by = url.indexOf('text/featured') === 0 ? 'manual' : 'published_on';

				if (url.indexOf('drafts') !== -1) {
					order_by = 'modified_on';
				}

				this.observers.order_by(order_by);
				this.observers.order_direction('desc');

				while( (matches = regex.exec(url)) !== null) {

					var ob = matches[1],
						val = matches[2];

					if (ob === 'selection') {
						obj.load_until = Number(val);
					}

					if ($.inArray(ob, non_api) !== -1) {
						final_url = final_url.replace(matches[0], '');
					}

					observers[ob] = val;

				}

				this.observers.firstPageLoad(true);

				if (this.observers.editor_on()) {
					this.events.toggle_editor();
				}

				if ( this.observers.api_url() !== final_url ) {
					this.observers.loading(true);
					this.observers.next_page = false;
					$('#entries').empty();

					if (final_url.indexOf('text/featured') !== -1) {
						this.controllers.featured_order();
					} else {
						$('#entries').hasClass('ui-sortable') && $('#entries').sortable('destroy');
					}

					this.observers.api_url( final_url );
					this.controllers.load_text(obj);
					this.events.close_sidebar_drawer();
				} else {
					obj.cb();
				}

				// When the route changes we need to reset the shortcode_media sheet otherwise thumbnails will get wacky
				if (window.location.hash.indexOf('selection') == -1) {
					// Force the shortcode_media template to reload
					this.observers.sheets.shortcode_media.id.valueHasMutated();
					// Reset for the sheet dropdowns
					this.observers.sheet_media_chosen.primary('all_content');
					this.observers.sheet_media_chosen.secondary('0');
				}

			}
		};

	},

	views: {
		close_sidebar_drawer: function() {
			this.events.close_sidebar_drawer()
		}
	},

	observers: function() {

		var cookies = {};

		cookies.theme = ( this.cookie('text:theme') === null ) ? 0 : this.helpers.clean( this.cookie('text:theme') );
		cookies.years = ( this.cookie('text:nav') === null ) ? [] : this.cookie('text:nav').split(',');
		cookies.grid  = ( this.cookie('text:view') === null ) ? 'rows' : this.cookie('text:view');

		$.each( cookies.years, function(k,v) {
			cookies.years[k] = parseInt(v,10);
		});

		return {
			list_index_to_delete: false,
			current_shortcode: ko.observable({}),
			current_shortcode_data: ko.observable([]),
			expand: ko.observable(false),
			theme: ko.observable(cookies.theme),
			is_loading: false,
			next_page: false,
			calendar_data: {},
			year: ko.observable(false),
			open_year: ko.observableArray(cookies.years),
			month: ko.observable(false),
			years: ko.observableArray([]),
			this_year: new Date().getFullYear(),
			this_month: new Date().getMonth()+1,
			state: ko.observable('none'),
			grid: ko.observable(cookies.grid),
			order_by: ko.observable('modified_on'),
			order_direction: ko.observable('desc'),
			isalbumset: ko.observable(false),
			last_edit: false,
			loading: ko.observable(true),
			selection: ko.observable(false),
			api_url: ko.observable(''),
			lastexeccommand: ko.observable(''),
			total: ko.observable(false),
			view: ko.observable('list'),
			type: ko.observable('essay'),
			list: ko.observableArray( [] ),
			drafts: ko.observableArray( [] ),
			hasChanges: ko.observable(false),
			firstPageLoad: ko.observable(true),
			editor_type: ko.observable('dock'),
			editor_on: ko.observable(false),
			text_edit_full: ko.observable(false),
			is_autosaving: ko.observable(false),
			is_sortable: ko.observable(false),
			getSelectSpacing: function(index, text) {
				if (!index) return text;
				var _spacer = '&nbsp&nbsp;',
					_out = '';
				for (var i = 0; i < index; i++) {
					_out += _spacer;
				}
				return _out + text;
			},
			sheet_media_chosen: {
				primary: ko.observable('all_content'),
				secondary: ko.observable('0')
			},
			selection_info: {
				selection: {},
				range: {}
			},
			to_replace: false,
			link: {
				redactor: false,
				noob: false
			},
			drawer: {
				template: ko.observable('default_edit'),
				state: ko.observable('closed'),
				title: ko.observable('')
			},
			is_topic: function(id) {
				var isTopic = false;
				if ($.isArray(this.selected_topics())) {
					$.each(this.selected_topics(), function(idx, item) {
						if (item.id == id) isTopic = true;
					});
				}
				return isTopic;
			},
			year_open: function(year) {
				if ( ! this.year() ) { return false; }
				if ( this.year() == year ) { return true; }
				if ( this.open_year.indexOf(year) >= 0 ) { return true; }
			},
			hasEditedItems: function() {
				var hasEdits = false;
				$.each(this.interfaces.text.observers.list(), function(i, item) {
					if (item.published && item.content !== item.draft) {
						hasEdits = true;
						return false;
					}
				});
				return hasEdits;
			},
			selected_topics: ko.observable([]),
			get_selected_topics: function() {
				this.selected_topics('Loading...');
				this.sync({
					request: this.observers.page_to_edit.topics.url(),
					context: this,
					finished: function(data) {
						if (data.albums.length) {
							this.selected_topics(data.albums);
						} else {
							this.selected_topics('- none -');
						}
					}
				});
			},
			selected_cats: ko.observable([]),
			get_selected_cats: function() {
				this.selected_cats('Loading...');
				this.sync({
					request: this.observers.page_to_edit.categories.url(),
					context: this,
					finished: function(data) {
						if (data.categories.length) {
							this.selected_cats(data.categories);
						} else {
							this.selected_cats('- none -');
						}
					}
				});
			},
			autosave_timeout_id: false,
			autosave_text_id: false,
			page_to_edit: {
				__loaded: ko.observable(false),
				title: ko.observable(''),
				draft_title: ko.observable(''),
				excerpt: ko.observable(''),
				published: ko.observable(false),
				modified_on: {
					timestamp: ko.observable(0)
				},
				topics: ko.observableArray([]),
				__featured_image: ko.observable(false),
				__topic_str: ko.observable(''),
				__topic_ids: ko.observableArray([])
			},
			dropdowns: {
				sort: {
					items: ko.observableArray([
						{
							title	: 'Manual',
							bind	: "visible: api_url().indexOf('text/featured') === 0, css: { check: order_by() === 'manual' }, click: function() { change_order_by('manual'); }"
						},
						{
							title	: 'Date created',
							bind	: "css: { check: order_by() === 'created_on' }, click: function() { change_order_by('created_on'); }"
						},
						{
							title	: 'Date modified',
							bind	: "css: { check: order_by() === 'modified_on' }, click: function() { change_order_by('modified_on'); }"
						},
						{
							title	: 'Date published',
							bind	: "css: { check: order_by() === 'published_on' }, click: function() { change_order_by('published_on'); }"
						},
						{
							title	: 'Title',
							bind	: "css: { check: order_by() === 'title' }, click: function() { change_order_by('title'); }"
						},
						{
							title	: 'insert_break'
						},
						{
							title	: 'Ascending',
							desc	: 'Sort results ascending',
							bind	: "visible: order_by() !== 'manual', css: { check: order_direction() === 'asc' }, click: function() { change_order_direction('asc'); }"
						},
						{
							title	: 'Descending',
							desc	: 'Sort results descending',
							bind	: "visible: order_by() !== 'manual', css: { check: order_direction() === 'desc' }, click: function() { change_order_direction('desc'); }"
						}
					]),
					id: ko.observable('sort'),
					active: ko.observable(false),
					offset: '42px',
					position: ko.observable('above'),
					wedge: {
						offset: '10px'
					}
				},
				filter: {
					items: ko.observableArray([
						{
							title : 'None',
							bind  : "css: { check: state() === 'none' }, click: function() { state('none'); }"
						},
						{
							title : 'Drafts',
							bind  : "css: { check: state() === 'draft' }, click: function() { state('draft'); }"
						}
					]),
					id: ko.observable('filter'),
					active: ko.observable(false),
					offset: '40px',
					wedge: {
						offset: '15px'
					}
				}
			},
			sheets: {
				insert_link: {
					active: ko.observable(false),
					id: ko.observable('insert_link'),
					state: {
						type: ko.observable('external'),
						url: ko.observable(''),
						album: ko.observable(false),
						album_type: ko.observable('standard'),
						new_window: ko.observable(false),
						lightbox: ko.observable(false),
						existing: ko.observable(false)
					},
					_reset: function() {
						this.state.type('external');
						this.state.album_type('standard');
						for ( var k in this.state ) {
							if ( k !== 'type' && k !== 'album_type' ) {
								this.state[k].reset();
							}
						}
					},
					onload: function(el) {
						el.find('input[type="text"]').off('keydown').on( 'keydown', function(e) {
							if ( e.keyCode == 13 ) {
								el.find('button[data-event="insert_link"]').trigger('click');
							}
						});
					}
				},
				create_text: {
					active: ko.observable(false),
					id: ko.observable('create_text'),
					type: ko.observable('essay'),
					title: ko.observable(''),
					wasCreated: ko.observable(false),
					onload: function(el) {

						var self = this;

						el.find('input').focus();

						$(document,el)
							.off('.textSheet')
							.on('click.textSheet', '.sheet-opt-types li', function() {
								self.classes.app.koken.lastFocused && self.classes.app.koken.lastFocused.focus();
							});

						el.find( ($('#text-lcol .label-custompage').closest('li').hasClass('selected')) ? '.label-custompage' : '.label-essays' ).closest('li').trigger('click');

					}
				},
				shortcode_media: {
					type: ko.observable('photo'),
					active: ko.observable(false),
					id: ko.observable('shortcode_media'),
					loaded: ko.observable(false),
					sheet_loaded: ko.observable(false),
					editing: ko.observable(false),
					isediting: ko.observable(false),
					isreplacing: ko.observable(false),
					albums: ko.observable([]),
					album: ko.observable(0),
					custom_url: ko.observable(''),
					link: ko.observable('false'),
					title: ko.observable(false),
					caption: ko.observable(false),
					selected: {
						view: ko.observable('')
					},
					onload: function(el) {

						$('#view_select').find( '.' + this.observers.sheet_media_chosen.primary() ).attr('selected','selected');
						$('#view_select').trigger('change');
						$('#media-browse-content').off().on( 'dblclick', 'li', function() {
							$('button[data-event="insert_media"]').trigger('click');
						});

						$('#shortcode_media h1').text($('#shortcode_media h1').text().replace('Edit', 'Insert'));
						$('#album_select').find('[data-id="' + this.observers.sheet_media_chosen.secondary() + '"]').attr('selected','selected');

					}
				},
				shortcode_custom: {
					active: ko.observable(false),
					id: ko.observable('shortcode_custom'),
					data: ko.observable({}),
					fields: ko.observableArray([]),
					editing: ko.observable(false),
					onload: function() {
						var self = this;

						$(document).off('.custom-fields').on('change.custom-fields', 'select.fields-data', function() {
							var data = self.observers.current_shortcode_data();
							self.observers.current_shortcode_data([]);
							self.observers.current_shortcode_data(data);
						});
					},
					onunload: function() {
						$(document).off('.custom-fields');
					}
				},
				shortcode_collection: {
					active: ko.observable(false),
					id: ko.observable('shortcode_collection'),
					favorites: ko.observableArray([]),
					quick_collection: ko.observableArray([]),
					last_upload: ko.observableArray([]),
					state: {
						selected: ko.observable(''),
						selected_text: ko.observable('')
					}
				},
				shortcode_edit: {
					active: ko.observable(false),
					id: ko.observable('shortcode_edit'),
					selected: ko.observable(''),
					link: ko.observable('false'),
					album: ko.observable(0),
					custom_url: ko.observable(''),
					albums: ko.observableArray([]),
					onload: function() {
						var self = this,
							hasChanged = false;
						$('#shortcode_edit select').off('.shortcode_edit').on('change.shortcode_edit', function() {
							hasChanged = true;
						});
						$('#shortcode_edit input').off('.shortcode_edit').on('keyup.shortcode_edit', function() {
							hasChanged = true;
						});
						$('button[data-event="update_shortcode"]').off('.shortcode_edit').on('click.shortcode_edit', function() {
							if (hasChanged) { self.notify().success('Media settings successfully assigned'); }
						});

						$(document).off('.custom-fields').on('change.custom-fields', 'select.fields-data', function() {
							var data = self.observers.current_shortcode_data();
							self.observers.current_shortcode_data([]);
							self.observers.current_shortcode_data(data);
						});
					},
					onunload: function() {
						$(document).off('.custom-fields');
					}
				},
				embed_item: {
					active: ko.observable(false),
					id: ko.observable('embed_item')
				},
				shortcode_upload: {
					active: ko.observable(false),
					id: ko.observable('shortcode_upload'),
					state: ko.observable('off'),
					filename: ko.observable(''),
					title: ko.observable(''),
					caption: ko.observable(''),
					link: ko.observable(''),
					new_window: ko.observable(false),
					reset: function() {
						$('#shortcode_upload .section-content').hide();
					},
					_reset: function() {
						this.state('off');
						this.title('');
						this.caption('');
						this.link('');
						this.new_window(false);
					}
				},
				add_topic: {
					active: ko.observable(false),
					id: ko.observable('add_topic'),
					onload: function(sheet) {
						var self = this,
							hasBeenAltered = false;

						sheet
							.find('a[data-event="toggle_topic"]')
							.off('.atns')
							.on('click.atns', function() {
								hasBeenAltered = true;
							});

						sheet
							.find('button[data-sheet="add_topic"]')
							.off('.atns')
							.on('click.atns', function() {
								if (hasBeenAltered && self.observers.page_to_edit.published()) self.notify().success('"' + self.observers.page_to_edit.title() + '" has been published');
							});
					}
				}
			}

		};

	}

};

	// /___INTERFACES___

	// Kick things off..
	__k = _Koken();

	window.Koken = __Koken();

})();